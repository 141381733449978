/** 
* 自动生成文件，请勿修改 
* @name: 红河州地灾-短信记录管理
* @description: Warning Message Controller
* @create: 2024/11/8 10:34:25
* @swagger: 2.0
* 合计:14条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const params = { 
	backTask : null, 
	checkSendHistory : [{"cantonId":{"type":"string","description":"所属行政区id(乡镇id)"},"isNowSend":{"type":"boolean","description":"是否立即发送,true:立即发送就不传时间sendTime，false就要传发送时间"},"sendTime":{"type":"string","description":"短信发送时间，格式为yyyy-MM-dd HH:mm,且分钟只能是每5分钟一个选项如00、05、10、15.。。。。。"}}], 
	createCrowds : null, 
	createWhite : null, 
	exportExcel : null, 
	getCrowd : null, 
	queryAll : [{"cantonIds":{"type":"string","description":"所属行政区id(乡镇id) 多个使用英文逗号隔开"},"easyOccurAreaIds":{"type":"string","description":"易发分区ids，多个使用英文逗号隔开"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"sendDate":{"type":"string","description":"短信发送日期"},"sent":{"type":"boolean","description":"发送状态，t已发送，f未发送"},"source":{"type":"integer","format":"int32","description":"数据来源，0：自动发送预警: 1：手动划范围发短信，2：根据历史气象数据发送"},"successFlag":{"type":"boolean","description":"是否发送成功，在创建客群或者创建短信任务时会失败的话就是false"},"warningLevel":{"type":"string","description":"预警级别（蓝色预警、黄色预警、橙色预警、红色预警）：warning_level"}}], 
	queryOne : null, 
	queryPage : [{"cantonIds":{"type":"string","description":"所属行政区id(乡镇id) 多个使用英文逗号隔开"},"easyOccurAreaIds":{"type":"string","description":"易发分区ids，多个使用英文逗号隔开"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"sendDate":{"type":"string","description":"短信发送日期"},"sent":{"type":"boolean","description":"发送状态，t已发送，f未发送"},"source":{"type":"integer","format":"int32","description":"数据来源，0：自动发送预警: 1：手动划范围发短信，2：根据历史气象数据发送"},"successFlag":{"type":"boolean","description":"是否发送成功，在创建客群或者创建短信任务时会失败的话就是false"},"warningLevel":{"type":"string","description":"预警级别（蓝色预警、黄色预警、橙色预警、红色预警）：warning_level"}}], 
	sendByHistory : [{"cantonId":{"type":"string","description":"所属行政区id(乡镇id)"},"content":{"type":"string","description":"短信内容"},"isNowSend":{"type":"boolean","description":"是否立即发送,true:立即发送就不传时间sendTime，false就要传发送时间"},"sendTime":{"type":"string","description":"短信发送时间，格式为yyyy-MM-dd HH:mm,且分钟只能是每5分钟一个选项如00、05、10、15.。。。。。"}}], 
	sendByManual : [{"list":{"type":"array","items":{"$ref":"#/definitions/手动绘制范围发送短信参数对象","originalRef":"手动绘制范围发送短信参数对象"}}}], 
	sendByManual111 : [{"list":{"type":"array","items":{"$ref":"#/definitions/手动绘制范围发送短信参数对象","originalRef":"手动绘制范围发送短信参数对象"}}}], 
	smsTaskCount : null, 
	smsTaskStatus : null, 
};
const message = { 
	/** 1 summary:撤回短信任务	method:post */ 
	backTask : new APIObject.postObject("/warning/message/backTask",params.backTask), 
	/** 2 summary:根据历史数据发送短信前验证	method:post */ 
	checkSendHistory : new APIObject.postObject("/warning/message/checkSendHistory",params.checkSendHistory), 
	/** 3 summary:创建静态客群	method:post */ 
	createCrowds : new APIObject.postObject("/warning/message/createCrowds",params.createCrowds), 
	/** 4 summary:上传白名单	method:post */ 
	createWhite : new APIObject.postObject("/warning/message/createWhite",params.createWhite), 
	/** 5 summary:导出短信发送记录	method:get */ 
	exportExcel : new APIObject.getObject("/warning/message/exportExcel",params.exportExcel), 
	/** 6 summary:查看客群信息	method:post */ 
	getCrowd : new APIObject.postObject("/warning/message/getCrowd",params.getCrowd), 
	/** 7 summary:查询所有	method:post */ 
	queryAll : new APIObject.postObject("/warning/message/queryAll",params.queryAll), 
	/** 8 summary:查询单条	method:get */ 
	queryOne : new APIObject.getObject("/warning/message/queryOne",params.queryOne), 
	/** 9 summary:分页查询	method:post */ 
	queryPage : new APIObject.postObject("/warning/message/queryPage",params.queryPage), 
	/** 10 summary:根据历史数据发送短信	method:post */ 
	sendByHistory : new APIObject.postObject("/warning/message/sendByHistory",params.sendByHistory), 
	/** 11 summary:手动绘制区域发送短信	method:post */ 
	sendByManual : new APIObject.postObject("/warning/message/sendByManual",params.sendByManual), 
	/** 12 summary:手动绘制区域发送短信-手动测试（增加白名单（15761635391）限额1人）	method:post */ 
	sendByManual111 : new APIObject.postObject("/warning/message/sendByManual111",params.sendByManual111), 
	/** 13 summary:查看短信任发送成功条数	method:post */ 
	smsTaskCount : new APIObject.postObject("/warning/message/smsTaskCount",params.smsTaskCount), 
	/** 14 summary:查看短信任务执行状态	method:post */ 
	smsTaskStatus : new APIObject.postObject("/warning/message/smsTaskStatus",params.smsTaskStatus), 
};
export default message;