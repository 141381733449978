<template>
  <a-config-provider :locale="locale">
    <router-view > </router-view>
  </a-config-provider>
</template>

<script>

import zhCN from "ant-design-vue/es/locale/zh_CN";

export default {
  name: 'App',
  data(){
    return{
      locale:zhCN,
    }
  }

}
</script>

<style>
  html,
  body,
  #app {
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  .content-title1,.content-title3{
    background: linear-gradient(90deg, rgb(61, 143, 208) 0%, rgba(43, 101, 147, 0) 100%)!important;
    border-left: 2px solid rgb(70, 181, 255)!important;
  }
  .foot-but-bjtb{
    border: 1px solid rgb(70, 181, 255)!important;
    background: linear-gradient(90deg, rgb(49,117,185) 0%, rgb(73,164,210) 100%)!important;
  }
  aside,header{
    /*display: none!important;*/
  }
  .ant-btn:hover{
    background-color: #00a2ff!important;
    border-color: #3dd0ff!important;
  }
  .no-bg{
    background: unset!important;
  }
  .ant-input-number-input-wrap{
    background: unset!important;
    border: unset!important;
  }
  .ant-table-tbody > tr.ant-table-row-selected > td{
    background: #0b5cad!important;
  }
  .detail-header{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px!important;
    font-weight: normal!important;
  }
  .fgx{
    width: 100%!important;
    margin: 0px!important;
  }
  .flex{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .ant-input:hover{
    border-color: unset!important;
  }
  .h100{
    height: 100%!important;
  }
  .fc{
    flex-direction: column;
  }
  .ant-input-number-disabled{
    background: unset!important;
    border: 1px solid #082d4f!important;
  }
  .ant-input-number{
    background: unset!important;
    border: 1px solid #082d4f!important;
  }
  .ant-btn-primary{
    background: linear-gradient(90deg, #0974BD 0%, #0CA6D7 100%)!important;
  }
  .bounced-title{
    position: relative;
    font-size: 16px!important;
    height: 100%!important;
    padding: 0px!important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bounced-gb{
    position: relative;
    margin-top:-28px!important;
    margin-left:91%!important;
  }
  .ant-form-item-control-input{
    min-height: unset!important;
  }
  .ant-form-item-label > label{
    height: unset!important;
  }
</style>
