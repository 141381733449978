/** 
* 自动生成文件，请勿修改 
* @name: 系统-图层管理
* @description: Layer Info Controller
* @create: 2024/11/8 10:34:25
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const params = { 
	add : [{"access":{"type":"boolean","description":"是否允许查询","required":false},"dataType":{"type":"string","description":"图层数据类型，type为1时，不填写；字典集code：sys_layer_data_type，1.1--影像，2.1--土地利用现状图，2.2--林业保护","required":false},"display":{"type":"boolean","description":"是否默认显示","required":false},"districtControl":{"type":"string","description":"辖区权限控制","required":false},"id":{"type":"string","description":"主键id","required":false},"layer":{"type":"string","description":"图层","required":false},"level":{"type":"integer","format":"int32","description":"优先级，值越大，优先级越高","required":false},"name":{"type":"string","description":"图层名称","required":true},"pid":{"type":"string","description":"父级id","required":false},"remarks":{"type":"string","description":"备注","required":false},"type":{"type":"string","description":"图层类型：1-目录、2-矢量、3-栅格、4-cesiumLab切片数据","required":true},"version":{"type":"string","description":"数据版本号，显示图层的在cql中增加版本查询","required":false},"workspace":{"type":"string","description":"工作区","required":false},"year":{"type":"string","description":"年度，type为1时，不填写","required":false}}], 
	del : null, 
	edit : [{"access":{"type":"boolean","description":"是否允许查询","required":false},"dataType":{"type":"string","description":"图层数据类型，type为1时，不填写；字典集code：sys_layer_data_type，1.1--影像，2.1--土地利用现状图，2.2--林业保护","required":false},"display":{"type":"boolean","description":"是否默认显示","required":false},"districtControl":{"type":"string","description":"辖区权限控制","required":false},"id":{"type":"string","description":"主键id","required":false},"layer":{"type":"string","description":"图层","required":false},"level":{"type":"integer","format":"int32","description":"优先级，值越大，优先级越高","required":false},"name":{"type":"string","description":"图层名称","required":true},"pid":{"type":"string","description":"父级id","required":false},"remarks":{"type":"string","description":"备注","required":false},"type":{"type":"string","description":"图层类型：1-目录、2-矢量、3-栅格、4-cesiumLab切片数据","required":true},"version":{"type":"string","description":"数据版本号，显示图层的在cql中增加版本查询","required":false},"workspace":{"type":"string","description":"工作区","required":false},"year":{"type":"string","description":"年度，type为1时，不填写","required":false}}], 
	queryAll : [{"name":{"type":"string","description":"图层名称"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"pid":{"type":"string","description":"父级id,为空时，查询顶级图层"}}], 
	queryAllTimeAxis : null, 
	queryOne : null, 
	queryPage : [{"name":{"type":"string","description":"图层名称"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"pid":{"type":"string","description":"父级id,为空时，查询顶级图层"}}], 
};
const layerInfo = { 
	/** 1 summary:添加图层	method:post */ 
	add : new APIObject.postObject("/sys/layerInfo/add",params.add), 
	/** 2 summary:删除图层（单条）	method:delete */ 
	del : new APIObject.deleteObject("/sys/layerInfo/del",params.del), 
	/** 3 summary:编辑图层	method:post */ 
	edit : new APIObject.postObject("/sys/layerInfo/edit",params.edit), 
	/** 4 summary:查询-不分页	method:post */ 
	queryAll : new APIObject.postObject("/sys/layerInfo/queryAll",params.queryAll), 
	/** 5 summary:查询-查询图层时间轴	method:post */ 
	queryAllTimeAxis : new APIObject.postObject("/sys/layerInfo/queryAllTimeAxis",params.queryAllTimeAxis), 
	/** 6 summary:查询-单条	method:get */ 
	queryOne : new APIObject.getObject("/sys/layerInfo/queryOne",params.queryOne), 
	/** 7 summary:查询-分页	method:post */ 
	queryPage : new APIObject.postObject("/sys/layerInfo/queryPage",params.queryPage), 
};
export default layerInfo;