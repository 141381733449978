<!--
* @program: 一张图
* @author: ly
* @component:MainMap
* @description:
* @create: 2022-12-08 14:07
-->
<template>
    <div style="width: 100%;height: 100%;display: flex;" id="map">
        <map-tool-bar ref="mapToolBar"/>
        <map-toggle-bar/>
        <!-- 图层管理 -->
        <layer-manage_map ref="layerManageMap"/>

        <a-picture-data ref="aPictureData"/>
        <note-send ref="note_send"/>
        <div id="popup" class="ol-popup" v-show="showTooltopStation">
            <img id="popup-closer" class="ol-popup-closer" @click="removePointLayer()"
                 :src="require('@/assets/image/busines/关闭.png')">
            <div class="tip-tits">{{tooltipTitle}}</div>
            <div id="popup-content"></div>
            <div class="inp-r" v-if="isStation">
                <a-input-number id="inputNumber" v-model:value="stationR" placeholder="范围半径(km)" :min="0"/>
                <a-button type="primary" size="small" style="height: 30px" @click="findByR">查询</a-button>
            </div>
        </div>
        <div class="tulie" v-if="tuLie.length>0">
            <div v-for="(mm,m) in tuLie" :key="m">
                <div style="margin: 6px 0">{{mm.title}}</div>
                <div v-for="(im,i) in mm.children" :key="i">
                    <span v-if="im.color" class="cycle" :style="{background:im.color}"></span>
                    <img v-if="im.img" width="20" height="20" :src="im.img" alt="">
                    <span>{{im.title}}</span>
                </div>
            </div>
        </div>
    </div>
    <mapMenus @updateLayer="updateLayer"></mapMenus>
    <!--        导入数据界面-->
    <shp-upload ref="shpUpload"/>
    <mask-send ref="maskSend"/>

</template>

<script>
    import './config/ol.css'
    import Map from 'ol/Map'
    import View from 'ol/View'
    import {OSM, Vector as VectorSource, XYZ, WMTS, ImageWMS} from 'ol/source'
    import {Tile as TileLayer, Vector as VectorLayer, Image} from 'ol/layer'
    import Feature from 'ol/Feature'
    import {fromLonLat, get as getProjection, Projection, transform, getTransform, transformExtent} from 'ol/proj'
    import Draw from 'ol/interaction/Draw';
    import {Style, Fill, Stroke, Circle as CircleStyle, Text, Icon} from 'ol/style'
    import Overlay from 'ol/Overlay'
    import {LineString, Point, Polygon, MultiPolygon, Circle} from "ol/geom";
    import {unByKey} from 'ol/Observable';
    import {defaults as defaultInteractions, Select} from 'ol/interaction';
    import {fromCircle} from 'ol/geom/Polygon';
    import {
        getWidth,
        getTopLeft,
        getCenter,
        createOrUpdateFromCoordinates,
        getBottomLeft,
        getTopRight,
        scaleFromCenter
    } from 'ol/extent'
    import WMTSTileGrid from 'ol/tilegrid/WMTS'

    import mapToolBar from "@/page/Map/Apicture/mapToolBar";
    import MapSettings from "@/page/Map/config/MapSettings";
    import layerManage_map from "@/page/Map/Apicture/layerManage_map";
    import mapToggleBar from "@/page/Map/Apicture/mapToggleBar";
    import picture from "@/assets/api/picture";
    import Canton from "@/assets/api/Canton";
    import shpUpload from "@/page/common/shpUpload";
    import WKT from "ol/format/WKT";
    import layerInfo from "@/assets/api/layerInfo";
    import layerRole from "@/assets/api/layerRole";
    import aPictureData from "@/page/Map/Apicture/aPictureData";
    import noteSend from "@/page/Map/Apicture/noteSend";
    import maskSend from "@/page/Map/Apicture/maskSend";
    import mapMenus from "@/page/Map/Apicture/mapMenus";
    import _default from "@/assets/api/_default";
    import geologyMonitor from "@/assets/api/geologyMonitor";
    import floodMonitor from "@/assets/api/floodMonitor";
    import rainfallMonitor from "@/assets/api/rainfallMonitor";

    export default {
        name: "ApictureMainMap",
        emits: ["map", "mapMethods"],
        components: {
            mapToolBar, mapToggleBar, aPictureData,
            layerManage_map, shpUpload, noteSend, maskSend, mapMenus
        },
        data() {
            return {
                visible: true,
                map: "uninitialized",
                coordinate: [102.91, 23.38],
                zoom: 8.6,
                mapObject: null,
                layers: [],
                layer_wkt: [],

                /** 测量 **/
                vector_draw: null,
                draw: null,// 定义一个交互式绘图对象
                listener: null,//定义一个事件监听
                count: 0, //定义一个控制鼠标点击次数的变量

                helpTooltipElement: null,//创建一个帮助提示框对象
                helpTooltip: null,//创建一个帮助提示信息对象
                measureTooltipElement: null,//创建一个测量提示框对象
                measureTooltip: null,//创建一个测量提示信息对象
                continuePolygonMsg: null,//继续绘制多边形的提示信息
                continueLineMsg: null,//继续绘制线段的提示信息


                drawCoordinateArr: [],// 已绘制的坐标集合
                deleteLineArea: require("@/page/Map/images/距离-悬停.png"),
                lengthMeasureCount: 0,// 绘制次数
                /** 测量 **/

                /**** 图层管理 start ****/
                layer_callout: null,// 标注
                layer_vector: null,// 矢量
                layer_image: null,// 影像
                layer_terrain: null,// 地形

                geoUrl: MapSettings.geoserver_url,
                layer_list: [],
                layerMage: [],// 图层管理
                checkedKeys: [],
                layerType: '1',// 1：图层管理；2：图层点击查询
                /**** 图层管理 end ****/

                highlightLayer: null,// 高亮图层

                vectorLayer: null,
                layer_weather: [],
                layer_area: [],
                layer_disaster: [],
                layer_cantons: [],

                cql_parms: "",
                cql_xzq_parms: "",
                cql_jz_parms: "",
                weatherData: [],//天气数据
                findWkt: null,
                stationTooltip: null,
                pointLayer: null, //所有点层,
                stationR: null,//基站半径画圆
                showTooltopStation: false,
                circleLayer: null,
                selectStation: null,
                isStation: false,
                selectPoint: null,
                hightPoint: null,
                tuLie: [],//图列
                tree_list1: [],
                list_info: '',
                tooltipTitle: ''
            }
        },
        mounted: function () {
            var projection = getProjection('EPSG:4326');// 4326
            var projectionExtent = projection.getExtent();
            var size = getWidth(projectionExtent) / 256;
            var resolutions = new Array(18);
            var matrixIds = new Array(18);
            for (var z = 1; z < 19; ++z) {
                resolutions[z] = size / Math.pow(2, z);
                matrixIds[z] = z;
            }

            this.layer_callout = new TileLayer({
                name: "天地图注记图层",
                source: new WMTS({
                    url: MapSettings.wmtsUrl_bz,
                    layer: 'cva',
                    matrixSet: 'c',
                    format: 'tiles',
                    style: 'default',
                    projection: projection,
                    tileGrid: new WMTSTileGrid({
                        origin: getTopLeft(projectionExtent),
                        resolutions: resolutions,
                        matrixIds: matrixIds
                    }),
                    wrapX: true
                })
            });
            this.layer_vector = new TileLayer({
                name: "天地图矢量图层",
                opacity: 0.7,
                source: new WMTS({
                    crossOrigin: "anonymous",
                    url: MapSettings.wmtsUrl_sl,
                    layer: 'cia',
                    matrixSet: 'c',
                    format: 'tiles',
                    style: 'default',
                    projection: projection,
                    tileGrid: new WMTSTileGrid({
                        origin: getTopLeft(projectionExtent),
                        resolutions: resolutions,
                        matrixIds: matrixIds
                    }),
                    wrapX: true
                })
            });
            this.layer_image = new TileLayer({
                name: "天地图影像图层",
                opacity: 0.7,
                source: new WMTS({
                    url: MapSettings.wmtsUrl_yx,
                    layer: 'img',
                    matrixSet: 'c',
                    format: 'tiles',
                    style: 'default',
                    projection: projection,
                    tileGrid: new WMTSTileGrid({
                        origin: getTopLeft(projectionExtent),
                        resolutions: resolutions,
                        matrixIds: matrixIds
                    }),
                    wrapX: true
                }),
                visible: false,
            });
            this.layer_terrain = new TileLayer({
                name: "天地图地形图层",
                opacity: 0.7,
                source: new WMTS({
                    url: MapSettings.wmtsUrl_dx,
                    layer: 'ter',
                    matrixSet: 'c',
                    format: 'tiles',
                    style: 'default',
                    projection: projection,
                    tileGrid: new WMTSTileGrid({
                        origin: getTopLeft(projectionExtent),
                        resolutions: resolutions,
                        matrixIds: matrixIds
                    }),
                    wrapX: true
                }),
                visible: false,
            });
            // 视图
            let userType = JSON.parse(localStorage.getItem('userInfo'))?.roles[0].roleCode;
            var view = new View({
                center: fromLonLat(this.coordinate),
                zoom: userType == 'countyAdmin' ? this.zoom + 2 : this.zoom,
            });

            // 地图
            this.map = new Map({
                target: 'map',
                layers: [
                    this.layer_vector,
                    this.layer_image,
                    this.layer_terrain,
                    this.layer_callout,
                ],
                /**
                 * 让地图视图拥有动画效果：关键点loadTilesWhileAnimating、vie.animate;最基本效果：移动、旋转、缩放
                 * */
                loadTilesWhileAnimating: true,//将这个设置为true，默认为false
                view: view
            });
            this.$emit("mapMethods", this.map)
            this.queryWeather();
            this.$refs['mapToolBar'].list_img.forEach((item, index) => {
                this.$refs['mapToolBar'].list_img[index].img = item.leave_img;
                this.$refs['mapToolBar'].list_img[index].selected = false;
            })
        },
        created() {
            picture.queryNext.get(this, {}, res => {
                if (res.data.success) {
                    let list_info = res.data.result;
                    this.list_info = list_info
                    if (list_info.length > 0) {
                        let info = list_info[0];
                        localStorage.setItem('map_level', info.level)
                        let cql = '';
                        let cql_xzq = 'canton_id in( ';
                        if (info.level == '3') {// 区县
                            cql = 'pid in( ';
                            this.cql_jz_parms = 'county_id in( ';
                        } else if (info.level == '2') {// 乡镇
                            cql = 'canton_id in( ';
                            this.cql_jz_parms = 'town_id in( ';
                        }
                        list_info.forEach((item, index) => {
                            let areaId = item.id;
                            if (index == 0) {
                                cql += '\'' + areaId + '\'';
                                cql_xzq += '\'' + areaId + '\'';
                                this.cql_jz_parms += '\'' + areaId + '\'';
                            } else {
                                cql += ',' + '\'' + areaId + '\'';
                                cql_xzq += ',' + '\'' + areaId + '\'';
                                this.cql_jz_parms += ',' + '\'' + areaId + '\'';
                            }
                        })
                        cql += ' )';
                        cql_xzq += ' )';
                        this.cql_jz_parms += ' )';
                        this.cql_parms = cql;
                        this.cql_xzq_parms = cql_xzq;
                    }
                    this.add_layers();
                }
            });

        },
        methods: {
            /******** 基础 start **********/

            /***** 图层管理 start ******/
            /**
             * @Description: 添加图层：管理图层
             * @params:
             * @return:
             * @Author: rzl
             * @Date: 2022/4/12
             */
            add_layers() {
                this.layer_list = [];
                this.layers = [];
                this.layerMage = [];
                this.layerType = '1';
                layerRole.getLayerQueryTree.post(this, {}, res => {
                    if (res.data.success) {
                        let list_info = res.data.result;
                        if (list_info.length > 0) {
                            // 添加图层
                            this.tree_list1 = list_info
                            this.parse_layer(list_info);
                            // 获取树
                            let layerTree = this.parse_tree(list_info);
                            this.layer_list = layerTree;
                            this.layerMage = layerTree;
                            if (layerTree != null && layerTree.length > 0) {
                                // 初始化树控件
                                this.$refs.layerManageMap.initTree(this.map, this.layers, this.layerMage, this.layerType, this.checkedKeys);
                            }
                        }
                    }
                });
            },
            /** 添加图层 未用**/
            parse_layer(tree_list) {
                return
                /* eslint-disable */
                var projection = getProjection('EPSG:4326');// 4326
                var projectionExtent = projection.getExtent();
                var size = getWidth(projectionExtent) / 256;
                var resolutions = new Array(18);
                var matrixIds = new Array(18);
                for (var z = 1; z < 19; ++z) {
                    resolutions[z] = size / Math.pow(2, z);
                    matrixIds[z] = z;
                }

                let base_stationArr = [tree_list[0]]
                for (let i = 0; i < base_stationArr.length; i++) {
                    let info = base_stationArr[i];
                    let cql = "";
                    if (info.layer == 'honghezhou:canton') {
                        let isSuperAdminArr = JSON.parse(localStorage.getItem('userInfo')).roles.find((ee => ee.roleCode == 'superAdmin'))
                        cql = isSuperAdminArr ? '' : this.cql_xzq_parms;
                        return
                    } else if (info.layer == 'honghezhou:hh_base_station') {
                        cql = this.cql_jz_parms;
                        return
                    } else if (info.layer == 'honghezhou:easy_occur_area') {
                        cql = this.cql_xzq_parms;
                    } else if (info.layer == 'honghezhou:disaster_point') {
                        cql = this.cql_xzq_parms;
                    } else {
                        cql = this.cql_parms;
                    }
                    let layerInfo = null;
                    layerInfo = new Image({     //TileLayer、ImageLayer
                        name: info.name,
                        source: new ImageWMS({    //TileWMS、ImageWMS
                            ratio: 1,
                            params: {
                                /*  "token":localStorage.getItem("token"),*/
                                //'FORMAT': 'image/jpeg',   //如果加了这行，地图底图将会被遮住
                                'VERSION': '1.1.0',
                                'LAYERS': info.layer,// geoserver发布服务
                                'STYLES': '',
                                /* "exceptions": 'application/vnd.ogc.se_inimage',*/
                                'CQL_FILTER': cql == null || cql == '' ? null : cql,  // 图层属性参数绑定
                            },
                            url: this.geoUrl + "/" + info.workspace + "/wms",
                        }),
                        visible: info.display
                    })
                    this.layers.push(layerInfo);
                    this.map.addLayer(layerInfo);

                }
            },
            //图层加载子图层
            loadChildlLayer(info) {
                Object.keys(info).forEach(el => {
                    let layerInfo = null;
                    let layer = ''
                    let sqlArr = []
                    let layerName = ''
                    if (!info[el][0]) return
                    let oneInfo = info[el][0].node
                    info[el].forEach(ee => {
                        layer = ee.node.layer
                        if (ee.node.layer == 'honghezhou:easy_occur_area') {
                            if(!isNaN(Number(ee.pos.split('-')[2])+1)){
                                sqlArr.push(Number(ee.pos.split('-')[2])+1)
                            }
                        }
                        if (ee.node.layer == 'honghezhou:disaster_point') {
                            sqlArr.push(ee.node.name)
                        }
                        if (ee.node.layer == 'honghezhou:hh_disaster_2024') {
                            sqlArr.push(ee.node.name)
                        }
                    })
                    let map_level = localStorage.getItem('map_level')
                    let cql = "";
                    if (oneInfo.layer == 'honghezhou:canton') {
                        let xzq_cql = ''
                        layerName = '行政区'
                        if (oneInfo.version == "乡镇") {
                            if (map_level == '2') {
                                xzq_cql = 'canton_id in( ';
                            } else {
                                xzq_cql = 'pid in( ';
                            }
                        } else {
                            if (map_level == '2') {
                                xzq_cql = 'pid in( ';
                            } else {
                                xzq_cql = 'canton_id in( ';
                            }

                        }
                        let hasXzqAll = info[el].filter((el) => {
                            return el.node.name == "行政区划范围"
                        }).length > 0 ? true : false
                        if (hasXzqAll) {
                            if (map_level == '3') {
                                xzq_cql = 'pid in( ';
                            } else {
                                xzq_cql = 'canton_id in( ';
                            }
                        }

                        if (this.list_info) {
                            this.list_info.forEach((item, index) => {
                                let areaId = item.id;
                                if (index == 0) {
                                    xzq_cql += '\'' + areaId + '\'';
                                } else {
                                    xzq_cql += ',' + '\'' + areaId + '\'';
                                }
                            })
                        }
                        xzq_cql += ' )';
                        cql = xzq_cql
                        // if (info[el].filter(ec => ec.pos == '0-3-0' || ec.pos == '0-3-1').length == 2) {
                        //     cql = null
                        // }
                    } else if (oneInfo.layer == 'honghezhou:hh_base_station') {
                        layerName = '基站'
                        cql = this.cql_jz_parms;
                    } else if (oneInfo.layer == 'honghezhou:easy_occur_area') {
                        layerName = '易发分区1'
                        cql = `level in (${sqlArr.join(',')}) AND ` + this.cql_parms;
                        this.layers = this.layers.filter((el) => el.values_.name != '易发分区1')
                    } else if (oneInfo.layer == 'honghezhou:disaster_point') {
                        layerName = '地灾点数据1'
                        let queryStr = sqlArr.map(item => `'${item}'`).join(', ')
                        cql = `risk_level in (${queryStr}) AND ` + this.cql_parms;
                        this.layers = this.layers.filter((el) => el.values_.name != '地灾点数据1')
                    } else if (oneInfo.layer == 'honghezhou:hh_disaster_2024') {
                        layerName = '地灾点数据2024'
                        let queryStr = sqlArr.map(item => `'${item}'`).join(', ')
                        cql = `fxdj in (${queryStr}) AND ` + this.cql_parms.replace('pid','county');
                        this.layers = this.layers.filter((el) => el.values_.name != '地灾点数据2024')
                    } else {
                        layerName = oneInfo.name
                        cql = this.cql_parms.replace('pid', 'county_id');
                        this.layers = this.layers.filter((el) => el.values_.name != oneInfo.name)
                    }
                    layerInfo = new Image({     //TileLayer、ImageLayer
                        name: layerName,
                        source: new ImageWMS({    //TileWMS、ImageWMS
                            ratio: 1,
                            params: {
                                /*  "token":localStorage.getItem("token"),*/
                                //'FORMAT': 'image/jpeg',   //如果加了这行，地图底图将会被遮住
                                'VERSION': '1.1.0',
                                'LAYERS': layer,// geoserver发布服务
                                'STYLES': '',
                                /* "exceptions": 'application/vnd.ogc.se_inimage',*/
                                'CQL_FILTER': cql,  // 图层属性参数绑定
                            },
                            url: this.geoUrl + "/" + oneInfo.workspace + "/wms",
                        }),
                        visible: oneInfo.display
                    })

                    layerInfo.setZIndex(oneInfo.level)
                    this.layers.push(layerInfo);
                    this.layers = this.layers.sort((a, b)=> a.values_.zIndex - b.values_.zIndex)
                    this.map.addLayer(layerInfo);
                })

            },
            parse_tree(tree_list) {
                var returnData = [];
                for (let i = tree_list.length - 1; i >= 0; i--) {
                    let info = tree_list[i];
                    let childArr = [];
                    info.children.forEach(ee => {
                        childArr.push({
                            ...ee,
                            id: ee.id,
                            name: ee.name,
                            layer: ee.layer,
                            version: ee.version,
                            access: ee.access,
                            level: ee.level,
                            step: 4,//树子集标识

                        })
                    })
                    let tempObj = {
                        id: info.id,
                        name: info.name,
                        layer: info.layer,
                        version: info.version,
                        access: info.access,
                        level: info.level,
                        children: childArr,
                        ...info
                    }

                    if (info.type == "1") {
                        let list_info = info.children;
                        tempObj.slots = {
                            icon: 'ml',
                        };
                        tempObj.children = this.parse_tree(list_info);
                    } else {
                        if (info.type == '2') {
                            tempObj.slots = {
                                icon: 'sl',
                            };
                        } else if (info.type == '3') {
                            tempObj.slots = {
                                icon: 'sg',
                            };
                        }

                        if (info.display) {
                            this.checkedKeys.push(info.id);
                        }

                    }
                    returnData.push(tempObj);

                }
                return returnData;
            },
            /***** 图层管理 end ******/

            /***************** 测量:长度、面积 start ****************/
            mapTool_cd(arg) {
                this.remove_measurement();
                this.clChange("LineString");
            },
            mapTool_mj(arg) {
                this.remove_measurement();
                this.clChange("Polygon");
            },
            /** 绘制测量 **/
            clChange(type) {
                //定义矢量数据源
                var source = new VectorSource();
                this.vector_draw = new VectorLayer({
                    source: source,
                    style: new Style({
                        fill: new Fill({
                            color: 'rgba(239,227,18,0.8)',
                        }),
                        stroke: new Stroke({
                            color: '#ffcc33',
                            width: 2,
                        }),
                        image: new CircleStyle({
                            radius: 7,
                            fill: new Fill({
                                color: '#ffcc33',
                            }),
                        }),
                    }),
                });
                // 将矢量图层放入map
                this.map.addLayer(this.vector_draw);

                //创建一个当前要绘制的对象
                var sketch = new Feature();


                //创建一个交互式绘图对象
                this.draw = new Draw({
                    //绘制的数据源
                    source: source,
                    //绘制类型
                    type: type,
                    //样式
                    style: new Style({
                        fill: new Fill({
                            color: 'rgba(201,178,32,0.28)',
                        }),
                        stroke: new Stroke({
                            color: 'rgba(0, 0, 0, 0.5)',
                            lineDash: [10, 10],
                            width: 2,
                        }),
                        image: new CircleStyle({
                            radius: 5,
                            stroke: new Stroke({
                                color: 'rgba(0, 0, 0, 0.7)',
                            }),
                            fill: new Fill({
                                color: 'rgba(255, 255, 255, 0.2)',
                            }),
                        }),
                    }),
                });
                //将交互绘图对象添加到地图中
                this.map.addInteraction(this.draw);
                //创建测量提示框
                this.createMeasureTooltip();
                //创建帮助提示框
                this.createHelpTooltip();


                //绘制开始事件
                this.draw.on('drawstart', (evt) => {
                    document.removeEventListener('click', this.deleteClick)
                    //The feature being drawn.
                    sketch = evt.feature;
                    //提示框的坐标
                    var tooltipCoord = evt.coordinate;

                    this.listener = sketch.getGeometry().on('change', (evt) => {
                        //The event target.
                        //获取绘制的几何对象
                        var geom = evt.target;
                        //定义一个输出对象，用于记录面积和长度
                        var output;
                        var cl_value;

                        if (geom instanceof Polygon) {
                            this.map.removeEventListener('singleclick');
                            this.map.removeEventListener('dblclick');
                            this.geom = geom;
                            var area = geom.getArea();
                            if (area > 10000) {
                                output = (Math.round(area / 1000000 * 100) / 100) + ' ' + 'km<sup>2</sup>';
                            } else {
                                output = (Math.round(area * 100) / 100) + ' ' + 'm<sup>2</sup>';
                            }
                            //输出多边形的面积
                            //output = this.formatArea(geom);
                            cl_value = (Math.round(area * 100) / 100);
                            //获取多变形内部点的坐标
                            tooltipCoord = geom.getInteriorPoint().getCoordinates();


                        } else if (geom instanceof LineString) {
                            //定义长度变量，计算平面距离
                            var length = Math.round(geom.getLength() * 100) / 100;
                            if (length > 1000) {
                                output = (Math.round(length / 1000 * 100) / 100) + ' ' + 'km'; //换算成KM单位
                            } else {
                                output = (Math.round(length * 100) / 100) + ' ' + 'm'; //m为单位
                            }
                            //输出多线段的长度
                            output = this.formatLength(geom);
                            cl_value = (Math.round(length * 100) / 100);
                            this.geom = geom;
                            //获取多线段的最后一个点的坐标
                            tooltipCoord = geom.getLastCoordinate();
                        }

                        output = output + `<img class="deleteLine" src="${this.deleteLineArea}" style="width: 20px;padding-left: 5px;" />`

                        //设置测量提示框的内标签为最终输出结果
                        this.measureTooltipElement.innerHTML = output;
                        this.setToolStyle(this.measureTooltipElement)
                        //设置测量提示信息的位置坐标
                        this.measureTooltip.setPosition(tooltipCoord);
                    });

                    //地图单击事件
                    this.map.on('singleclick', (evt) => {

                        //设置测量提示信息的位置坐标，用来确定鼠标点击后测量提示框的位置
                        this.measureTooltip != null ? this.measureTooltip.setPosition(evt.coordinate) : null;
                        //根据鼠标点击位置生成一个点
                        var point = new Point(evt.coordinate);
                        //将该点要素添加到矢量数据源中
                        source.addFeature(new Feature(point));
                        //点击次数增加
                        this.count++;
                    });

                    //地图双击事件
                    this.map.on('dblclick', (evt) => {
                        var point = new Point(evt.coordinate);
                        source.addFeature(new Feature(point));
                    });

                }, this);
                //绘制结束事件
                this.draw.on('drawend', (evt) => {
                    const coordinates = evt.feature.getGeometry().getCoordinates()
                    evt.feature.set('tempdata', this.lengthMeasureCount)
                    this.drawCoordinateArr.push(coordinates)

                    document.addEventListener('click', this.deleteClick)
                    // 绘制结束之后 绘制出的点线面数据
                    evt.feature.getGeometry().getCoordinates()
                    this.count = 0;
                    //设置测量提示框的样式
                    this.measureTooltipElement.className = 'tooltip tooltip-static';
                    //设置偏移量
                    this.measureTooltip.setOffset([0, -7]);
                    //清空绘制要素
                    sketch = null;
                    //清空测量提示要素
                    this.measureTooltipElement = null;
                    //创建测量提示框
                    this.createMeasureTooltip();
                    //移除事件监听
                    unByKey(this.listener);
                    //移除之前的绘制对象
                    this.map.removeInteraction(this.draw);
                    //移除地图单击事件
                    this.map.removeEventListener('singleclick');
                    this.map.removeEventListener('dblclick');
                }, this);

            },

            setToolStyle(measureTooltipElement) {
                // 这里可以根据你需要的测量提示框的样式做修改
                measureTooltipElement.style.color = '#C3C5C9'
                measureTooltipElement.style.padding = '0 10px'
                measureTooltipElement.style.height = '25px'
                measureTooltipElement.style.lineHeight = '20px'
                measureTooltipElement.style.background = '#1A1A1B'
                measureTooltipElement.style.fontSize = '15px'
                measureTooltipElement.style.fontWeight = '400'
            },
            // 点击测距的删除
            deleteClick(e) {
                const target = e.target
                const eIndex = target.getAttribute('tempimgdata')
                if (target.className === 'deleteLine') {
                    this.deleteLayers(eIndex)
                    this.drawCoordinateArr[parseInt(eIndex) - 1] = []
                }
            },
            // 删除当前点击的图层
            deleteLayers(eIndex, target) {
                const overlaysArr = this.map.getOverlays().getArray()
                const layersArr = this.map.getLayers().getArray()
                // 先把需要删除的Overlays存在一个数组中
                // 如果不储存直接去删除Overlays就会出现需要删除却识别不到的图层
                const deleteOverlayArr = []
                const deleteLayerArr = []
                for (let i = 0; i < overlaysArr.length; i++) {
                    const lastChild = overlaysArr[i].values_.element?.lastChild
                    if (lastChild?.id && lastChild?.getAttribute('tempimgdata') === eIndex) {
                        deleteOverlayArr.push(overlaysArr[i])
                    }
                }
                this.map.getLayers().getArray().forEach((n, i) => {
                    if (n.getSource() && n instanceof VectorLayer && n.getSource().getFeatures()[0] && n.getSource().getFeatures()[0].get('tempdata') && n.getSource().getFeatures()[0].get('tempdata').toString() === eIndex) {
                        deleteLayerArr.push(n)
                    }
                })
                for (let i = 0; i < layersArr.length; i++) {
                    if (layersArr[i].values_.tempdata && layersArr[i].values_.tempdata.toString() === eIndex) {
                        deleteLayerArr.push(layersArr[i])
                    }
                }
                // 单独处理需要删除的Overlays和Layers
                deleteOverlayArr.forEach(n => this.map.removeOverlay(n))
                deleteLayerArr.forEach(n => this.map.removeLayer(n))

            },
            /** 创建帮助提示框 **/
            createHelpTooltip() {
                //如果已经存在帮助提示框则移除
                if (this.helpTooltipElement) {
                    this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
                }
                //创建帮助提示要素的div
                this.helpTooltipElement = document.createElement('div');
                //设置帮助提示要素的样式
                this.helpTooltipElement.className = 'tooltip hidden';
                //创建一个帮助提示的覆盖标注
                this.helpTooltip = new Overlay({
                    element: this.helpTooltipElement,
                    offset: [15, 0],
                    positioning: 'center-left'
                });
                //将帮助提示的覆盖标注添加到地图中
                this.map.addOverlay(this.helpTooltip);
            },
            /** 创建测量提示框 **/
            createMeasureTooltip() {
                //创建测量提示框的div
                this.measureTooltipElement = document.createElement('div');
                this.measureTooltipElement.setAttribute('id', 'lengthLabel');
                //设置测量提示要素的样式
                this.measureTooltipElement.className = 'tooltip tooltip-measure';
                //创建一个测量提示的覆盖标注
                this.measureTooltip = new Overlay({
                    element: this.measureTooltipElement,
                    offset: [0, -15],
                    positioning: 'bottom-center'
                });
                //将测量提示的覆盖标注添加到地图中
                this.map.addOverlay(this.measureTooltip);
            },
            /** 格式化测量长度 **/
            formatLength(line) {
                //定义长度变量，计算平面距离
                var length = Math.round(line.getLength() * 100) / 100;
                //定义输出变量
                var output;
                //如果长度大于1000，则使用km单位，否则使用m单位
                if (length > 1000) {
                    output = (Math.round(length / 1000 * 100) / 100) + ' ' + 'km'; //换算成KM单位
                } else {
                    output = (Math.round(length * 100) / 100) + ' ' + 'm'; //m为单位
                }
                // output = (Math.round(length * 100) / 100);
                return output;
            },
            /** 格式化测量面积 **/
            formatArea(polygon) {
                //定义面积变量，获取平面面积
                var area = polygon.getArea();
                //定义输出变量
                var output;
                //当面积大于10000时，转换为平方千米，否则为平方米
                /*if (area > 10000) {
                  output = (Math.round(area / 1000000 * 100) / 100) + ' ' + 'km<sup>2</sup>';
                } else {
                  output = (Math.round(area * 100) / 100) + ' ' + 'm<sup>2</sup>';
                }*/
                output = (Math.round(area * 100) / 100);
                return output;
            },
            /*************** 测量:长度、面积 end *****************/

            /** 高亮图层 **/
            highlight_Layer(features, coordinate, easyId) {
                this.isStation = false
                if (this.highlightLayer) {
                    this.map.removeLayer(this.highlightLayer);
                }
                if (features == undefined || features == null) {
                    return;
                }
                let coordinates = features[0].geometry.coordinates;
                if (this.getArrayDimension(coordinates) == 3) {
                    coordinates = [coordinates]
                }
                if (!Array.isArray(coordinates[0])) {
                    // this.addPoints(coordinates, '#FF8000')
                    if (this.$refs.layerManageMap.queryLayer.replace(/\s/g, '') == 'honghezhou:hh_base_station') {
                        this.tooltipTitle = '基站详情'
                        this.isStation = true
                    }
                    setTimeout(() => {
                        this.createTooltip(coordinate, features);
                    }, 300)
                    return;

                }
                // 定义高亮区域的样式：
                var highlightStyle = new Style({
                    fill: new Fill({
                        color: 'rgba(0, 220, 225, 0.4)',	//高亮区域填充颜色，可用rgba值
                    }),
                    stroke: new Stroke({
                        color: "#00FFFF",	//高亮区域的边界线颜色
                        width: 2
                    })
                });
                // 定义高亮区域的图层：
                var highlightSource = new VectorSource();
                this.highlightLayer = new VectorLayer({
                    source: highlightSource,
                    style: highlightStyle
                })

                for (let coordinate of coordinates) {
                    for (let po of coordinate) {
                        let arr = [];
                        for (let i = 0; i < po.length; i++) {
                            // 投影坐标转为经纬度
                            let jwd = transform(po[i], 'EPSG:3857', 'EPSG:4326');
                            arr.push(jwd);
                        }
                        var polygon = new Polygon([arr]);
                        polygon.applyTransform(getTransform('EPSG:4326', 'EPSG:3857'));
                        var featuretest = new Feature(polygon);
                        highlightSource.addFeature(featuretest);
                    }

                }

                this.map.addLayer(this.highlightLayer);
                this.$refs.mapToolBar.send_expand_open();

                if (easyId) {
                    let parms = {id: easyId}
                    Canton.queryOne.get(this, parms, res => {
                        if (res.data.success) {
                            let wkt = res.data.result;
                            this.$refs.aPictureData.open(wkt[0].geom);
                            if (wkt[0].level == '2') {
                                this.$refs.note_send.openYFFQ(wkt);
                            }
                            if (wkt[0].level == '3') {
                                Canton.queryNextWithGeom.get(this, parms, res => {
                                    if (res.data.success) {
                                        let wkt = res.data.result;
                                        this.$refs.note_send.openYFFQ(wkt);
                                    } else {
                                        this.$message.error(res.data.message);
                                    }
                                });
                            }
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
                    // let layerExtent = this.highlightLayer.getSource().getExtent();
                    // this.fitMap(layerExtent)
                } else {
                    var strwkt = new WKT().writeFeature(highlightSource.getFeatures()[0], {
                        dataProjection: 'EPSG:4326',//目标坐标系
                        featureProjection: 'EPSG:3857'  //当前坐标系
                    });

                    this.findWkt = strwkt;
                    this.$refs.aPictureData.open(strwkt);
                    this.$refs.note_send.open(this.findWkt);
                }

            },
            /******** 基础 end ***********/

            /******** 清除 start **********/

            /** 清除绘制所用到的元素 **/
            remove_measurement() {
                this.showTooltopStation = false
                this.stationR = 0
                this.findWkt = null;
                //移除之前的绘制对象
                if (this.draw != null) {
                    this.map.removeInteraction(this.draw);
                }
                // 移除测量矢量图层
                if (this.vector_draw != null) {
                    this.map.removeLayer(this.vector_draw);
                }

                if (this.hightPoint && this.hightPoint != null) {
                    this.map.removeLayer(this.hightPoint)
                }
                this.map.removeLayer(this.vectorLayer);

                this.$refs.layerManageMap.type = '1';
                this.$refs.aPictureData.close();
                this.$refs.note_send.close();
                this.$refs.mapToolBar.send_expand_off();

                // 清除高亮
                if (this.highlightLayer) {
                    this.map.removeLayer(this.highlightLayer);
                }

                // 清除帮助提示信息对象
                if (this.helpTooltip != null) {
                    this.helpTooltip = null;
                    this.helpTooltipElement = null;
                    // 清除测量提示信息对象
                    this.measureTooltip = null;
                    this.measureTooltipElement = null;
                    this.map.getOverlays().clear();//关键点
                }
                this.$refs.shpUpload.visible=false
                // this.map.setView(
                //     new View({
                //         center: fromLonLat(this.coordinate),
                //         zoom: this.zoom,
                //     })
                // )

            },
            /** 清除绘制、提示信息 ***/
            /******** 清除 end ***********/
            /**图层切换 */
            handleSwitchMap(type) {
                switch (type) {
                    case "vector":
                        this.layer_vector.setVisible(true);
                        this.layer_image.setVisible(false);
                        this.layer_terrain.setVisible(false);
                        this.active = 1;
                        break;
                    case "image":
                        this.layer_vector.setVisible(false);
                        this.layer_image.setVisible(true);
                        this.layer_terrain.setVisible(false);
                        this.active = 2;
                        break;
                    case "terrain":
                        this.layer_vector.setVisible(false);
                        this.layer_image.setVisible(false);
                        this.layer_terrain.setVisible(true);
                        this.active = 2;
                        break;
                }
            },
            wkt_show(wktGeom, title) {
                if (wktGeom == null || wktGeom == undefined || wktGeom == '') {
                    return;
                }
                var source = new VectorSource();
                // 解析 wkt字符串
                var feature = new WKT().readFeature(wktGeom, {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                });

                source.addFeature(feature);
                // wkt加入图层
                let wkt_layer = new VectorLayer({source: source});
                this.vectorLayer = wkt_layer;

                this.map.addLayer(wkt_layer);
                /* let view = this.map.getView();
                 view.fit(feature.getGeometry(),{
                     duration:1000,
                     maxZoom:20,
                 });*/
            },


            /***** 工具栏 start ******/
            map_djcx() {
                this.remove_measurement();
                this.$refs.layerManageMap.type = '2';
                //  this.$refs.aPictureData.open();
                // this.$refs.mapToolBar.send_expand_open();
            },
            /** 范围分析 **/
            hgfxChange() {
                this.remove_measurement();
                //定义矢量数据源
                var source = new VectorSource();
                this.vector_draw = new VectorLayer({
                    source: source,
                    style: new Style({
                        fill: new Fill({
                            color: 'rgba(255, 255, 255, 0.2)',
                        }),
                        stroke: new Stroke({
                            color: '#ffcc33',
                            width: 2,
                        }),
                        image: new CircleStyle({
                            radius: 7,
                            fill: new Fill({
                                color: '#ffcc33',
                            }),
                        }),
                    }),
                });
                // 将矢量图层放入map
                this.map.addLayer(this.vector_draw);

                //创建一个当前要绘制的对象
                var sketch = new Feature();


                //创建一个交互式绘图对象
                this.draw = new Draw({
                    //绘制的数据源
                    source: source,
                    //绘制类型
                    type: 'Polygon',
                    //样式
                    style: new Style({
                        fill: new Fill({
                            color: 'rgba(255, 255, 255, 0.2)',
                        }),
                        stroke: new Stroke({
                            color: 'rgba(0, 0, 0, 0.5)',
                            lineDash: [10, 10],
                            width: 2,
                        }),
                        image: new CircleStyle({
                            radius: 5,
                            stroke: new Stroke({
                                color: 'rgba(0, 0, 0, 0.7)',
                            }),
                            fill: new Fill({
                                color: 'rgba(255, 255, 255, 0.2)',
                            }),
                        }),
                    }),
                });
                //将交互绘图对象添加到地图中
                this.map.addInteraction(this.draw);

                //绘制开始事件
                this.draw.on('drawstart', (evt) => {
                    sketch = evt.feature;
                    //提示框的坐标
                    var tooltipCoord = evt.coordinate;
                    this.listener = sketch.getGeometry().on('change', (evt) => {
                        //The event target.
                        //获取绘制的几何对象
                        var geom = evt.target;
                        //定义一个输出对象，用于记录面积和长度
                        var output;
                        if (geom instanceof Polygon) {
                            this.geom = geom;
                            //输出多边形的面积
                            output = this.formatArea(geom);
                            //获取多变形内部点的坐标
                            tooltipCoord = geom.getInteriorPoint().getCoordinates();
                        }
                    });
                    var singleClickHandler = (evt) => {
                        //根据鼠标点击位置生成一个点
                        var point = new Point(evt.coordinate);
                        //将该点要素添加到矢量数据源中
                        source.addFeature(new Feature(point));
                        //点击次数增加
                        this.count++;
                    };
                    var dbClickHandler = (evt) => {
                        var point = new Point(evt.coordinate);
                        source.addFeature(new Feature(point));
                        this.map.un('singleclick', singleClickHandler);
                        this.map.un('dblclick', dbClickHandler);
                    }
                    //地图单击事件
                    this.map.on('singleclick', singleClickHandler);
                    //地图双击事件
                    this.map.on('dblclick', dbClickHandler);

                }, this);
                //绘制结束事件
                this.draw.on('drawend', (evt) => {
                    // 绘制结束之后 绘制出的点线面数据
                    evt.feature.getGeometry().getCoordinates();
                    // 绘制结束后去获取绘制图形
                    var polygon = evt.feature.getGeometry();
                    // 绘制的是polygon, 此处使用 WKT().writeGeometry() 方法
                    //文档有其他要素方法, 可以自己查一下
                    var polygonWKT = new WKT().writeGeometry(polygon, {
                        dataProjection: "EPSG:4326",
                        featureProjection: "EPSG:3857"
                    });
                    this.showHg(polygonWKT);

                    this.count = 0;
                    //清空绘制要素
                    sketch = null;
                    //移除事件监听
                    unByKey(this.listener);
                    //移除地图单击事件
                    this.map.removeEventListener('singleclick');
                    //移除之前的绘制对象
                    this.map.removeInteraction(this.draw);
                    this.map.removeEventListener('dblclick');
                }, this);

            },
            /** 合规分析页面 **/
            showHg(polygonWKT) {
                this.findWkt = polygonWKT;
                // this.$refs.aPictureData.open(polygonWKT);
                this.noteSends();
                this.$refs.mapToolBar.send_expand_open();
            },
            noteSends() {
                this.$refs.note_send.showNote = !this.$refs.note_send.showNote
                this.$refs.note_send.open(this.findWkt);
                this.$refs.aPictureData.open(this.findWkt);
            },
            uploadTb() {
                this.remove_measurement();
                this.$refs.shpUpload.visible = true;
            },
            eventHandle_file(type, arg) {
                switch (type) {
                    case "importInfo": {
                        this.findWkt = arg[0];
                        this.wkt_show(arg[0]);
                        this.$refs.aPictureData.open();
                        this.$refs.mapToolBar.send_expand_open();
                        break;
                    }
                    case "close": {
                        break;
                    }
                    case "uploadFile": {
                        this.queryFile();
                        break;
                    }
                }
            },
            sendPopOpen(ids, areaName, datas) {
                this.$refs.maskSend.openMask(ids, areaName, datas);
            },
            /***** 工具栏 end ******/

            createTooltip(coordinate, features) {
                this.showTooltopStation = false
                // 获取popup的dom对象
                var container = document.getElementById('popup');
                var content = document.getElementById('popup-content');
                var closers = document.getElementById('popup-closer');
                //创建一个测量提示的覆盖标注
                this.stationTooltip = new Overlay({
                    element: container,
                    offset: [0, -15],
                    positioning: 'bottom-center'
                });
                //将测量提示的覆盖标注添加到地图中
                if (this.stationTooltip) {
                    this.map.removeOverlay(this.stationTooltip);
                }
                content.innerHTML = '';
                let _this = this
                switch (this.$refs.layerManageMap.queryLayer.replace(/\s/g, '')) {
                    case 'honghezhou:hh_base_station':
                        this.stationR = null
                        var station_name = document.createElement('p');
                        station_name.innerHTML = '<span class="tip-title">站址名称：<span>' + '<br>' + `<div>${features[0].properties.station_name}</div>`;
                        content.appendChild(station_name);

                        var code = document.createElement('p');
                        code.innerHTML = '<span class="tip-title">站址编码：<span>' + '<br>' + `<div>${features[0].properties.code}</div>`;
                        content.appendChild(code);
                        var buildWay = document.createElement('p');
                        buildWay.innerHTML = '<span class="tip-title">建设方式：<span>' + '<br>' + `<div>${features[0].properties.build_way}</div>`;
                        content.appendChild(buildWay);
                        this.showTooltopStation = true
                        break;
                    case'honghezhou:disaster_point':
                        this.tooltipTitle = '地灾点详情'
                        var station_name = document.createElement('p');
                        station_name.innerHTML = '<span class="tip-title">地灾点名称：<span>' + '<br>' + `<div>${features[0].properties.name}</div>`;
                        content.appendChild(station_name);

                        var pointCode = document.createElement('p');
                        pointCode.innerHTML = '<span class="tip-title">地灾点编号：<span>' + '<br>' + `<div>${features[0].properties.point_code}</div>`;
                        content.appendChild(pointCode);

                        var riskLevel = document.createElement('p');
                        riskLevel.innerHTML = '<span class="tip-title">风险等级：<span>' + '<br>' + `<div>${features[0].properties.risk_level}</div>`;
                        content.appendChild(riskLevel);

                        var scaleLevel = document.createElement('p');
                        scaleLevel.innerHTML = '<span class="tip-title">规模等级：<span>' + '<br>' + `<div>${features[0].properties.scale_level}</div>`;
                        content.appendChild(scaleLevel);

                        var threatAmount = document.createElement('p');
                        threatAmount.innerHTML = '<span class="tip-title">威胁财产：<span>' + '<br>' + `<div>${features[0].properties.threat_amount}万元</div>`;
                        content.appendChild(threatAmount);

                        var threatPersonCount = document.createElement('p');

                        threatPersonCount.innerHTML = '<span class="tip-title">威胁人数：<span>' + '<br>' + `<div>${features[0].properties.threat_person_count}人</div>`;
                        content.appendChild(threatPersonCount);

                        var type = document.createElement('p');
                        type.innerHTML = '<span class="tip-title">地灾点类型：<span>' + '<br>' + `<div>${features[0].properties.type}</div>`;
                        content.appendChild(type);
                        this.showTooltopStation = true
                        break;
                    case'honghezhou:hh_disaster_2024':
                        this.tooltipTitle = '地灾点2024详情'
                        let parms = {id: features[0].properties.county}
                        Canton.queryOne.get(this, parms, res => {
                            if (res.data.success) {

                                var station_name = document.createElement('p');
                                station_name.innerHTML = '<span class="tip-title">编号：<span>' + '<br>' + `<div>${features[0].properties.bh==null?'无':features[0].properties.bh}</div>`;
                                content.appendChild(station_name);
                                var name = document.createElement('p');
                                name.innerHTML = '<span class="tip-title">名称：<span>' + '<br>' + `<div>${features[0].properties.name==null?'无':features[0].properties.name}</div>`;
                                content.appendChild(name);
                                var pointCode = document.createElement('p');
                                pointCode.innerHTML = '<span class="tip-title">县（市）：<span>' + '<br>' + `<div>${res.data.result[0].name==null?'无':res.data.result[0].name}</div>`;
                                content.appendChild(pointCode);
                                var town = document.createElement('p');
                                town.innerHTML = '<span class="tip-title">乡（镇）：<span>' + '<br>' + `<div>${features[0].properties.town==null?'无':features[0].properties.town} </div>`;
                                content.appendChild(town);
                                var scaleLevel = document.createElement('p');
                                scaleLevel.innerHTML = '<span class="tip-title">村委会：<span>' + '<br>' + `<div>${features[0].properties.cwh==null?'无':features[0].properties.cwh}</div>`;
                                content.appendChild(scaleLevel);
                                var riskLevel = document.createElement('p');
                                riskLevel.innerHTML = '<span class="tip-title">村：<span>' + '<br>' + `<div>${features[0].properties.cun==null?'无':features[0].properties.cun}</div>`;
                                var riskLevel = document.createElement('p');
                                content.appendChild(riskLevel);

                                var zu = document.createElement('p');
                                zu.innerHTML = '<span class="tip-title">组：<span>' + '<br>' + `<div> ${features[0].properties.zu==null?'无':features[0].properties.zu}</div>`;
                                content.appendChild(zu);


                                var type = document.createElement('p');
                                type.innerHTML = '<span class="tip-title">风险等级：<span>' + '<br>' + `<div>${features[0].properties.fxdj==null?'无':features[0].properties.fxdj}</div>`;
                                content.appendChild(type);

                                var zhlx = document.createElement('p');
                                zhlx.innerHTML = '<span class="tip-title">灾害类型：<span>' + '<br>' + `<div> ${features[0].properties.zhlx==null?'无':features[0].properties.zhlx}</div>`;
                                content.appendChild(zhlx);

                                var xqfj = document.createElement('p');
                                xqfj.innerHTML = '<span class="tip-title">险情分级：<span>' + '<br>' + `<div>${features[0].properties.xqfj==null?'无':features[0].properties.xqfj} </div>`;
                                content.appendChild(xqfj);

                                var fzcsjs = document.createElement('p');
                                fzcsjs.innerHTML = '<span class="tip-title">防治措施建设：<span>' + '<br>' + `<div>${features[0].properties.fzcsjs==null?'无':features[0].properties.fzcsjs}</div>`;
                                content.appendChild(fzcsjs);
                                var gmfj = document.createElement('p');
                                gmfj.innerHTML = '<span class="tip-title">规模分级：<span>' + '<br>' + `<div>${features[0].properties.gmfj==null?'无':features[0].properties.gmfj}</div>`;
                                content.appendChild(gmfj);
                                var wxcc  = document.createElement('p');
                                wxcc.innerHTML = '<span class="tip-title">威胁财产(万元)：<span>' + '<br>' + `<div>${features[0].properties.wxcc==null?'无':features[0].properties.wxcc}</div>`;
                                content.appendChild(wxcc);
                                var wxrk  = document.createElement('p');
                                wxrk.innerHTML = '<span class="tip-title">威胁人口：<span>' + '<br>' + `<div>${features[0].properties.wxrk==null?'无':features[0].properties.wxrk}</div>`;
                                content.appendChild(wxrk);
                                var wxhs  = document.createElement('p');
                                wxhs.innerHTML = '<span class="tip-title">威胁户数：<span>' + '<br>' + `<div>${features[0].properties.wxhs==null?'无':features[0].properties.wxhs}</div>`;
                                content.appendChild(wxhs);
                                var sfnrjg = document.createElement('p');
                                sfnrjg.innerHTML = '<span class="tip-title">是否纳入监测员补助：<span>' + '<br>' + `<div>${features[0].properties.sfnrjg==null?'无':features[0].properties.sfnrjg}</div>`;
                                content.appendChild(sfnrjg);
                                var sfwqp = document.createElement('p');
                                sfwqp.innerHTML = '<span class="tip-title">是否为切坡建房点：<span>' + '<br>' + `<div>${features[0].properties.sfwqp==null?'无':features[0].properties.sfwqp}</div>`;
                                content.appendChild(sfwqp);
                                var threatAmount = document.createElement('p');
                                threatAmount.innerHTML = '<span class="tip-title">村委会负责人：<span>' + '<br>' + `<div>${features[0].properties.cwhfzr==null?'无':features[0].properties.cwhfzr}人</div>`;
                                content.appendChild(threatAmount);
                                var sjhmCwhfzr = document.createElement('p');
                                sjhmCwhfzr.innerHTML = '<span class="tip-title">手机号码-村委会负责人：<span>' + '<br>' + `<div> ${features[0].properties.sjhm_cwhfzr==null?'无':features[0].properties.sjhm_cwhfzr}</div>`;
                                content.appendChild(sjhmCwhfzr);
                                var threatPersonCount = document.createElement('p');
                                threatPersonCount.innerHTML = '<span class="tip-title">村（组）负责人：<span>' + '<br>' + `<div>${features[0].properties.czfzr==null?'无':features[0].properties.czfzr}人</div>`;
                                content.appendChild(threatPersonCount);
                                var sjhmCzfzr = document.createElement('p');
                                sjhmCzfzr.innerHTML = '<span class="tip-title">手机号码-村（组）负责人：<span>' + '<br>' + `<div>${features[0].properties.sjhm_czfzr==null?'无':features[0].properties.sjhm_czfzr}</div>`;
                                content.appendChild(sjhmCzfzr);
                                var jcry = document.createElement('p');
                                jcry.innerHTML = '<span class="tip-title">监测人员：<span>' + '<br>' + `<div> ${features[0].properties.jcry==null?'无':features[0].properties.jcry}</div>`;
                                content.appendChild(jcry);
                                var sjhmJcry = document.createElement('p');
                                sjhmJcry.innerHTML = '<span class="tip-title">手机号码-监测人员：<span>' + '<br>' + `<div>${features[0].properties.sjhm_jcry==null?'无':features[0].properties.sjhm_jcry} </div>`;
                                content.appendChild(sjhmJcry);
                                var xzfgld = document.createElement('p');
                                xzfgld.innerHTML = '<span class="tip-title">乡镇分管领导：<span>' + '<br>' + `<div> ${features[0].properties.xzfgld==null?'无':features[0].properties.xzfgld}</div>`;
                                content.appendChild(xzfgld);
                                var sjhmXzfgld = document.createElement('p');
                                sjhmXzfgld.innerHTML = '<span class="tip-title">手机号码-乡镇分管领导：<span>' + '<br>' + `<div>${features[0].properties.sjhm_xzfgld==null?'无':features[0].properties.sjhm_xzfgld} </div>`;
                                content.appendChild(sjhmXzfgld);
                                var yfys = document.createElement('p');
                                yfys.innerHTML = '<span class="tip-title">引发因素：<span>' + '<br>' + `<div> ${features[0].properties.yfys==null?'无':features[0].properties.yfys}</div>`;
                                content.appendChild(yfys);
                                var yhdly = document.createElement('p');
                                yhdly.innerHTML = '<span class="tip-title">隐患点来源：<span>' + '<br>' + `<div> ${features[0].properties.yhdly==null?'无':features[0].properties.yhdly}</div>`;
                                content.appendChild(yhdly);
                                var yjfs = document.createElement('p');
                                yjfs.innerHTML = '<span class="tip-title">预警方式：<span>' + '<br>' + `<div> ${features[0].properties.yjfs==null?'无':features[0].properties.yjfs}</div>`;
                                content.appendChild(yjfs);
                                var ylscs = document.createElement('p');
                                ylscs.innerHTML = '<span class="tip-title">已落实措施：<span>' + '<br>' + `<div> ${features[0].properties.ylscs==null?'无':features[0].properties.ylscs}</div>`;
                                content.appendChild(ylscs);
                                var zrztdw = document.createElement('p');
                                zrztdw.innerHTML = '<span class="tip-title">责任主体单位：<span>' + '<br>' + `<div> ${features[0].properties.zrztdw==null?'无':features[0].properties.zrztdw}</div>`;
                                content.appendChild(zrztdw);
                                var zzlscs = document.createElement('p');
                                zzlscs.innerHTML = '<span class="tip-title">正在落实措施：<span>' + '<br>' + `<div>${features[0].properties.zzlscs==null?'无':features[0].properties.zzlscs}</div>`;
                                content.appendChild(zzlscs);
                                this.showTooltopStation = true
                            } else {
                                this.$message.error(res.data.message);
                            }
                        });

                        break;
                    case 'honghezhou:rainfall_monitor_point':
                        this.getpointInfo('rainfall', features[0].properties.id).then((res) => {
                            this.tooltipTitle = '雨量监测站点详情'
                            var station_name = document.createElement('p');
                            station_name.innerHTML = '<span class="tip-title">站点名称：<span>' + '<br>' + `<div>${res.data.result.name}</div>`;
                            content.appendChild(station_name);
                            var code = document.createElement('p');
                            code.innerHTML = '<span class="tip-title">所属县<span>' + '<br>' + `<div>${res.data.result.countyName}</div>`;
                            content.appendChild(code);
                            var buildWay = document.createElement('p');
                            buildWay.innerHTML = '<span class="tip-title">所属乡镇：<span>' + '<br>' + `<div>${res.data.result.cantonName}</div>`;
                            content.appendChild(buildWay);
                            this.showTooltopStation = true
                        })
                        break;
                    case 'honghezhou:geology_monitor_point':
                        this.getpointInfo('geology', features[0].properties.id).then((res) => {
                            this.tooltipTitle = '地质灾害监测点'
                            var station_name = document.createElement('p');
                            station_name.innerHTML = '<span class="tip-title">站点名称：<span>' + '<br>' + `<div>${res.data.result.name}</div>`;
                            content.appendChild(station_name);
                            var code = document.createElement('p');
                            code.innerHTML = '<span class="tip-title">所属县<span>' + '<br>' + `<div>${res.data.result.countyName}</div>`;
                            content.appendChild(code);
                            var buildWay = document.createElement('p');
                            buildWay.innerHTML = '<span class="tip-title">所属乡镇：<span>' + '<br>' + `<div>${res.data.result.cantonName}</div>`;
                            content.appendChild(buildWay);
                            this.showTooltopStation = true
                        })
                        break;
                    case 'honghezhou:flood_monitor_point':
                        this.getpointInfo('flood', features[0].properties.id).then((res) => {
                            this.tooltipTitle = '山洪灾害监测点'
                            var station_name = document.createElement('p');
                            station_name.innerHTML = '<span class="tip-title">站点名称：<span>' + '<br>' + `<div>${res.data.result.name}</div>`;
                            content.appendChild(station_name);
                            var code = document.createElement('p');
                            code.innerHTML = '<span class="tip-title">所属县<span>' + '<br>' + `<div>${res.data.result.countyName}</div>`;
                            content.appendChild(code);
                            var buildWay = document.createElement('p');
                            buildWay.innerHTML = '<span class="tip-title">所属乡镇：<span>' + '<br>' + `<div>${res.data.result.cantonName}</div>`;
                            content.appendChild(buildWay);
                            this.showTooltopStation = true
                        })
                        break;
                }

                this.selectStation = features[0].properties
                this.selectStation.coordinate = coordinate
                this.map.addOverlay(this.stationTooltip);
                this.stationTooltip.setPosition(coordinate);
                closers.onclick = function () {
                    _this.stationTooltip.setPosition(undefined);
                    closers.blur();
                };


            },
            getpointInfo(type, id) {
                return new Promise((resolve, reject) => {
                    let parms = {id: id}
                    let requset = null
                    switch (type) {
                        case 'rainfall':
                            requset = rainfallMonitor
                            break;
                        case 'geology':
                            requset = geologyMonitor
                            break;
                        case 'flood':
                            requset = floodMonitor
                            break;
                    }
                    requset.queryOne.get(this, parms, res => {
                        if (res.data.success) {
                            resolve(res)
                        } else {
                            this.$message.error(res.data.message);
                        }
                    })
                })
            },
            //天气查询
            queryWeather() {
                picture.queryWeather.get(this, {}, res => {
                    if (res.data.success) {
                        this.weatherData = res.data.result
                        var source = new VectorSource();
                        if (this.weatherData.length > 0 && this.weatherData != null) {
                            this.weatherData.forEach((el) => {
                                let wktGeom = el.geomCenter;
                                // 解析 wkt字符串坐标转换
                                var feature = new WKT().readFeature(wktGeom, {
                                    dataProjection: 'EPSG:4326',
                                    featureProjection: 'EPSG:3857'
                                });
                                let iconUrls = ''
                                if (el.weatherType == '1') {
                                    iconUrls = require('@/assets/image/map/weather/晴.png')
                                }
                                if (el.weatherType == '2') {
                                    iconUrls = require('@/assets/image/map/weather/小雨.png')
                                }
                                if (el.weatherType == '3') {
                                    iconUrls = require('@/assets/image/map/weather/阵雨.png')
                                }
                                if (el.weatherType == '4') {
                                    iconUrls = require('@/assets/image/map/weather/阴.png')
                                }
                                if (el.weatherType == '5') {
                                    iconUrls = require('@/assets/image/map/weather/多云.png')
                                }
                                if (el.weatherType == '6') {
                                    iconUrls = require('@/assets/image/map/weather/暴雨.png')
                                }
                                feature.setStyle(new Style({
                                    image: new Icon({
                                        src: iconUrls,
                                        scale: 0.4, // 图标缩放比例，默认为 1
                                        opacity: 1, // 图标透明度，默认为 1
                                        rotateWithView: false, // 图标是否随地图旋转，默认为 false
                                        rotation: 0 // 图标旋转角度，默认为 0
                                    })
                                }));
                                source.addFeature(feature);
                            })
                        }
                        // // wkt加入图层
                        this.pointLayer = new VectorLayer({source: source, zIndex: 800});
                        this.pointLayer.setZIndex(200)
                        this.map.addLayer(this.pointLayer);
                    }
                });
            },
            //切换选择
            updateLayer() {
                if (this.pointLayer.getVisible()) {
                    this.pointLayer.setVisible(false);
                } else {
                    this.pointLayer.setVisible(true);
                }
            },
            //绘制圆形区域
            drawCircle(center, R) {
                var source = new VectorSource();
                var circle = new Circle(center, R * 1000);// 新建圆对象
                var feature = new Feature(circle);// 新建Feature对象 并将circle传入
                source.addFeature(feature);
                this.vector_draw = new VectorLayer({source: source, zIndex: 1000});
                this.vector_draw.setZIndex(210)
                this.map.addLayer(this.vector_draw);
                this.$refs.mapToolBar.send_expand_open();
                this.getCircleGeom(circle, 32)

            },
            getCircleGeom(circle, sides) {
                const polygon = fromCircle(circle, sides);
                var polygonWKT = new WKT().writeGeometry(polygon, {
                    dataProjection: "EPSG:4326",
                    featureProjection: "EPSG:3857"
                });
                this.$refs['note_send'].open(polygonWKT)
                this.$refs['aPictureData'].open(polygonWKT)
            },
            //根据半径查询范围
            findByR() {
                if (this.stationR == 0) {
                    this.$message.error('请输入半径!')
                    return
                }
                // 移除测量矢量图层
                if (this.vector_draw != null) {
                    this.map.removeLayer(this.vector_draw);
                }
                this.drawCircle(this.selectStation.coordinate, this.stationR);

            },
            addPoints(coordinate, color) {

                if (this.hightPoint && this.hightPoint != null) {
                    this.map.removeLayer(this.hightPoint)
                }
                var source = new VectorSource();
                var point = new Point(coordinate);
                source.addFeature(new Feature(point));
                this.hightPoint = new VectorLayer({
                    source: source,
                    style: new Style({
                        image: new CircleStyle({
                            radius: 8,
                            fill: new Fill({
                                color: color ? color : '#ffcc33',
                            }),
                        }),
                    }),
                });
                this.hightPoint.setZIndex(310)
                this.map.addLayer(this.hightPoint);
            },
            removePointLayer() {
                if (this.hightPoint && this.hightPoint != null) {
                    this.map.removeLayer(this.hightPoint)
                }
            },
            setView(coordinate, zoom) {
                this.map.setView(
                    new View({
                        center: coordinate,
                        zoom: zoom || this.zoom,
                    })
                )
            },
            fitMap(layerExtent) {
                this.map.getView().fit(layerExtent, {padding: [10, 10, 10, 10], duration: 500});
            },
            getArrayDimension(arr) {
                if (!Array.isArray(arr)) {
                    return 0;
                }
                // 递归检查子数组的维度
                const subDimension = this.getArrayDimension(arr[0]);

                // 返回子数组维度加1
                return 1 + subDimension;
            },
        },
    }
</script>

<style scoped>

    .ol-popup {
        position: absolute;
        border-radius: 4px;
        bottom: 12px;
        left: -50px;
        width: 300px;
        background: rgba(0, 38, 77, 0.94);
        box-shadow: 0px 4px 8px 1px rgba(0, 11, 32, 0.53);
        border: 1px solid #3D8FC8;
        color: #00A2FF;
    }

    #popup-content {
        text-align: left;
        padding: 20px;
        max-height: 400px;
        overflow: auto;
    }

    .ol-popup:after, .ol-popup:before {
        top: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
    }

    .ol-popup:after {
        border-top-color: rgba(0, 38, 77, 0.98);
        border-width: 10px;
        left: 48px;
        margin-left: -10px;

    }

    .ol-popup:before {
        border-top-color: #cccccc;
        border-width: 11px;
        left: 48px;
        margin-left: -11px;
    }

    .ol-popup-closer {
        text-decoration: none;
        position: absolute;
        top: 8px;
        right: 8px;
        color: #00A2FF;
    }

    .inp-r {
        display: flex;
        justify-content: flex-start;
        padding: 20px;
    }

    .ant-input-number {
        background: unset !important;
        margin-right: 20px;
        width: 180px;
    }

    .tip-tits {
        background: linear-gradient(90deg, rgba(58, 136, 255, 0.1) 0%, #2D6BC9 47%, rgba(58, 136, 255, 0.1) 100%);
        border-radius: 0px 0px 0px 0px;
        height: 32px;
        width: 100%;
        display: flex;
        line-height: 33px;
        align-items: center;
        font-size: 16px;
        font-weight: bold;
        justify-content: center;
        color: #fff;

    }

    #popup-content p {
        border-bottom: 10px;
    }

    .tulie {
        position: fixed;
        bottom: 120px;
        right: 40px;
        background: linear-gradient(180deg, rgba(0, 145, 191, 0.53) 0%, rgba(0, 34, 58, 0.4) 100%);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        border: 1px solid #3D8FC8;
        transition: width 1s;
        float: left;
        right: 11px;
        color: #fff;
        padding: 10px;
        z-index: 99999;
    }

    .cycle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-bottom: 6px;
    }

    .tulie div {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        flex-direction: column;
    }
</style>
