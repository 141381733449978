<!--
* @program: 前台
* @author: ly
* @component:ResetPassword
* @description:
* @create: 2023-02-08 16:28
-->
<template>
    <a-layout class="layout-main-logn">
<!--        <a-layout-header class="header">
            <oaBanner />
        </a-layout-header>-->
        <a-layout-content class="main">
            <a-card title="修改密码" class="card">
                <LForm ref="Lform" :view-model="false" :form-data="formData" :form-columns="columns">
                    <template #action="{record}">
                        <div style="color: #ff7a64">{{tips}}</div>
                        <a-button style="margin:5px" type="primary"  @click="changePwd(record)">
                            修改密码
                        </a-button>
                        <a-button @click="backHome" type="link">返回</a-button>
                    </template>
                </LForm>
            </a-card>
<!--            <oa-footer />-->
        </a-layout-content>
    </a-layout>
</template>

<script>
    import oaBanner from "./oaBanner";
    import oaFooter from "./oaFooter";
    import LForm from "../../components/form/LForm";
    import Column from "../../assets/tables/column";
    import ColumnType from "../../assets/tables/column_type";
    import sysSets from "../../assets/api/sysSets";

    export default {
        name: "ResetPassword",
        components:{
            oaBanner,oaFooter,LForm
        },
        data() {
            return {
                columns:[
                    new Column("旧密码","oldPwd",ColumnType.String,true).setTableView()
                        .addRule({trigger: 'blur',required:true}),
                    new Column("新密码","newPwd",ColumnType.Password,true).setTableView()
                        .addRule({trigger: 'blur',required:true}),
                    new Column("再输入新密码","newPwdReview",ColumnType.Password,true).setTableView()
                        .addRule({trigger: 'blur',required:true}),
                ],
                formData:{},
                tips:""
            }
        },
        methods: {
            init() {
                this.formData = {
                    "newPwd": "",
                    "newPwdReview": "",
                    "oldPwd": ""
                };
            },
            changePwd(record){
                this.tips = this.getTips(record);
                if(this.tips){
                    return
                }
                sysSets.updatePwd.post(this,record,res=>{
                    if(res.data.success){
                        this.$message.success(res.data.message);
                        this.$router.push({ path: '/' });
                    }
                    else{
                        this.$message.error(res.data.message)
                    }
                })
            },
            backHome() {
                this.$router.go(-1);
            },
            getTips(record){
                let reg=/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!.,@$%^&*-]).{8,}$/;
                if(!reg.test(record.newPwd)){
                    return "密码需由8位以上大写字母、小写字母、数字及特殊符号组成"
                }
                else if(record.newPwd !== record.newPwdReview){
                    return "两次输入的密码不一致"
                }
                else{
                    return ""
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>
    .card{
        width: 400px;
        position: absolute;
        right: calc(50% - 200px);
        top:20%;
        box-shadow: 3px 3px 4px 0px #3dd0ff;
    }
</style>
