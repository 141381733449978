/**
 * 地图工具配置参数
 * @description：
 * @author:rzl
 * @date:2022/10/18 14:41
 **/
const mapToolConfig={
    /*** 地图工具 ***/
    list_img:[
        {
            id:'img_1',
            name:'鼠标点选',
            tip:'鼠标点选',
            type:'sbdx',
            selected:false,// 是否选中
            class:'imgClass',
            recovery:false,// 是否选中后复原
            img:require('@/page/Map/images/鼠标点选-默认.png'),// 默认图片
            enter_img:require('@/page/Map/images/鼠标点选-选中.png'),// 鼠标移入
            leave_img:require('@/page/Map/images/鼠标点选-默认.png'),// 鼠标离开
            click_img:require('@/page/Map/images/鼠标点选-选中.png'),// 鼠标点击
        },
        {
            id:'img_2',
            name:'矩形框选',
            tip:'矩形框选',
            type:'jxkx',
            selected:false,// 是否选中
            class:'imgClass',
            recovery:false,// 是否选中后复原
            img:require('@/page/Map/images/矩形框选-默认.png'),// 默认图片
            enter_img:require('@/page/Map/images/矩形框选-选中.png'),// 鼠标移入
            leave_img:require('@/page/Map/images/矩形框选-默认.png'),// 鼠标离开
            click_img:require('@/page/Map/images/矩形框选-选中.png'),// 鼠标点击
        },

        {
            id:'img_3',
            name:'SHP上传',
            tip:'SHP上传',
            type:'shpsc',
            selected:false,// 是否选中
            class:'imgClass',
            recovery:false,// 是否选中后复原
            img:require('@/page/Map/images/SHP上传-默认.png'),// 默认图片
            enter_img:require('@/page/Map/images/SHP上传-选中.png'),// 鼠标移入
            leave_img:require('@/page/Map/images/SHP上传-默认.png'),// 鼠标离开
            click_img:require('@/page/Map/images/SHP上传-选中.png'),// 鼠标点击
        },
    ],
    list_img2:[
        {
            id:'img_4',
            name:'短信发送',
            tip:'短信发送',
            type:'notSend',
            selected:false,// 是否选中
            class:'imgClass',
            recovery:false,// 是否选中后复原
            img:require('@/page/Map/images/短信-默认.png'),// 默认图片
            enter_img:require('@/page/Map/images/短信-选中.png'),// 鼠标移入
            leave_img:require('@/page/Map/images/短信-默认.png'),// 鼠标离开
            click_img:require('@/page/Map/images/短信-选中.png'),// 鼠标点击
        },
        {
            id:'img_5',
            name:'清除',
            tip:'清除',
            type:'cleans',
            selected:false,// 是否选中
            class:'imgClass',
            recovery:false,// 是否选中后复原
            img:require('@/page/Map/images/清除-默认.png'),// 默认图片
            enter_img:require('@/page/Map/images/清除-选中.png'),// 鼠标移入
            leave_img:require('@/page/Map/images/清除-默认.png'),// 鼠标离开
            click_img:require('@/page/Map/images/清除-选中.png'),// 鼠标点击
        },




    ],
};
export default mapToolConfig;
