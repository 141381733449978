/** 
* 自动生成文件，请勿修改 
* @name: 红河州地灾-数据统计管理
* @description: Data Stat Controller
* @create: 2024/11/8 10:34:25
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const params = { 
	easyOccurRankStat : [{"cantonIds":{"type":"string","description":"统计的行政区划ids，多个使用逗号隔开"},"dateFormat":{"type":"string","description":"日期格式化参数，年度：yyyy，季度：yyyy-Q，月度：yyyy-MM，日度：yyyy-MM-dd"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"queryDate":{"type":"string","description":"查询日期"}}], 
	messageSendStat : [{"cantonIds":{"type":"string","description":"统计的行政区划ids，多个使用逗号隔开"},"dateFormat":{"type":"string","description":"日期格式化参数，年度：yyyy，季度：yyyy-Q，月度：yyyy-MM，日度：yyyy-MM-dd"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"queryDate":{"type":"string","description":"查询日期"}}], 
	messageSendTotal : [{"cantonIds":{"type":"string","description":"统计的行政区划ids，多个使用逗号隔开"},"dateFormat":{"type":"string","description":"日期格式化参数，年度：yyyy，季度：yyyy-Q，月度：yyyy-MM，日度：yyyy-MM-dd"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"queryDate":{"type":"string","description":"查询日期"}}], 
	pagePeakStat : [{"cantonIds":{"type":"string","description":"统计的行政区划ids，多个使用逗号隔开"},"dateFormat":{"type":"string","description":"日期格式化参数，年度：yyyy，季度：yyyy-Q，月度：yyyy-MM，日度：yyyy-MM-dd"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"queryDate":{"type":"string","description":"查询日期"}}], 
	queryMessageGroupByDate : [{"cantonIds":{"type":"string","description":"统计的行政区划ids，多个使用逗号隔开"},"dateFormat":{"type":"string","description":"日期格式化参数，年度：yyyy，季度：yyyy-Q，月度：yyyy-MM，日度：yyyy-MM-dd"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"queryDate":{"type":"string","description":"查询日期"}}], 
	queryReportTotal : [{"cantonIds":{"type":"string","description":"统计的行政区划ids，多个使用逗号隔开"},"dateFormat":{"type":"string","description":"日期格式化参数，年度：yyyy，季度：yyyy-Q，月度：yyyy-MM，日度：yyyy-MM-dd"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"queryDate":{"type":"string","description":"查询日期"}}], 
	totalRainfallGroupByDate : [{"cantonIds":{"type":"string","description":"统计的行政区划ids，多个使用逗号隔开"},"dateFormat":{"type":"string","description":"日期格式化参数，年度：yyyy，季度：yyyy-Q，月度：yyyy-MM，日度：yyyy-MM-dd"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"queryDate":{"type":"string","description":"查询日期"}}], 
	warningInfoStat : [{"cantonIds":{"type":"string","description":"统计的行政区划ids，多个使用逗号隔开"},"dateFormat":{"type":"string","description":"日期格式化参数，年度：yyyy，季度：yyyy-Q，月度：yyyy-MM，日度：yyyy-MM-dd"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"queryDate":{"type":"string","description":"查询日期"}}], 
};
const stat = { 
	/** 1 summary:易发分区推送人次	method:post */ 
	easyOccurRankStat : new APIObject.postObject("/data/stat/easyOccurRankStat",params.easyOccurRankStat), 
	/** 2 summary:短信记录-预警短信统计	method:post */ 
	messageSendStat : new APIObject.postObject("/data/stat/messageSendStat",params.messageSendStat), 
	/** 3 summary:发送总量查询	method:post */ 
	messageSendTotal : new APIObject.postObject("/data/stat/messageSendTotal",params.messageSendTotal), 
	/** 4 summary:气象数据-气象数据极值	method:post */ 
	pagePeakStat : new APIObject.postObject("/data/stat/pagePeakStat",params.pagePeakStat), 
	/** 5 summary:预警触发趋势	method:post */ 
	queryMessageGroupByDate : new APIObject.postObject("/data/stat/queryMessageGroupByDate",params.queryMessageGroupByDate), 
	/** 6 summary:查询上报总次数	method:post */ 
	queryReportTotal : new APIObject.postObject("/data/stat/queryReportTotal",params.queryReportTotal), 
	/** 7 summary:降雨量统计	method:post */ 
	totalRainfallGroupByDate : new APIObject.postObject("/data/stat/totalRainfallGroupByDate",params.totalRainfallGroupByDate), 
	/** 8 summary:气象预警次数	method:post */ 
	warningInfoStat : new APIObject.postObject("/data/stat/warningInfoStat",params.warningInfoStat), 
};
export default stat;