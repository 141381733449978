<!--
* @description：地图查询条件
* @author:rzl
* @date:2022/1/20 11:40
-->

<template>
  <div class="map-tool-bar" :style="op_map_tool">
    <div class="op-tool-tb" :style="op_tool_tb">
      <img style="cursor: pointer;" :src="op_tool_tb_url" @click="op_expand_click">
    </div>

    <div class="xm_query" :style="op_content" @mouseenter="op_expand_enter" @mouseleave="op_expand_leave">
      <div v-for="item in list_img" :key="item.id" class="img-div" v-show="op_show">
        <div>
          <img
              :class="item.class" :title="item.tip" :id="item.id"  :src="item.img"
              @mouseenter="enter_op(item)" @mouseleave="leave_op(item)"  @click="click_op(item)"
          />
        </div>
        <div v-show="op_show_text">
          {{item.name}}
        </div>
      </div>
      <div class="op-tool" v-show="!op_show" >
        <div class="op-tool-img">
          <img :src="require('@/page/Map/images/工具-默认.png')" >
        </div>
        <div class="op-tool-text">
          操作工具
        </div>
      </div>
    </div>

  </div>

</template>

<script>
import mapToolConfig from "@/page/Map/visual/mapToolConfig";

export default {
  name: "mapToolBar",
  data(){
    return{
      list_img:mapToolConfig.list_img,
      selected_type:'',// 选中图标type

      op_map_tool:'',
      op_show:false,
      op_show_text:false,
      op_content:'',
      op_tool_tb:'',
      op_tool_tb_url:require('@/page/Map/images/展开箭头.png'),
      flag_op_tb:false,// 图层图标展开收缩：true:展开；false:收缩
      op_select:false,
    }
  },
  methods:{
    op_expand_click(){
      this.op_select = !this.op_select;
      this.flag_op_tb = !this.flag_op_tb;
      if(this.flag_op_tb){
        this.op_expand_enter();
      }else{
        this.op_show = false;
        this.op_map_tool = "width:100px;";
        this.op_content = "width:70px;"
        setTimeout(()=>{
          this.op_show_text = false;
        },500);
        this.op_tool_tb_url = require('@/page/Map/images/展开箭头.png');
      }
    },
    op_expand_enter(){
      this.flag_op_tb = true;
      this.op_show = true;
      this.op_map_tool = "width:240px;";
      this.op_content = "width:200px;"
      setTimeout(()=>{
        this.op_show_text = true;
      },500);
      this.op_tool_tb_url = require('@/page/Map/images/收起箭头.png');
    },
    op_expand_leave(){
      if(!this.op_select){
        this.flag_op_tb = false;
        this.op_show = false;
        this.op_map_tool = "width:100px;";
        this.op_content = "width:70px;"
        setTimeout(()=>{
          this.op_show_text = false;
        },500);
        this.op_tool_tb_url = require('@/page/Map/images/展开箭头.png');
      }
    },

    /**
    * @Description:鼠标悬停进入
    * @params: 操作类别
    * @return:
    * @Author: rzl
    * @Date: 2022/4/13
    */
    enter_op(arg){
      let but_id = arg.id;
      this.list_img.forEach((item,index)=>{
        if(!item.selected){
          if(item.id != but_id){
            item.img = item.leave_img;
          }else{
            if(item.recovery){
              item.img = item.enter_img;
            }else{
              item.img = item.enter_img;
            }
          }
        }

      })
    },
    /**
    * @Description: 鼠标离开
    * @params: 操作类别
    * @return:
    * @Author: rzl
    * @Date: 2022/4/13
    */
    leave_op(arg){
      this.list_img.forEach((item,index)=>{
        if(!item.selected){
          item.img = item.leave_img;
        }
      })
    },
    /**
    * @Description: 点击
    * @params: 操作类别
    * @return:
    * @Author: rzl
    * @Date: 2022/4/13
    */
    click_op(arg){
      this.op_select = true;
      let type = arg.type;
      this.selected_type = type;
      let but_id = arg.id;
      this.list_img.forEach((item,index)=>{
        if(item.selected){
          if(item.id != but_id){
            item.img = item.leave_img;
            item.selected = false;
          }
        }else{
          if(item.id != but_id){
            item.img = item.leave_img;
            item.selected = false;
          }else{
            if(item.recovery){
              item.img = item.leave_img;
              item.selected = false;
            }else{
              item.img = item.click_img;
              item.selected = true;
            }
          }
        }
      })
      this.click_methods(type);
    },
    /** 清除工具选中，改为默认 **/
    clean_op(){
      let type = this.selected_type;
      this.list_img.forEach((item,index)=>{
        item.img = item.leave_img;
        item.selected = false;
      })
    },
    click_methods(opType){
      switch (opType){
        case "cd":{
          this.$parent.mapTool_cd();
          break;
        }
        case "mj":{
          this.$parent.mapTool_mj();
          break;
        }
        case "cleans":{
          this.$parent.remove_measurement();
          break;
        }
      }
    },

  }
}
</script>

<style scoped>
.map-tool-bar{
  right: 1px;
  position: absolute;
  width: 100px;
  transition: width 1s;
}
.xm_query{
  position: absolute;
  z-index: 1000;
  /*right: 20px;*/
  top: 26px;

  width: 70px;
  height: 64px;
  background: linear-gradient(180deg, rgba(0,145,191,0.53) 0%, rgba(0,34,58,0.4) 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #3D8FC8;
  transition: width 1s;

  float: left;
  margin-left: 16px;
}
.op-tool-tb{
  transition: width 1s;
  top: 40px;
  position: absolute;
  z-index: 1000;
  float: left;
}
.op-tool{
  margin-top: 8px;
}
.op-tool-text{
  font-size: 10px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #B7E5EF;
}

.img-div{
  width: 30%;
  float: left;
  margin: 15px 2px;
  font-size: 10px;
  font-weight: 400;
  color: #B7E5EF;
}
</style>
