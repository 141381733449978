<!--
* @program: 前台 
* @author: ly
* @component:typeYear 
* @description: 
* @create: 2023-01-12 14:12
-->
<template>
    <div v-if="viewModel">
        <div @click="onClick">{{presentValue}}</div>
    </div>
    <div v-else>
        <a-date-picker @blur="blurTrigger" placeholder="请选择年度"
                style="width: 100%" v-model:value="date" picker="year" @change="columnTrigger" />
    </div>
</template>

<script>
    import typeBase from "./typeBase";
    import dayjs from "dayjs";

    export default {
        name: "typeYear",
        mixins:[typeBase],
        watch:{
            date(){
                if(this.date instanceof dayjs)
                    this.record[this.column.dataIndex] = this.date.format(this.dateFormat);
            },
            record(){
                this.date = this.record[this.column.dataIndex]?dayjs(this.record[this.column.dataIndex]):null;
            }
        },
        data(){
            return{
                dateFormat: 'YYYY',
                date:null,
            }
        },
        computed:{
            presentValue(){
                let date = this.record[this.column.dataIndex];
                if(date){
                    return dayjs(date).format(this.dateFormat);
                }
                return "无";
            }
        }
    }
</script>

<style scoped>

</style>