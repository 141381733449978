/** 
* 自动生成文件，请勿修改 
* @name: 系统-部门管理
* @description: Depart Controller
* @create: 2024/11/8 10:34:25
* @swagger: 2.0
* 合计:11条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const params = { 
	add : [{"cantonId":{"type":"string","description":"所属行政区划id，即该部门所在的行政区划","required":false},"cantonIds":{"type":"string","description":"行政区划ids,多个逗号分隔（自动关联该部门管理哪些行政区划）","required":false},"depType":{"type":"string","description":"部门类型（数据字典depart_type）","required":false},"departCode":{"type":"string","description":"机构编码","required":true},"departName":{"type":"string","description":"机构/部门名称","required":true},"description":{"type":"string","description":"描述","required":false},"id":{"type":"string","description":"主键id","required":false},"leaderId":{"type":"string","description":"部门负责人id,可空，新建部门后再选择负责人","required":false},"parentId":{"type":"string","description":"父机构ID,一级为0","required":true},"remarks":{"type":"string","description":"备注","required":false}}], 
	del : null, 
	delBatch : null, 
	edit : [{"cantonId":{"type":"string","description":"所属行政区划id，即该部门所在的行政区划","required":false},"cantonIds":{"type":"string","description":"行政区划ids,多个逗号分隔（自动关联该部门管理哪些行政区划）","required":false},"depType":{"type":"string","description":"部门类型（数据字典depart_type）","required":false},"departCode":{"type":"string","description":"机构编码","required":true},"departName":{"type":"string","description":"机构/部门名称","required":true},"description":{"type":"string","description":"描述","required":false},"id":{"type":"string","description":"主键id","required":false},"leaderId":{"type":"string","description":"部门负责人id,可空，新建部门后再选择负责人","required":false},"parentId":{"type":"string","description":"父机构ID,一级为0","required":true},"remarks":{"type":"string","description":"备注","required":false}}], 
	modifyLeader : [{"id":{"type":"string","description":"部门主键id","required":true},"leaderId":{"type":"string","description":"部门负责人id","required":false},"remarks":{"type":"string","description":"备注","required":false}}], 
	queryAll : [{"cantonId":{"type":"string","description":"所属行政区划id，即该部门所在的行政区划"},"depType":{"type":"string","description":"部门类型（数据字典depart_type）"},"departCode":{"type":"string","description":"机构编码"},"departName":{"type":"string","description":"机构/部门名称"},"leaderId":{"type":"string","description":"部门负责人id"},"level":{"type":"string","description":"部门级别，3：区县；2：乡镇（街道）；1：村"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"parentId":{"type":"string","description":"父机构ID,一级为0"}}], 
	queryAllTree : null, 
	queryByCurrentUser : null, 
	queryByUser : null, 
	queryOne : null, 
	queryPage : [{"cantonId":{"type":"string","description":"所属行政区划id，即该部门所在的行政区划"},"depType":{"type":"string","description":"部门类型（数据字典depart_type）"},"departCode":{"type":"string","description":"机构编码"},"departName":{"type":"string","description":"机构/部门名称"},"leaderId":{"type":"string","description":"部门负责人id"},"level":{"type":"string","description":"部门级别，3：区县；2：乡镇（街道）；1：村"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"parentId":{"type":"string","description":"父机构ID,一级为0"}}], 
};
const depart = { 
	/** 1 summary:添加部门	method:post */ 
	add : new APIObject.postObject("/sys/depart/add",params.add), 
	/** 2 summary:删除部门（单条）	method:delete */ 
	del : new APIObject.deleteObject("/sys/depart/del",params.del), 
	/** 3 summary:删除部门（批量）	method:delete */ 
	delBatch : new APIObject.deleteObject("/sys/depart/delBatch",params.delBatch), 
	/** 4 summary:编辑部门	method:post */ 
	edit : new APIObject.postObject("/sys/depart/edit",params.edit), 
	/** 5 summary:编辑部门负责人	method:post */ 
	modifyLeader : new APIObject.postObject("/sys/depart/modifyLeader",params.modifyLeader), 
	/** 6 summary:查询-不分页	method:post */ 
	queryAll : new APIObject.postObject("/sys/depart/queryAll",params.queryAll), 
	/** 7 summary:查询-得到所有组织机构树形结构	method:post */ 
	queryAllTree : new APIObject.postObject("/sys/depart/queryAllTree",params.queryAllTree), 
	/** 8 summary:查询-得到当前登录用户所属部门	method:get */ 
	queryByCurrentUser : new APIObject.getObject("/sys/depart/queryByCurrentUser",params.queryByCurrentUser), 
	/** 9 summary:查询-根据用户id或account查询所属部门	method:get */ 
	queryByUser : new APIObject.getObject("/sys/depart/queryByUser",params.queryByUser), 
	/** 10 summary:查询-根据id查询单条	method:get */ 
	queryOne : new APIObject.getObject("/sys/depart/queryOne",params.queryOne), 
	/** 11 summary:查询-分页	method:post */ 
	queryPage : new APIObject.postObject("/sys/depart/queryPage",params.queryPage), 
};
export default depart;