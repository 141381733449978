<!--
* @program: 前台
* @author: ly
* @component:SLTable
* @description:
* @create: 2023-01-13 09:31
-->
<template>
    <a-table
            size="small"
            bordered
            :columns="cols"
            :row-selection="{ selectedRowKeys: selectedRowKeys ,onChange: onSelectChange }"
            :data-source="dataSource"
            :rowClassName = "rowClass"
            :rowKey="rowKey"
            :pagination="pagination"
            :loading="loading"
            :custom-row="customRow"
            @resizeColumn="handleResizeColumn"
            :scroll="{x:offsetWidth,y:offsetHeight}"
    >
        <template #headerCell="{ column }">
            <div class = "action-column">{{column.title}}</div>
        </template>
        <template #bodyCell="{record, index, column}">
            <template v-if="column.type === 'actions'">
                <slot name="action" :index="index" :record="record" :column="column"/>
            </template>
            <template v-else>
                <DataPresent @click="handleClick(index, record , column)" :index="index+1+passedNum" :column="column" :record="record" :view-model="record.viewModel !== false"/>
            </template>
        </template>

        <template v-if="download" #summary>
            <a-table-summary fixed>
                <a-table-summary-row>
                    <a-table-summary-cell style="text-align: center" :col-span="12">
                        <a-button @click="downLoadExcel" type="primary" danger>
                            下载为excel
                        </a-button>
                    </a-table-summary-cell>
                </a-table-summary-row>
            </a-table-summary>
        </template>
    </a-table>
</template>

<script>
    import LTable from "./LTable";
    export default {
        name: "SLTable",
        mixins:[LTable],
        emits:["selectChange"],
        data() {
            return {
                selectedRowKeys:[],
                selectedRows:null,
            }
        },
        methods: {
            onSelectChange(keys){
                this.selectedRowKeys = keys;
                this.$emit("selectChange",keys);
            }
        }
    }
</script>

<style scoped>

</style>
