<!--
* @program: 前台
* @author: ly
* @component:HeadToolBar
* @description:
* @create: 2023-03-14 17:05
-->
<template>
<!--    {{department}}-->
    <img style="position: fixed;right: 270px;cursor: pointer" @click="headClick_back" src="../../assets/image/main/后台切换.png"  title="返回一张图" >
    <div class="non-select" style="position: fixed;right: 120px;font-size: 14px;font-weight: 500;color:#00A2FF;">欢迎：{{realName}}</div>

    <a-tooltip title="修改密码">
        <a-button type="link" class="user-btn" @click="resetPwd" >
            <template #icon><user-outlined style="color:#00A2FF;"/></template>
        </a-button>
    </a-tooltip>
    <a-button type="link" class="logout-btn"  @click="logout">
        <template #icon><LogoutOutlined style="color:#00A2FF;"/></template>
    </a-button>
</template>

<script>
    import {LogoutOutlined, UserOutlined} from "@ant-design/icons-vue";

    import login from "../../assets/api/sysSets";
    export default {
        name: "HeadToolBar",
        components:{
            UserOutlined,LogoutOutlined
        },
        computed:{
            realName(){
                return this.userInfo?.userInfo.realName;
            },
            department(){
                if(this.userInfo.departs && this.userInfo.departs.length>0){
                    return  this.userInfo.departs[0].departName+" — "
                }
                else{
                    return "";
                }

            }
        },
        data() {
            return {
                userInfo:JSON.parse(localStorage.getItem("userInfo"))
            }
        },
        methods: {
            resetPwd(){
                this.$router.push({ path: '/ResetPassword'});
            },
            logout(){
                login.logout.get(this,{},res=>{
                    if(res.data.success){
                        this.$message.success('退出成功！')
                        setTimeout(()=>{
                            localStorage.setItem("userInfo","");
                            localStorage.setItem("token","");
                            localStorage.setItem("menus","");
                            this.$router.push({path: '/'});
                        },2000)
                    }
                })
            },
            headClick_back(){
                this.$router.push({ path: '/ApictureMainPage' });
            },
        }
    }
</script>

<style scoped>

</style>
