<!--
* @description：地图查询条件
* @author:rzl
* @date:2022/1/20 11:40
-->

<template>
    <div class="map-tool-bar" :style="op_map_tool">
        <!--    <div class="op-tool-tb" :style="op_tool_tb">
              <img style="cursor: pointer;" :src="op_tool_tb_url" @click="op_expand_click">
            </div>-->

        <!-- @mouseenter="op_expand_enter" @mouseleave="op_expand_leave" -->
        <div class="xm_query2" :style="op_content2">
            <div v-for="item in list_img2" :key="item.id" :class="[img_div2?'img-div2-none':'img-div2-block']">
                <div>
                    <img
                            :class="item.class" :title="item.tip" :id="item.id" :src="item.img"
                            @mouseenter="enter_op(item)" @mouseleave="leave_op(item)" @click="click_op(item)"
                    />
                </div>
                <div v-show="op_show_text2">
                    {{item.name}}
                </div>
            </div>
        </div>


        <div class="xm_query" :style="op_content">
            <div v-for="item in list_img" :key="item.id" class="img-div" v-show="op_show">
                <div>
                    <img
                            :class="item.class" :title="item.tip" :id="item.id" :src="item.img"
                            @mouseenter="enter_op(item)" @mouseleave="leave_op(item)" @click="click_op(item)"
                    />
                </div>
                <div v-show="op_show_text">
                    {{item.name}}
                </div>
            </div>
            <div class="op-tool" v-show="!op_show">
                <div class="op-tool-img">
                    <img :src="require('@/page/Map/images/工具-默认.png')">
                </div>
                <div class="op-tool-text">
                    操作工具
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import mapToolConfig from "@/page/Map/Apicture/mapToolConfig";

    export default {
        name: "mapToolBar",
        data() {
            return {
                list_img: mapToolConfig.list_img,
                list_img2: mapToolConfig.list_img2,
                selected_type: '',// 选中图标type

                op_map_tool: 'width:240px;',
                op_show: true,
                op_show_text: true,
                op_show_text2: true,
                op_content: 'width:180px;',
                op_content2: 'width:0px;',
                img_div2: true,
                op_tool_tb: '',
                op_tool_tb_url: require('@/page/Map/images/展开箭头.png'),
                flag_op_tb: true,// 图层图标展开收缩：true:展开；false:收缩
                op_select: false,
            }
        },
        methods: {
            op_expand_click() {
                this.op_select = !this.op_select;
                this.flag_op_tb = !this.flag_op_tb;
                if (this.flag_op_tb) {
                    this.op_expand_enter();
                } else {
                    this.op_show = false;
                    this.op_map_tool = "width:100px;";
                    this.op_content = "width:70px;"
                    setTimeout(() => {
                        this.op_show_text = false;
                    }, 500);
                    this.op_tool_tb_url = require('@/page/Map/images/展开箭头.png');
                }
            },
            op_expand_enter() {
                this.flag_op_tb = true;
                this.op_show = true;
                this.op_map_tool = "width:240px;";
                this.op_content = "width:180px;"
                setTimeout(() => {
                    this.op_show_text = true;
                }, 500);
                this.op_tool_tb_url = require('@/page/Map/images/收起箭头.png');
            },
            op_expand_leave() {
                if (!this.op_select) {
                    this.flag_op_tb = false;
                    this.op_show = false;
                    this.op_map_tool = "width:100px;";
                    this.op_content = "width:70px;"
                    setTimeout(() => {
                        this.op_show_text = false;
                    }, 500);
                    this.op_tool_tb_url = require('@/page/Map/images/展开箭头.png');
                }
            },
            send_expand_open() {

                this.op_content2 = "width:110px;border: 1px solid #3D8FC8;"
                setTimeout(() => {
                    this.img_div2 = false;
                }, 400);
            },
            send_expand_off() {
                this.img_div2 = true;
                this.op_content2 = "width:0px;"
            },
            /**
             * @Description:鼠标悬停进入
             * @params: 操作类别
             * @return:
             * @Author: rzl
             * @Date: 2022/4/13
             */
            enter_op(arg) {
                console.log('arg', arg)
                let but_id = arg.id;
                this.list_img.forEach((item, index) => {
                    if (!item.selected) {
                        if (item.id != but_id) {
                            item.img = item.leave_img;
                        } else {
                            if (item.recovery) {
                                item.img = item.enter_img;
                            } else {
                                item.img = item.enter_img;
                            }
                        }
                    }

                })
            },
            /**
             * @Description: 鼠标离开
             * @params: 操作类别
             * @return:
             * @Author: rzl
             * @Date: 2022/4/13
             */
            leave_op(arg) {
                this.list_img.forEach((item, index) => {
                    if (!item.selected) {
                        item.img = item.leave_img;
                    }
                })
            },
            /**
             * @Description: 点击
             * @params: 操作类别
             * @return:
             * @Author: rzl
             * @Date: 2022/4/13
             */
            click_op(arg) {
                this.op_select = true;
                let type = arg.type;
                this.selected_type = type;
                let but_id = arg.id;
                let selectOp = ''
                this.list_img.forEach((item, index) => {
                    if (item.id == but_id) {
                        selectOp = item
                        item.selected = !item.selected
                        item.selected ? item.img = item.click_img : item.img = item.leave_img
                    } else {
                        item.selected = false
                        item.img = item.leave_img
                    }
                })
                this.list_img2.forEach((item1, index) => {
                    if (item1.id == but_id) {
                        selectOp = item1
                        console.log('selectOp',selectOp)
                        item1.selected = !item1.selected
                        item1.selected ? item1.img = item1.click_img : item1.img = item1.leave_img
                    } else {
                        item1.selected = false
                        item1.img = item1.leave_img
                    }
                })
                if (arg.type == 'notSend') {
                    this.click_methods(type);
                    return
                }

                if (selectOp.selected) {
                    this.click_methods(type);
                } else {
                    if (arg.type != 'notSend') {
                        this.$parent.remove_measurement();
                    }
                }

            },
            /** 清除工具选中，改为默认 **/
            clean_op() {
                let type = this.selected_type;
                this.list_img.forEach((item, index) => {
                    item.img = item.leave_img;
                    item.selected = false;
                })
            },
            click_methods(opType) {
                switch (opType) {
                    case "sbdx": {
                        this.$parent.map_djcx();
                        break;
                    }
                    case "jxkx": {
                        this.$parent.hgfxChange();
                        break;
                    }
                    case "shpsc": {
                        this.$parent.uploadTb();
                        break;
                    }
                    case "notSend": {
                        this.$parent.noteSends();
                        break;
                    }
                    case "cleans": {
                        this.$parent.remove_measurement();
                        this.list_img.forEach((el, i) => {
                            this.list_img[i].selected = false
                            this.list_img[i].img = this.list_img[i].leave_img
                        })
                        break;
                    }
                }
            },

        }
    }
</script>

<style scoped>
    .map-tool-bar {
        right: 1px;
        position: absolute;
        width: 100px;
        transition: width 1s;
    }

    .xm_query {
        position: absolute;
        z-index: 1000;
        top: 26px;

        width: 180px;
        height: 64px;
        background: linear-gradient(180deg, rgba(0, 145, 191, 0.53) 0%, rgba(0, 34, 58, 0.4) 100%);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        border: 1px solid #3D8FC8;
        transition: width 1s;
        float: left;
        right: 10px;
    }

    .xm_query2 {
        position: absolute;
        z-index: 1000;
        top: 26px;

        width: 0px;
        height: 64px;
        background: linear-gradient(180deg, rgba(0, 145, 191, 0.53) 0%, rgba(0, 34, 58, 0.4) 100%);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        /* border: 1px solid #3D8FC8;*/
        transition: width 1s;
        float: left;
        right: 200px;
    }

    .op-tool-tb {
        transition: width 1s;
        top: 40px;
        position: absolute;
        z-index: 1000;
        float: left;
    }

    .op-tool {
        margin-top: 8px;
    }

    .op-tool-text {
        font-size: 10px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #B7E5EF;
    }

    .img-div {
        width: 30%;
        float: left;
        margin: 15px 2px;
        font-size: 10px;
        font-weight: 400;
        color: #B7E5EF;
    }

    .img-div2-none {
        width: 45%;
        float: left;
        margin: 15px 2px;
        font-size: 10px;
        font-weight: 400;
        color: #B7E5EF;
        display: none;
    }

    .img-div2-block {
        width: 45%;
        float: left;
        margin: 15px 2px;
        font-size: 10px;
        font-weight: 400;
        color: #B7E5EF;
        display: block;
    }
</style>
