/** 
* 自动生成文件，请勿修改 
* @name: 二维码-包含操作手册二维码、app最新版二维码
* @description: QR Code Controller
* @create: 2023/4/12 16:48:00
* @swagger: 2.0
* 合计:2条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const code = { 
	/** 1 summary:获取APP最新版二维码
	description:接口说明：获取APP最新版二维码
	method:get
	params:
	*/ 
	appVersion : new APIObject.getObject("/qr/code/appVersion"), 
	/** 2 summary:获取操作手册二维码
	description:接口说明：获取操作手册二维码
	method:get
	params:
	*/ 
	operationManual : new APIObject.getObject("/qr/code/operationManual"), 
};
export default code;