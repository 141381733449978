/** 
* 自动生成文件，请勿修改 
* @name: 系统-数据字典管理
* @description: Sys Dict Controller
* @create: 2024/11/8 10:34:25
* @swagger: 2.0
* 合计:8条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const params = { 
	add : [{"description":{"type":"string","description":"描述","required":false},"dictCode":{"type":"string","description":"字典编码","required":true},"dictName":{"type":"string","description":"字典名称","required":true},"id":{"type":"string","description":"主键id","required":false},"remarks":{"type":"string","description":"备注","required":false}}], 
	del : null, 
	delBatch : null, 
	edit : [{"description":{"type":"string","description":"描述","required":false},"dictCode":{"type":"string","description":"字典编码","required":true},"dictName":{"type":"string","description":"字典名称","required":true},"id":{"type":"string","description":"主键id","required":false},"remarks":{"type":"string","description":"备注","required":false}}], 
	findOneByCode : null, 
	queryAll : [{"dictCode":{"type":"string","description":"字典编码"},"dictName":{"type":"string","description":"字典名称"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"}}], 
	queryOne : null, 
	queryPage : [{"dictCode":{"type":"string","description":"字典编码"},"dictName":{"type":"string","description":"字典名称"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"}}], 
};
const sysDict = { 
	/** 1 summary:添加数据字典	method:post */ 
	add : new APIObject.postObject("/sys/sysDict/add",params.add), 
	/** 2 summary:删除数据字典（单条）	method:delete */ 
	del : new APIObject.deleteObject("/sys/sysDict/del",params.del), 
	/** 3 summary:删除数据字典（批量）	method:delete */ 
	delBatch : new APIObject.deleteObject("/sys/sysDict/delBatch",params.delBatch), 
	/** 4 summary:编辑数据字典	method:post */ 
	edit : new APIObject.postObject("/sys/sysDict/edit",params.edit), 
	/** 5 summary:查询-单条(根据字典code得到单条)	method:get */ 
	findOneByCode : new APIObject.getObject("/sys/sysDict/findOneByCode",params.findOneByCode), 
	/** 6 summary:查询-不分页	method:post */ 
	queryAll : new APIObject.postObject("/sys/sysDict/queryAll",params.queryAll), 
	/** 7 summary:查询-单条	method:get */ 
	queryOne : new APIObject.getObject("/sys/sysDict/queryOne",params.queryOne), 
	/** 8 summary:查询-分页	method:post */ 
	queryPage : new APIObject.postObject("/sys/sysDict/queryPage",params.queryPage), 
};
export default sysDict;