/** 
* 自动生成文件，请勿修改 
* @name: 公共模块-文件上传/下载/预览（业务表直接存储附件url的模式，现在有单独的表存储文件信息了，上传不用这个）
* @description: File Controller
* @create: 2023/4/12 16:48:00
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const file = { 
	/** 1 summary:app下载二维码生成
	description:接口说明：app下载二维码生成
	method:get
	params:
	*/ 
	app : new APIObject.getObject("/sys/file/app"), 
	/** 2 summary:文件删除
	description:接口说明：文件删除，可多条，逗号分割
	method:post
	params:需要配合Column函数使用
	*/ 
	batchDeleteFile : new APIObject.postObject("/sys/file/batchDeleteFile"), 
	/** 3 summary:文件预览/下载,需前端指定接收方式下载
	description:接口说明：文件预览/下载
	method:get
	params:需要配合Column函数使用
	*/ 
	download : new APIObject.getObject("/sys/file/download"), 
	/** 4 summary:图片引用（可直接src=...进行引用图片展示）
	description:接口说明：图片引用，url直接替换**，如：sys/file/image/3/aaa.jpg
	method:get
	params:
	*/
	image : new APIObject.getObject("/sys/file/image"),
	/** 5 summary:文件预览/下载(直接强制调用浏览器的下载)
	description:接口说明：文件预览/下载，url直接替换**，如：sys/file/static/3/aaa.jpg
	method:get
	params:
	*/
	static : new APIObject.getObject("/sys/file/static"),
	/** 6 summary:文件上传-单文件
	description:接口说明：文件上传-单文件
	method:post
	params:需要配合Column函数使用
	*/ 
	upload : new APIObject.postObject("/sys/file/upload"), 
	/** 7 summary:文件上传-多文件
	description:接口说明：文件上传-多文件
	method:post
	params:需要配合Column函数使用
	*/ 
	uploads : new APIObject.postObject("/sys/file/uploads"), 
};
export default file;