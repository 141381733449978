<!--
* @program: office_automation 
* @author: ly
* @component:typeStrArr 
* @description: 
* @create: 2022-06-14 11:39
-->
<template>
    <div style="width: 100%" @click="onClick">
        {{strArr}}
    </div>
</template>

<script>
    import typeBase from "./typeBase";

    export default {
        name: "typeStrArr",
        mixins:[typeBase],
        computed:{
            strArr(){
                return this.record[this.column.dataIndex].toString();
            }
        }
    }
</script>

<style scoped>

</style>