import Column from "./column";
import ColumnType from "./column_type";
import GroupColumn from "./GroupColumn";
import {getAllNodes} from "@/assets/utils/general";

/**
 * @program: 前台
 * @author: ly
 * @decription:
 * @create: 2020-08-27 10:23
 **/

export default class Table{
    /**
     * @param name 表名
     * @param CNName 表中文名
     * @param columnArray 缩略版本的表字段
     */
    constructor(name,CNName) {
        this.name = name;
        this.CNName = CNName;
        this.columnArray = [];
        this.assFileArr = [];
        this.searchSegments = [];
        this._columnArray =[];
        this.actions = [];
    }
    set columnArray(columnArray){
        this._columnArray = columnArray;
        let keys = [];
        for(let col of this.allSubColumns){
            if(col.dataIndex){
                keys.push(col.dataIndex)
            }
        }
        if((new Set(keys)).size !== keys.length){ //确保keys不重复，keys有重复的时候失败
            this._columnArray = [new Column("有重复的序号","dataIndex",ColumnType.String,false).setTableView(100)];
        }
    }
    get columnArray(){
        return this._columnArray.concat(this.actions);//带上 table的actions一起返回
    }
    get allSubColumns(){
        return getAllNodes([],this._columnArray)
    }
    getFormData(){
        let data={};
        for(let col of this.allSubColumns){
            let seg = col.dataIndex;
            data[seg] = col.defaultValue;
        }
        return data;
    }
    getFormColumns(){
        let arr = [];
        for(let col of this.allSubColumns){
            if(col instanceof Column){
                arr.push(col);
            }
        }
        return arr;
    }
    getTableColumns(){
        return this.columnArray;
    }
    //随机生成测试数据
    getObject() {
        let data = {};
        for (let col of this.getFormColumns()) {
            if(col.enums?.length>0){
                let index = Math.floor(Math.random()*col.enums?.length);
                data[col.dataIndex] = col.enums[index];
            }
            else{
                data[col.dataIndex] = Math.floor(Math.random()*1000)
            }
        }
        return data;
    }
    //随机生成测试数据组
    getObjectArray() {
        return [this.getObject(),this.getObject(),this.getObject(),this.getObject(),this.getObject(),
            this.getObject(),this.getObject(),this.getObject(),this.getObject(),this.getObject(),
            this.getObject(),this.getObject(),this.getObject(),this.getObject(),this.getObject(),
            this.getObject(),this.getObject(),this.getObject(),this.getObject(),this.getObject()];
    }
}

