<!--
* @program: TYH_office_automation 
* @author: ly
* @component:dataTypes 
* @description: 
* @create: 2021-12-06 14:17
-->
<template>
    <div>
        
    </div>
</template>

<script>
    
    import typeBooleanCheck from "./typeBooleanCheck";
    import typeDate from "./typeDate";
    import typeEnum from "./typeEnum";
    import typeString from "./typeString";
    import typeNumber from "./typeNumber";
    import typeBoolean from "./typeBoolean";
    import typeMonth from "./typeMonth";
    import typeIndex from "./typeIndex";
    import typeIdReplace from "./typeIdReplace";
    import typeIdReplace2 from "./typeIdReplace2";
    import typeSelector from "./typeSelector";
    import columnType from "../../assets/tables/column_type";
    import typeObjectReplace from "./typeObjectReplace";
    import typeRemoteEnum from "./typeRemoteEnum";
    import typeStrArr from "./typeStrArr";

    import typeIdReplaceXmgd from "./typeIdReplaceXmgd";
    import typeYear from "./typeYear";
    import typePassword from "./typePassword";
    import typeOperateEnum from "./typeOperateEnum";
    import typePreViewFile from "./typePreViewFile";

    import typeGeometry from "./typeGeometry";
    import typeSubSegment from "./typeSubSegment";
    import typeDepartsRole from "./typeDepartsRole";
    import typeUserRoles from "./typeUserRoles";
    export default {
        name: "dataTypes",
        components:{
            typeBooleanCheck, typeDate, typeEnum,
            typeString, typeNumber, typeBoolean,
            typeMonth, typeIndex, typeIdReplace,
            typeIdReplace2, typeSelector,
            typeObjectReplace,
            typeRemoteEnum,typeStrArr,
          typeIdReplaceXmgd,typeYear,typePassword,typeOperateEnum,typePreViewFile,typeGeometry,
            typeSubSegment,typeDepartsRole,typeUserRoles
        },
        computed:{
            type() {
                switch (this.column.type) {
                    case columnType.String:{return "typeString";}
                    case columnType.Number:{return "typeNumber";}
                    case columnType.Date:{return "typeDate"}
                    case columnType.BooleanCheck:{return "typeBooleanCheck"}
                    case columnType.Enum:{return "typeEnum"}
                    case columnType.Boolean:{return "typeBoolean"}
                    case columnType.Month:{return "typeMonth"}
                    case columnType.Index:{return "typeIndex"}
                    case columnType.IdReplace:{return "typeIdReplace"}
                    case columnType.IdReplace2:{return "typeIdReplace2"}
                    case columnType.PreViewFile:{return "typePreViewFile"}
                    case columnType.Avatar:{return "typeAvatar"}
                    case columnType.Selector:{return "typeSelector"}
                    case columnType.ObjectReplace:{return "typeObjectReplace"}
                    case columnType.RemoteEnum:{return "typeRemoteEnum"}
                    case columnType.IdReplaceXmgd:{return "typeIdReplaceXmgd"}
                    case columnType.Year:{return "typeYear"}
                    case columnType.Password:{return "typePassword"}
                    case columnType.OperateEnum:{return "typeOperateEnum"}
                    case columnType.Geometry:{return "typeGeometry"}
                    case columnType.SubSegment:{return "typeSubSegment"}
                    case columnType.DepartsRole:{return "typeDepartsRole"}
                    case columnType.UserRoles:{return "typeUserRoles"}
                    default :{return "";}
                }
            },
        },
    }
</script>

<style scoped>

</style>