<!--
* @program: tyh-oa
* @author: ly
* @description: loginPage
* @create: 2021-03-19 16:16
-->
<template>
    <a-layout class="layout-main" v-on:keyup.enter="login">
        <a-layout-header class="layout-header">
            <oaBanner/>
        </a-layout-header>
        <a-layout-content class="main">
            <Mark1 class="styles_pulse_1" style="position: fixed;left: 780px;top: 420px;"/>
            <Mark2 class="styles_pulse_2" style="position: fixed;left: 600px;top: 230px;"/>
            <Mark3 class="styles_pulse_3" style="position: fixed;left: 230px;top: 150px;"/>
            <Mark4 class="styles_pulse_4" style="position: fixed;left: 60px;top: 500px;"/>
            <div class="card">
                <a-form
                        style="width: 300px;margin: 100px 50px"
                        :model="formData"
                        :label-col="labelCol"
                        :wrapper-col="wrapperCol">
                    <a-form-item :colon="false" v-if="loginType==1">
                        <template #label/>
                        <a-input v-model:value="formData.account" :maxlength="11" placeholder="用户名">
                            <template #prefix>
                                <UserOutlined style="color: rgba(4, 201, 251, 0.8)"/>
                            </template>
                        </a-input>
                    </a-form-item>
                    <a-form-item :colon="false" v-if="loginType==1">
                        <template #label/>
                        <a-input-password v-model:value="formData.password" type="password" placeholder="密码">
                            <template #prefix>
                                <LockOutlined style="color: rgba(4, 201, 251, 0.8)"/>
                            </template>
                        </a-input-password>
                    </a-form-item>

                    <!--                  短信验证登录-->
                    <a-form-item :colon="false" v-if="loginType==2">
                        <template #label/>
                        <a-input v-model:value="formData.phone" :maxlength="11" placeholder="手机号">
                            <template #prefix>
                                <UserOutlined style="color: rgba(4, 201, 251, 0.8)"/>
                            </template>
                        </a-input>
                    </a-form-item>
                    <a-form-item :colon="false">
                        <template #label/>
                        <div style="display: flex;justify-content: space-between;align-items: center">
                            <a-input style="width: 50%;" v-model:value="formData.captcha" placeholder="图形验证码">
                            </a-input>
                            <div style="width: 40%;"><img @click="getCaptcha" style="width: 100%;" :src="image"/></div>
                        </div>
                    </a-form-item>
                    <a-form-item :colon="false" v-if="loginType==1">
                        <template #label/>
                        <div style="display: flex;align-items: center;">
                            <a-checkbox v-model:checked="checked">记住密码</a-checkbox>
                        </div>
                    </a-form-item>
                    <a-form-item :colon="false" v-if="loginType==2">
                        <template #label/>
                        <div style="display: flex;justify-content: space-between;align-items: center">
                            <a-input style="width: 60%;" v-model:value="formData.smsCode" placeholder="短信验证码">
                            </a-input>
                            <a-button style="width: 60px" :disabled="checkSend()" type="primary" @click="sendCap">
                                发送
                            </a-button>
                        </div>
                    </a-form-item>

                    <a-form-item :colon="false">
                        <template #label/>
                        <div style="display: flex;justify-content: space-evenly;">
                            <a-button style="width: 200px" type="primary" @click="login">
                                登录
                            </a-button>
                        </div>
                    </a-form-item>
                    <a-form-item :colon="false">
                        <template #label/>
                        <!--                        style="display: flex;align-items: center;justify-content: space-between;"-->
                        <div style="display: flex;justify-content: space-between">
                            <span type="link" @click="loginType=2" v-if="loginType==1" style="margin-top: 4px">
                                短信登录
                            </span>
                            <span type="link" @click="loginType=1" v-if="loginType==2" style="margin-top: 4px">
                                密码登录
                            </span>
                            <a-button type="link" @click="forgetPassword">
                                忘记密码
                            </a-button>
                            <!--                            <a-button type="link"  @click="reg">
                                                            立即注册
                                                        </a-button>-->
                        </div>
                    </a-form-item>
                </a-form>
            </div>
            <oa-footer/>
        </a-layout-content>
    </a-layout>
</template>

<script>
    import oaBanner from "./oaBanner";
    import oaFooter from "./oaFooter";
    import {UserOutlined, LockOutlined} from '@ant-design/icons-vue';
    import login from "../../assets/api/sysSets";
    import Mark1 from "./Mark1";
    import Mark2 from "./Mark2";
    import Mark3 from "./Mark3";
    import Mark4 from "./Mark4";
    import {readToken} from "../../assets/utils/APIObject";
    import ApplyComponents from "../../Components";
    import RouterGuards from "../../routerGuards";
    import sysDict from "../../assets/api/sysDict";
    import role from "../../assets/api/role";
    import code from "../../assets/api/code";

    export default {
        name: "loginPage",
        components: {
            oaBanner, oaFooter, UserOutlined, LockOutlined,
            Mark1, Mark2, Mark3, Mark4
        },
        data() {
            return {
                formData: {
                    account: "",
                    password: "",
                    captcha: "1",
                    checkKey: "",
                    phone: "",
                    smsCode: ''
                },
                labelCol: {span: 6},
                wrapperCol: {span: 16},
                checked: false,
                imageCode: false,
                image: null,
                needCaptcha: true,
                loginType: 1//密码，2短信

            }
        },
        methods: {
            login() {
                if (this.loginType == 1) {
                    if (this.checkData()) {
                        localStorage.setItem("account", this.formData.account);
                        localStorage.setItem("password", this.formData.password);
                        localStorage.setItem("savePassword", this.checked ? "t" : "")
                        login.login.post(this, {
                            account: this.formData.account,
                            password: this.formData.password,
                            captcha: this.formData.captcha,
                            checkKey: this.formData.checkKey,
                        }, res => {
                            if (res.data.success) {
                                localStorage.setItem("token", res.data.result.token);
                                localStorage.setItem("userInfo", JSON.stringify(res.data.result));
                                let menus = res.data.result.menus;
                                localStorage.setItem("menus", JSON.stringify(menus));
                                /** 登录成功后查询数据字典 */
                                sysDict.queryAll.post(this, {}, res => {
                                    if (res.data.success) {
                                        let dict = res.data.result;
                                        localStorage.setItem("dict", JSON.stringify(dict))
                                    }
                                });
                                /** 登录成功后查询所有角色 */
                                role.queryAll.post(this, {}, res => {
                                    this.loading = false;
                                    if (res.data.success) {
                                        let roles = res.data.result;
                                        localStorage.setItem("roles", JSON.stringify(roles));
                                    }
                                });

                                /** 登录成功后添加动态路由 */
                                let routes = [];
                                ApplyComponents(menus, routes);
                                for (let item of routes) {
                                    item.path = "/" + item.path;
                                    this.$router.addRoute(item);
                                }
                                RouterGuards(this.$router);
                                //this.$router.push({ name: 'MainPage'});
                                this.$router.push({path: '/ApictureMainPage'});
                            } else {
                                this.needCaptcha = res.data.result.needCaptcha;
                                if (this.needCaptcha) {
                                    this.getCaptcha();
                                }
                                this.$message.error(res.data.message);
                            }
                        })
                    }

                }
         //短信验证登录
                if (this.loginType == 2) {
                    if (this.checkData1()) {
                        login.smsLogin.post(this, {
                            phone:this.formData.phone,
                            smsCode:this.formData.smsCode,
                        }, res => {
                            console.log(222222,res)
                            if (res.data.success) {
                                localStorage.setItem("token", res.data.result.token);
                                localStorage.setItem("userInfo", JSON.stringify(res.data.result));
                                let menus = res.data.result.menus;
                                localStorage.setItem("menus", JSON.stringify(menus));
                                /** 登录成功后查询数据字典 */
                                sysDict.queryAll.post(this, {}, res => {
                                    if (res.data.success) {
                                        let dict = res.data.result;
                                        localStorage.setItem("dict", JSON.stringify(dict))
                                    }
                                });
                                /** 登录成功后查询所有角色 */
                                role.queryAll.post(this, {}, res => {
                                    this.loading = false;
                                    if (res.data.success) {
                                        let roles = res.data.result;
                                        localStorage.setItem("roles", JSON.stringify(roles));
                                    }
                                });

                                /** 登录成功后添加动态路由 */
                                let routes = [];
                                ApplyComponents(menus, routes);
                                for (let item of routes) {
                                    item.path = "/" + item.path;
                                    this.$router.addRoute(item);
                                }
                                RouterGuards(this.$router);
                                //this.$router.push({ name: 'MainPage'});
                                this.$router.push({path: '/ApictureMainPage'});
                            } else {
                                this.$message.error(res.data.message);
                                this.needCaptcha = res.data.result.needCaptcha;
                                if (this.needCaptcha) {
                                    this.getCaptcha();
                                }

                            }
                        })
                    }

                }
            },
            checkSend() {
                if (!this.formData.captcha || !this.formData.phone) {
                    return true
                }
                const phoneRegex = /^1[34578]\d{9}$/;
                if (this.formData.phone) {
                    return phoneRegex.test(this.formData.phone) ? false : true
                }

            },
            checkData() {
                if (!this.formData.account) {
                    this.$message.error("请输入用户名");
                    return false;
                }
                if (!this.formData.password) {
                    this.$message.error("请输入密码");
                    return false;
                }
                if (!this.formData.captcha) {
                    this.$message.error("请输入验证码");
                    return false;
                }
                return true;
            },
            checkData1() {
                if (!this.formData.phone) {
                    this.$message.error("请输入手机号");
                    return false;
                }
                if (!this.formData.smsCode) {
                    this.$message.error("请输入短信验证码");
                    return false;
                }
                return true;
            },
            sendCap() {
                login.smsCode.post(this, {
                    captcha: this.formData.captcha,
                    checkKey: this.formData.checkKey,
                    phone: this.formData.phone,
                }, (res) => {
                    if (res.data.success) {
                        this.$message.success('短信发送成功，请注意查收！')
                    }else{
                        this.$message.error(res.data.message)
                    }
                })
            },
            getCaptcha() {
                this.formData.captcha = null;
                this.formData.checkKey = new Date().valueOf();
                login.randomImage.get(this, {key: this.formData.checkKey}, res => {
                    if (res.data.success) {
                        this.image = res.data.result
                    }
                })
            },
            forgetPassword() {
                this.$router.push({path: '/ForgetPassword'});
            },
            reg() {
                this.$router.push({path: '/RegisterPage'});
            }
        },
        created() {
            this.formData.account = localStorage.getItem("account");
            this.checked = (localStorage.getItem("savePassword") === "t");
            if (this.checked) {
                this.formData.password = localStorage.getItem("password");
            }
            this.getCaptcha();
            readToken();
        }
    }
</script>

<style scoped>
    .layout-main {
        cursor: default;
    }

    .card {
        position: absolute;
        right: 200px;
        top: 26%;
        width: 460px;
        height: 480px;
        /*background: rgba(255, 255 ,255, 0.8);*/
        border-radius: 16px 16px 16px 16px;
        opacity: 1;

        background: url('../../assets/image/login/登录框.png')
    }

    .styles_pulse_1 {
        -webkit-animation: styles_pulse-loader_1 4s ease-out infinite;
        animation: styles_pulse-loader_1 4s ease-out infinite;
    }

    @keyframes styles_pulse-loader_1 {
        0% {
            transform: scale(0.8);
        }
        80% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(0.8);
        }
    }

    .styles_pulse_3 {
        -webkit-animation: styles_pulse-loader_3 4s ease-out infinite;
        animation: styles_pulse-loader_3 4s ease-out infinite;
    }

    @keyframes styles_pulse-loader_3 {
        0% {
            transform: scale(0.8);
        }
        20% {
            transform: scale(1.2);
        }
        100% {
            transform: scale(0.8);
        }
    }

    .styles_pulse_2 {
        -webkit-animation: styles_pulse-loader_2 4s ease-out infinite;
        animation: styles_pulse-loader_2 4s ease-out infinite;
    }

    @keyframes styles_pulse-loader_2 {
        0% {
            transform: scale(1.2);
        }
        80% {
            transform: scale(0.8);
        }
        100% {
            transform: scale(1.2);
        }
    }

    .styles_pulse_4 {
        -webkit-animation: styles_pulse-loader_4 4s ease-out infinite;
        animation: styles_pulse-loader_4 4s ease-out infinite;
    }

    @keyframes styles_pulse-loader_4 {
        0% {
            transform: scale(1.2);
        }
        20% {
            transform: scale(0.8);
        }
        100% {
            transform: scale(1.2);
        }
    }

    .logo_div {
        margin-left: 49px;
        margin-top: 31px;
        position: absolute;
    }

    .abutLogin {
        width: 368px;
        height: 60px;
        background: #0B58DA;
        box-shadow: 0px 4px 6px 1px rgba(69, 103, 161, 0.4);
        border-radius: 12px 12px 12px 12px;
        opacity: 1;
    }

    .login_title {
        font-size: 31px;
        font-weight: 600;
        color: #6A7992;
        position: absolute;
        margin: 41px -162px;
    }

    .login_put {
        height: 50px;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        border: 1px solid #d3cccc;
        background: rgba(255, 255, 255, 0.4);
    }

    .logo_content {
        position: absolute;
    }
</style>
