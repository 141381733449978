<!--
* @program: office_automation2.0 
* @author: ly
* @component:Page404 
* @description: 
* @create: 2022-11-08 16:37
-->
<template>
    <div class="layout-main">
        <a-result style="padding: 200px" status="404" title="404" sub-title="无法访问">
            <template #extra>
                <a-button @click="backHome" type="primary">返回</a-button>
            </template>
        </a-result>
    </div>

</template>

<script>
    export default {
        name: "Page-404",
        data() {
            return {}
        },
        methods: {
            backHome() {
                this.$router.go(-2);
            }
        }
    }
</script>

<style scoped>

</style>