/** 
* 自动生成文件，请勿修改 
* @name: 公共模块-天气模块
* @description: Weather Controller
* @create: 2024/11/8 10:34:25
* @swagger: 2.0
* 合计:1条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const params = { 
	online : null, 
};
const weather = { 
	/** 1 summary:获取在线天气	method:get */ 
	online : new APIObject.getObject("/sys/weather/online",params.online), 
};
export default weather;