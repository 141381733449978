<!--
* @program: tyh-oa
* @author: ly
* @description: typeEnum
* @create: 2021-03-23 10:44
-->
<template>
    <div v-if="viewModel" >
        <a-tag  :style="'border: 1px solid '+rColor" @click="onClick">
          <div  :style="'color:'+rColor" >{{getTags(presentValue)}}</div>
        </a-tag>
    </div>
    <div v-else>
        <a-select v-if="editable"  @blur="blurTrigger" allowClear
                  :placeholder="placeholder"
                  v-model:value="record[column.dataIndex]" style="width: 100%"
                  :options="options"
                  @change="columnTrigger">
            <template #option="{label,color}">
                <a-tag><div :style="'color:'+ColorMap[color]" >{{label}}</div></a-tag>
            </template>
        </a-select>
        <div v-else >
            <a-tag @click="onClick">
                <div :style="'color:'+rColor">{{getTags(presentValue)}}</div>
            </a-tag>
        </div>
    </div>
</template>

<script>
    import typeBase from "./typeBase";
    import ColorMap from "../../assets/utils/ColorMap";
    import {MyEnum} from "../../assets/tables/column";
    export default {
        name: "typeEnum",
        mixins:[typeBase],
        computed:{
            options(){
                let options = [];
                for(let item of this.column.enums){
                    if(item instanceof MyEnum){
                        options.push(item)
                    }
                    else{
                        options.push(new MyEnum(item,item,item))
                    }
                }
                return options;
            },
            rColor(){
                if(ColorMap[this.color]){
                    return ColorMap[this.color];
                }
                else{
                    return "#295584"
                }
            }
        },
        data(){
            return{
                color:"",
                defaultColor:"pink",
                ColorMap:ColorMap
            }
        },
        methods:{
            getTags(value){
                let label = "";
                let option = this.options.find(item=>item.value === value);
                if(option){
                    label = option.label;
                    this.color = option.color;
                }
                else{
                    label = value;
                }
                return label;
            },
        }
    }
</script>

<style scoped>
.tagDiv{
  background: rgb(50 90 140 / 85%);
}
</style>
