/** 
* 自动生成文件，请勿修改 
* @name: 系统-角色管理
* @description: Role Controller
* @create: 2024/11/8 10:34:25
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const params = { 
	add : [{"description":{"type":"string","description":"描述","required":false},"id":{"type":"string","description":"主键id","required":false},"remarks":{"type":"string","description":"备注","required":false},"roleCode":{"type":"string","description":"角色编码","required":true},"roleName":{"type":"string","description":"角色名称","required":true}}], 
	del : null, 
	delBatch : null, 
	edit : [{"description":{"type":"string","description":"描述","required":false},"id":{"type":"string","description":"主键id","required":false},"remarks":{"type":"string","description":"备注","required":false},"roleCode":{"type":"string","description":"角色编码","required":true},"roleName":{"type":"string","description":"角色名称","required":true}}], 
	queryAll : [{"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"roleCode":{"type":"string","description":"角色编码"},"roleName":{"type":"string","description":"角色名称"}}], 
	queryByMenu : null, 
	queryByUser : null, 
	queryOne : null, 
	queryPage : [{"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"roleCode":{"type":"string","description":"角色编码"},"roleName":{"type":"string","description":"角色名称"}}], 
};
const role = { 
	/** 1 summary:添加角色	method:post */ 
	add : new APIObject.postObject("/sys/role/add",params.add), 
	/** 2 summary:删除角色（单条）	method:delete */ 
	del : new APIObject.deleteObject("/sys/role/del",params.del), 
	/** 3 summary:删除角色（批量）	method:delete */ 
	delBatch : new APIObject.deleteObject("/sys/role/delBatch",params.delBatch), 
	/** 4 summary:编辑角色	method:post */ 
	edit : new APIObject.postObject("/sys/role/edit",params.edit), 
	/** 5 summary:查询-不分页	method:post */ 
	queryAll : new APIObject.postObject("/sys/role/queryAll",params.queryAll), 
	/** 6 summary:查询-根据菜单id得到拥有该菜单的角色List	method:post */ 
	queryByMenu : new APIObject.postObject("/sys/role/queryByMenu",params.queryByMenu), 
	/** 7 summary:查询-根据用户id或account得到拥有的角色List	method:post */ 
	queryByUser : new APIObject.postObject("/sys/role/queryByUser",params.queryByUser), 
	/** 8 summary:查询-单条	method:get */ 
	queryOne : new APIObject.getObject("/sys/role/queryOne",params.queryOne), 
	/** 9 summary:查询-分页	method:post */ 
	queryPage : new APIObject.postObject("/sys/role/queryPage",params.queryPage), 
};
export default role;