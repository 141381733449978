<!--
* @program: qt
* @author: ly
* @component:MainDZ
* @description:
* @create: 2023-06-30 11:51
-->
<template>
    <a-layout style="overflow: hidden" class="layout-main">
        <a-layout-header class="layout-header">
            <oaBanner>
                <div style="width: 100%;display: flex;align-items: center;">
<!--                        <span class="head-title" v-for="item in menuLists" :key="item.id" @click="headClick(item)">
                            <span :class="item.enable?'menu-enable':'menu-disable'">{{item.name}}</span>
                        </span>-->

                        <span class="head-title" v-for="item in menuLists" :key="item.id" @click="headClick(item)">
                            <span :class="item.enable?'menu-enable':'menu-disable'">
                                 <img src="../../assets/image/main/数据管理-选中.png" v-if="item.component == 'MainDZ' && item.enable"    >
                                <img src="../../assets/image/main/数据管理-默认.png" v-if="item.component == 'MainDZ'  && !item.enable"    >
                                <img src="../../assets/image/main/系统维护-选中.png"  v-if="item.component == 'MainPage'  && item.enable"    >
                                <img src="../../assets/image/main/系统维护-默认.png"  v-if="item.component == 'MainPage'  && !item.enable"    >
                                {{item.name}}
                            </span>
                        </span>
                    <HeadToolBar />
                </div>
            </oaBanner>
        </a-layout-header>
        <a-layout style="background: #ffffff;">
            <a-layout-sider v-if="subMenus.length>0" style="overflow: auto;" v-model:collapsed="collapsed" class="aside-menus" :trigger="null" collapsible>
                <sub-group-menu :sub-menus="subMenus" @item="itemClick" @enable="()=>{this.visible = true}"/>
            </a-layout-sider>
            <a-layout-content style="overflow:auto; background: #fff; min-height:500px">
                <div class="app-main-map" v-if="visible">
                    <router-view />
<!--                    <div style="width: 100%;height: 100%;display: flex" >
                        <div v-show="visible" ref="left">
                            <router-view :map="mapObject"/>
                        </div>
                        <div ref="right" style="height: 100%;width: 100%;">
                            <MainMap @mapMethods="setMap" ref="mainMap"/>
                        </div>
                        <div @click="visible =!visible" style="position: absolute;top:50%;left:0;z-index: 30; background: rgb(164 169 176);cursor: pointer;padding: 5px">
                            <div :class="visible?'arrow-right':'arrow-left'"  />
                        </div>
                    </div>-->
                </div>
                <div v-else class="app-main-emty">
                    <div class="app-main-emty-img"></div>
                    <div class="app-main-emty-text">正在使用后台管理功能</div>
                </div>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
    import MainPage from "./MainPage";
    export default {
        name: 'MainPatrol',
        mixins:[MainPage]
    }
</script>

<style scoped>
    .aside-menus {
        background-image: url("@/assets/image/menu-aside.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    /deep/ .ant-menu,.ant-menu-sub {
        background: unset!important;
    }
</style>
