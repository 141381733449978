<!--
* @program: 前台 
* @author: ly
* @component:ReadAuth 
* @description: 
* @create: 2023-02-15 10:44
-->
<template>
    <div>
        
    </div>
</template>

<script>
    import {getHaveOpEnum} from "../assets/utils/general";
    export default {
        name: "ReadAuth",
        data() {
            return {
                ReadAuth:{}
            }
        },
        methods: {
            initAuth() {
                this.ReadAuth = {};
                let haveOpEnum = getHaveOpEnum(this);
                let enumArr = [];
                if(haveOpEnum){
                    enumArr = haveOpEnum.split(",")
                }
                for(let item of enumArr){
                    this.ReadAuth[item] = true;
                }
            }
        },
        created() {
            this.initAuth()
        }
    }
</script>

<style scoped>

</style>