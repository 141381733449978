/** 
* 自动生成文件，请勿修改 
* @name: 红河州地灾-行政区划管理
* @description: Canton Area Controller
* @create: 2024/11/8 10:34:25
* @swagger: 2.0
* 合计:4条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const params = { 
	queryIntersections : [{"level":{"type":"string","description":"行政区划级别","required":false},"srid":{"type":"integer","format":"int32","required":false},"wkt":{"type":"string","description":"wkt","required":true}}], 
	queryOneWithParent : null, 
	uploadAreaShp : null, 
	uploadExcel : null, 
};
const zone = { 
	/** 1 summary:查询相交区域	method:post */ 
	queryIntersections : new APIObject.postObject("/canton/zone/queryIntersections",params.queryIntersections), 
	/** 2 summary:查询上级	method:get */ 
	queryOneWithParent : new APIObject.getObject("/canton/zone/queryOneWithParent",params.queryOneWithParent), 
	/** 3 summary:行政区划-上传范围红线	method:post */ 
	uploadAreaShp : new APIObject.postObject("/canton/zone/uploadAreaShp",params.uploadAreaShp), 
	/** 4 summary:行政区划-上传表格数据	method:post */ 
	uploadExcel : new APIObject.postObject("/canton/zone/uploadExcel",params.uploadExcel), 
};
export default zone;