/**
 * @description:字段配置
 * @author:rzl
 * @date:2022/11/11 11:14
 **/
import Table from "@/assets/tables/table";
import Column, {MyEnum} from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type"

export default class noteSend_item extends Table{
    constructor() {
        super();
        this.name = "noteSend_item";
        this.CNName = "短信详情";
        this.columnArray = [
            new Column("区划","areaName",ColumnType.String,true).setTableView(120),
        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(60)];
    }
}
