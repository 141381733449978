<!--
* @program: 前台 
* @author: ly
* @component:Mark2
* @description: 
* @create: 2022-11-15 10:40
-->
<template>
    <img src="../../assets/image/login/装饰2.png" width="176" height="190" class="non-select" >
</template>

<script>
    export default {
        name: "mark_2",
        data() {
            return {}
        },
        methods: {
            init() {
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>