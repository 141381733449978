/**
 * 字典js
 * @description：
 * @author:rzl
 * @date:2022/12/20 9:22
 **/

const lookUp_dictCode=(dictCode)=>{
    let allDict = JSON.parse(localStorage.getItem("dict"));
    if(!allDict) return
    let dict = allDict.filter(item=>{
        return  item.dictCode === dictCode;
    });
    return dict != null && dict.length > 0 ? dict[0] : null;
}

const find_dict=(type,dictCode)=>{
    let dict_info = lookUp_dictCode(dictCode);
    let itemText = "";
    if(dict_info != null){
        let dictItems = dict_info.dictItems;
        if(dictItems != null && dictItems.length > 0){
            dictItems.forEach((item,index)=>{
                if(type == item.itemValue){
                    itemText =  item.itemText;
                }
            })
        }
    }
    return itemText;
}

const find_dict_list=(type)=>{
    let list = [];
    let dict_info = lookUp_dictCode(type);
    // console.log(dict_info)
    if(dict_info != null){
        let dictItems = dict_info.dictItems;
        if(dictItems != null && dictItems.length > 0){
            dictItems.forEach((item,index)=>{
                let parm = {
                    value:item.itemValue,
                    label:item.itemText,
                    description:item.description,
                }
                list.push(parm);
            })
        }
    }
    list.sort(function(a,b){
        return  parseFloat(a.value) > parseFloat(b.value) ? 1 : -1
    });
    return list;
}
export {lookUp_dictCode,find_dict,find_dict_list}
