<!--
* @program: 地图可视化
* @component:MainMapVisual
-->
<template>
    <div style="width: 100%;height: 100vh;" id="mapVisual">
      <map-tool-bar />
      <div class="layer-tree" :style="tree_style">
        <div class="layer-content" :style="tree_content">
          <a-tree
              id="layerManager"
              class="tree_style"
              checkable
              show-icon
              :tree-data="layer_tree"
              v-model:checkedKeys="checkedKeys"
              :fieldNames="{children:'children', title:'name', key:'id' }"
              @check="handleCheck"
          >

            <template #title="node" >
              <span style="font-weight: 500;color: #FFFFFF;line-height: 20px;margin-left: 6px">
                {{ node.name }}
              </span>
              <!--          <a-tooltip title="图例">
              <a-button v-if="node.layer != null" style="padding: 0 10px" type="link" @click="showLande(node)"><layers style="color: #FFFFFF"/></a-button>
            </a-tooltip>-->
            </template>
            <template #ml>
              <img :src="require('@/assets/image/map/目录.png')" >
            </template>
            <template #sl>
              <img :src="require('@/assets/image/map/矢量.png')" >
            </template>
            <template #sg>
              <img :src="require('@/assets/image/map/栅格.png')" >
            </template>
          </a-tree>
        </div>
        <div class="layer-tb" :style="layer_tb">
          <img style="cursor: pointer;" :src="layer_tb_url" @click="tc_hide_expand">
        </div>
      </div>
    </div>

</template>

<script>
import './config/ol.css'
import Map from 'ol/Map'
import View from 'ol/View'
import {OSM,Vector as VectorSource,XYZ,WMTS,ImageWMS} from 'ol/source'
import {Tile as TileLayer,Vector as VectorLayer,Image} from 'ol/layer'
import Feature from 'ol/Feature'
import {fromLonLat,get as getProjection,Projection,transform,getTransform,transformExtent} from 'ol/proj'
import Draw from 'ol/interaction/Draw';
import {Style,Fill,Stroke,Circle as CircleStyle,Text,Icon} from 'ol/style'
import Overlay from 'ol/Overlay'
import { LineString, Point, Polygon, MultiPolygon } from "ol/geom";
import {unByKey} from 'ol/Observable';
import {getWidth,getTopLeft,getCenter,createOrUpdateFromCoordinates,getBottomLeft,getTopRight,scaleFromCenter} from 'ol/extent'
import WMTSTileGrid from 'ol/tilegrid/WMTS'

import MapSetings from "@/page/Map/config/MapSettings";
import mapToolBar from "@/page/Map/visual/mapToolBar";
import MapSettings from "@/page/Map/config/MapSettings";

export default {
  name: "MainMapVisual",
  emits:["map","mapMethods"],
  components:{
    mapToolBar
  },
  data() {
      return {
        map:null,
        layers:[],
        layer_wkt:[],

        /** 测量 **/
        draw_type:null,// 测量类别
        vector_draw:null,
        draw:null,// 定义一个交互式绘图对象
        listener:null,//定义一个事件监听
        count:0, //定义一个控制鼠标点击次数的变量

        helpTooltipElement:null,//创建一个帮助提示框对象
        helpTooltip:null,//创建一个帮助提示信息对象
        measureTooltipElement:null,//创建一个测量提示框对象
        measureTooltip:null,//创建一个测量提示信息对象
        continuePolygonMsg:null,//继续绘制多边形的提示信息
        continueLineMsg:null,//继续绘制线段的提示信息
        /** 测量 **/

        geoUrl_fp:MapSettings.geoserver_url,
        layer_list:[],

        layer_tree:[],// 图层
        checkedKeys:[],
        tree_style:"",
        tree_content:"",
        layer_tb:"",
        layer_tb_url:require('@/assets/image/map/图层树-收起.png'),
        flag_tc_tb:false,// 图层图标展开收缩：true:展开；false:收缩


      }
  },
  mounted: function () {
    var projection = getProjection('EPSG:4326');// 4326
    var projectionExtent = projection.getExtent();
    var size = getWidth(projectionExtent) / 256;
    var resolutions = new Array(18);
    var matrixIds = new Array(18);
    for(var z=1;z<19;++z){
      resolutions[z]=size/Math.pow(2,z);
      matrixIds[z]=z;
    }

    let layer_vector = new TileLayer({
      name: "天地图矢量图层",
      opacity:0.7,
      source:new WMTS({
        crossOrigin: "anonymous",
        url:MapSetings.wmts_tdt,
        layer:'cia',
        matrixSet:'c',
        format:'tiles',
        style:'default',
        projection:projection,
        tileGrid:new WMTSTileGrid({
          origin:getTopLeft(projectionExtent),
          resolutions:resolutions,
          matrixIds:matrixIds
        }),
        wrapX:true
      })
    });
    this.layers.push(layer_vector);

    let layer_callout = new TileLayer({
      name: "天地图注记图层",
      source: new WMTS({
        url:MapSetings.wmtsUrl_bz,
        layer:'cva',
        matrixSet:'c',
        format:'tiles',
        style:'default',
        projection:projection,
        tileGrid:new WMTSTileGrid({
          origin:getTopLeft(projectionExtent),
          resolutions:resolutions,
          matrixIds:matrixIds
        }),
        wrapX:true
      })
    });
   // this.layers.push(layer_callout);
    let layer_vector2 = new TileLayer({
      name: "天地图矢量图层",
      opacity:0.7,
      source:new WMTS({
        url:MapSetings.wmtsUrl_sl,
        layer:'vec',
        matrixSet:'c',
        format:'tiles',
        style:'default',
        projection:projection,
        tileGrid:new WMTSTileGrid({
          origin:getTopLeft(projectionExtent),
          resolutions:resolutions,
          matrixIds:matrixIds
        }),
        wrapX:true
      })
    });
    //this.layers.push(layer_vector2);

    let layer_image = new TileLayer({
      name: "天地图影像图层",
      opacity:0.7,
      source:new WMTS({
        url:MapSetings.wmtsUrl_yx,
        layer:'img',
        matrixSet:'c',
        format:'tiles',
        style:'default',
        projection:projection,
        tileGrid:new WMTSTileGrid({
          origin:getTopLeft(projectionExtent),
          resolutions:resolutions,
          matrixIds:matrixIds
        }),
        wrapX:true
      }),
      visible:false,
    });
    let layer_terrain = new TileLayer({
      name: "天地图地形图层",
      opacity:0.7,
      source:new WMTS({
        url:MapSetings.wmtsUrl_dx,
        layer:'ter',
        matrixSet:'c',
        format:'tiles',
        style:'default',
        projection:projection,
        tileGrid:new WMTSTileGrid({
          origin:getTopLeft(projectionExtent),
          resolutions:resolutions,
          matrixIds:matrixIds
        }),
        wrapX:true
      }),
      visible:false,
    });

    let coordinate = [106.7241,26.3586];
    let zoom = 11;
    // 视图
    var view = new View({
      center: fromLonLat(coordinate),
      zoom:zoom,
    });

    // 地图
    this.map = new Map({
      target:'mapVisual',
      layers:this.layers,
      /**
       * 让地图视图拥有动画效果：关键点loadTilesWhileAnimating、vie.animate;最基本效果：移动、旋转、缩放
       * */
      loadTilesWhileAnimating: true,//将这个设置为true，默认为false
      view:view
    });

    this.$emit("mapMethods",this.map)
},
  created() {
    setTimeout(()=>{
      this.add_layers();
    },100);
  },
  methods:{
    /**
     * @Description: 添加图层：管理图层
     * @params:
     * @return:
     * @Author: rzl
     * @Date: 2022/4/12
     */
    add_layers(){
      this.layer_list = [];
      this.layers = [];
      MapSettings.getLayerMapTree.post(this,{},res=>{
        if(res.data.success){
          let list_info = res.data.result;
          if(list_info.length > 0){
            // 添加图层
            this.parse_layer(list_info);
            // 获取树
            let layerTree = this.parse_tree(list_info);
            this.layer_list = layerTree;
            if(layerTree != null && layerTree.length > 0){
              this.layer_tree = this.layer_list;
            }
          }
        }
      });

    },
    /** 添加图层 **/
    parse_layer(tree_list){
      var projection = getProjection('EPSG:4326');// 4326
      var projectionExtent = projection.getExtent();
      var size = getWidth(projectionExtent) / 256;
      var resolutions = new Array(18);
      var matrixIds = new Array(18);
      for(var z=1;z<19;++z){
        resolutions[z]=size/Math.pow(2,z);
        matrixIds[z]=z;
      }
      for(let i=0;i<tree_list.length;i++){
        let info = tree_list[i];
        let layerInfo = null;
        if(info.type == '1'){
          let children = info.children;
          if(children.length > 0){
            // 排序
            children.sort(function(a,b){
              return a.level > b.level ? 1 : -1
            });
            this.parse_layer(children);
          }
        }else if(info.type == '2'){// 矢量
          let cql ='version in(\''+info.version+'\')';
          layerInfo = new Image({     //TileLayer、ImageLayer
            name: info.name,
            source: new ImageWMS({    //TileWMS、ImageWMS
              ratio: 1,
              params: {
                "authkey":localStorage.getItem("token"),
                //'FORMAT': 'image/jpeg',   //如果加了这行，地图底图将会被遮住
                'VERSION': '1.1.0',
                'LAYERS': info.layer,// geoserver发布村服务
                'STYLES': '',
                "exceptions": 'application/vnd.ogc.se_inimage',
                'CQL_FILTER': cql,  // 图层属性参数绑定
              },
              url: this.geoUrl_fp + "/"+info.workspace + "/wms",
            }),
            visible:info.display
          })

          this.layers.push(layerInfo);
          this.map.addLayer(layerInfo);
        }else if(info.type == '3'){
          let layer = new TileLayer({
            name:info.name,
            source: this.constructSource(info),
            visible:info.display
          });
          this.layers.push(layer);
          this.map.addLayer(layer);
        }
      }
    },
    /** 切片影像封装 **/
    constructSource(info) {
      var gridsetName = 'EPSG:4326';
      var gridNames = ['EPSG:4326:0', 'EPSG:4326:1', 'EPSG:4326:2', 'EPSG:4326:3', 'EPSG:4326:4', 'EPSG:4326:5', 'EPSG:4326:6', 'EPSG:4326:7', 'EPSG:4326:8', 'EPSG:4326:9', 'EPSG:4326:10', 'EPSG:4326:11', 'EPSG:4326:12', 'EPSG:4326:13', 'EPSG:4326:14', 'EPSG:4326:15', 'EPSG:4326:16', 'EPSG:4326:17', 'EPSG:4326:18', 'EPSG:4326:19', 'EPSG:4326:20', 'EPSG:4326:21'];

      var style = '';
      var format = 'image/png';// image/jpeg
      var infoFormat = 'text/html';

      var baseParams = ['VERSION','LAYER','STYLE','TILEMATRIX','TILEMATRIXSET','SERVICE','FORMAT'];
      var resolutions = [0.703125, 0.3515625, 0.17578125, 0.087890625, 0.0439453125, 0.02197265625, 0.010986328125, 0.0054931640625, 0.00274658203125, 0.001373291015625, 6.866455078125E-4, 3.4332275390625E-4, 1.71661376953125E-4, 8.58306884765625E-5, 4.291534423828125E-5, 2.1457672119140625E-5, 1.0728836059570312E-5, 5.364418029785156E-6, 2.682209014892578E-6, 1.341104507446289E-6, 6.705522537231445E-7, 3.3527612686157227E-7];
      var projection = new Projection({
        code: 'EPSG:4326',
        units: 'degrees',
        axisOrientation: 'neu'
      });
      //var baseUrl = 'http://192.168.10.190:8084/geoserver/gwc/service/wmts';
      //var layerName = "huaxi:huaxitif2020";
      var baseUrl = this.geoUrl_fp +'/gwc/service/wmts';
      var layerName = info.layer;

      var params = {
        "authkey":localStorage.getItem("token"),
        'VERSION': '1.0.0',
        'LAYER': layerName,
        'STYLE': style,
        'TILEMATRIX': gridNames,
        'TILEMATRIXSET': gridsetName,
        'SERVICE': 'WMTS',
        'FORMAT': format
      };

      var url = baseUrl+'?'
      for (var param in params) {
        if (baseParams.indexOf(param.toUpperCase()) < 0) {
          url = url + param + '=' + params[param] + '&';
        }
      }
      url = url.slice(0, -1);

      var source = new WMTS({
        url: url,
        layer: params['LAYER'],
        matrixSet: params['TILEMATRIXSET'],
        format: params['FORMAT'],
        projection: projection,
        tileGrid: new WMTSTileGrid({
          tileSize: [256,256],
          extent: [-180.0,-90.0,180.0,90.0],
          origin: [-180.0, 90.0],
          resolutions: resolutions,
          matrixIds: params['TILEMATRIX']
        }),
        style: params['STYLE'],
        wrapX: true
      });
      return source;
    },
    parse_tree(tree_list){
      var returnData = [];
      for(let i=tree_list.length-1;i>=0;i--){
        let info = tree_list[i];
        let tempObj = {
          id:info.id,
          name:info.name,
          layer:info.layer,
          version:info.version,
          access:info.access,
        }

        if(info.type == "1"){
          let list_info = info.children;
          tempObj.slots = {
            icon: 'ml',
          };
          tempObj.children = this.parse_tree(list_info);
        }else{
          if(info.type == '2'){
            tempObj.slots = {
              icon: 'sl',
            };
          }else if(info.type == '3'){
            tempObj.slots = {
              icon: 'sg',
            };
          }

          if(info.display){
            this.checkedKeys.push(info.id);
          }

        }
        returnData.push(tempObj);

      }
      return returnData;
    },
    /************* 多选 start *******************/
    handleCheck(checkedKeys, e){
      this.checkedNodes = e.checkedNodes;
      let layer_list = this.layer_tree;
      if(layer_list != null){
        this.layerVisible(layer_list);
      }
    },
    layerVisible(layer_list){
      for(let k in layer_list){
        let layer_info = layer_list[k];
        let children = layer_info.children;
        if(children !=null && children.length > 0){
          this.layerVisible(children);
        }else{
          let flag = false;
          let checkName = '';
          for(let i=0;i<this.checkedNodes.length;i++){
            /* let props = this.checkedNodes[i].props;
             let name = props.name;*/
            let props = this.checkedNodes[i];
            let name = props.name;
            if(layer_info.name == name){
              checkName = name;
              flag = true;
            }
          }

          if(flag){
            let layer = this.getLayerByName(checkName);
            this.setLayerVisible(layer, true);
          }else{
            checkName = layer_info.name;
            let layer = this.getLayerByName(checkName);
            this.setLayerVisible(layer, false);
          }
        }
      }
    },
    /**
     * @Description: 根据图层名称获取图层
     * @params: 图层名称
     * @return:
     * @Author: rzl
     * @Date: 2022/4/11
     */
    getLayerByName(name) {
      let layers = this.map.getLayers().array_
      let nameArr = []
      let targetIndex = -1
      for (let i = 0; i < layers.length; i++) {
        nameArr.push(layers[i].values_.name)
      }
      targetIndex = nameArr.indexOf(name)
      return layers[targetIndex]
    },

    /**
     * @Description: 设置图层显隐
     * @params: layer:图层；flag：true显示;false隐藏
     * @return:
     * @Author: rzl
     * @Date: 2022/4/11
     */
    setLayerVisible(layer, flag) {
      if(layer == undefined || layer == null){
        return;
      }
      switch (flag) {
        case true:
          layer.setVisible(true)
          break
        case false:
          layer.setVisible(false)
          break
      }
    },

    /**
     * @Description: 图层隐藏
     * @params:
     * @return:
     * @Author: rzl
     * @Date: 2022/4/14
     */
    tc_hide_expand(){
      this.flag_tc_tb = !this.flag_tc_tb;
      if(this.flag_tc_tb){// 展开状态


        this.tree_style = "width:0px;border: none;";
        this.tree_content = "width:0px;border: none;";
        setTimeout(()=>{
          this.tree_style = "width:0px;border: none;background: none;";
          this.tree_content = "width:0px;border: none;background: none;";
        },900);

        this.layer_tb = " width: 0px;";
        this.layer_tb_url = require('@/assets/image/map/图层树-展开.png');
      }else{

        this.tree_style = "width:260px;border: 1px solid #3D8FC8;background: rgba(14,65,118,0.6);";

        this.tree_content = "width:248px;border: 1px solid #3987BE;background: linear-gradient(90deg, rgba(12,166,215,0.4) 0%, rgba(0,193,255,0) 100%);";

        this.layer_tb = " width: 533px;";
        this.layer_tb_url = require('@/assets/image/map/图层树-收起.png');

      }
    },
    /************* 多选 end *******************/


    /***************** 测量:长度、面积 start ****************/
    mapTool_cd(arg){
      this.remove_measurement();
      if(this.draw_type != 'cd'){
        this.draw_type == 'cd';
        this.clChange("LineString");
      }
    },
    mapTool_mj(arg){
      this.remove_measurement();
      if(this.draw_type != 'mj'){
        this.draw_type == "mj";
        this.clChange("Polygon");
      }
    },
    /** 绘制测量 **/
    clChange(type){
      //定义矢量数据源
      var source = new VectorSource();
      this.vector_draw = new VectorLayer({
        source: source,
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: '#ffcc33',
            width: 2,
          }),
          image: new CircleStyle({
            radius: 7,
            fill: new Fill({
              color: '#ffcc33',
            }),
          }),
        }),
      });
      // 将矢量图层放入map
      this.map.addLayer(this.vector_draw);

      //创建一个当前要绘制的对象
      var sketch = new Feature();


      //创建一个交互式绘图对象
      this.draw = new Draw({
        //绘制的数据源
        source: source,
        //绘制类型
        type: type,
        //样式
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 255, 255, 0.2)',
          }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.5)',
            lineDash: [10, 10],
            width: 2,
          }),
          image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
              color: 'rgba(0, 0, 0, 0.7)',
            }),
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0.2)',
            }),
          }),
        }),
      });
      //将交互绘图对象添加到地图中
      this.map.addInteraction(this.draw);
      //创建测量提示框
      this.createMeasureTooltip();
      //创建帮助提示框
      this.createHelpTooltip();


      //绘制开始事件
      this.draw.on('drawstart',  (evt)=> {
        //The feature being drawn.
        sketch = evt.feature;
        //提示框的坐标
        var tooltipCoord = evt.coordinate;

        this.listener = sketch.getGeometry().on('change',  (evt) =>{
          //The event target.
          //获取绘制的几何对象
          var geom = evt.target;
          //定义一个输出对象，用于记录面积和长度
          var output;
          var cl_value;

          if (geom instanceof Polygon) {
            this.map.removeEventListener('singleclick');
            this.map.removeEventListener('dblclick');
            this.geom = geom;
            var area = geom.getArea();
            if (area > 10000) {
              output = (Math.round(area / 1000000 * 100) / 100) + ' ' + 'km<sup>2</sup>';
            } else {
              output = (Math.round(area * 100) / 100) + ' ' + 'm<sup>2</sup>';
            }
            //输出多边形的面积
            //output = this.formatArea(geom);
            cl_value = (Math.round(area * 100) / 100);
            //获取多变形内部点的坐标
            tooltipCoord = geom.getInteriorPoint().getCoordinates();
          } else if (geom instanceof LineString) {
            //定义长度变量，计算平面距离
            var length = Math.round(geom.getLength() * 100) / 100;
            if (length > 1000) {
              output = (Math.round(length / 1000 * 100) / 100) + ' ' + 'km'; //换算成KM单位
            } else {
              output = (Math.round(length * 100) / 100) + ' ' + 'm'; //m为单位
            }
            //输出多线段的长度
             output = this.formatLength(geom);
            cl_value = (Math.round(length * 100) / 100);
            this.geom = geom;
            //获取多线段的最后一个点的坐标
            tooltipCoord = geom.getLastCoordinate();
          }
          //设置测量提示框的内标签为最终输出结果
          this.measureTooltipElement.innerHTML = output;
          //设置测量提示信息的位置坐标
          this.measureTooltip.setPosition(tooltipCoord);
        });

        //地图单击事件
        this.map.on('singleclick',  (evt)=> {

          //设置测量提示信息的位置坐标，用来确定鼠标点击后测量提示框的位置
          this.measureTooltip != null ? this.measureTooltip.setPosition(evt.coordinate) : null;
          //根据鼠标点击位置生成一个点
          var point = new Point(evt.coordinate);
          //将该点要素添加到矢量数据源中
          source.addFeature(new Feature(point));
          //点击次数增加
          this.count++;
        });

        //地图双击事件
        this.map.on('dblclick',  (evt)=> {
          var point = new Point(evt.coordinate);
          source.addFeature(new Feature(point));
        });

      }, this);
      //绘制结束事件
      this.draw.on('drawend',  (evt)=> {
        // 绘制结束之后 绘制出的点线面数据
        evt.feature.getGeometry().getCoordinates()
        this.count = 0;
        //设置测量提示框的样式
        this.measureTooltipElement.className = 'tooltip tooltip-static';
        //设置偏移量
        this.measureTooltip.setOffset([0, -7]);
        //清空绘制要素
        sketch = null;
        //清空测量提示要素
        this.measureTooltipElement = null;
        //创建测量提示框
        this.createMeasureTooltip();
        //移除事件监听
        unByKey(this.listener);
        //移除地图单击事件
        this.map.removeEventListener('singleclick');
      }, this);

    },

    /** 创建帮助提示框 **/
    createHelpTooltip() {
      //如果已经存在帮助提示框则移除
      if (this.helpTooltipElement) {
        this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
      }
      //创建帮助提示要素的div
      this.helpTooltipElement = document.createElement('div');
      //设置帮助提示要素的样式
      this.helpTooltipElement.className = 'tooltip hidden';
      //创建一个帮助提示的覆盖标注
      this.helpTooltip = new Overlay({
        element: this.helpTooltipElement,
        offset: [15, 0],
        positioning: 'center-left'
      });
      //将帮助提示的覆盖标注添加到地图中
      this.map.addOverlay(this.helpTooltip);
    },
    /** 创建测量提示框 **/
    createMeasureTooltip() {
      //创建测量提示框的div
      this.measureTooltipElement = document.createElement('div');
      this.measureTooltipElement.setAttribute('id', 'lengthLabel');
      //设置测量提示要素的样式
      this.measureTooltipElement.className = 'tooltip tooltip-measure';
      //创建一个测量提示的覆盖标注
      this.measureTooltip = new Overlay({
        element: this.measureTooltipElement,
        offset: [0, -15],
        positioning: 'bottom-center'
      });
      //将测量提示的覆盖标注添加到地图中
      this.map.addOverlay(this.measureTooltip);
    },
    /** 格式化测量长度 **/
    formatLength(line) {
      //定义长度变量，计算平面距离
      var length = Math.round(line.getLength() * 100) / 100;
      //定义输出变量
      var output;
      //如果长度大于1000，则使用km单位，否则使用m单位
      /*if (length > 1000) {
        output = (Math.round(length / 1000 * 100) / 100) + ' ' + 'km'; //换算成KM单位
      } else {
        output = (Math.round(length * 100) / 100) + ' ' + 'm'; //m为单位
      }*/
      output = (Math.round(length * 100) / 100);
      return output;
    },
    /** 格式化测量面积 **/
    formatArea(polygon) {
      //定义面积变量，获取平面面积
      var area = polygon.getArea();
      //定义输出变量
      var output;
      //当面积大于10000时，转换为平方千米，否则为平方米
      /*if (area > 10000) {
        output = (Math.round(area / 1000000 * 100) / 100) + ' ' + 'km<sup>2</sup>';
      } else {
        output = (Math.round(area * 100) / 100) + ' ' + 'm<sup>2</sup>';
      }*/
      output = (Math.round(area * 100) / 100);
      return output;
    },
    /*************** 测量:长度、面积 end *****************/

    /** 清除绘制所用到的元素 **/
    remove_measurement() {
      //移除之前的绘制对象
      if (this.draw != null) {
        this.map.removeInteraction(this.draw);
      }
      // 移除测量矢量图层
      if (this.vector_draw != null) {
        this.map.removeLayer(this.vector_draw);
      }

      // 清除帮助提示信息对象
      if (this.helpTooltip != null) {
        this.helpTooltip = null;
        this.helpTooltipElement = null;
        // 清除测量提示信息对象
        this.measureTooltip = null;
        this.measureTooltipElement = null;
        this.map.getOverlays().clear();//关键点
      }

    },
    /** 清除绘制、提示信息 ***/



    mapTool_fpdb(arg){
      this.$refs.splitScreen.init();
    },
    mapTool_jldb(arg){
      this.$refs.shutterPreview.openShow();
    },


  },
}
</script>

<style scoped>
.layer-tree{
  width: 260px;
  height: 1022px;
  opacity: 1;
  position: absolute;
  z-index: 1001;
  background: red;
  background: rgba(14,65,118,0.6);
  border-radius: 0px 0px 0px 0px;
  border: 1px solid #3D8FC8;
  padding: 6px 4px;
  transition: width 1s;
}
.tree_style{
  z-index: 1100;
  position: relative;
  margin-left: 40px !important;
  margin-top: 73px;

  text-align: left;
  width: 100%;/*60*/
  height: 200px;
  overflow: auto;

  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.4);
  border-radius: 0px 0px 0px 8px;
  opacity: 1;


  background: rgba(51,72,91,0.2);
  border: 1px solid #FFFFFF;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 20px;

  overflow-y: scroll;

}
.layer-content{
  width: 248px;
  background: linear-gradient(90deg, rgba(12,166,215,0.4) 0%, rgba(0,193,255,0) 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #3987BE;
  overflow: auto;
  transition: width 1s;
  padding: 6px 0px;
}
.layer-tb{
  width: 533px;
  transition: width 1s;
  top: 35%;
  position: absolute;
}
</style>
