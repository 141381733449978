<!--
* @program: 前台
* @author: ly
* @component:MainMap
* @description:
* @create: 2022-12-08 14:07
-->
<template>
    <div style="width: 100%;height: 100%;display: flex;" id="map" >
<!--      <map-tool-bar />-->

      <!-- 图层管理 -->
<!--      <layer-manage_map ref="layerManageMap" />-->
    </div>

</template>

<script>
import './config/ol.css'
import Map from 'ol/Map'
import View from 'ol/View'
import {OSM,Vector as VectorSource,XYZ,WMTS,ImageWMS} from 'ol/source'
import {Tile as TileLayer,Vector as VectorLayer,Image} from 'ol/layer'
import Feature from 'ol/Feature'
import {fromLonLat,get as getProjection,Projection,transform,getTransform,transformExtent} from 'ol/proj'
import Draw from 'ol/interaction/Draw';
import {Style,Fill,Stroke,Circle as CircleStyle,Text,Icon} from 'ol/style'
import Overlay from 'ol/Overlay'
import { LineString, Point, Polygon, MultiPolygon } from "ol/geom";
import {unByKey} from 'ol/Observable';
import {getWidth,getTopLeft,getCenter,createOrUpdateFromCoordinates,getBottomLeft,getTopRight,scaleFromCenter} from 'ol/extent'
import WMTSTileGrid from 'ol/tilegrid/WMTS'

import MapSetings from "@/page/Map/config/MapSettings";
import mapToolBar from "@/page/Map/visual/mapToolBar";
import MapSettings from "@/page/Map/config/MapSettings";
import layerManage_map from "@/page/Map/component/layerManage_map";

export default {
  name: "MainMap",
  emits:["map","mapMethods"],
  components:{
    mapToolBar,
    layerManage_map
  },
  data() {
      return {
        visible:true,
        map:"uninitialized",
        mapObject:null,
        layers:[],
        layer_wkt:[],

        /** 测量 **/
        vector_draw:null,
        draw:null,// 定义一个交互式绘图对象
        listener:null,//定义一个事件监听
        count:0, //定义一个控制鼠标点击次数的变量

        helpTooltipElement:null,//创建一个帮助提示框对象
        helpTooltip:null,//创建一个帮助提示信息对象
        measureTooltipElement:null,//创建一个测量提示框对象
        measureTooltip:null,//创建一个测量提示信息对象
        continuePolygonMsg:null,//继续绘制多边形的提示信息
        continueLineMsg:null,//继续绘制线段的提示信息



        drawCoordinateArr:[],// 已绘制的坐标集合
        deleteLineArea:require("@/page/Map/images/距离-悬停.png"),
        lengthMeasureCount:0,// 绘制次数
        /** 测量 **/

        /**** 图层管理 start ****/
        geoUrl:MapSettings.geoserver_url,
        layer_list:[],
        layerMage:[],// 图层管理
        checkedKeys:[],
        layerType:'1',// 1：图层管理；2：图层点击查询
        /**** 图层管理 end ****/

      }
  },
  mounted: function () {
    var projection = getProjection('EPSG:4326');// 4326
    var projectionExtent = projection.getExtent();
    var size = getWidth(projectionExtent) / 256;
    var resolutions = new Array(18);
    var matrixIds = new Array(18);
    for(var z=1;z<19;++z){
      resolutions[z]=size/Math.pow(2,z);
      matrixIds[z]=z;
    }

      let layer_image = new TileLayer({
          name: "天地图影像图层",
          opacity:0.7,
          source:new WMTS({
              url:MapSetings.wmtsUrl_yx,
              layer:'img',
              matrixSet:'c',
              format:'tiles',
              style:'default',
              projection:projection,
              tileGrid:new WMTSTileGrid({
                  origin:getTopLeft(projectionExtent),
                  resolutions:resolutions,
                  matrixIds:matrixIds
              }),
              wrapX:true
          }),
      });
      let layer_callout_yx = new TileLayer({
          name: "影像标注",
          source: new WMTS({
              url:MapSetings.wmtsUrl_yxbz,
              layer:'cia',
              matrixSet:'c',
              format:'tiles',
              style:'default',
              projection:projection,
              tileGrid:new WMTSTileGrid({
                  origin:getTopLeft(projectionExtent),
                  resolutions:resolutions,
                  matrixIds:matrixIds
              }),
              wrapX:true
          })
      });

      this.layers.push(layer_image);
      this.layers.push(layer_callout_yx);
    let coordinate = [102.91,23.38];
    let zoom = 8.2;
    // 视图
    var view = new View({
      center: fromLonLat(coordinate),
      zoom:zoom,
    });

    // 地图
    this.map = new Map({
      target:'map',
      layers:this.layers,
      /**
       * 让地图视图拥有动画效果：关键点loadTilesWhileAnimating、vie.animate;最基本效果：移动、旋转、缩放
       * */
      loadTilesWhileAnimating: true,//将这个设置为true，默认为false
      view:view
    });

    this.$emit("mapMethods",this.map)

  },
  created() {
    setTimeout(()=>{
      this.add_layers();
    },100);
  },
  methods:{
    /******** 基础 start **********/

    /***** 图层管理 start ******/
    /**
     * @Description: 添加图层：管理图层
     * @params:
     * @return:
     * @Author: rzl
     * @Date: 2022/4/12
     */
    add_layers(){
      this.layer_list = [];
      this.layers = [];
      this.layerMage = [];
      this.layerType = '1';
      MapSettings.getLayerMapTree.post(this,{},res=>{
        if(res.data.success){
          let list_info = res.data.result;
          if(list_info.length > 0){

            let cql_xz = 'canton_id in( ' ;
            let cql_c = 'canton_id in( ' ;
            let userInfo = JSON.parse(localStorage.getItem("userInfo"));
            let cantonList = userInfo.cantonList;
            if(cantonList != null && cantonList.length > 0){
              let flag_i = true;
              cantonList.forEach((item,index)=>{
                let areaId = item.id;
                if(index == 0){
                  cql_xz += '\''+areaId+'\'';
                }else{
                  cql_xz += ','+ '\''+areaId+'\'';
                }

                let children = item.children;
                if(children != null && children.length > 0){
                  for(let i=0;i<children.length;i++){
                    let areaId = children[i].id;
                    if(flag_i){
                      flag_i = false;
                      cql_c += '\''+areaId+'\'';
                    }else{
                      cql_c += ','+ '\''+areaId+'\'';
                    }
                  }
                }
              })
              cql_xz += ' )';
              cql_c += ' )';
            }

            // 添加图层
            this.parse_layer(list_info,cql_xz,cql_c);
            // 获取树
            let layerTree = this.parse_tree(list_info);
            this.layer_list = layerTree;
            this.layerMage = layerTree;
            if(layerTree != null && layerTree.length > 0){
              // 初始化树控件
              this.$refs.layerManageMap.initTree(this.map,this.layers,this.layerMage,this.layerType,this.checkedKeys,this.checkedName_fp,this.fp_type);
            }
          }
        }
      });

    },
    /** 添加图层 **/
    parse_layer(tree_list,cql_xz,cql_c){
      var projection = getProjection('EPSG:4326');// 4326
      var projectionExtent = projection.getExtent();
      var size = getWidth(projectionExtent) / 256;
      var resolutions = new Array(18);
      var matrixIds = new Array(18);
      for(var z=1;z<19;++z){
        resolutions[z]=size/Math.pow(2,z);
        matrixIds[z]=z;
      }
      for(let i=0;i<tree_list.length;i++){
        let cql = "";
        let info = tree_list[i];
        let districtControl = info.districtControl;
        if(districtControl == '2'){
          cql = cql_xz;
        }else if(districtControl == '1'){
          cql = cql_c;
        }

        let layerInfo = null;
        if(info.type == '1'){
          let children = info.children;
          if(children.length > 0){
            // 排序
            children.sort(function(a,b){
              return a.level > b.level ? 1 : -1
            });
            this.parse_layer(children,cql_xz,cql_c);
          }
        }else if(info.type == '2'){// 矢量

         /* if(cql == ''){
            cql +='version in(\''+info.version+'\')';
          }else{
            cql +=' and version in(\''+info.version+'\')';
          }*/
          layerInfo = new Image({     //TileLayer、ImageLayer
            name: info.name,
            source: new ImageWMS({    //TileWMS、ImageWMS
              ratio: 1,
              params: {
                /*"authkey":localStorage.getItem("token"),*/
                //'FORMAT': 'image/jpeg',   //如果加了这行，地图底图将会被遮住
                'VERSION': '1.1.0',
                'LAYERS': info.layer,// geoserver发布村服务
                'STYLES': '',
                "exceptions": 'application/vnd.ogc.se_inimage',
                'CQL_FILTER': cql == null || cql == '' ? null : cql,  // 图层属性参数绑定
              },
              url: this.geoUrl + "/"+info.workspace + "/wms",
            }),
            visible:info.display
          })

          this.layers.push(layerInfo);
          this.map.addLayer(layerInfo);
        }else if(info.type == '3'){
          let layer = new TileLayer({
            name:info.name,
            source: this.constructSource(info),
            visible:info.display
          });
          this.layers.push(layer);
          this.map.addLayer(layer);
        }
      }
    },
    /** 切片影像封装 **/
    constructSource(info) {
      var gridsetName = 'EPSG:4326';
      var gridNames = ['EPSG:4326:0', 'EPSG:4326:1', 'EPSG:4326:2', 'EPSG:4326:3', 'EPSG:4326:4', 'EPSG:4326:5', 'EPSG:4326:6', 'EPSG:4326:7', 'EPSG:4326:8', 'EPSG:4326:9', 'EPSG:4326:10', 'EPSG:4326:11', 'EPSG:4326:12', 'EPSG:4326:13', 'EPSG:4326:14', 'EPSG:4326:15', 'EPSG:4326:16', 'EPSG:4326:17', 'EPSG:4326:18', 'EPSG:4326:19', 'EPSG:4326:20', 'EPSG:4326:21'];

      var style = '';
      var format = 'image/png';// image/jpeg
      var infoFormat = 'text/html';

      var baseParams = ['VERSION','LAYER','STYLE','TILEMATRIX','TILEMATRIXSET','SERVICE','FORMAT'];
      var resolutions = [0.703125, 0.3515625, 0.17578125, 0.087890625, 0.0439453125, 0.02197265625, 0.010986328125, 0.0054931640625, 0.00274658203125, 0.001373291015625, 6.866455078125E-4, 3.4332275390625E-4, 1.71661376953125E-4, 8.58306884765625E-5, 4.291534423828125E-5, 2.1457672119140625E-5, 1.0728836059570312E-5, 5.364418029785156E-6, 2.682209014892578E-6, 1.341104507446289E-6, 6.705522537231445E-7, 3.3527612686157227E-7];
      var projection = new Projection({
        code: 'EPSG:4326',
        units: 'degrees',
        axisOrientation: 'neu'
      });
      //var baseUrl = 'http://192.168.10.190:8084/geoserver/gwc/service/wmts';
      //var layerName = "huaxi:huaxitif2020";
      var baseUrl = this.geoUrl +'/gwc/service/wmts';
      var layerName = info.layer;

      var params = {
       /* "authkey":localStorage.getItem("token"),*/
        'VERSION': '1.0.0',
        'LAYER': layerName,
        'STYLE': style,
        'TILEMATRIX': gridNames,
        'TILEMATRIXSET': gridsetName,
        'SERVICE': 'WMTS',
        'FORMAT': format
      };

      var url = baseUrl+'?'
      for (var param in params) {
        if (baseParams.indexOf(param.toUpperCase()) < 0) {
          url = url + param + '=' + params[param] + '&';
        }
      }
      url = url.slice(0, -1);

      var source = new WMTS({
        url: url,
        layer: params['LAYER'],
        matrixSet: params['TILEMATRIXSET'],
        format: params['FORMAT'],
        projection: projection,
        tileGrid: new WMTSTileGrid({
          tileSize: [256,256],
          extent: [-180.0,-90.0,180.0,90.0],
          origin: [-180.0, 90.0],
          resolutions: resolutions,
          matrixIds: params['TILEMATRIX']
        }),
        style: params['STYLE'],
        wrapX: true
      });
      return source;
    },
    parse_tree(tree_list){
      var returnData = [];
      for(let i=tree_list.length-1;i>=0;i--){
        let info = tree_list[i];
        let tempObj = {
          id:info.id,
          name:info.name,
          layer:info.layer,
          version:info.version,
          access:info.access,
        }

        if(info.type == "1"){
          let list_info = info.children;
          tempObj.slots = {
            icon: 'ml',
          };
          tempObj.children = this.parse_tree(list_info);
        }else{
          if(info.type == '2'){
            tempObj.slots = {
              icon: 'sl',
            };
          }else if(info.type == '3'){
            tempObj.slots = {
              icon: 'sg',
            };
          }

          if(info.display){
            this.checkedKeys.push(info.id);
          }

        }
        returnData.push(tempObj);

      }
      return returnData;
    },
    /***** 图层管理 end ******/

    /***************** 测量:长度、面积 start ****************/
    mapTool_cd(arg){
      this.remove_measurement();
      this.clChange("LineString");
    },
    mapTool_mj(arg){
      this.remove_measurement();
      this.clChange("Polygon");
    },
    /** 绘制测量 **/
    clChange(type){
      //定义矢量数据源
      var source = new VectorSource();
      this.vector_draw = new VectorLayer({
        source: source,
        style: new Style({
          fill: new Fill({
            color: 'rgba(239,227,18,0.8)',
          }),
          stroke: new Stroke({
            color: '#ffcc33',
            width: 2,
          }),
          image: new CircleStyle({
            radius: 7,
            fill: new Fill({
              color: '#ffcc33',
            }),
          }),
        }),
      });
      // 将矢量图层放入map
      this.map.addLayer(this.vector_draw);

      //创建一个当前要绘制的对象
      var sketch = new Feature();


      //创建一个交互式绘图对象
      this.draw = new Draw({
        //绘制的数据源
        source: source,
        //绘制类型
        type: type,
        //样式
        style: new Style({
          fill: new Fill({
            color: 'rgba(201,178,32,0.28)',
          }),
          stroke: new Stroke({
            color: 'rgba(0, 0, 0, 0.5)',
            lineDash: [10, 10],
            width: 2,
          }),
          image: new CircleStyle({
            radius: 5,
            stroke: new Stroke({
              color: 'rgba(0, 0, 0, 0.7)',
            }),
            fill: new Fill({
              color: 'rgba(255, 255, 255, 0.2)',
            }),
          }),
        }),
      });
      //将交互绘图对象添加到地图中
      this.map.addInteraction(this.draw);
      //创建测量提示框
      this.createMeasureTooltip();
      //创建帮助提示框
      this.createHelpTooltip();


      //绘制开始事件
      this.draw.on('drawstart',  (evt)=> {
        document.removeEventListener('click', this.deleteClick)
        //The feature being drawn.
        sketch = evt.feature;
        //提示框的坐标
        var tooltipCoord = evt.coordinate;

        this.listener = sketch.getGeometry().on('change',  (evt) =>{
          //The event target.
          //获取绘制的几何对象
          var geom = evt.target;
          //定义一个输出对象，用于记录面积和长度
          var output;
          var cl_value;

          if (geom instanceof Polygon) {
            this.map.removeEventListener('singleclick');
            this.map.removeEventListener('dblclick');
            this.geom = geom;
            var area = geom.getArea();
            if (area > 10000) {
              output = (Math.round(area / 1000000 * 100) / 100) + ' ' + 'km<sup>2</sup>';
            } else {
              output = (Math.round(area * 100) / 100) + ' ' + 'm<sup>2</sup>';
            }
            //输出多边形的面积
            //output = this.formatArea(geom);
            cl_value = (Math.round(area * 100) / 100);
            //获取多变形内部点的坐标
            tooltipCoord = geom.getInteriorPoint().getCoordinates();


          } else if (geom instanceof LineString) {
            //定义长度变量，计算平面距离
            var length = Math.round(geom.getLength() * 100) / 100;
            if (length > 1000) {
              output = (Math.round(length / 1000 * 100) / 100) + ' ' + 'km'; //换算成KM单位
            } else {
              output = (Math.round(length * 100) / 100) + ' ' + 'm'; //m为单位
            }
            //输出多线段的长度
            output = this.formatLength(geom);
            cl_value = (Math.round(length * 100) / 100);
            this.geom = geom;
            //获取多线段的最后一个点的坐标
            tooltipCoord = geom.getLastCoordinate();
          }

          output = output + `<img class="deleteLine"
src="${this.deleteLineArea}"
style="width: 20px;padding-left: 5px;" />`

          //设置测量提示框的内标签为最终输出结果
          this.measureTooltipElement.innerHTML = output;
          this.setToolStyle(this.measureTooltipElement)
          //设置测量提示信息的位置坐标
          this.measureTooltip.setPosition(tooltipCoord);
        });

        //地图单击事件
        this.map.on('singleclick',  (evt)=> {

          //设置测量提示信息的位置坐标，用来确定鼠标点击后测量提示框的位置
          this.measureTooltip != null ? this.measureTooltip.setPosition(evt.coordinate) : null;
          //根据鼠标点击位置生成一个点
          var point = new Point(evt.coordinate);
          //将该点要素添加到矢量数据源中
          source.addFeature(new Feature(point));
          //点击次数增加
          this.count++;
        });

        //地图双击事件
        this.map.on('dblclick',  (evt)=> {
          var point = new Point(evt.coordinate);
          source.addFeature(new Feature(point));
        });

      }, this);
      //绘制结束事件
      this.draw.on('drawend',  (evt)=> {
        const coordinates = evt.feature.getGeometry().getCoordinates()
        evt.feature.set('tempdata', this.lengthMeasureCount)
        this.drawCoordinateArr.push(coordinates)

        document.addEventListener('click', this.deleteClick)
        // 绘制结束之后 绘制出的点线面数据
        evt.feature.getGeometry().getCoordinates()
        this.count = 0;
        //设置测量提示框的样式
        this.measureTooltipElement.className = 'tooltip tooltip-static';
        //设置偏移量
        this.measureTooltip.setOffset([0, -7]);
        //清空绘制要素
        sketch = null;
        //清空测量提示要素
        this.measureTooltipElement = null;
        //创建测量提示框
        this.createMeasureTooltip();
        //移除事件监听
        unByKey(this.listener);
        //移除之前的绘制对象
        this.map.removeInteraction(this.draw);
        //移除地图单击事件
        this.map.removeEventListener('singleclick');
        this.map.removeEventListener('dblclick');
      }, this);

    },

   setToolStyle(measureTooltipElement){
      // 这里可以根据你需要的测量提示框的样式做修改
      measureTooltipElement.style.color = '#C3C5C9'
      measureTooltipElement.style.padding = '0 10px'
      measureTooltipElement.style.height = '25px'
      measureTooltipElement.style.lineHeight = '20px'
      measureTooltipElement.style.background = '#1A1A1B'
      measureTooltipElement.style.fontSize = '15px'
      measureTooltipElement.style.fontWeight = '400'
    },
    // 点击测距的删除
    deleteClick (e) {
      const target = e.target
      const eIndex = target.getAttribute('tempimgdata')
      if (target.className === 'deleteLine') {
        this.deleteLayers(eIndex)
        this.drawCoordinateArr[parseInt(eIndex) - 1] = []
      }
    },
    // 删除当前点击的图层
    deleteLayers (eIndex, target) {
        const overlaysArr = this.map.getOverlays().getArray()
        const layersArr = this.map.getLayers().getArray()
        // 先把需要删除的Overlays存在一个数组中
        // 如果不储存直接去删除Overlays就会出现需要删除却识别不到的图层
        const deleteOverlayArr = []
        const deleteLayerArr = []
        for (let i = 0; i < overlaysArr.length; i++) {
          const lastChild = overlaysArr[i].values_.element?.lastChild
          if (lastChild?.id && lastChild?.getAttribute('tempimgdata') === eIndex) {
            deleteOverlayArr.push(overlaysArr[i])
          }
        }
            this.map.getLayers().getArray().forEach((n, i) => {
          if (n.getSource() && n instanceof VectorLayer && n.getSource().getFeatures()[0] && n.getSource().getFeatures()[0].get('tempdata') && n.getSource().getFeatures()[0].get('tempdata').toString() === eIndex) {
            deleteLayerArr.push(n)
          }
        })
        for (let i = 0; i < layersArr.length; i++) {
          if (layersArr[i].values_.tempdata && layersArr[i].values_.tempdata.toString() === eIndex) {
            deleteLayerArr.push(layersArr[i])
          }
        }
        // 单独处理需要删除的Overlays和Layers
        deleteOverlayArr.forEach(n => this.map.removeOverlay(n))
        deleteLayerArr.forEach(n => this.map.removeLayer(n))

    },
    /** 创建帮助提示框 **/
    createHelpTooltip() {
      //如果已经存在帮助提示框则移除
      if (this.helpTooltipElement) {
        this.helpTooltipElement.parentNode.removeChild(this.helpTooltipElement);
      }
      //创建帮助提示要素的div
      this.helpTooltipElement = document.createElement('div');
      //设置帮助提示要素的样式
      this.helpTooltipElement.className = 'tooltip hidden';
      //创建一个帮助提示的覆盖标注
      this.helpTooltip = new Overlay({
        element: this.helpTooltipElement,
        offset: [15, 0],
        positioning: 'center-left'
      });
      //将帮助提示的覆盖标注添加到地图中
      this.map.addOverlay(this.helpTooltip);
    },
    /** 创建测量提示框 **/
    createMeasureTooltip() {
      //创建测量提示框的div
      this.measureTooltipElement = document.createElement('div');
      this.measureTooltipElement.setAttribute('id', 'lengthLabel');
      //设置测量提示要素的样式
      this.measureTooltipElement.className = 'tooltip tooltip-measure';
      //创建一个测量提示的覆盖标注
      this.measureTooltip = new Overlay({
        element: this.measureTooltipElement,
        offset: [0, -15],
        positioning: 'bottom-center'
      });
      //将测量提示的覆盖标注添加到地图中
      this.map.addOverlay(this.measureTooltip);
    },
    /** 格式化测量长度 **/
    formatLength(line) {
      //定义长度变量，计算平面距离
      var length = Math.round(line.getLength() * 100) / 100;
      //定义输出变量
      var output;
      //如果长度大于1000，则使用km单位，否则使用m单位
      if (length > 1000) {
        output = (Math.round(length / 1000 * 100) / 100) + ' ' + 'km'; //换算成KM单位
      } else {
        output = (Math.round(length * 100) / 100) + ' ' + 'm'; //m为单位
      }
     // output = (Math.round(length * 100) / 100);
      return output;
    },
    /** 格式化测量面积 **/
    formatArea(polygon) {
      //定义面积变量，获取平面面积
      var area = polygon.getArea();
      //定义输出变量
      var output;
      //当面积大于10000时，转换为平方千米，否则为平方米
      /*if (area > 10000) {
        output = (Math.round(area / 1000000 * 100) / 100) + ' ' + 'km<sup>2</sup>';
      } else {
        output = (Math.round(area * 100) / 100) + ' ' + 'm<sup>2</sup>';
      }*/
      output = (Math.round(area * 100) / 100);
      return output;
    },
    /*************** 测量:长度、面积 end *****************/
    /******** 基础 end ***********/

    /******** 清除 start **********/

    /** 清除绘制所用到的元素 **/
    remove_measurement() {
      //移除之前的绘制对象
      if (this.draw != null) {
        this.map.removeInteraction(this.draw);
      }
      // 移除测量矢量图层
      if (this.vector_draw != null) {
        this.map.removeLayer(this.vector_draw);
      }

      // 清除帮助提示信息对象
      if (this.helpTooltip != null) {
        this.helpTooltip = null;
        this.helpTooltipElement = null;
        // 清除测量提示信息对象
        this.measureTooltip = null;
        this.measureTooltipElement = null;
        this.map.getOverlays().clear();//关键点
      }

    },
    /** 清除绘制、提示信息 ***/
    /******** 清除 end ***********/





  },
}
</script>

<style scoped>

</style>
