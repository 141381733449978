/**
 * @program: 前台
 * @author: ly
 * @description: ColorMap
 * @create: 2023-03-03 11:48
 **/

const ColorMap={
        "green":"#0cd40b",
        "orange":"#fe7700",
        "pink":"#fa47fa",
        "purple":"#c537b3",
        "blue":"#368ec3",
        "red":"#db3f36",
        "yellow":"#cbc304",
        "cyan":"#1bd0c8",
        "CadetBlue":"#016ed8"
};
const ColorKeys = Object.keys(ColorMap);
const ColorValues = Object.values(ColorMap);
export default ColorMap;
export {ColorKeys,ColorValues}