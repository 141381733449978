<!--
* @program: tyh-oa 
* @author: ly
* @component:typePreViewFile  报账凭证用
* @description: 
* @create: 2021-04-19 17:48
-->
<template>
    <div v-if="viewModel" style="display: flex;text-align: center;justify-content: center;">
        <a-button v-for="url in imageUrls" :key="url" type="link" shape="circle" @click="showDetail(url)">
            <template #icon>
                <FileImageTwoTone v-if="preViewType(url) ===2"  />
                <FilePdfOutlined v-else-if="preViewType(url) ===1" style="color:green"/>
                <QuestionCircleOutlined v-else style="color:purple"/>
            </template>
        </a-button>
    </div>
    <div v-else>
        <a-upload
                @blur="blurTrigger"
                :action="uploadUrl"
                list-type="picture-card"
                v-model:file-list="fileList"
                @preview="handlePreview"
                :accept="accept"
                @change="handleChange"
                @remove="handleRemove"
                withCredentials
                :headers="headers"
        >
            <div v-if="fileList.length < 4">
                <plus-outlined />
                <div class="ant-upload-text">上传</div>
            </div>
        </a-upload>
    </div>
    <a-drawer v-model:visible="previewVisible"
              :width="drawerWidth"
              placement="right"
              destroy-on-close>
        <template #title>
            <div class="center-flex">
                文件详情
                <a-button type="link"  @click="download">
                    <template #icon><DownloadOutlined /></template>
                </a-button>
            </div>
        </template>
        <div style="display: flex;overflow-x: auto;height: calc(100vh - 80px)">
            <div style="border:1px solid black;height:calc(100vh - 90px) " v-for="url in imageUrls" :key="url">
                <a-image style="height:100%" v-if="preViewType(url) === 2" :width="430" :src="preViewUrl + url"/>
                <embed v-else-if="preViewType(url) === 1" style="width: 430px;text-align: center;height:calc(100vh - 90px)" :src="preViewUrl + url" />
                <div style="width: 430px;text-align: center" v-else>当前文件格式不支持在线浏览</div>
            </div>
        </div>
    </a-drawer>
</template>

<script>
    import typeBase from "./typeBase";
    import { PlusOutlined,FileImageTwoTone,FilePdfOutlined,QuestionCircleOutlined,DownloadOutlined } from '@ant-design/icons-vue';
    import api_file from "/src/assets/api/file"
    import {uuid} from "/src/assets/utils/general"
    export default {
        name: "typePreViewFile",
        mixins:[typeBase],
        components:{PlusOutlined,FileImageTwoTone,FilePdfOutlined,QuestionCircleOutlined,DownloadOutlined},
        watch:{
            record(){
                this.columnTrigger();
                this.init();
            }
        },
        computed:{
            imageUrls(){
                if(this.record[this.column.dataIndex]){
                    return this.record[this.column.dataIndex].split(",");
                }
                else {
                    return []
                }
            },
            drawerWidth(){
                let width = this.imageUrls.length*450;
                return width<1350?width:1350;
            }
        },
        data(){
            return{
                accept:"image/png,image/jpeg,application/pdf",
                fileList:[],
                previewVisible:false,
                previewImage:null,
                uploadUrl:api_file.upload.url,
                preViewUrl:api_file.image.url,
                downloadUrl:api_file.static.url,
                preView:false, //预览的类型   1 为pdf  2为 图片文件
                headers:{
                    "X-Access-Token":localStorage.getItem("token")
                }
            }
        },
        methods:{
            handlePreview({response}){
                this.previewImage = this.preViewUrl + response.result.fileUrl;
                this.previewVisible = true;
            },
            handleCancel(){
                this.previewVisible=false;
            },
            handleChange({file,fileList,event}){
                if(file.status==="done"){//status: "done"
                    this.record[this.column.dataIndex] = this.getRealUrls(fileList);
                }
            },
            showDetail(url){
                this.previewImage = this.preViewUrl+url;
                this.previewVisible = true;
                this.preView = this.preViewType(url);
            },
            preViewType(url){
                let suffix = url.split(".").pop();
                switch (suffix) {
                    case "pdf":{
                        return 1;
                    }
                    case "jpg":{
                        return 2;
                    }
                    case "png":{
                        return 2;
                    }
                    case "gif":{
                        return 2;
                    }
                    default:{
                        return 0;
                    }
                }
            },
            async handleRemove(file){
                let data = new FormData();
                if(Object.prototype.hasOwnProperty.call(file,"response")){
                    data.append("batchFileUrl",file.response.result.fileUrl);
                }
                else{
                    data.append("batchFileUrl",file.path);
                }
               await api_file.batchDeleteFile.post(this,data,res=>{
                    if(res.data.success){
                        //let index = this.fileList.indexOf(file);
                        //this.fileList.splice(index,1);
                        //let urls = this.getRealUrls(this.fileList);
                        this.record[this.column.dataIndex] = this.getRealUrls(this.fileList);
                        return true
                    }
                    else{
                        this.$message.error(res.data.message);
                        return false
                    }
                });
            },
            handleClear(){
                this.fileList = [];
            },
            getRealUrls(fileList){
                let urls ="";
                for(let i in fileList){
                    if(fileList[i].response && fileList[i].response.success){
                        let url = fileList[i].response.result.fileUrl;
                        urls += url + ",";
                    }
                    else{
                        let url = fileList[i].path;
                        urls += url + ",";
                    }
                }
                urls = urls.substr(0,urls.length-1);
                return urls;
            },
            init(){
                this.fileList = [];
                if(this.record[this.column.dataIndex]){
                    let urls = this.record[this.column.dataIndex].split(",");
                    for(let i in urls){
                        let id = uuid(3,16);
                        let img = {
                            uid: id,
                            name: id,
                            status: 'done',
                            url: this.preViewUrl+urls[i],
                            thumbUrl:this.preViewUrl+urls[i],
                            path:urls[i]
                        };
                        this.fileList.push(img);
                    }
                }
            },
            download(){
                for(let url of this.imageUrls){
                    let name = url.split("\\").pop();
                    api_file.static.fileDownload(this,url,name);
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>
    .ant-upload-select-picture-card i {
        font-size: 32px;
        color: #999;
    }

    .ant-upload-select-picture-card .ant-upload-text {
        margin-top: 8px;
        color: #666;
    }
    .ant-upload-list-picture-card-container{
        float: left;
        width: 80px;
        height: 80px;
        margin: 0 8px 8px 0;
    }
</style>