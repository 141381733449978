/**
 * @program: 前台
 * @author: ly
 * @description: routerGuards
 * @create: 2022-12-20 11:04
 **/


function RouterGuards(router){
    router.beforeEach((to, from,next) => {
        if (checkRoute(to.path,router)){
            next()
        }
        else{
            next({path: '/404' })
        }
    })
}
function checkRoute(path,router){
    let routes = router.getRoutes();
    for(let i in routes){
        if(routes[i].path === path){
            return true
        }
    }
    return false
}
export default RouterGuards

