/** 
* 自动生成文件，请勿修改 
* @name: 红河州地灾-预警级别配置管理
* @description: Warning Level Config Controller
* @create: 2024/11/8 10:34:25
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const params = { 
	del : null, 
	delBatch : null, 
	edit : [{"id":{"type":"string"},"precaution":{"type":"string","description":"防范措施"},"warningLevel":{"type":"string","description":"预警级别（蓝色预警、黄色预警、橙色预警、红色预警）：warning_level"}}], 
	judge : null, 
	queryAll : [{"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"warningLevel":{"type":"string","description":"预警级别（蓝色预警、黄色预警、橙色预警、红色预警）：warning_level"}}], 
	queryOne : null, 
	queryPage : [{"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"warningLevel":{"type":"string","description":"预警级别（蓝色预警、黄色预警、橙色预警、红色预警）：warning_level"}}], 
};
const config = { 
	/** 1 summary:删除（单条）	method:delete */ 
	del : new APIObject.deleteObject("/warningLevel/config/del",params.del), 
	/** 2 summary:删除（批量）	method:delete */ 
	delBatch : new APIObject.deleteObject("/warningLevel/config/delBatch",params.delBatch), 
	/** 3 summary:新增/编辑-预警级别配置	method:post */ 
	edit : new APIObject.postObject("/warningLevel/config/edit",params.edit), 
	/** 4 summary:判别-预警级别	method:post */ 
	judge : new APIObject.postObject("/warningLevel/config/judge",params.judge), 
	/** 5 summary:查询所有	method:post */ 
	queryAll : new APIObject.postObject("/warningLevel/config/queryAll",params.queryAll), 
	/** 6 summary:查询单条	method:get */ 
	queryOne : new APIObject.getObject("/warningLevel/config/queryOne",params.queryOne), 
	/** 7 summary:分页查询	method:post */ 
	queryPage : new APIObject.postObject("/warningLevel/config/queryPage",params.queryPage), 
};
export default config;