const showTlie = function (title, positions) {
    let arr=[]
    switch (title) {
        case'地灾点(风险等级)':
            if (positions) {
                if(positions.includes(1)) arr.push({
                    title: '极高',
                    img: require('@/assets/image/icon_dzd_jg.svg')

                })
                if(positions.includes(2)) arr.push({
                    title: '高',
                    img: require('@/assets/image/icon_dzd_g.svg')

                })
                if(positions.includes(3)) arr.push({
                    title: '中',
                    img: require('@/assets/image/icon_dzd_z.svg')
                })
                if(positions.includes(4)) arr.push({
                    title: '低',
                    img: require('@/assets/image/icon_dzd_d.svg')
                })
            }else{
               arr= [
                    {
                        title: '低',
                        color: '#00ff00'
                    },
                    {
                        title: '中',
                        color: '#ffcc00'
                    },
                    {
                        title: '高',
                        color: '#ff0000 '
                    },
                    {
                        title: '极高',
                        color: '#800080 '
                    }
                ]
            }
            return  arr
        case'地灾点(风险等级)2024':
            if (positions) {
                if(positions.includes(1)) arr.push({
                    title: '极高',
                    img: require('@/assets/image/icon_dzd_jg.svg')

                })
                if(positions.includes(2)) arr.push({
                    title: '高',
                    img: require('@/assets/image/icon_dzd_g.svg')

                })
                if(positions.includes(3)) arr.push({
                    title: '中',
                    img: require('@/assets/image/icon_dzd_z.svg')
                })
                if(positions.includes(4)) arr.push({
                    title: '低',
                    img: require('@/assets/image/icon_dzd_d.svg')
                })
            }else{
                arr= [
                    {
                        title: '低',
                        color: '#00ff00'
                    },
                    {
                        title: '中',
                        color: '#ffcc00'
                    },
                    {
                        title: '高',
                        color: '#ff0000 '
                    },
                    {
                        title: '极高',
                        color: '#800080 '
                    }
                ]
            }
            return  arr
        case'易发分区(级别)':
            if (positions) {
                if(positions.includes(3)) arr.push({
                    title: '极高',
                    color: '#ff0000'
                })
                if(positions.includes(2)) arr.push({

                    title: '高',
                    color: '#fe7700'
                })
                if(positions.includes(1)) arr.push({
                    title: '中',
                    color: '#00d600'
                })
            }else{
                arr= [
                    {
                        title: '中',
                        color: '#00d600'
                    },
                    {
                        title: '高',
                        color: '#fe7700'
                    },
                    {
                        title: '极高',
                        color: '#ff0000'
                    }
                ]
            }
            return  arr
        default:
            return []
    }

}
export default showTlie
