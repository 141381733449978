<!--
* @description：图层控件管理
* @author:rzl
* @date:2022/1/20 11:40
-->

<template>
  <!-- 图层管理 start-->
  <div v-drag class="layer-manage" :style="tc_style">
    <div  class="layer-header"  >
      <div class="layer-title " ><img :src="require('@/assets/image/map/图层管理.png')"> 图层管理</div>
      <div class="layer-tb" ><img style="cursor: pointer;" :src="tc_tb_url" @click="tc_hide_expand"></div>
    </div>

    <div class="layer-content" :style="tree_style">
      <a-tree
          id="layerManager"
          class="tree_style"
          checkable
          show-icon
          :tree-data="layer_list_tree_fp"
          v-model:checkedKeys="checkedKeys"
          :fieldNames="{children:'children', title:'name', key:'id' }"
          @check="handleCheck"
      >

        <template #title="node" >
          <span style="font-weight: 500;color: #FFFFFF;line-height: 20px;">
            {{ node.name }}
          </span>
        </template>
        <template #ml>
          <img :src="require('@/assets/image/map/目录.png')" >
        </template>
        <template #sl>
          <img :src="require('@/assets/image/map/矢量.png')" >
        </template>
        <template #sg>
          <img :src="require('@/assets/image/map/栅格.png')" >
        </template>
      </a-tree>
    </div>
  </div>
  <!-- 图层管理 end-->

</template>

<script>
import APIObject from "@/assets/utils/APIObject";
import MapSettings from "@/page/Map/config/MapSettings";
export default {
  name: "layerManage_map",
  components:{

  },
  // 自定义指令 实现可拖动
  directives: {
    drag(el, bindings) {
      el.onmousedown = function (e) {
        const disx = e.pageX - el.offsetLeft;
        const disy = e.pageY - el.offsetTop;
        document.onmousemove = function (e) {
          el.style.left = e.pageX - disx + 'px';
          el.style.top = e.pageY - disy + 'px'
        };
        document.onmouseup = function () {
          document.onmousemove = document.onmouseup = null
        }
      }
    }
  },

  data(){
    return{
      map_tree_fp:null,// 地图容器
      layer_list_tree_fp:[],// 图层

      checkedKeys:[],
      tc_style:"",
      tree_style:"",
      tc_tb_url:require('@/assets/image/map/展开.png'),
      flag_tc_tb:false,// 图层图标展开收缩：true:展开；false:收缩
      checkedNodes:null,// 图层管理选中值

      layer_info:null,// 点击查询的图层

      type:'1',// 1：图层管理；2：图层点击查询
      layers:[],


      queryName:null,// 查询最上面的图层名称
      layer_pz:null,// 图层配置
      layerInfo_list:[],
      argument:{
        formColumns:[], //提供Form的字段集 this.table.getFormColumns()
        formData:{},
      },
      fpType:'2',
    }
  },
  methods:{
    setStyle(type,fpType){
      this.fpType = fpType;
    },
    /**
    * @Description: 初始化树控件
    * @params: map:地图容器；layer_list:图层数组;type:1：图层管理；2：图层点击查询
    * @return: 
    * @Author: rzl
    * @Date: 2022/4/11
    */
    initTree(map,layers,layer_list,type,checkedKeys,checkedName,fp_type){
      if(this.layer_list_tree_fp != null){
        this.layer_list_tree_fp = [];
      }
      if(this.layers.length > 0){
        this.layers = [];
      }
      this.map_tree_fp = map;
      this.layer_list_tree_fp = layer_list;
      this.layers = layers;
      this.type = type;
      this.checkedKeys = checkedKeys;
      this.mapChick();
    },

    /************* 多选 start *******************/
    handleCheck(checkedKeys, e){
      this.checkedNodes = e.checkedNodes;
      let layer_list = this.layer_list_tree_fp;
      if(layer_list != null){
        this.layerVisible(layer_list);
      }
      //this.$emit("event",this.$GetEmitData(this.$eventTags.map_djcx));
    },
    layerVisible(layer_list){
      for(let k in layer_list){
        let layer_info = layer_list[k];
        let children = layer_info.children;
        if(children !=null && children.length > 0){
          this.layerVisible(children);
        }else{
          let flag = false;
          let checkName = '';
          for(let i=0;i<this.checkedNodes.length;i++){
           /* let props = this.checkedNodes[i].props;
            let name = props.name;*/
            let props = this.checkedNodes[i];
            let name = props.name;
            if(layer_info.name == name){
              checkName = name;
              flag = true;
            }
          }

          if(flag){
            let layer = this.getLayerByName(checkName);
            this.setLayerVisible(layer, true);
          }else{
            checkName = layer_info.name;
            let layer = this.getLayerByName(checkName);
            this.setLayerVisible(layer, false);
          }
        }
      }
    },
    /**
     * @Description: 图层隐藏
     * @params:
     * @return:
     * @Author: rzl
     * @Date: 2022/4/14
     */
    tc_hide_expand(){
      this.flag_tc_tb = !this.flag_tc_tb;
      if(!this.flag_tc_tb){
        this.tc_style = "height:45px";
        this.tree_style = "height:0px;border: 0px solid #3987BE";

        this.tc_tb_url = require('@/assets/image/map/展开.png');
      }else{// 展开状态
        this.tc_style = "height:251px";
        this.tree_style = "height:201px;border: 1px solid #3987BE";

        this.tc_tb_url = require('@/assets/image/map/收缩.png');

      }
    },
    /************* 多选 end *******************/

    /************* 单选 start *******************/
    /**
    * @Description: 获取查询图层
    * @params:
    * @return:
    * @Author: rzl
    * @Date: 2022/4/21
    */
    getLayer(){
      let layers = this.layers;
      for(let i=layers.length-1;i>=0;i--){
        let layer_info = this.layers[i].values_;
        let visible = layer_info.visible;
        if(visible){
          this.queryName = layer_info.name;
          this.layer_pz = layer_info.source?.params_?.LAYERS;
          return  layer_info;
        }
      }
    },
    mapChick(){
      this.map_tree_fp.on('singleclick', e=> {
        if(this.type == '1'){
          return;
        }
        var view = this.map_tree_fp.getView();
        var viewResolution = view.getResolution();

        let layers = this.layers;
        let i = layers.length-1;
        this.map_query(e,i,layers,view,viewResolution);
      })
    },
    map_query(e,i,layers,view,viewResolution){
      if(i < 0){
        this.$message.info("当前点击位置暂无数据！");
        return;
      }
      let j = i-1;
      let layerInfo = layers[i].values_;
      if(layerInfo == null || layerInfo == undefined){
        this.map_query(e,j,layers,view,viewResolution);
      }else{
        let visible = layerInfo.visible;
        if(visible){
          this.queryName = layerInfo.name;
          this.layer_pz = layerInfo.source?.params_?.LAYERS;
          let flag = true;// 判断图层是否可以查询
          let layerList = this.layer_list_tree_fp;
          if(layerList.length > 0){
            for (const key in layerList) {
              let name = layerInfo.name;
              if(name == layerList[key].name && !layerList[key].access){
                flag = false;
              }
            }
          }

          if(flag){
            var source = layerInfo.source;
            let url_ = source.url_;
            if(this.$checkUtil.empty_str(url_)){// 空：影像
              this.map_query(e,j,layers,view,viewResolution);
            }else{
              var url = source.getFeatureInfoUrl(
                  e.coordinate, viewResolution, view.getProjection(),
                  {'INFO_FORMAT': 'application/json', 'FEATURE_COUNT': 50,format_options: 'callback: callback'});
              new APIObject(url).requestUrlParam(this,{},res=>{
                let list = res.data.features;
                if(list.length > 0){
                  let info = list[0];
                  if(this.$checkUtil.empty_str(info.id)){
                    this.$message.info("当前点击位置暂无数据！");
                    return;
                  }


                }else{
                  this.map_query(e,j,layers,view,viewResolution);
                }

              })
            }
          }else{
            this.map_query(e,j,layers,view,viewResolution);
          }
        }else{
          this.map_query(e,j,layers,view,viewResolution);
        }
      }

    },
    eventHandle(){

    },

    /**
    * @Description: 设置图层显隐
    * @params: layer:图层；flag：true显示;false隐藏
    * @return:
    * @Author: rzl
    * @Date: 2022/4/11
    */
    setLayerVisible(layer, flag) {
      if(layer == undefined || layer == null){
        return;
      }
      switch (flag) {
        case true:
          layer.setVisible(true)
          break
        case false:
          layer.setVisible(false)
          break
      }
    },
    /**
    * @Description: 根据图层名称获取图层
    * @params: 图层名称
    * @return:
    * @Author: rzl
    * @Date: 2022/4/11
    */
    getLayerByName(name) {
      let layers = this.map_tree_fp.getLayers().array_
      let nameArr = []
      let targetIndex = -1
      for (let i = 0; i < layers.length; i++) {
        nameArr.push(layers[i].values_.name)
      }
      targetIndex = nameArr.indexOf(name)
      return layers[targetIndex]
    },
    gb_layerInfo(){
      this.type = '1';
    },
    showLande(node){
      let data = this.$GetEmitData(this.$eventTags.showLande,node);
      this.$emit("event",data);
    }

  }
}
</script>

<style scoped>
.layer-manage{
  position: absolute;
  left: 1447px;
  top: 105px;
  width: 260px;
  height: 45px;
  background: rgba(14,65,118,0.6);
  opacity: 1;
  border: 1px solid #3D8FC8;
  padding: 0px 5px;
  z-index: 10;
  transition: height 0.5s;
}
.layer-header{
  color: #ffffff;
  height: 34px;
  background: linear-gradient(90deg, #0974BD 0%, #0CA6D7 100%);
  margin: 5px 0px;
}
.layer-title{
  font-size: 18px;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 20px;
  float: left;
  padding: 5px 5px;
  position: absolute;
}
.layer-tb{
  padding: 5px 18px;
  margin-left: 75%;
  position: absolute;
}
.layer-content{
  width: 248px;
  height: 0px;
  background: linear-gradient(90deg, rgba(12,166,215,0.4) 0%, rgba(0,193,255,0) 100%);
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 0px solid #3987BE;
  overflow: auto;
  transition: height 0.5s;
}

.tree_style{
  z-index: 1100;
  position: relative;
  margin-left: 40px !important;
  margin-top: 73px;

  text-align: left;
  width: 100%;/*60*/
  height: 200px;
  overflow: auto;

  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.4);
  border-radius: 0px 0px 0px 8px;
  opacity: 1;
  transition: height 2s;

  background: rgba(51,72,91,0.2);
  border: 1px solid #FFFFFF;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 20px;

  overflow-y: scroll;
}

:global(.layer-content .ant-tree){
  background: none;
}
:global(.layer-content .ant-tree-checkbox-checked .ant-tree-checkbox-inner){
  background-color: #0ca4d6;
}




</style>