/**
 * @description：地图配置
 * @author:rzl
 * @date:2022/12/13 10:32
 **/
import APIObject, {geoserver_url} from "@/assets/utils/APIObject";

let webKey = '4ed50da3a61d11e0e3b0a23eec4e276d';
const MapSetings = {
    wmts_tdt:'http://t0.tianditu.gov.cn/cia_c/wmts?tk=' + webKey,

    wmtsUrl_bz:'http://t0.tianditu.gov.cn/cva_c/wmts?tk=' + webKey, //矢量注记
    wmtsUrl_sl:'http://t0.tianditu.gov.cn/cia_c/wmts?tk=' + webKey, //矢量底图
    wmtsUrl_yx:'http://t0.tianditu.gov.cn/img_c/wmts?tk=' + webKey, //影像底图
    wmtsUrl_yxbz:'http://t0.tianditu.gov.cn/cia_c/wmts?tk=' + webKey, //影像标注
    wmtsUrl_dx:'http://t0.tianditu.gov.cn/ter_c/wmts?tk=' + webKey, //地形底图

    geoserver_url:geoserver_url,

    getLayerQueryTree:new APIObject.postObject("/sys/layerRole/getLayerQueryTree"),// 角色图层关联-获取用户的地图查询图层树
    getLayerMapTree:new APIObject.postObject("/sys/layerRole/getLayerMapTree"),// 角色图层关联-获取用户的地图图层管理树

    readShpGeometry:new APIObject.postObject("/sys/FileInfo/queryAll"),// 通用模块-读取上传的shp图形
    readShpGeometryAll:new APIObject.postObject("/sys/FileInfo/queryAll"),// 通用模块-读取上传的shp图形（读取全部）

};
export default MapSetings;
