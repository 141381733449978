<!--
* @program: 前台
* @author: ly
* @component:RegisterPage
* @description:
* @create: 2022-11-14 10:25
-->
<template>
    <a-layout class="layout-main-logn">
<!--        <a-layout-header class="header">
            <oaBanner />
        </a-layout-header>-->
        <a-layout-content class="main">
            <a-card title="新用户注册" class="card">
                <a-row style="margin: 5px;display: flex;align-items: center;">
                    <a-col style="display: flex;align-items: center;justify-content: flex-end;" :span="8">
                        <div class="col-required">*</div><div style="text-align: end;">部门选择：</div>
                    </a-col>
                    <a-col :span="16">
                        <DepartmentPicker style="width: 100%" @select="handleSelect"  v-model:depId="depId"/>
                    </a-col>
                </a-row>
                <a-row v-if="currentDep.remarks" style="margin: 5px;display: flex;align-items: center;">
                    <a-col style="display: flex;align-items: center;justify-content: flex-end;" :span="8">
                        <div class="col-required">*</div><div style="text-align: end;">角色选择：</div>
                    </a-col>
                    <a-col :span="16">
                        <RegRoleSelect style="width: 100%" :DepartsRoles="currentDep.remarks" v-model:roleId="roleId"/>
                    </a-col>
                </a-row>
                <LForm  ref="Lform" :view-model="false" :form-data="formData" :form-columns="columns">
                    <template #action="{record}">
                        <GetSMSCode :phone="record.phone" style="border: dashed 1px #258fbf;" />
                        <a-button style="margin:5px" type="primary"  @click="reg(record)">
                            注册
                        </a-button>
                        <a-button @click="backHome" type="link">返回</a-button>
                    </template>
                </LForm>
            </a-card>
<!--            <oa-footer />-->
        </a-layout-content>
    </a-layout>
</template>

<script>
    import LForm from "../../components/form/LForm";
    import oaBanner from "./oaBanner";
    import oaFooter from "./oaFooter";
    import Register from "./Register";
    import GetSMSCode from "./GetSMSCode";
    import DepartmentPicker from "../common/DepartmentPicker";
    import RegRoleSelect from "./RegRoleSelect";
    import sysSets from "../../assets/api/sysSets";
    import role from "../../assets/api/role";

    export default {
        name: "RegisterPage",
        components:{
            oaBanner,oaFooter,LForm,GetSMSCode,DepartmentPicker,RegRoleSelect
        },
        data() {
            return {
                columns:new Register().getFormColumns(),
                formData:new Register().getFormData(),
                depId:null,
                roleId:null,
                currentDep:{}
            }
        },
        watch:{
            currentDep(){
                if(!this.currentDep.remarks){
                    this.$message.error("该部门没有允许注册的角色，无法注册")
                }
            }
        },
        methods: {
            init() {
                role.queryAll.post(this,{},res=>{
                    this.loading = false;
                    if(res.data.success){
                        let roles = res.data.result;
                        localStorage.setItem("roles",JSON.stringify(roles));
                    }
                });
            },
            reg(record){
                if(!this.depId){
                    this.$message.error("还未选择部门");
                    return
                }
                if(!this.roleId){
                    this.$message.error("还未选择角色");
                    return
                }
                record.depId = this.depId;
                record.roleId = this.roleId;
                sysSets.appRegister.post(this,record,res=>{
                    if(res.data.success){
                        this.$message.success("已注册成功，待该部门的管理员进行审核，通过后可以登录本系统");
                        this.$router.push({ path: '/' });
                    }
                    else{
                        this.$message.error(res.data.message)
                    }
                })
            },
            handleSelect(node){
                this.currentDep = node;
                if(this.currentDep.remarks){
                    this.roleId = this.currentDep.remarks.split(",")[0];
                }
            },
            backHome(){
                this.$router.go(-1);
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>
    .card{
        width: 400px;
        position: absolute;
        right: calc(50% - 200px);
        top:20%;
        box-shadow: 3px 3px 4px 0px #3dd0ff;
    }
    .col-required{
        display: inline-block;
        margin-right: 4px;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }
</style>
