<template>
    <a-layout style="overflow: hidden" class="layout-main">
        <a-layout-header class="layout-header">
            <oaBanner>
                <div style="width: 100%;display: flex;align-items: center;">
                        <span class="head-title" v-for="item in menuLists" :key="item.id" @click="headClick(item)">
                            <span :class="item.enable?'menu-enable':'menu-disable'">{{item.name}}</span>
                        </span>
                    <HeadToolBar />
                </div>
            </oaBanner>
        </a-layout-header>
        <a-layout>
            <a-layout-sider v-if="subMenus.length>0" style="overflow: auto" v-model:collapsed="collapsed" :trigger="null" collapsible>
                <sub-group-menu :sub-menus="subMenus" @item="itemClick" @enable="()=>{this.visible = true}"/>
            </a-layout-sider>
            <a-layout-content style="overflow:auto; background: #fff; min-height:500px">
                <div class="app-main-map" >
                    <div style="width: 100%;height: 100%;display: flex" >
                        <div v-show="visible" ref="left">
                            <router-view :map="mapObject"/>
                        </div>
                        <div ref="right" style="height: 100%;width: 100%;border-left: 1px solid #3D8FC8;">
                            <MainMap @mapMethods="setMap" ref="mainMap"/>
                        </div>
                        <div @click="visible =!visible" style="position: absolute;top:50%;left:0;z-index: 30; background: #0e4887;cursor: pointer;padding: 5px">
                            <div :class="visible?'arrow-right':'arrow-left'"  />
                        </div>
                    </div>
                </div>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
    import MainPage from "./MainPage";
    export default {
        name: 'MainPatrol',
        mixins:[MainPage]
  }
</script>

<style scoped>

</style>
