<!--
* @description：
* @author:wqy
-->

<template>
    <div id="index">
        <div class="analysis" ref="appRef">
            <div class="top-bg">
                <div class="top-l">
                    <div>
                        <span>{{weatherData.weather}}</span> <span class="temp">{{weatherData.temperature}}℃</span>
                    </div>
                    <div style="display: flex;align-content: center">
                        <img src="@/assets/image/bigmap/position.png" style="object-fit: contain" alt="">
                        <span style="white-space: nowrap">{{weatherData.city}}</span>
                    </div>
                </div>
                <div class="top-r">
                    {{nowTime}}
                    <span class="fullScream-btn" @click="changeFullScreen">{{isFullScream?'退出全屏':'全屏'}}</span>
                </div>
            </div>
            <div class="analysis-main">
                <div class="analysis-main-l">
                    <card :title="'气象预警次数'" class="cards" :tips="'天气预报预警次数统计'">
                        <!--                        <table v-if="table1.length>0">-->
                        <!--                            <tbody>-->
                        <!--                            <tr v-for="(im,i) in table1" :key="i">-->
                        <!--                                <td>NO.{{i+1}}</td>-->
                        <!--                                <td style="width: 120px">{{im.canton.name}}</td>-->
                        <!--                                <td><span class="dot1"><span class="dot2"></span></span>{{im.details[0].count}}</td>-->
                        <!--                                <td><span class="dot3"><span class="dot4"></span></span>{{im.details[1]?.count||0}}</td>-->
                        <!--                                <td>{{im.total}}次</td>-->
                        <!--                            </tr>-->
                        <!--                            </tbody>-->
                        <!--                        </table>-->
                        <dv-scroll-board v-if="table1.length>0" :config="configTable1" ref="table1"
                                         style="width:420px;height:200px;position: relative;left: 6px"/>
                        <div v-else style='text-align: center'>暂无数据</div>
                    </card>
                    <card :title="'预警短信统计'" class="cards" :tips="'根据预警短信推送人次从高到低排名'" style=" pointer-events: none">
                        <!--                        <ul v-if="msgData.length>0">-->
                        <!--                            <li v-for="(im,i) in msgData" :key="i">-->
                        <!--                                <img :src="require('@/assets/image/bigmap/sms_t.png')" alt=""> <span-->
                        <!--                                    class="canton-name"> {{im.canton.name}}</span>-->
                        <!--                                <div class="slides">-->
                        <!--                                    <slideOne :max="maxCount" :count=" "></slideOne>-->
                        <!--                                </div>-->
                        <!--                                <div class="count-div">{{im.sendCount||0}}</div>-->
                        <!--                            </li>-->
                        <!--                        </ul>-->
                        <!--                        <div v-else style='text-align: center'>暂无数据</div>-->
                        <dv-scroll-board v-if="msgData.length>0" :config="configMsgData"
                                         style="width:420px;height:200px;position: relative;left: 40px"/>
                        <div v-else style='text-align: center'>暂无数据</div>

                    </card>
                    <card :title="'预警触发趋势'" class="cards" :tips="'其它：指手动发送短信数量'">
                        <div class="echart1" id="echart1" v-show="Object.keys(msgDate).length>0"
                             style="width:100%;height: 100%"></div>
                        <div v-show="Object.keys(msgDate).length==0" style='text-align: center'>暂无数据</div>
                    </card>
                </div>
                <div>
                    <div class="notices">
                        <div class="notices-l">
                            <span class="notices-tit">短信通知人次</span>
                            <div class="nums">
                                <div v-for="(im,i) in messageSendTotalNum" :key="i"><span
                                        class="mun">{{im}} </span><span
                                        class="points"
                                        v-if="messageSendTotalNum.length>4&&i==messageSendTotalNum.length-5">，</span>
                                </div>
                            </div>
                        </div>
                        <div class="notices-r">
                            <span class="notices-tit">数据上报次数</span>
                            <div class="nums">
                                <div v-for="(im,i) in queryReportTotalNum" :key="i"><span
                                        class="mun">{{im}} </span><span
                                        class="points"
                                        v-if="queryReportTotalNum.length>4&&i==queryReportTotalNum.length-5">，</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="chinaMap" style="padding-top: 40px">
                    </div>
                    <div class="contorl">
                        <div class="contorl-btn" @wheel="handleScroll">

                            {{control1[selectIndex-1].name}}
                            <div style="display: flex;flex-direction: column;font-size: 12px;margin-left: 10px">
                                <CaretUpOutlined @click="handleScroll({deltaY:123})"/>
                                <CaretDownOutlined @click="handleScroll({deltaY:-123})"/>
                            </div>
                        </div>
                        <div class="contorl-btn" @wheel="handleScroll1">
                            {{control1[selectIndex-1].id!=4?control1[selectIndex-1].children[selectIndex1-1]:currentYear}}{{control1[selectIndex-1].unit}}
                            <div style="display: flex;flex-direction: column;font-size: 12px;margin-left: 10px">
                                <CaretUpOutlined @click="handleScroll1({deltaY:123})"/>
                                <CaretDownOutlined @click="handleScroll1({deltaY:-123})"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="analysis-main-r">
                    <card :title="'气象数据极值'" class="cards" :tips="'每个行政区降雨量最大值'">
                        <!--                        <table v-if="pagePeakStatData.length>0">-->
                        <!--                            <tbody>-->
                        <!--                            <tr>-->
                        <!--                                <td>时间</td>-->
                        <!--                                <td style="width: 90px">单位</td>-->
                        <!--                                <td style="max-width: 80px">气候</td>-->
                        <!--                                <td>数值</td>-->
                        <!--                            </tr>-->
                        <!--                            <tr v-for="(im,i) in pagePeakStatData" :key="i">-->
                        <!--                                <td style="width: 110px">{{im.rainDate}}</td>-->
                        <!--                                <td style="width: 90px;"> {{im.depart.departName}}</td>-->
                        <!--                                <td style="max-width: 80px">-->
                        <!--                                    <span> 暴雨</span>-->
                        <!--                                </td>-->
                        <!--                                <td style="width: 150px">{{im.rainfall}}</td>-->
                        <!--                            </tr>-->
                        <!--                            </tbody>-->
                        <!--                        </table>-->
                        <!--                        <div v-else style='text-align: center'>暂无数据</div>-->
                        <dv-scroll-board v-if="pagePeakStatData.length>0" :config="configPagePeakStatData"
                                         style="width:420px;height:200px;position: relative;left: 6px"/>
                        <div v-else style='text-align: center'>暂无数据</div>
                    </card>
                    <card :title="'降雨量统计'" class="cards" :tips="'全州降雨量累计之和'">
                        <div id="echarts_rain" v-show="rainDate.length>0"></div>
                        <div v-show="rainDate.length==0" style='text-align: center'>暂无数据</div>
                    </card>
                    <card :title="'易发分区推送人次'" class="cards" :tips="'根据易发分区推送人次从高到低排名'">
                        <!--                        <table v-if="easyOccurRankStatData.length>0">-->
                        <!--                            <tbody>-->
                        <!--                            <tr v-for="(im,i) in easyOccurRankStatData" :key="i">-->
                        <!--                                <td>NO.{{i+1}}</td>-->
                        <!--                                <td>{{im.occurArea.name}}</td>-->
                        <!--                                <td> {{im.sendCount}}<img :src="require('@/assets/image/bigmap/ico_people.png')" alt="">-->
                        <!--                                </td>-->
                        <!--                            </tr>-->
                        <!--                            </tbody>-->
                        <!--                        </table>-->
                        <!--                        <div v-else style='text-align: center'>暂无数据</div>-->
                        <dv-scroll-board v-if="easyOccurRankStatData.length>0" :config="configEasyOccurRankStatData"
                                         ref="table1"
                                         style="width:420px;height:200px;position: relative;left: 6px"/>
                        <div v-else style='text-align: center'>暂无数据</div>
                    </card>

                </div>
            </div>


        </div>
    </div>
</template>

<script>
    import * as echarts from "echarts";
    // import 'echarts-gl'
    import axios from "axios";
    import {find_dict_list} from "@/assets/utils/dictUtil";

    const allJson = require('@/assets/utils/honghe.json')

    import card from '@/components/bigCard.vue'
    import stat from '@/assets/api/stat.js'
    import picture from '@/assets/api/picture.js'
    import weather from '@/assets/api/weather.js'
    import Canton from '@/assets/api/Canton.js'
    import iconYJ from '@/assets/image/bigmap/yujing.png'
    import slideOne from '@/components/slideOne.vue'
    import drawMixin from '@/assets/utils/drawMixin'
    import {readToken} from '@/assets/utils/APIObject'

    import Column from "@/assets/tables/column";
    import ColorMap from '@/assets/utils/ColorMap.js'
    import ColumnType from "@/assets/tables/column_type"
    import {
        FullscreenOutlined, CaretUpOutlined, CaretDownOutlined
    } from "@ant-design/icons-vue"

    export default {
        name: "analysis_main",
        props: ["map"],
        mixins: [drawMixin],
        components: {card, slideOne, FullscreenOutlined, CaretUpOutlined, CaretDownOutlined},
        data() {
            return {
                weatherKey: '6ee9a205f8c455a9af2406d56cc2e43d',//高德地图天气key
                cityCode: '532500',//红河地区编码
                weatherData: '',
                nowTime: null,
                queryDate: "",
                table1: [],//气象预警次数
                easyOccurRankStatData: [],//易发分区推送人次
                msgData: [],
                maxCount: 0,
                isFullScream: document.fullscreenElement,
                dateFormat: 'yyyy',
                echartsData1: [],
                //年月日季组
                control1: [{
                    id: 1,
                    type: 'yyyy-MM-dd',
                    name: '日度统计',
                    children: [],
                    unit: '日'
                }, {
                    id: 2,
                    type: 'yyyy-MM',
                    name: '月度统计',
                    children: [],
                    unit: '月'
                }, {
                    id: 3,
                    type: 'yyyy-Q',
                    name: '季度统计',
                    children: [],
                    unit: '季'
                }, {
                    id: 4,
                    type: 'yyyy',
                    name: '年度统计',
                    children: [],
                    unit: '年'
                }
                ],
                selectIndex: 4,//控制左
                selectIndex1: 1,//控制右
                control2: [],
                hongheJson: {},
                currentYear: '',//今年
                cuuretMouth: '',//今月
                pageNum1: 1,//气象预警次数
                pageNum2: 1,
                pageNum3: 1,
                pageNum4: 1,
                pageNum5: 1,
                pageNum6: 1,
                messageSendTotalNum: '',//当月短信通知人次
                queryReportTotalNum: '',//当月数据上报次数
                rainDate: '',//气象统计降雨量数据
                pagePeakStatData: '',//气象数据极值
                msgDate: '',//预警触发趋势
                defValue: '000000',
                warning_level: '',
                configTable1: '',
                configPagePeakStatData: '',
                configEasyOccurRankStatData: '',
                configMsgData: '',
                dataInterval: null,
                timeInterval: null,
                cantonIds: [],

            }
        },
        created() {
            this.warning_level = new Column("", "reportType", ColumnType.String, true).getDictEnum("warning_level")
            readToken();
            this.repRegionStrategy()
            // 注册地图
            echarts.registerMap('honghe', this.hongheJson) // 如果是js引入就不需要这一行了
            this.fetchDataFromApi1();
            this.$nextTick(() => {
                this.drawChinaMap('chinaMap')
            })
            this.dataInterval = setInterval(() => {
                this.loadData();
            }, 6000)
            this.timeInterval = setInterval(() => {
                this.updateTime()
            }, 1000);

            const now = new Date();
            const hours = now.getHours();
            const minutes = now.getMinutes();
            const seconds = now.getSeconds();
            const year = now.getFullYear() //获取完整的年份(4位)
            const month = now.getMonth() + 1
            this.queryDate = year + '-' + month
            this.control1.forEach((el, i) => {
                if (el.id == 1) {
                    const dayMount = this.getDaysInMonth();
                    for (let j = 1; j < dayMount + 1; j++) {
                        this.control1[i].children.push(
                            j
                        );
                    }
                }
                if (el.id == 2) {
                    for (let j = 1; j < 13; j++) {
                        this.control1[i].children.push(
                            j
                        );
                    }
                }
                if (el.id == 3) {
                    for (let j = 1; j < 5; j++) {
                        this.control1[i].children.push(
                            j
                        );
                    }
                }
            })
            this.currentYear = new Date().getFullYear();
            this.cuuretMouth = new Date().getMonth() + 1;
            this.messageSendTotal();
            this.queryReportTotal();

            //浏览器退出全屏时的操作
            let app = this
            document.addEventListener("fullscreenchange", function () {
                if (document.fullscreenElement === null) {
                    app.isFullScream = false
                }
            });
            window.addEventListener("resize", function () {
                if (window.innerHeight > 1000) {
                    app.isFullScream = true
                } else {
                    app.isFullScream = false
                }
            });
            window.addEventListener('keydown', this.KeyDown, true);// 监听按键事件
            window.addEventListener('resize', this.changeIsFullScreen, false);


        },
        beforeUnmount() {
            // 在组件销毁前清除定时任务
            clearInterval(this.dataInterval);
            clearInterval(this.timeInterval);
            window.removeEventListener('keydown', this.KeyDown);
            window.removeEventListener('resize', this.changeIsFullScreen);
        },
        methods: {
            KeyDown(event) {
                event = event || window.event;
                if (event.keyCode === 122) {
                    // 禁用f11
                    event.returnValue = false;
                    this.changeFullScreen();
                }
            },
            // 加载所有数据
            loadData() {
                this.warningInfoStat();
                this.easyOccurRankStat();
                this.pagePeakStat();
                this.totalRainfallGroupByDate();
                this.queryMessageGroupByDate();
                this.messageSendStat();
                this.queryReportTotal();
                this.messageSendTotal();
            },
            //获取天气
            fetchDataFromApi1() {
                weather.online.get(this, {
                    city: this.cityCode,
                    key: this.weatherKey
                }, res => {
                    if (res.data.success) {
                        this.weatherData = res.data.result.lives[0] || ''

                    }
                })

            },
            //气象预警次数
            warningInfoStat() {
                stat.warningInfoStat.post(this, {
                    dateFormat: this.dateFormat,
                    "pageNum": this.pageNum1,
                    "pageSize": 12,
                    cantonIds: this.cantonIds.join(','),
                    queryDate: this.queryDate

                }, res => {
                    if (res.data.success) {
                        this.table1 = res.data.result.list
                        let arrs = []
                        this.table1.forEach((el, i) => {
                            arrs.push([
                                `NO.${i + 1}`, `${el.canton.name}`, `<span class="dot1"><span class="dot2"></span></span>${el.details[0].count}`, `<span class="dot3"><span class="dot4"></span></span>${el.details[1]?.count || 0}`, `${el.total}次`
                            ])
                        })
                        if (this.pageNum1 == res.data.result.lastPage) {
                            this.pageNum1 = 0
                        }
                        if (this.pageNum1 < res.data.result.lastPage) {
                            this.pageNum1++
                        }

                        this.configTable1 = {
                            data: arrs,
                            columnWidth: 20,
                            oddRowBGC: 'rgba(0,16,26,0.2)',
                            evenRowBGC: 'rgba(0,16,26,0)',
                            align: ['center']
                        }
                    }
                });
            },
            messageSendStat() {
                let newData = ''
                // this.msgData = [];
                stat.messageSendStat.post(this, {
                    dateFormat: this.dateFormat,
                    "pageNum": this.pageNum2,
                    "pageSize": 12,
                    cantonIds: this.cantonIds.join(','),
                    queryDate: this.queryDate

                }, res => {
                    if (res.data.success) {
                        let arr = []
                        this.maxCount = 0
                        this.msgData = res.data.result.list
                        newData = res.data.result.list
                        if (this.pageNum2 == res.data.result.lastPage) {
                            this.pageNum2 = 0
                        }
                        if (this.pageNum2 < res.data.result.lastPage) {
                            this.pageNum2++
                        }
                        this.maxCount = newData.sort((a, b) => b.sendCount - a.sendCount)[0].sendCount;
                        this.msgData.forEach((el, i) => {
                            arr.push([
                                `<div style="display: flex;align-items: center;justify-content: flex-start"><div class="sms_t"></div><span class="canton-name"> ${el.canton.name}</span></div>`, `<div class="slides" style=" transform: scaleX(1.5);position: relative;left: -20px" >
        <input type="range" id="slider-input" min="0" disabled   value="${el.sendCount}" max="${this.maxCount}" />
    </div>`, `<div class="count-div">${el.sendCount || 0}</div>`
                            ])
                        })
                        this.configMsgData = {
                            data: arr,
                            columnWidth: 20,
                            evenRowBGC: 'rgba(0,16,26,0)',
                            oddRowBGC: 'rgba(0,16,26,0)',
                            align: ['center']
                        }
                    }
                });
            },
            // 易发分区推送人次
            easyOccurRankStat() {
                stat.easyOccurRankStat.post(this, {
                    dateFormat: this.dateFormat,
                    "pageNum": this.pageNum6,
                    "pageSize": 12,
                    cantonIds: this.cantonIds.join(','),
                    queryDate: this.queryDate

                }, res => {
                    if (res.data.success) {
                        let arr = []
                        this.easyOccurRankStatData = res.data.result.list
                        this.easyOccurRankStatData.forEach((el, i) => {
                            arr.push([
                                `NO.${i + 1}`, `${el.occurArea.name}`, `<div style="display: flex;align-items: center"><div class="peoples"></div>${el.sendCount}</div>`,

                            ])
                        })
                        if (this.pageNum6 == res.data.result.lastPage) {
                            this.pageNum6 = 0
                        }
                        if (this.pageNum6 < res.data.result.lastPage) {
                            this.pageNum6++
                        }
                        this.configEasyOccurRankStatData = {
                            data: arr,
                            columnWidth: 20,
                            headerBGC: 'rgba(0,16,26,0.2)',
                            oddRowBGC: 'rgba(0,16,26,0.2)',
                            evenRowBGC: 'rgba(0,16,26,0)',
                            align: ['center']
                        }

                    }
                });
            },
            // 气象数据极值
            pagePeakStat() {
                stat.pagePeakStat.post(this, {
                    dateFormat: this.dateFormat,
                    "pageNum": this.pageNum4,
                    "pageSize": 12,
                    cantonIds: this.cantonIds.join(','),
                    queryDate: this.queryDate

                }, res => {
                    if (res.data.success) {
                        let arr = []
                        this.pagePeakStatData = res.data.result.list
                        this.pagePeakStatData.forEach((el) => {
                            arr.push([
                                `${el.rainDate}`, `${el.depart.departName}`, `${el.rainfall}`
                            ])
                        })
                        if (this.pageNum4 == res.data.result.lastPage) {
                            this.pageNum4 = 0
                        }
                        if (this.pageNum4 < res.data.result.lastPage) {
                            this.pageNum4++
                        }
                        this.configPagePeakStatData = {
                            header: ['时间', '单位', '数值(毫米)'],
                            headerHeight: 30,
                            data: arr,
                            columnWidth: 20,
                            headerBGC: 'rgba(0,16,26,0.2)',
                            oddRowBGC: 'rgba(0,16,26,0.2)',
                            evenRowBGC: 'rgba(0,16,26,0)',
                            align: ['center', 'center', 'center']
                        }

                    }
                });
            },
            //实时获取当前时间
            updateTime() {
                const now = new Date();
                const hours = now.getHours();
                const minutes = now.getMinutes();
                const seconds = now.getSeconds();
                const year = now.getFullYear() //获取完整的年份(4位)
                const month = now.getMonth() + 1
                const strDate = now.getDate()
                // 格式化小时、分钟、秒，确保显示为两位数
                const formattedHours = hours < 10 ? '0' + hours : hours;
                const formattedMinutes = minutes < 10 ? '0' + minutes : minutes;
                const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
                // 输出当前时分秒信息
                this.nowDay = this.getData();
                this.nowTime = `${year}-${month}-${strDate}     ${formattedHours}:${formattedMinutes}:${formattedSeconds}`
            },
            // 获取日期
            getData() {
                // 创建一个 Date 对象
                const now = new Date();

                // 获取年份
                const year = now.getFullYear();

                // 获取月份（注意：getMonth() 返回的是 0 到 11，需要加 1 才是实际月份）
                const month = now.getMonth() + 1;

                // 获取日期
                const day = now.getDate();
                return `${year}-${month}-${day}`
            },
            //左下角图表  预警触发趋势
            initEchart1() {
                if (Object.keys(this.msgDate).length == 0) return
                var chartDom = document.getElementById('echart1');
                var myChart = echarts.init(chartDom);
                var option;
                const warningLevel1 = [];
                const warningLevel2 = [];
                const totals = [];

                let seriesData = []
                this.warning_level.forEach(el => {
                    seriesData.push({
                        barWidth: 30,
                        name: el.itemText,
                        stack: '预警',
                        type: 'bar',
                        emphasis: {
                            focus: 'series'
                        },
                        itemStyle: {
                            color: ColorMap[el.remarks]
                        },
                        data: []
                    })
                })
                seriesData[4] = {
                    barWidth: 30,
                    name: '其它',
                    stack: '预警',
                    type: 'bar',
                    emphasis: {
                        focus: 'series'
                    },
                    itemStyle: {
                        color: '#a2a2a2'
                    },
                    data: []
                }

                Object.keys(this.msgDate).forEach((el) => {
                    this.msgDate[el].forEach(ee => {
                        let indexs = Object.keys(this.msgDate).indexOf(el)
                        let warningLevel = Number(ee.warningLevel)
                        switch (warningLevel) {
                            case 1:
                                seriesData[0].data[indexs] = ee.sendTotal
                                break;
                            case 2:
                                seriesData[1].data[indexs] = ee.sendTotal
                                break;
                            case 3:
                                seriesData[2].data[indexs] = ee.sendTotal
                                break;
                            case 4:
                                seriesData[3].data[indexs] = ee.sendTotal
                                break;
                            default:
                                seriesData[4].data[indexs] = ee.sendTotal
                                break;
                        }
                    })
                })

                option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        textStyle: {
                            color: '#fff' // 设置图例项的颜色为红色
                        }
                    },
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: [
                        {
                            type: 'category',
                            data: Object.keys(this.msgDate),
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: seriesData,
                };


                option && myChart.setOption(option);
            },

            //地图
            drawChinaMap(id) {
                this.mapCharts = echarts.init(document.getElementById(id));
                var option = {
                    geo: [{
                        map: 'honghe',
                        zlevel: 5,
                        top: '10%',
                        selectedMode: 'single',
                        label: {
                            show: true,
                            color: '#eee'
                        },
                        itemStyle: {
                            color: '#2075B8', // 背景
                            opacity: 0.5,
                            borderWidth: '1', // 边框宽度
                            borderColor: '#95C3FF', // 边框颜色
                        },
                        select: {
                            itemStyle: {
                                color: '#00FFFF', // 背景
                                opacity: 0.5,
                                borderWidth: '1', // 边框宽度
                                borderColor: '#95C3FF', // 边框颜色
                            },
                            label: {
                                show: true,
                                color: '#eee'
                            },
                        },
                        emphasis: {
                            itemStyle: {
                                color: '#27a8df' // 鼠标覆盖时的颜色为绿色
                            },
                            label: {
                                show: true,
                                color: '#eee'
                            },
                        }
                    },
                        {
                            map: 'honghe',
                            top: '10%',
                            zlevel: 4,
                            silent: true,
                            itemStyle: {
                                color: '#3E96E2 ', // 背景
                                // borderWidth: '1', // 边框宽度
                                // borderColor: '#95C3FF ', // 边框颜色
                            }
                        },
                        {
                            map: 'honghe',
                            top: '10.8%',
                            zlevel: 3,
                            silent: true,
                            itemStyle: {
                                color: '#3E96E2 ', // 背景
                                // borderWidth: '1', // 边框宽度
                                // borderColor: '#95C3FF', // 边框颜色
                            }
                        },
                        {
                            map: 'honghe',
                            top: '10.8%',
                            silent: true,
                            zlevel: 2,
                            itemStyle: {
                                // color: '#31A0E6', // 背景
                                // borderWidth: '1', // 边框宽度
                                borderColor: '#3E96E2', // 边框颜色
                                shadowColor: '#52d2e2',  // 外部阴影
                                shadowBlur: '20'
                            }
                        }
                    ],
                    series: [
                        {
                            type: "scatter",
                            coordinateSystem: "geo",
                            symbolSize: [55, 67],
                            zlevel: 5,
                            //自定义图片的 路径
                            symbol: 'image://' + iconYJ,
                        },
                    ]
                };
                this.mapCharts.setOption(option);
                let app = this;
                this.mapCharts.on('geoselectchanged', params => {
                    this.initPage();
                    if (params.allSelected[0].name.length == 0) {

                        this.cityCode = '532500'
                        this.fetchDataFromApi1()
                        app.cantonIds = []
                        app.loadData()
                    } else {
                        let selectCity = this.hongheJson.features.find(el => {
                            return el.properties.name == params.name
                        })
                        app.queryNextCity(selectCity.properties.id)
                        app.cityCode = selectCity.properties.adcode
                        app.fetchDataFromApi1()
                    }
                });
            },
            // 重组json
            repRegionStrategy() {
                var features = allJson.features;
                this.hongheJson.type = "FeatureCollection";
                this.hongheJson.features = features;
            },
            //    获取当月天数
            getDaysInMonth() {
                var currentYear = new Date().getFullYear();
                var currentMonth = new Date().getMonth() + 1;
                return new Date(currentYear, currentMonth, 0).getDate();
            },
            //左边滚动
            initPage() {
                this.pageNum1 = 1
                this.pageNum2 = 1
                this.pageNum3 = 1
                this.pageNum4 = 1
                this.pageNum5 = 1
                this.pageNum6 = 1
            },
            handleScroll(event) {
                this.initPage();
                if (event.deltaY > 0) {
                    if (this.selectIndex == 4) return
                    this.selectIndex++
                } else {
                    if (this.selectIndex == 1) {
                        // this.selectIndex = this.control1.length
                        return
                    } else {
                        if (this.selectIndex == 1) return
                        this.selectIndex--
                    }
                }
                this.selectIndex1 = 1
                switch (this.selectIndex) {
                    case 1:
                        this.selectIndex1 = new Date().getDate();
                        break;
                    case 2:
                        this.selectIndex1 = new Date().getMonth() + 1;
                        break;
                    case 4:
                        this.control1[this.selectIndex - 1].children[0] = new Date().getFullYear();
                        this.currentYear = new Date().getFullYear();
                        break;
                    case 3:
                        this.selectIndex1 = Math.ceil(new Date().getMonth() / 3);
                        break;
                    default:
                        this.selectIndex1 = new Date().getMonth() + 1;
                        break;
                }

            },
            //右边滚动
            handleScroll1(event) {
                if (this.selectIndex1 < this.control1[this.selectIndex - 1].children.length) {
                    if (event.deltaY > 0) {
                        if (this.selectIndex1 == this.control1[this.selectIndex - 1].children.length) return
                        this.selectIndex1++
                    } else {
                        if (this.selectIndex1 == 1) {
                            // this.selectIndex1 = this.control1[this.selectIndex - 1].children.length
                        } else {
                            this.selectIndex1--
                        }
                    }
                } else {
                    this.selectIndex1 = 1
                }
                if (this.selectIndex == 4) {
                    if (event.deltaY > 0) {
                        if (this.currentYear > new Date().getFullYear() - 1) return
                        this.currentYear++;

                    } else {
                        this.currentYear--;
                    }

                }
            },
            // 点击顶部控制全屏
            changeFullScreen() {
                if (!document.fullscreenElement) {
                    if (document.documentElement.RequestFullScreen) {
                        document.documentElement.RequestFullScreen();
                    }
                    // 兼容火狐
                    if (document.documentElement.mozRequestFullScreen) {
                        document.documentElement.mozRequestFullScreen();
                    }
                    // 兼容谷歌等可以webkitRequestFullScreen也可以webkitRequestFullscreen
                    if (document.documentElement.webkitRequestFullScreen) {
                        document.documentElement.webkitRequestFullScreen();
                    }
                    // 兼容IE,只能写msRequestFullscreen
                    if (document.documentElement.msRequestFullscreen) {
                        document.documentElement.msRequestFullscreen();
                    }
                } else {
                    if (document.exitFullScreen) {
                        document.exitFullscreen();
                    }
                    // 兼容火狐
                    if (document.mozCancelFullScreen) {
                        document.mozCancelFullScreen();
                    }
                    // 兼容谷歌等
                    if (document.webkitExitFullscreen) {
                        document.webkitExitFullscreen();
                    }
                    // 兼容IE
                    if (document.msExitFullscreen) {
                        document.msExitFullscreen();
                    }
                }
            },
            //当月短信通知人次
            messageSendTotal() {
                stat.messageSendTotal.post(this, {
                    dateFormat: this.dateFormat,
                    queryDate: this.queryDate,
                    cantonIds: this.cantonIds.join(','),
                }, res => {
                    if (res.data.success) {
                        let result = res.data.result == null ? '' : res.data.result.toString()
                        if (result.length == 0) {
                            this.messageSendTotalNum = this.defValue
                        } else {
                            let strtNum = this.defValue.length - result.length
                            this.messageSendTotalNum = this.defValue.substring(0, strtNum) + result
                        }
                    }

                })
            },
            //当月数据上报次数
            queryReportTotal() {
                stat.queryReportTotal.post(this, {
                    dateFormat: this.dateFormat,
                    queryDate: this.queryDate,
                    cantonIds: this.cantonIds.join(','),
                }, res => {
                    if (res.data.success) {
                        let result = res.data.result == null ? '' : res.data.result.toString()
                        if (result.length == 0) {
                            this.queryReportTotalNum = this.defValue
                        } else {
                            let strtNum = this.defValue.length - result.length
                            this.queryReportTotalNum = this.defValue.substring(0, strtNum) + result
                        }

                    }

                })
            },
            //气象统计
            totalRainfallGroupByDate() {
                stat.totalRainfallGroupByDate.post(this, {
                    dateFormat: this.dateFormat,
                    "pageNum": this.pageNum5,
                    "pageSize": 12,
                    cantonIds: this.cantonIds.join(','),
                    queryDate: this.queryDate
                }, res => {
                    if (res.data.success) {
                        this.rainDate = res.data.result
                        // if (this.pageNum5 == res.data.result.lastPage) {
                        //     this.pageNum5 = 0
                        // }
                        // if (this.pageNum5 < res.data.result.lastPage) {
                        //     this.pageNum5++
                        // }
                        this.echartRain();
                    }

                })
            },
            //预警触发趋势
            queryMessageGroupByDate() {
                stat.queryMessageGroupByDate.post(this, {
                    dateFormat: this.dateFormat,
                    "pageNum": this.pageNum3,
                    "pageSize": 6,
                    cantonIds: this.cantonIds.join(','),
                    queryDate: this.queryDate
                }, res => {
                    if (res.data.success) {
                        if (Object.keys(res.data.result).length == 0) {
                            this.msgDate = {}
                        }
                        this.msgDate = res.data.result
                        if (Object.keys(res.data.result).length > 0) {
                            if (this.pageNum3 == res.data.result?.lastPage) {
                                this.pageNum3 = 0
                            }
                            if (this.pageNum3 < res.data.result?.lastPage) {
                                this.pageNum3++
                            }
                        }

                        this.$nextTick(() => {
                            this.initEchart1();
                        })
                    }

                })
            },

            echartRain() {
                if (this.rainDate.length == 0) return
                var chartDom = document.getElementById('echarts_rain');
                var myChart = echarts.init(chartDom);
                var option;
                option = {
                    xAxis: {
                        type: 'category',
                        data: this.rainDate.map(el => el.rainDate),
                        axisLabel: {
                            textStyle: {
                                color: '#A5D8FF'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                        show: false
                    },
                    tooltip: {
                        trigger: 'axis'
                    },
                    series: [
                        {
                            data: this.rainDate?.map(el => el.rainfall),
                            type: 'bar',
                            showBackground: true,
                            barWidth: 20, // 尝试设置固定宽度，但可能不被支持
                            barCategoryGap: '5%', // 同一类别柱子间间距
                            barGap: '10%',// 不同类别柱子间间距
                            backgroundStyle: {
                                color: 'rgba(180, 180, 180, 0.2)'
                            }
                        }
                    ], graphic: [
                        {
                            type: 'text',
                            left: 30,
                            top: 30,
                            style: {
                                text: 'mm',
                                fontSize: 12,
                                fill: '#A5D8FF'
                            },
                        }
                    ]
                };

                option && myChart.setOption(option);
            },
            formatNum(value) {
                if (Number(value) < 10) {
                    return '0' + value
                }
                return value
            },
            queryNextCity(id) {
                this.cantonIds = []
                Canton.queryNext.get(this, {id: id}, res => {
                    if (res.data.success) {
                        let list = res.data.result;
                        list.forEach((item) => {
                            this.cantonIds.push(item.id)
                        })
                        this.loadData();

                    } else {
                        this.$message.error(res.data.message);
                    }
                })
            }
        },
        computed: {
            combinedData() {
                return this.selectIndex + this.selectIndex1
            }
        },
        watch: {
            combinedData() {
                switch (this.selectIndex) {
                    case 1:
                        this.dateFormat = 'yyyy-MM-dd'
                        this.queryDate = `${new Date().getFullYear()}-${this.formatNum(new Date().getMonth() + 1)}-${this.formatNum(this.selectIndex1)}`
                        this.loadData();
                        break;
                    case 2:
                        this.dateFormat = 'yyyy-MM'
                        this.queryDate = `${new Date().getFullYear()}-${this.formatNum(this.selectIndex1)}`
                        this.loadData();
                        break;
                    case 4:
                        this.dateFormat = 'yyyy'
                        this.queryDate = `${this.currentYear}`
                        this.loadData();
                        break;
                    case 3:
                        this.dateFormat = 'yyyy-Q'
                        this.queryDate = `${new Date().getFullYear()}-${this.selectIndex1}`
                        this.loadData();
                        break;
                    default:
                        break

                }
            },
            currentYear() {
                this.dateFormat = 'yyyy'
                this.queryDate = `${this.currentYear}`
                this.loadData();
            },


        }
    }
</script>

<style scoped>
    #index {
        color: #fff;
        width: 100%;
        height: 100%;
        background-image: url("@/assets/image/bigmap/bg.png");
        cursor: default;
    }

    .analysis {

        width: 1920px;
        height: 1080px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transform-origin: left top;
        overflow: hidden;
    }

    .top-bg {
        display: flex;
        font-weight: 500;
        font-size: 16px;
        color: #B7D0EF;
        justify-content: space-between;
        align-items: center;
        width: 1920px;
        height: 92px;
        background-image: url("@/assets/image/bigmap/big_tb.png");
    }

    .top-l, top-r {
        width: 400px;
        display: flex;
        font-size: 20px;
    }

    .top-l div {
        display: flex;
    }

    .top-l {
        margin-left: 80px;
    }

    .top-r {
        text-align: center;
        margin-right: 180px;
        font-family: LcdD, LcdD;
        font-size: 28px;
    }

    .top-l span {
        width: 80px;
        display: block;
    }

    .temp {
        margin: 0px 20px;
    }

    .analysis-main {
        padding: 18px 38px;
        position: relative;
        z-index: 9999;
    }

    td {
        padding: 4px 24px;
        /*background: rgba(0, 16, 26, 0.2);*/
        border-radius: 0px 0px 0px 0px;
        margin: 0px 0;
        max-width: 240px; /* 设置最大宽度 */
        overflow: hidden; /* 溢出内容隐藏 */
        text-overflow: ellipsis; /* 超出部分用省略号表示 */
        white-space: nowrap; /* 防止文本换行 */
    }

    .dot1 {
        width: 16px;
        height: 16px;
        background: rgba(225, 0, 0, 0.36);
        display: inline-grid;
        justify-items: center;
        align-items: center;
        border-radius: 999px;
        margin-right: 10px;
    }

    .dot2 {
        width: 10px;
        height: 10px;
        background: #E10000;
        border-radius: 999px;
    }

    .dot3 {
        width: 16px;
        height: 16px;
        background: rgba(255, 128, 0, 0.36);
        display: inline-grid;
        justify-items: center;
        align-items: center;
        border-radius: 999px;
        margin-right: 10px;
    }

    .dot4 {
        width: 10px;
        height: 10px;
        background: #FF8000;
        border-radius: 999px;
    }

    .analysis-main {
        display: flex;
        justify-content: space-between;
        /*pointer-events: none*/
    }

    .analysis-main-l, .analysis-main-r {
        width: 400px;
    }

    .analysis-main-r {
        margin-right: 40px;
    }


    .cards {
        margin: 20px 0;
    }

    .bgmap {
        position: absolute;
        left: 0px;
        right: 0px;
        top: 0px;
        bottom: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    ul {
        list-style: none;
    }

    li {
        margin: 10px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;

    }

    li > img {
        width: 14px;
        height: 9px;
    }

    .canton-name {
        padding: 0px 5px;
        width: 80px;
    }

    .count-div {
        background-image: url("@/assets/image/bigmap/sms_b.png");
        width: 56px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }

    #chinaMap {
        /*background-image: url("@/assets/image/bigmap/bg.png");*/
        width: 800px;
        height: 800px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .top-bg {
        position: relative;
        z-index: 9999;
    }

    .contorl {
        display: flex;
        justify-content: center;
        margin-top: 20px;
    }

    .contorl-btn {
        background-image: url("@/assets/image/bigmap/btn.png");
        width: 192px;
        height: 46px;
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: YouSheBiaoTiHei, YouSheBiaoTiHei;
        font-weight: 400;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 0px;
        text-shadow: 0px 0px 12px #004CFF;
        text-align: center;
        font-style: normal;
        margin: 0px 30px;
    }

    .notices {
        display: flex;
        justify-content: center;

    }

    .notices > div {
        margin: 0px 20px;
    }

    .nums {
        display: flex;
        font-family: Degatil;
        font-weight: bold;
        font-size: 20px;
        color: #FFFFFF;
        line-height: 34px;
        text-shadow: 0px 0px 8px #0088FF;
        text-align: left;
        font-style: normal;
        text-transform: none;

    }

    .nums div {
        display: flex;
    }

    .mun {
        background-image: url("@/assets/image/bigmap/num_bg.png");
        width: 25px;
        height: 37px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 10px 6px;
    }

    .notices-tit {
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 18px;
        color: #78B1FF;
        line-height: 34px;
        text-align: left;
        font-style: normal;
        text-transform: none;
        margin-bottom: 40px;
    }

    #echarts_rain {
        width: 400px;
        height: 260px;
    }

    .echart1 {
        width: 400px;
        height: 260px;
    }

    .fullScream-btn {
        font-size: 16px;
        position: absolute;
        right: 60px;
        display: flex;
        top: 34px;
    }

    .points {
        font-family: cursive;
        position: relative;
        left: 6px;
        top: 12px;
    }
</style>
