<!--
* @program: office_automation2.0 
* @author: ly
* @component:testA
* @description: 
* @create: 2022-11-01 09:38
-->
<template>

    <user-picker />
</template>

<script>
    import UserPicker from "../page/common/UserPicker";
    export default {
        name: "testA",
        components: {
            UserPicker
        },
        data() {
            return {

            }
        },
        methods: {
            init() {

            },
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>