<!--
* @program: tyh-oa
* @author: ly
* @description: oaHeader  菜单初始化
* @create: 2021-03-19 16:50
-->
<template>
    <div class="header"
         style="font-size: 20px;
    color: #1e79d2;padding: 0 20px;">
        <img style="padding-left: 10px" src="../../assets/image/login/hhzlogo.png" class="non-select"  >
<!--        <img style="position: fixed;right: 200px" src="../../assets/image/login/导航粒子.png" class="non-select" >-->

<!--        <img class="header-img non-select"  src="../../assets/image/wndz/logo.png"  >-->
<!--        红河州地质灾害气象风险预警平台-->
<!--        <img style="position: fixed;right: 200px" src="../../assets/image/login/导航粒子.png" class="non-select" >-->
        <slot/>
    </div>
</template>

<script>
    export default {
        name: "oaHeader",
    }
</script>

<style scoped>
    .title{
        font-size: 40px;
        float: right;
        color: white;
        margin-left: 10px;
        font-weight: 900;
        font-family:"楷体","楷体_GB2312",serif;
    }
    .header-img{
      padding-left: 10px;
      width: 300px;
      height: 32px;
      margin-top: 12px;
    }
</style>

