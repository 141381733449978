<template>
    <div v-drag class="shp_import" v-show="visible">
        <div style="height: 20px;width: 20px;position: absolute;top: 10px;right: 5px;z-index: 100;cursor: pointer">
            <img :src="require('@/assets/image/busines/关闭.png')" @click="close"/>
        </div>
        <div class="detail-header">
            上传文件
        </div>
        <a-divider class="fgx" />
        <div class="import_context">
            <div class="import_shp_file" @click="importSHPFile"></div>
            <div style="color: #FFFFFF;font-size: 12px">点击选择文件</div>
            <div class="import-requirement-container">
                <div class="import-requirement" >
                    文件坐标：zip（带带号的投影坐标 / CGCS2000地理坐标）；<br/>dxf（有带号（大数）的CGCS2000投影坐标）
                </div>
            </div>
        </div>
        <div class="shp-file-list">
            <div class="file-item" v-for="(item,index) in fileList" :key="index">
                <div class="file-icon"><img :src="item.iconUrl"/></div>
                <div class="file-describe">
                    <div class="file-name">
                        {{item.name}}
                    </div>
                    <div class="file-size">
                        {{item.size}}kb
                    </div>
                </div>
                <div class="delete-file-item" title="删除" @click="deleteFileItem(index)"></div>
            </div>
        </div>
        <div class="detail-footer">
            <div class="cancel" @click="close">取消</div>
            <div class="confirm" @click="upload">读取</div>

        </div>
    </div>
</template>

<script>
import {message} from "ant-design-vue"
import file from "@/assets/api/file";
import geometryCommon from "@/assets/api/geometryCommon";

export default {
    name: "shpUpload",
    // 自定义指令 实现可拖动
    directives: {
        drag (el) {
            // el.style.cursor = 'move';

            el.onmousedown = function (e) {
                var disx = e.pageX- el.offsetLeft;
                var disy = e.pageY- el.offsetTop;

                if (e.preventDefault) {
                    e.preventDefault()
                } else {
                    e.returnValue = false  // 解决快速频繁拖动滞后问题
                }

                document.onmousemove = function (e) {
                    // 鼠标位置-鼠标相对元素位置=元素位置
                    let left = e.clientX - disx;
                    let top = e.clientY - disy;
                    // 限制拖拽范围不超出可视区
                    if ( left <= 200) {
                        left = 200    // 设置成5,离边缘不要太近
                    } else if (left > document.documentElement.clientWidth - el.clientWidth) { // document.documentElement.clientWidth屏幕可视区宽度
                        left = document.documentElement.clientWidth - el.clientWidth - 5
                    }

                    if ( top <= 70) {
                        top = 70
                    } else if (top > document.documentElement.clientHeight - el.clientHeight) {
                        top = document.documentElement.clientHeight - el.clientHeight - 5
                    }
                    el.style.left = left + 'px';
                    el.style.top= top + 'px'
                };

                document.onmouseup = function () {
                    document.onmousemove = document.onmouseup = null
                }
            }
        }
    },
    data(){
        return{
          visible:false,
          fileList:[],
          fileType:null,
          batchId:null,
        }
    },
    methods:{
        closeAndCancel(){
            this.visible = false;
            this.fileList = [];
        },
        upload(){
            if(this.fileList.length === 0){
                message.warn("请先选择文件");
                return
            }
            let file = this.fileList[0];
            let form = new FormData();

          form.append("file", file); //第一个参数是后台读取的请求key值
          let fileType = this.fileType;
          if(fileType.toLowerCase() === 'zip'){
            geometryCommon.readShpGeometryAll.post(this,form,res=> {
              if (!res.data.success) {
                message.error(res.data.message);
                return
              }

              let result = res.data.result;
              message.success(res.data.message);
              this.closeAndCancel();
              this.$parent.eventHandle_file("importInfo",result);
            })
          }else if(fileType.toLowerCase() === 'dxf'){
            geometryCommon.readCadGeometryAll.post(this,form,res=> {
              if (!res.data.success) {
                message.error(res.data.message);
                return
              }
              let result = res.data.result;
              message.success(res.data.message);
              this.closeAndCancel();
              this.$parent.eventHandle_file("importInfo",result);
            })
          }
        },
        close(){
          this.visible = false;
          this.fileList = [];
          this.$parent.eventHandle_file("close");
        },
        deleteFileItem(index){
            this.fileList.splice(index,1);
        },
        importSHPFile(){
            let _this = this;
            _this.fileList = [];
            const input = document.createElement("input");
            input.type = "file";
            input.accept = ".zip,.dxf";
            input.click();
            input.onchange = ()=> {
              let file = input.files[0];
              let fileName = file.name;
              let fileType = fileName.substring(fileName.lastIndexOf('.')+1);
              this.fileType = fileType;
              if(fileType.toLowerCase() === 'zip'){
                file.iconUrl = require('@/assets/image/busines/fileIcon/ZIP.png');
              } else if(fileType.toLowerCase() === 'dxf'){
                file.iconUrl = require('@/assets/image/busines/fileIcon/dxf.png');
              }
                _this.fileList.push(file);
            }
        }
    }
}
</script>

<style scoped>
.shp_import{
    z-index: 1001;
    position: absolute;
    top: 90px;
    left: 1260px;
    width: 400px;
    height: 512px;
    border: 1px solid #3D8FC8;
    border-radius: 8px 8px 8px 8px;
    background: rgba(14,65,118,0.6);
}
.detail-header{
    position: relative;
    width: 100%;
    line-height: 38px;
    font-weight: bold;
    font-size: 14px;
    color: #FFFFFF;
    height: 50px;
    background-color: #ffffff;
    border-radius: 8px 8px 0 0;

    -webkit-background-clip: text;
}
.fgx{
  background: rgb(61 143 200);
  width: 92%;
  margin: 0px 20px;
  min-width: 0 !important;
}

.import_context{
    position: relative;
    margin: 20px 2% 10px 2%;
    width: 96%;
    height: 280px;
    border: 1px dashed  #FFFFFF;
    background: rgba(0,16,26,0.2);
}
.import-requirement-container{
    position: absolute;
    width: 100%;
    height: 80px;
    bottom: 20px;
}
.import-requirement{
    position: relative;
    margin: 5px 2% 5px 2%;
    width: 96%;
    height: 25px;
    line-height: 25px;
    font-size: 12px;
    color: #B5B5B5 ;
    text-align: center;
}
.shp-file-list{
    margin: 20px 2% 10px 2%;
    width: 96%;
    height: 70px;
}
.file-item{
    position: relative;
    width: 100%;
    height: 52px;margin-bottom: 10px;
    background-color: rgba(255, 255, 255, 0.3);
    text-align: left;
}
.file-icon{
    position: relative;
 /*   left:5px;*/
    top: -4px;
    display: inline-block;
    height: 40px;
    width: 40px;

}
.file-describe{
    position: relative;
    display: inline-block;
    margin-left: 10px;
    height: 40px;
}
.file-name{
    height: 25px;
    line-height: 25px;
    font-size: 12px;
    color: #FFFFFF;
}
.file-size{
    height: 15px;
    line-height: 15px;
    font-size: 8px;
    color: #DBDBDB;
}
.delete-file-item{
    position: absolute;
    right: 15px;
    top: 14px;
    display: inline-block;
    margin-left: 10px;
    height: 24px;
    width: 24px;
    cursor: pointer;
    background-image: url("../../assets/image/busines/删除.png");
}
.delete-file-item:hover{
    background-color: rgba(255, 255, 255, 0.22);
}
.detail-footer{
    position: relative;
    height: 60px;
    width: 100%;
    text-align: right;
}
.cancel{
    display: inline-block;
    border-radius: 5px;
    line-height: 25px;
    color: #FFFFFF;
    text-align: center;
    margin-right: 20px;
    width: 68px;
    height: 28px;
    background: rgba(0,37,62,0.2);
    opacity: 1;
    border: 1px solid #0CA4D6;
}
.cancel:hover{
  cursor: pointer;
}


.confirm{
  display: inline-block;
  border-radius: 5px;
  line-height: 24px;
  color: #FFFFFF;
  text-align: center;
  margin-right: 20px;
  width: 64px;
  height: 28px;
  background: linear-gradient(90deg, #0974BD 0%, #0CA6D7 100%);
  opacity: 1;
  border: 1px solid #0CA4D6;
}
.confirm:hover{
  cursor: pointer;
}
.confirm_down{
  display: inline-block;
  border-radius: 5px;
  line-height: 24px;
  color: #FFFFFF;
  text-align: center;
  margin-right: 20px;
  width: 74px;
  height: 28px;
  background: linear-gradient(90deg, #0974BD 0%, #0CA6D7 100%);
  opacity: 1;
  border: 1px solid #0CA4D6;
}
.import_shp_file{
    position: relative;
    display: inline-block;
    margin-top: 75px;
    width: 53px;
    height: 63px;
    cursor: pointer;
    background-image: url("../../assets/image/busines/上传图标.png");
}
</style>
