import Table from "@/assets/tables/table";
import Column, {MyEnum} from "@/assets/tables/column";
import ColumnType from "@/assets/tables/column_type";

/**
 * @program: 前台
 * @author: ly
 * @description: Register
 * @create: 2022-11-14 13:59
 **/

export default class Register extends Table{
    constructor() {
        super();
        this.name = "Register";
        this.CNName = "用户注册";
        this.columnArray = [
            new Column("真实姓名","realName",ColumnType.String,true).setTableView()
                .addRule({trigger: 'blur',required:true}),
            new Column("手机号","phone",ColumnType.String,true).setTableView()
                .addRule({len: 11, trigger: 'blur',required:true}),
            new Column("电子邮箱","email",ColumnType.String,true).setTableView(),
            new Column("性别","sex",ColumnType.String,true).setTableView()
                .addRule({trigger: 'blur'})
                .setEnum(new MyEnum(0,"隐藏","red"),new MyEnum(1,"男","blue"),new MyEnum(2,"女","pink")),
            new Column("生日","birthday",ColumnType.Date,true).setTableView()
                .addRule({trigger: 'blur'}),
            new Column("短信验证码","smsCode",ColumnType.String,true).setTableView()
                .addRule({trigger: 'blur',required:true}),
        ];
        this.actions = [new Column("操作","actions","actions",false)
            .setDefaultValue(["view","delete","edit"]).setTableView(250)];
    }
}