<!--
* @description：一张图数据展示
* @author:rzl
* @date:2024/3/28 15:23
-->

<template>
    <div class="bounced-data" v-if="showFlag">
        <div class="bounced-header">
            <div class="bounced-title">{{title}}</div>
            <div class="bounced-gb">
                <CloseOp @click="close"/>
            </div>
        </div>
        <div class="bounced-content">
            <a-tabs v-model:activeKey="activeKey" centered>
                <a-tab-pane key="1" tab="预警统计">
                    <div v-if="yj_data.length > 0">
                        <a-row :gutter="24"
                               style="text-align: left;background-color: rgba(0,38,77,0.94);;margin-top: 5px;color: #f5f5f5;width: 100%;display: flex;flex-wrap: nowrap">
<!--                            <a-col :xs="24" :sm="24" :xl="4"  >排名</a-col>-->
                            <a-col :xs="24" :sm="24" :xl="6"  >区域</a-col>
                            <a-col :xs="24" :sm="24" :xl="6"  style="white-space: nowrap" >红色预警次数</a-col>
                            <a-col :xs="24" :sm="24" :xl="6"   style="white-space: nowrap" >橙色预警次数</a-col>
                            <a-col :xs="24" :sm="24" :xl="6"   style="white-space: nowrap">预警总次数</a-col>
                        </a-row>
                        <a-row :gutter="24" style="text-align: left;background-color: rgba(0,16,26,0.2);;margin-top: 5px;padding: 6px 0;color: #f5f5f5;width: 100%;display: flex;flex-wrap: nowrap"
                               v-for="(item,index) in yj_data" :key="index">
<!--                            <a-col :xs="24" :sm="24" :xl="4"  >{{item.a}}</a-col>-->
                            <a-col :xs="24" :sm="24" :xl="6"  >{{item.canton.name}}</a-col>
                            <a-col :xs="24" :sm="24" :xl="6"  >{{item.hsyj}}</a-col>
                            <a-col :xs="24" :sm="24" :xl="6"  >{{item.csyj}}</a-col>
                            <a-col :xs="24" :sm="24" :xl="6"  >{{item.total}}</a-col>
                        </a-row>
                        <a-row :gutter="24" style="text-align: left;
                        background-color: rgba(0,38,77,0.94);
                        margin-top: 10px;color: #f5f5f5;
                        display: flex; justify-content: center;">
<!--                            <a-pagination-->
<!--                                    :current="current"-->
<!--                                    :pageSize="pageSize"-->
<!--                                    :total="yj_total"-->
<!--                                    @change="handlePageChange"-->
<!--                                    show-less-items-->
<!--                            />-->
                        </a-row>
                    </div>
                    <div v-else style="margin-top: 20%;">
                        <a-empty />
                    </div>
                </a-tab-pane>
                <a-tab-pane key="2" tab="近期气象数据" force-render>
                    <div v-if="qx_data.length > 0">
                        <a-row :gutter="24"
                               style="text-align: left;background-color: rgba(0,38,77,0.94);;margin-top: 5px;color: #f5f5f5;width: 100%;display: flex;flex-wrap: nowrap">
                            <a-col :xs="24" :sm="24" :xl="9"  >时间</a-col>
                            <a-col :xs="24" :sm="24" :xl="5"  >气候类型</a-col>
                            <a-col :xs="24" :sm="24" :xl="4"  >降雨量</a-col>
<!--                            <a-col :xs="24" :sm="24" :xl="4"  >推送人次</a-col>-->
                            <a-col :xs="24" :sm="24" :xl="4"  >地区</a-col>
                        </a-row>
                        <a-row :gutter="24" style="text-align: left;background-color: rgba(0,16,26,0.2);;margin-top: 5px;padding: 6px 0;color: #f5f5f5;width: 100%;display: flex;flex-wrap: nowrap"
                               v-for="(item,index) in qx_data" :key="index">
                            <a-col :xs="24" :sm="24" :xl="9"  >{{item.happenTime}}</a-col>
                            <a-col :xs="24" :sm="24" :xl="5"  >{{item.typeName}}</a-col>
                            <a-col :xs="24" :sm="24" :xl="4"  >{{item.rainfall}}</a-col>
<!--                            <a-col :xs="24" :sm="24" :xl="4"  >{{item.a}}</a-col>-->
                            <a-col :xs="24" :sm="24" :xl="4"  style="white-space: nowrap" >{{item.canton.name}}</a-col>
                        </a-row>
                        <a-row :gutter="24" style="text-align: left;
                        background-color: rgba(0,38,77,0.94);
                        margin-top: 10px;color: #f5f5f5;
                        display: flex; justify-content: center;">
<!--                            <a-pagination-->
<!--                                    :current="current"-->
<!--                                    :pageSize="pageSize"-->
<!--                                    :total="qx_total"-->
<!--                                    @change="handlePageChange"-->
<!--                                    show-less-items-->
<!--                            />-->
                        </a-row>
                    </div>
                    <div v-else style="margin-top: 20%;">
                        <a-empty />
                    </div>
                </a-tab-pane>
            </a-tabs>
        </div>
    </div>
</template>

<script>
    import CloseOp from "@/components/table/op/CloseOp";
    import picture from "@/assets/api/picture";
    import {find_dict_list} from "@/assets/utils/dictUtil";
    export default {
        name: "aPictureData",
        components:{
            CloseOp
        },
        computed: {
            // 计算当前页码的数据
            yj_data() {
                const start = (this.current - 1) * this.pageSize;
                const end = this.current * this.pageSize;
                return this.yj_list.slice(start, end);
            },
            qx_data() {
                const start = (this.current - 1) * this.pageSize;
                const end = this.current * this.pageSize;
                return this.qx_list.slice(start, end);
            },
        },
        data(){
            return{
                title:'行政区',
                activeKey:'1',
                current:1,
                pageSize: 18, // 每页显示的数据数量
                yj_total: 0, // 总数据数量
                yj_list:[],
                qx_total: 0, // 总数据数量
                qx_list:[],
                showFlag:false,
                polygonWKT:null,
                reportType_op:[],
            }
        },
        created() {
            this.reportType_op = find_dict_list("weather_type");
        },
        methods:{
            // 处理页码变化的事件
            handlePageChange(page) {
                this.current = page;
            },
            open(polygonWKT){
                this.polygonWKT = polygonWKT;
                this.queryWarningWithWkt();
                this.queryMeteorologyWithWkt();
                this.$nextTick(()=>{
                    this.showFlag = true;
                });
            },
            queryWarningWithWkt(){
                this.yj_list = [];
                this.yj_total = 0;
                let data = new FormData();
                data.append("wkt",this.polygonWKT)
                picture.queryWarningWithWkt.post(this, data,res=>{
                    this.loading =false;
                    this.flag = true;
                    if(res.data.success){
                        let list = res.data.result.list;
                        list = this.model_to_model(list);
                        this.yj_list = list;
                        this.yj_total = list.length;
                    }
                });
            },
            model_to_model(list){
                let listInfo = [];
                if(list != null && list.length > 0){
                    list.forEach((item,index)=>{
                       let details = item.details;
                       let hsyj = 0;
                       let csyj = 0;
                       if(details != null && details.length > 0){
                           details.forEach((yj_item)=>{
                                if(yj_item.warningLevel == '1'){
                                    hsyj += 1;
                                }
                               if(yj_item.warningLevel == '2'){
                                   csyj += 1;
                               }
                           })
                       }
                       item.hsyj = hsyj;
                        item.csyj = csyj;
                        listInfo.push(item);
                    })
                }
                return listInfo;
            },
            queryMeteorologyWithWkt(){
                this.qx_list = [];
                this.qx_total = 0;
                let data = new FormData();
                data.append("wkt",this.polygonWKT)
                picture.queryMeteorologyWithWkt.post(this, data,res=>{
                    this.loading =false;
                    this.flag = true;
                    if(res.data.success){
                        let list = res.data.result;
                        list = this.model_to_model2(list);
                        this.qx_list = list;
                        this.qx_total = list.length;
                    }
                });
            },

            model_to_model2(list){
                let listInfo = [];
                if(list != null && list.length > 0){
                    list.forEach((item,index)=>{
                        this.reportType_op.forEach((item_type)=>{
                            if(item_type.value == item.weatherType){
                                item.typeName = item_type.label
                            }
                        })
                        listInfo.push(item);
                    })
                }
                return listInfo;
            },
            close(){
                this.showFlag = false;
            },

        },
    }
</script>

<style scoped>
    .bounced-data{
        width: 460px;
        height: 645px;
        background: rgba(0,38,77,0.94);
        border: 1px solid #3D8FC8;
        position: absolute;
        z-index: 1000;
        top: 110px;
        right: 12px
    }
    .bounced-header{
        width: 100%;
        height: 5%;
        background: linear-gradient( 90deg, rgba(58,136,255,0.1) 0%, #2D6BC9 47%, rgba(58,136,255,0.1) 100%);
    }
    .bounced-content{
        width: 100%;
        height: 95%;
        border-radius: 0px 0px 8px 8px;
        padding: 0 15px;
    }
    .bounced-title{
        font-size: 14px;
        font-weight: bold;
        color: white;
        padding-top: 6px;
    }
    .bounced-gb{
        margin-top: -23px;
        margin-left: 95%;
    }
    /deep/.ant-pagination-item{
        min-width: 28px;
        height: 28px;
    }
    /deep/.ant-pagination-prev{
        min-width: 28px;
        height: 28px;
    }
    /deep/ .ant-pagination-next{
        min-width: 28px;
        height: 28px;
    }

    /deep/.ant-pagination-item-active a{
        color: #afb9c1;
    }
</style>
