<!--
* @program: office_automation2.0 
* @author: ly
* @component:LForm 
* @description: 
* @create: 2022-11-07 10:39
-->
<template>
    <div style="margin: 5px">
        <div v-if="title" class="form-title">{{title}}</div>
        <a-form ref="form_controls"
                layout="horizontal"
                :label-col="labelCol" :wrapper-col="wrapperCol"
                size="small"
                :model="lfd">
            <div v-for="(column,index) in cols" :key="index">
                <a-form-item style="display: flex;align-items: center;"  :name="column.dataIndex" :label="column.title" :rules="viewModel?[]:column.rules">
                    <DataPresent style="border: solid 1px #3987BE" @trigger="handleTrigger" :column="column" :record="lfd" :view-model="viewModel" @update="handleUpdate" />
                </a-form-item>
            </div>
        </a-form>
        <slot v-if="!viewModel" name="action" :record="lfd" :columns="cols"/>
    </div>
</template>

<script>
    import DataPresent from "../dataPresentType/DataPresent";
    export default {
        name: "LForm",
        provide:{
            holder:"form",
        },
        components:{
            DataPresent
        },
        props:{
            formColumns:{required:true}, //提供Form的字段集 this.table.getFormColumns()
            formData:{
                required:false,
                default:{}
            },//提供Form的formData
            viewModel:{
                required:false,
                default:true
            },
            title:{
                required:false,
                default:null
            },
        },
        emits:["update:formData"],
        computed:{
            cols(){
              if(this.formColumns === null){
                return [];
              }
              if(!this.viewModel){
                  return  this.formColumns.filter(col=>{
                      return col.editable === true && col.visible;
                  });
              }
              else{
                  return this.formColumns.filter(col=>col.visible);
              }
            },
        },
        watch:{
            formData(){
                this.init()
            }
        },
        data() {
            return {
                labelCol:{ span: 8 },
                wrapperCol:{ span: 16 },
                lfd:null,
            }
        },
        methods: {
            init() {
                this.lfd = {...this.formData};
            },
            handleUpdate(){
                this.$emit("update:formData",this.lfd);
            },
            validate(){
                this.$refs.form_controls.validate().catch(err=>{
                    this.$message.error("输入不满足条件")
                });
            },
            handleTrigger(action){
                if(action){
                    action(this.lfd,this.formColumns);
                }
            }
        },
        created() {
            this.init()
        }
    }
</script>

<style scoped>

</style>