/** 
* 自动生成文件，请勿修改 
* @name: 红河州地灾-自动推送条件管理
* @description: Auto Push Conf Controller
* @create: 2024/11/8 10:34:25
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const params = { 
	approve : [{"ids":{"type":"string","description":"数据记录编号, 多个使用逗号分隔"},"remarks":{"type":"string","description":"审核情况说明"},"state":{"type":"string","description":"审核状态: 0：未审核，1：通过，不通过"}}], 
	batchDelete : null, 
	batchSave : [{"list":{"type":"array","items":{"$ref":"#/definitions/自动推送配置保存实体","originalRef":"自动推送配置保存实体"}}}], 
	delete : null, 
	queryAll : [{"approveState":{"type":"string","description":"审核状态"},"cantonIds":{"type":"string","description":"行政区划编号ids"},"easyOccurLevel":{"type":"string","description":"易发分区等级"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"warningLevel":{"type":"string","description":"气象预警级别"}}], 
	queryOne : null, 
	queryPage : [{"approveState":{"type":"string","description":"审核状态"},"cantonIds":{"type":"string","description":"行政区划编号ids"},"easyOccurLevel":{"type":"string","description":"易发分区等级"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"warningLevel":{"type":"string","description":"气象预警级别"}}], 
	queryUnApprove : [{"approveState":{"type":"string","description":"审核状态"},"cantonIds":{"type":"string","description":"行政区划编号ids"},"easyOccurLevel":{"type":"string","description":"易发分区等级"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"warningLevel":{"type":"string","description":"气象预警级别"}}], 
	save : [{"cantonId":{"type":"string","description":"行政区划编号"},"easyOccurLevel":{"type":"string","description":"易发分区等级"},"id":{"type":"string"},"interval":{"type":"string","description":"时间点：24小时制整点逗号,分隔多个时间点"},"warningLevel":{"type":"string","description":"气象预警级别"}}], 
};
const conf = { 
	/** 1 summary:审核数据	method:post */ 
	approve : new APIObject.postObject("/autoPush/conf/approve",params.approve), 
	/** 2 summary:批量删除	method:delete */ 
	batchDelete : new APIObject.deleteObject("/autoPush/conf/batchDelete",params.batchDelete), 
	/** 3 summary:批量保存	method:post */ 
	batchSave : new APIObject.postObject("/autoPush/conf/batchSave",params.batchSave), 
	/** 4 summary:删除单条	method:delete */ 
	delete : new APIObject.deleteObject("/autoPush/conf/delete",params.delete), 
	/** 5 summary:查询所有	method:post */ 
	queryAll : new APIObject.postObject("/autoPush/conf/queryAll",params.queryAll), 
	/** 6 summary:查询单条	method:post */ 
	queryOne : new APIObject.postObject("/autoPush/conf/queryOne",params.queryOne), 
	/** 7 summary:分页查询	method:post */ 
	queryPage : new APIObject.postObject("/autoPush/conf/queryPage",params.queryPage), 
	/** 8 summary:查询未审核的数据	method:post */ 
	queryUnApprove : new APIObject.postObject("/autoPush/conf/queryUnApprove",params.queryUnApprove), 
	/** 9 summary:保存单条数据	method:post */ 
	save : new APIObject.postObject("/autoPush/conf/save",params.save), 
};
export default conf;