<!--
* @description：一张图数据展示
* @author:rzl
* @date:2024/3/28 15:23
-->

<template>
    <div class="bounced-data" v-if="noteShow&&showNote">
        <div class="bounced-header">
            <div class="bounced-title">{{title}}</div>
            <div class="bounced-gb">
                <CloseOp @click="close"/>
            </div>
        </div>
        <div class="bounced-content">

            <!-- table start -->
            <SLTable v-if="showTable" ref="pstable2" :data-source="dataSource2" :columns="columns2" style="height: 412px;margin-top: 4px"
                     :pageSize="9999" :selectedRowKeys="selectDataKey" :rowKey="'cantonId'"
                     @selectChange="handleSelect" :scroll="{x:400,y:380}">
                <template #action="{index,record}">
                    <div style="justify-content: center;display: flex;">
                        <a-button class="action-button" type="link" title="发送短信" :disabled="selectDataKey.length>0" @click="sendData(record,index)">
                            <UpSquareOutlined class="pointer"/>
                        </a-button>
                    </div>
                </template>
            </SLTable>
            <div class="op-bar" style="display: flex;margin: 10px 6px 6px auto">
                <a-button type="primary" style="margin-left: 10px" @click="batchAudit_open">
                    批量发送短信
                </a-button>
            </div>
            <!-- table end -->
        </div>
    </div>


</template>

<script>
    import {SearchOutlined, UpSquareOutlined} from "@ant-design/icons-vue"
    import CloseOp from "@/components/table/op/CloseOp";
    import SLTable from "@/components/table/SLTable";
    import ReadAuth from "@/components/ReadAuth";
    import noteSend_item from "@/page/Map/Apicture/noteSend_item";
    import zone from "@/assets/api/zone";
    import conf from "@/assets/api/conf";

    export default {
        name: "noteSend",
        mixins: [ReadAuth],
        components: {
            CloseOp, SLTable, SearchOutlined, UpSquareOutlined
        },
        data() {
            return {
                title: '短信发送',
                noteShow: false,
                polygonWKT: null,
                columns2: new noteSend_item().getTableColumns(),
                dataSource2: [],
                selectDataKey: [],
                showTable:false,
                showNote:false,
            }
        },
        methods: {
            open(polygonWKT) {
                if(!polygonWKT)return
                this.polygonWKT = polygonWKT;
                this.noteShow =  !this.noteShow;
                 this.doSearch();
            },
            openYFFQ(data){
                this.selectDataKey=[];
                this.dataSource2 = [];
                this.noteShow = true;
                this.dataSource2 = this.model_to_model(data);
                this.dataSource2 =this.dataSource2.map((el)=>{
                    return {
                        areaName:el.name,
                        cantonId:el.id,
                        cantonNoGeom:el,
                        geom:el.geom
                    }
                })
                this.showTable=true
            },
            close() {
                this.noteShow = false;
            },
            doSearch() {
                this.selectDataKey=[];
                this.dataSource2 = [];
                let parms = {
                    wkt: this.polygonWKT
                };
                this.loading = true;
                zone.queryIntersections.post(this, parms, res => {
                    this.loading = false;
                    this.flag = true;
                    if (res.data.success) {
                        let list = res.data.result;
                        list = this.model_to_model(list);
                        this.dataSource2 = list;
                        //根据县级筛选乡镇
                        if(localStorage.getItem('map_level')=='2'){
                            let neWarr=[];
                            this.dataSource2.forEach((el)=>{
                              if(this.$parent.list_info.find(ee=>ee.id==el.cantonId)){
                                  neWarr.push(el)
                              }
                            })
                            this.dataSource2 = neWarr;
                        }
                        this.showTable=true
                        this.noteShow=true
                    }else{
                        this.$message.error(res.data.message)
                    }
                });
            },
            model_to_model(list) {
                let listInfo = [];
                if (list != null && list.length > 0) {
                    list.forEach((item, index) => {
                        if (item.cantonNoGeom != null) {
                            item.areaName = item.cantonNoGeom?.name;
                        }
                        listInfo.push(item);
                    })
                }
                return listInfo;
            },
            handleSelect(keys) {
                this.selectDataKey = keys;
            },
            sendData(arg) {
                if (this.selectDataKey.length > 1) {
                    this.$message.warning('请选择批量发送短信')
                    return
                }
                let ids = arg.cantonId;
                this.$parent.sendPopOpen(ids, arg.areaName,[arg]);
            },
            batchAudit_open() {
                let key = this.selectDataKey;
                if (key == null || key.length <= 0) {
                    this.$message.info("请选择需要操作的数据！");
                    return;
                }
                let ids = '';
                let datas=[]
                key.forEach((item, index) => {
                    if (index == 0) {
                        ids += item;
                    } else {
                        ids += "," + item;
                    }
                    datas.push(this.dataSource2.filter(el=>el.cantonId==item)[0])

                })
                this.$parent.sendPopOpen(ids,'',datas);
            },

        },
    }
</script>

<style scoped>
    .bounced-data {
        width: 460px;
        height: 500px;
        background: rgba(0, 38, 77, 0.94);
        border: 1px solid #3D8FC8;
        position: absolute;
        z-index: 1000;
        top: 27px;
        left: 12px
    }

    .bounced-header {
        width: 100%;
        height: 6%;
        background: linear-gradient(90deg, rgba(58, 136, 255, 0.1) 0%, #2D6BC9 47%, rgba(58, 136, 255, 0.1) 100%);
    }

    .bounced-content {
        width: 100%;
        height: 94%;
        border-radius: 0px 0px 8px 8px;
        padding: 0 15px;
        /* overflow: auto;*/
    }

    .bounced-title {
        font-size: 14px;
        font-weight: bold;
        color: white;
        padding-top: 6px;
    }

    .bounced-gb {
        margin-top: -23px;
        margin-left: 95%;
    }

    /deep/ .ant-pagination-item {
        min-width: 28px;
        height: 28px;
    }

    /deep/ .ant-pagination-prev {
        min-width: 28px;
        height: 28px;
    }

    /deep/ .ant-pagination-next {
        min-width: 28px;
        height: 28px;
    }

    /deep/ .ant-pagination-item-active a {
        color: #afb9c1;
    }

    .op-bar {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
</style>
