<!--
* @description：
* @author:rzl
* @date:2024/3/29 10:59
-->

<template>
    <div>
        <!-- 遮罩层 -->
        <div v-if="showMask" class="mask-layer">
            <div class="bounced-data">
                <div class="bounced-header">
                    <div class="bounced-title">{{title}}</div>
                </div>
                <div class="bounced-content">
                    <a-row :gutter="24" style="width: 100%">
                        <a-col :xs="24" :sm="24" :xl="24" class="aCol">
                            <a-form-item
                                    label="所属行政区："
                                    name="cantonId"
                            >
                                <a-col class="aColRight">{{areaName}}</a-col>
                            </a-form-item>
                        </a-col>
                        <a-col :xs="24" :sm="24" :xl="24" class="aCol">
                            <div style="display: flex">
                                <a-form-item style="width: 70%;"
                                             label="短信发送时间："
                                             name="sendTime"
                                >
                                    <a-date-picker :disabled="isNowSend" :show-time="{ format: 'HH:mm',minuteStep: 5 }"
                                                   v-model:value="sendTime"
                                                   :disabledDate="disabledDate" format="YYYY-MM-DD HH:mm"
                                                   @change="checkSend"
                                                   style="width: 100%;"/>
                                </a-form-item>
                                <div style="color: #fff;margin-left: 20px">
                                    <a-switch :style="{backgroundColor: !isNowSend?'#333':'#fff'}"
                                              v-model:checked="isNowSend"/>
                                    立即发送
                                </div>

                            </div>

                        </a-col>
                        <a-col :xs="24" :sm="24" :xl="24" class="aCol">
                            <a-form-item
                                    label="短信模板："
                                    name="content"
                            >
                               <div style="display: flex;justify-content: flex-start;margin-left: 20px">
                                   <a-select class="aColRight" placeholder="选择短信模板"
                                             ref="select"
                                             v-model:value="selectValue"
                                             style="width: 120px"
                                             :options="templateOptions"
                                   >
                                   </a-select>
                               </div>
                            </a-form-item>
                        </a-col>

                        <a-col :xs="24" :sm="24" :xl="24" class="aCol">
                            <a-form-item
                                    label="短信内容："
                                    name="content"
                            >
                                <a-textarea v-model:value="content" :rows="12"/>
                            </a-form-item>
                        </a-col>
                    </a-row>

                </div>
                <div class="bounced-footer">
                    <div class="cancel" @click="hideMask">取消</div>
                    <a-popconfirm
                            ok-text="是"
                            cancel-text="否"
                            class="action-button"
                            :title="send_title"
                            @confirm="sendSubmit()">
                        <a-button class="confirm" style="width: 100px;">
                            发送短信
                        </a-button>
                    </a-popconfirm>
                </div>
            </div>

        </div>
        <div class="sendingMask" v-if="sending">
            <a-spin style="color:#42ff65;" tip="发送中，请稍等..."/>
        </div>
        <a-modal v-model:visible="showMsg" title="发送消息信息" @ok="showMsg=false">
            <p>{{returnwMsg}}</p>
        </a-modal>
    </div>

</template>

<script>
    import CloseOp from "@/components/table/op/CloseOp";
    import dayjs from "dayjs";
    import message from "@/assets/api/message";
    import {lookUp_dictCode} from '@/assets/utils/dictUtil.js'

    import config from "@/assets/api/config";

    export default {
        name: "maskSend",
        components: {
            CloseOp
        },
        data() {
            return {
                title: '发送短信内容',
                showMask: false, // 控制遮罩层的显示与隐藏
                content: null,
                sendTime: null,
                areaName: null,
                cantonId_send: null,
                send_title: '确定发送短信吗?',
                datas: null,
                isNowSend: true,
                sending: false,
                showMsg: false,
                returnwMsg: '',
                templateOptions: '',
                selectValue: '',
                dicWarningList: ''
            };
        },
        mounted() {
            this.dicWarningList = lookUp_dictCode('warning_level')
            this.content = null
            this.sendTime = null
            this.areaName = null
            this.getTemplate();
        },

        methods: {
            openMask(ids, areaName, datas) {
                this.areaName = areaName;
                this.showMask = true;
                this.isNowSend = false
                this.sending = false
                this.datas = datas
                this.content = null
            },
            hideMask() {
                this.showMask = false;
            },
            disabledDate(current) {
                // 禁用当前时间之前的日期
                return current && current < dayjs().startOf('day');
            },
            checkSend() {
                /* let sendTime = this.sendTime == null ? null : dayjs(this.sendTime).format("YYYY-MM-DD HH:mm:ss");
                 let data = {
                     cantonId:this.cantonId_send,
                     sendTime:sendTime,
                     source:1,
                 }
                 message.checkSendManual.post(this,data,res=>{
                     if(res.data.success){
                         this.send_title = "确定发送短信吗?";
                         this.$message.success(res.data.message)
                     }
                     else{
                         this.$message.error(res.data.message)
                     }
                 })*/
            },
            sendSubmit() {
                // this.$message.success("发送成功");
                // this.hideMask();
                if (this.sending) return;
                this.sending = true
                if ((this.sendTime == null || this.sendTime == '') && !this.isNowSend) {
                    this.$message.info('请选择短信发送时间');
                    return;
                }
                let sendTime = this.sendTime == null ? null : dayjs(this.sendTime).format("YYYY-MM-DD HH:mm");
                this.showMsg = false
                this.returnwMsg = ''
                let list = [];
                this.datas.forEach((el) => {
                    let data = {
                        cantonId: el.cantonId,
                        content: this.content,
                        isNowSend: this.isNowSend,
                        wkt: el.geom
                    }
                    if (!this.isNowSend) {
                        data.sendTime = sendTime
                    }
                    list.push(data)
                })
                message.sendByManual.post(this, {list: list}, res => {
                    if (res.data.success) {
                        this.showMask = false;
                        this.sending = false
                        this.returnwMsg = res.data.message
                        this.showMsg = true
                    } else {
                        this.returnwMsg = ''
                        this.sending = false
                        this.$message.error(res.data.message)
                    }
                })
                this.content = null
                this.sendTime = null
                this.areaName = null

            },
            //获取短信模板
            getTemplate() {
                config.queryAll.post(this, {}, res => {
                    if (res.data.success) {
                        let list = res.data.result;
                        this.templateOptions = list.map((el) => {
                            return {
                                ...el,
                                value: el.id,
                                label:el.warningLevel
                            }
                        })

                    }
                });
            }
        },
        watch: {
            selectValue() {
                this.content = this.templateOptions.find(el => el.id == this.selectValue)?.precaution
            }
        }
    }
</script>

<style scoped>
    .mask-layer {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(44, 62, 80, 0.5); /* 半透明背景 */
        z-index: 1001; /* 确保遮罩层在最上层 */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bounced-data {
        width: 700px;
        height: 500px;
        background: rgba(0, 38, 77, 0.94);
        border: 1px solid #536a7a;
        position: absolute;
        z-index: 1000;
        top: 115px;
        left: 32%;
    }

    .bounced-header {
        width: 100%;
        height: 6%;
        background: linear-gradient(90deg, rgba(58, 136, 255, 0.1) 0%, #2D6BC9 47%, rgba(58, 136, 255, 0.1) 100%);
    }

    .bounced-content {
        width: 100%;
        height: 84%;
        border-radius: 0px 0px 8px 8px;
        padding: 0 15px;
        overflow: auto;
    }

    .bounced-footer {
        width: 100%;
        height: 10%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .bounced-title {
        font-size: 14px;
        font-weight: bold;
        color: white;
        padding-top: 6px;
    }

    .cancel {
        border-radius: 5px;
        /*line-height: 25px;*/
        color: #FFFFFF;
        text-align: center;
        margin-right: 20px;
        width: 68px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 28px;
        background: rgba(0, 37, 62, 0.2);
        opacity: 1;
        border: 1px solid #0CA4D6;
    }

    .cancel:hover {
        cursor: pointer;
    }

    .confirm {
        border-radius: 5px;
        /*line-height: 24px;*/
        color: #FFFFFF;
        text-align: center;
        margin-right: 20px;
        width: 64px;
        height: 28px;
        background: linear-gradient(90deg, #0974BD 0%, #0CA6D7 100%);
        opacity: 1;
        border: 1px solid #0CA4D6;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .confirm:hover {
        cursor: pointer;
    }

    .aColRight {
        text-align: left;
    }

    /deep/ .ant-pagination-item {
        min-width: 28px;
        height: 28px;
    }

    /deep/ .ant-pagination-prev {
        min-width: 28px;
        height: 28px;
    }

    /deep/ .ant-pagination-next {
        min-width: 28px;
        height: 28px;
    }

    /deep/ .ant-pagination-item-active a {
        color: #afb9c1;
    }

    .sendingMask {
        width: 100vw;
        height: 100%;
        z-index: 9999;
        background-color: rgba(0, 0, 0, .5);
        position: fixed;
        color: #0b5cad;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0px;
        left: 0px;
    }

    /deep/ .ant-spin-dot-item {
        background-color: #42ff65;
    }

    /deep/ .ant-switch-handle::before {
        background-color: #00a2ff;
    }

    /deep/ .ant-picker-disabled {
        background-color: unset;
    }
</style>
