<template>
    <div class="card-bg">
        <div class="card-bg-tit"><span>{{ $props.title}}</span>
            <a-tooltip :title="tips||'无'" :placement="'right'">
                <QuestionCircleOutlined class="tips"></QuestionCircleOutlined>
            </a-tooltip>
        </div>
        <div class="card-cont">
            <div class="inner-container">
                <slot></slot>
            </div>

        </div>
    </div>
</template>

<script>
    import {
        QuestionCircleOutlined
    } from "@ant-design/icons-vue"

    export default {
        name: "bigCard.vue",
        props: ['title', 'tips'],
        components: {QuestionCircleOutlined},
        data() {
            return {}
        }
    }
</script>

<style scoped>
    .card-bg {
        background-image: url("@/assets/image/bigmap/big_card.png");
        background-size: 436px;
        background-repeat: no-repeat;
        width: 436px;
        height: 301px;

    }

    .card-bg-tit {
        text-indent: 60px;
        font-weight: bold;
        font-size: 18px;
        color: #FFFFFF;
        line-height: 10px;
        text-shadow: 0px 0px 10px #004CFF;
        text-align: left;
        height: 40px;
        display: flex;
        align-items: center;
    }

    .card-cont {
        padding: 40px 24px;
        text-align: left;
        height: 220px;
        position: relative;
        overflow: hidden;
        margin-top: 30px;

    }

    .inner-container {
        position: absolute;
        left: 0;
        top: 0;
        right: -17px;
        bottom: 0;
        overflow-x: hidden;
        overflow-y: scroll;
    }

    .tips {
        position: relative;
        left: -50px;
        font-size: 14px;
    }

</style>
