/**
 * @program: 前台
 * @author: ly
 * @description: operateEnum
 * 菜单权限设定
 * @create: 2023-02-14 11:03
 **/

//value不允许出现重复值，如果有重复的，重复的值表示同一个权限
const OperateEnums =[
    {label:"添加",value:"add",color:"green"},
    {label:"编辑",value:"edit",color:"blue"},
    {label:"删除",value:"del",color:"red"},
    {label:"查询",value:"query",color:"green"},

    {label:"下载",value:"download",color:"green"},
    {label:"上传",value:"upload",color:"green"},
    {label:"审核",value:"audits",color:"green"},
    {label:"提交",value:"submits",color:"green"},

    // {label: "基本情况", value: "auth_basic_info",color:"green"},
    // {label: "调查", value: "auth_survey",color:"green"},
    // {label: "巡查", value: "auth_patrol",color:"green"},
    // {label: "人员情况", value: "auth_survey_situation",color:"green"},
    // {label: "巡查情况", value: "auth_patrol_situation",color:"green"},
    // {label: "险情上报", value: "auth_danger_report",color:"green"},
    // {label: "隐患排查", value: "auth_pitfall_report",color:"green"},
    // {label: "我的巡查", value: "auth_user_patrol",color:"green"},
    // {label: "隐患排查记录", value: "auth_pitfall_report_record",color:"green"},

    // {label:"区级群发",value:"level1",color:"green"},
    // {label:"乡镇级群发",value:"level2",color:"green"},
];


const OEnums = [],OEnumsColors = [],OEnumsLabels = [];

for(let item of OperateEnums){
    let {label,value,color} = item;
    OEnums.push(value);
    OEnumsColors.push(color);
    OEnumsLabels.push(label);
}
let arr = [...new Set(OEnums)];
if(arr.length !== OEnumsColors.length){
    console.log("菜单权限设定重复");
}

export default OperateEnums;
export {OEnums,OEnumsLabels,OEnumsColors};
