<template>
    <div class="map-meuns">
        <a-checkbox  v-model:checked="checked">天气</a-checkbox>
    </div>
</template>

<script>
    export default {
        name: "mapMenus.vue",
        data() {
            return {
                checked: true
            }
        },
        watch: {
            checked() {
                this.$emit('updateLayer')
            }
        }
    }
</script>

<style scoped>
    .map-meuns {
        position: absolute;
        z-index: 9999;
        bottom: 10px;
        left: 280px;
        background: linear-gradient(180deg, rgba(0, 145, 191, 0.53) 0%, rgba(0, 34, 58, 0.4) 100%);
        height: 50px;
        display: flex;
        align-content: center;
        padding: 0px 20px;
    }

    .ant-checkbox-wrapper {
        display: flex;
        align-items: center;
    }

</style>