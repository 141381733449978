<!--
* @program: tyh-oa 
* @author: ly
* @description: typeString 
* @create: 2021-03-23 10:01
-->
<template>
    <a-textarea
            @blur="blurTrigger"
            @input="columnTrigger"
            :placeholder="placeholder"
            auto-size
            v-if="editable"
            v-model:value="record[column.dataIndex]"
    />
    <div v-else @click="onClick">
        <a-tooltip trigger="click" :title="presentValue">
            <div  class="type-text" >{{presentValue}}</div>
        </a-tooltip>
    </div>
</template>

<script>
    import typeBase from "./typeBase";


    export default {
        name: "typeString",
        mixins:[typeBase],
    }
</script>

<style scoped>
    .type-text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

</style>