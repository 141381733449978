/** 
* 自动生成文件，请勿修改 
* @name: 红河州地灾-雨量监测站点分布管理(新增)
* @description: Rainfall Monitor Controller
* @create: 2024/11/8 10:34:25
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const params = { 
	add : [{"cantonId":{"type":"string","description":"行政区划id(乡镇id)"},"countyId":{"type":"string","description":"区县id,部分点找不到对应乡镇，增加该区县id"},"geom":{"description":"几何对象","$ref":"#/definitions/Geometry","originalRef":"Geometry"},"name":{"type":"string","description":"名称"},"remarks":{"type":"string","description":"备注"}}], 
	batchAdd : [{"list":{"type":"array","items":{"$ref":"#/definitions/雨量监测站点分布-新增参数对象","originalRef":"雨量监测站点分布-新增参数对象"}}}], 
	del : null, 
	delBatch : null, 
	edit : [{"cantonId":{"type":"string","description":"行政区划id(乡镇id)"},"countyId":{"type":"string","description":"区县id,部分点找不到对应乡镇，增加该区县id"},"geom":{"description":"几何对象","$ref":"#/definitions/Geometry","originalRef":"Geometry"},"id":{"type":"string","description":"主键id"},"name":{"type":"string","description":"名称"},"remarks":{"type":"string","description":"备注"}}], 
	queryAll : [{"cantonId":{"type":"string","description":"行政区划id(乡镇id)(精确匹配)"},"countyId":{"type":"string","description":"区县id,部分点找不到对应乡镇，增加该区县id(精确匹配)"},"name":{"type":"string","description":"名称(模糊匹配)"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"}}], 
	queryCount : [{"cantonId":{"type":"string","description":"行政区划id(乡镇id)(精确匹配)"},"countyId":{"type":"string","description":"区县id,部分点找不到对应乡镇，增加该区县id(精确匹配)"},"name":{"type":"string","description":"名称(模糊匹配)"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"}}], 
	queryOne : null, 
	queryPage : [{"cantonId":{"type":"string","description":"行政区划id(乡镇id)(精确匹配)"},"countyId":{"type":"string","description":"区县id,部分点找不到对应乡镇，增加该区县id(精确匹配)"},"name":{"type":"string","description":"名称(模糊匹配)"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"}}], 
};
const rainfallMonitor = { 
	/** 1 summary:新增	method:post */ 
	add : new APIObject.postObject("/honghe/rainfallMonitor/add",params.add), 
	/** 2 summary:新增(批量)	method:post */ 
	batchAdd : new APIObject.postObject("/honghe/rainfallMonitor/batchAdd",params.batchAdd), 
	/** 3 summary:删除(单条)	method:delete */ 
	del : new APIObject.deleteObject("/honghe/rainfallMonitor/del",params.del), 
	/** 4 summary:删除(批量)	method:delete */ 
	delBatch : new APIObject.deleteObject("/honghe/rainfallMonitor/delBatch",params.delBatch), 
	/** 5 summary:编辑	method:post */ 
	edit : new APIObject.postObject("/honghe/rainfallMonitor/edit",params.edit), 
	/** 6 summary:查询-不分页	method:post */ 
	queryAll : new APIObject.postObject("/honghe/rainfallMonitor/queryAll",params.queryAll), 
	/** 7 summary:查询-数据条数	method:post */ 
	queryCount : new APIObject.postObject("/honghe/rainfallMonitor/queryCount",params.queryCount), 
	/** 8 summary:查询-单条	method:get */ 
	queryOne : new APIObject.getObject("/honghe/rainfallMonitor/queryOne",params.queryOne), 
	/** 9 summary:查询-分页	method:post */ 
	queryPage : new APIObject.postObject("/honghe/rainfallMonitor/queryPage",params.queryPage), 
};
export default rainfallMonitor;