<!--
* @program: tyh-oa 
* @author: ly
* @component:typeIdReplace 
* @description: 
* @create: 2021-04-16 10:27
-->
<template>
    <div v-if="viewModel">
        <div  @click="onClick">{{presentValue}}</div>
    </div>
    <div v-else>
        <a-select
                v-if="editable"
                show-search
                v-model:value="record[column.dataIndex]"
                placeholder="输入关键字查23询"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @select="handleSelect"
                @search="handleSearch"
                @change="columnTrigger"
        >
            <a-select-option v-for="option in selectOptions" :key="option.id">
                <a-tooltip :title="getName(option)">
                    <a-button style="padding: 0" type="text">
                        {{ getName(option) }}
                    </a-button>
                </a-tooltip>
            </a-select-option>
        </a-select>
        <div  v-else @click="onClick">{{presentValue}}</div>
    </div>
</template>

<script>
    import typeBase from "./typeBase";
    import {computed} from "vue";
    export default {
        name: "typeIdReplace",
        mixins:[typeBase],

        computed:{
            presentValue(){
                return this.record[this.column.idReplaceObject.replaceSegment]?this.record[this.column.idReplaceObject.replaceSegment]:"无"
            },
            //能够选择的选项
            selectOptions(){
                return this.defaultOptions.concat(this.options)
            },
        },
        data(){
            return{
                //查询到的选项
                options:[],
                queryObject:computed(()=>this.column.idReplaceObject.queryObject),
                defaultOptions:[]
            }
        },
        methods:{
            handleSearch(key){
                if(key){
                    key = key.replace(/'/g, '');
                    let data={};
                    data[this.queryObject.tag] = key;
                    this.queryObject.api.post(this,data,res=>{
                        if(res.data.success){
                            this.options = res.data.result;
                        }
                        else{
                            this.options =[];
                        }
                    })
                }
            },
            handleSelect(value){
                let option = this.selectOptions.filter(item=>item.id === value)[0];
                this.record[this.column.idReplaceObject.replaceSegment] = this.getName(option);
            },
            getName(option){
                if(Object.hasOwnProperty.call(option,"name")){
                    return option.name
                }
                if(Object.hasOwnProperty.call(option,"title")){
                    return option.title
                }
                return "指定显示字段不存在"
            }
        },
        created() {
            this.defaultOptions=[
                {id:this.record[this.column.dataIndex], name:this.record[this.column.idReplaceObject.replaceSegment]}
            ]
        }
    }
</script>

<style scoped>

</style>