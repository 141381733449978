<template>
    <a-layout style="overflow: hidden" class="layout-main">
        <a-layout-header class="layout-header">
            <oaBanner>
                <div style="display: flex;align-items: center;">
                    <!--                        <span :class="item.enable?'head-title':'head-title2'" v-for="item in menuLists" :key="item.id" @click="headClick(item)">
                                                <span :class="item.enable?'menu-enable':'menu-disable'">{{item.name}}</span>
                                            </span>-->

                    <span class="head-title" v-for="item in menuLists" :key="item.id" @click="headClick(item)">
                            <span :class="item.enable?'menu-enable':'menu-disable'">
                               <img src="../../assets/image/main/数据管理-选中.png"
                                    v-if="item.component == 'MainDZ' && item.enable">
                                <img src="../../assets/image/main/数据管理-默认.png"
                                     v-if="item.component == 'MainDZ'  && !item.enable">
                                <img src="../../assets/image/main/系统维护-选中.png"
                                     v-if="item.component == 'MainPage'  && item.enable">
                                <img src="../../assets/image/main/系统维护-默认.png"
                                     v-if="item.component == 'MainPage'  && !item.enable">
                                {{item.name}}
                            </span>
                        </span>
                    <HeadToolBar/>
                </div>
            </oaBanner>
        </a-layout-header>
        <a-layout>
            <a-layout-sider v-if="subMenus.length>0" style="overflow: auto" v-model:collapsed="collapsed"
                            class="aside-menus" :trigger="null" collapsible>
                <sub-group-menu :sub-menus="subMenus" @item="itemClick" @enable="()=>{this.visible = true}"/>
            </a-layout-sider>
            <a-layout-content style="overflow:auto; background: #fff; min-height:500px">
                <div class="app-main" v-if="visible">
                    <router-view/>
                </div>
                <div v-else class="app-main-emty">
                    <div class="app-main-emty-img"></div>
                    <div class="app-main-emty-text">正在使用后台管理功能</div>
                </div>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
    import subGroupMenu from "../../components/menu/subGroupMenu";
    import oaBanner from "./oaBanner";
    import {readToken} from "../../assets/utils/APIObject";
    import MainMap from "../Map/MainMap";
    import HeadToolBar from "./HeadToolBar";
    import login from "../../assets/api/sysSets";
    export default {
        name: 'MainPage',
        components: {
            subGroupMenu, oaBanner, MainMap, HeadToolBar
        },
        data() {
            return {

                userInfo: null,
                collapsed: false,
                componentTags: [],
                maxLength: 8,
                mapObject: null,
                mapCode: null,
                visible: false,
                dlWidth: 40,
                defaultStyle: null,
            }
        },
        watch: {
            visible() {
                this.divResize()
            }
        },
        computed: {
            drWidth() {
                return 100 - this.dlWidth;
            },
            menuLists() {
                if (this.userInfo?.menus) {
                    let arr = this.userInfo.menus.filter(item => item.belongEnd === "0");//获取到客户端为PC的菜单
                    if (arr.length > 0) {
                        return arr
                    } else {
                        this.$message.error("还未配置菜单信息");
                        return []
                    }

                } else {
                    this.$message.error("无法读取菜单信息");
                    return []
                }
            },
            subMenus() {
                let menu = this.menuLists.find(menu => menu.enable);
                if (menu && menu.children) {
                    return this.menuLists.find(menu => menu.enable).children
                } else {
                    return []
                }
            }
        },
        methods: {
            divResize() {
                if (!this.$refs.left) {
                    return
                } else {//需要将上一个组件的style记录下来，然后将修改过的style进行恢复，这里只考虑组件的style只做position赋值，其他情况需要修改代码
                    this.$refs.left.style.removeProperty("position")
                }
                let component = this.$route.matched[this.$route.matched.length - 1];
                let style = {};
                if (component.components && component.components.default?.data) {
                    let width = component.components.default.data().componentWidth;
                    this.dlWidth = (width !== undefined) ? width : 60;
                    style = component.components.default.data().style;
                }
                if (this.visible) {
                    this.$refs.left.style.width = this.dlWidth + "%";
                    this.$refs.right.style.width = this.drWidth + "%";
                } else {
                    this.$refs.left.style.width = "0%";
                    this.$refs.right.style.width = "100%";
                }
                if (style) {
                    Object.assign(this.$refs.left.style, style);
                }
                this.mapObject.updateSize();
            },
            headClick(item) {
                this.$router.push({name: item.componentName});
                this.visible = false;
            },

            itemClick(item) {
                if (this.mapCode === "map" && this.mapObject != null) {
                    this.$refs.mainMap.cleanMap();
                }
                this.$router.push({name: item.componentName});
                this.visible = true;
            },
            logout() {
                login.logout.get(this,{},res=>{
                    if(res.data.success){
                        this.$message.success('退出成功！')
                        setTimeout(()=>{
                            localStorage.setItem("userInfo","");
                            localStorage.setItem("token","");
                            localStorage.setItem("menus","");
                            this.$router.push({path: '/'});
                        },3000)
                    }
                })

            },
            setMap(map) {
                this.mapObject = map;
            },
            resetPwd() {
                this.$router.push({path: '/ResetPassword'});
            }
        },
        created() {
            this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
            readToken();
            matchEnableMenu(this.$route.matched, this.menuLists);
            this.$router.afterEach((to, from) => {
                this.divResize();
            })
        },
        mounted() {
            this.divResize();
        }
    }

    //在刷新的时候根据当前url激活菜单
    function matchEnableMenu(paths, menuList) {
        for (let menu of menuList) {
            let me = paths.find(path => menu.component === path.name);
            menu.enable = !!me;
            if (menu.children && menu.children.length > 0) {
                matchEnableMenu(paths, menu.children)
            }
        }
    }
</script>

<style scoped>
    /*
    /deep/.ant-menu.ant-menu-dark{
      font-size: 16px;
      font-weight: 400;
      color: #546175;
      background: #FFFFFF;
    }
    /deep/.ant-layout-sider{
      font-size: 16px;
      font-weight: 400;
      color: #546175;
        background: #0b3a6d;
      box-shadow: 0px 4px 8px 1px rgba(0,0,0,0.16);
    }
    /deep/.ant-menu.ant-menu-dark .ant-menu-item-selected{
      background-color: #F3F8FF;
    }
    /deep/.ant-menu-dark .ant-menu-inline.ant-menu-sub {
      background: #ffffff;
    }
    /deep/.ant-menu-submenu{
      border-bottom: none;
      font-weight: 400;
      color: #546175;

    }
    /deep/.ant-menu-item{
      border-bottom: none;
      font-weight: 400;
      color: #546175;
    }
    /deep/.ant-menu.ant-menu-dark .ant-menu-item-selected{
      background: #F3F8FF;
      font-weight: 400;
    }
    /deep/.ant-menu-submenu-open{
      background-color: #0B58DA;
      font-size: 16px;
      font-weight: 400;
      color: #FFFFFF;
    }
    /deep/ .ant-menu-dark .ant-menu-submenu-title:hover  {
      background-color: #0B58DA;
      color: #FFFFFF;
    }

    */
    .aside-menus {
        background-image: url("@/assets/image/menu-aside.png");
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    /deep/ .ant-menu,.ant-menu-sub {
        background: unset!important;
    }
</style>
