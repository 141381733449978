    <!--
* @program: tyh-oa
* @author: ly
* @description: subGroupMenu
* @create: 2021-03-22 16:56
-->
<template>
    <a-menu v-model:openKeys="openKeys" theme="dark" mode="inline" >
        <div v-for="(sub) in subMenus" :key="sub.id">
            <a-sub-menu v-if="sub.children && sub.children.length>0" :key="sub.id">
                <template #title>
                    <span >{{sub.name}}</span>
                </template>
<!--
                <a-menu-item @click="itemClick(item)" v-for="item in sub.children" :key="item.id">
                    <span :style="item.enable?'font-weight: 400;color: #0B58DA;':'font-weight: 400;color: #6A7992;'">{{item.name}}</span>
                </a-menu-item>
-->

                <a-menu-item @click="itemClick(item)" v-for="item in sub.children" :key="item.id">
                    <span :style="item.enable?'color:#3dd0ff':'color:white'">{{item.name}}</span>
                </a-menu-item>
            </a-sub-menu>
<!--            <a-menu-item v-else @click="itemClick(sub)" :key="sub.id"  >
                <span :style="sub.enable?'font-weight: 400;color: #0B58DA;':'font-weight: 400;color: #6A7992;'">{{sub.name}}</span>
            </a-menu-item>-->

            <a-menu-item v-else @click="sub.name=='统计分析'? openBigMap():itemClick(sub)" :key="sub.id"  >
                <span :style="sub.enable?'color:#3dd0ff':'color:white'">{{sub.name}}</span>
            </a-menu-item>
        </div>
    </a-menu>
</template>

<script>
import {QqOutlined} from "@ant-design/icons-vue"
    export default {
        name: "subGroupMenu",
        components:{QqOutlined},
        props:["subMenus"],
        emits:["item","enable"],
        data(){
            return{
                openKeys:[]
            }
        },
        methods:{
            resetEnable(){
                for(let menu of this.subMenus){
                    menu.enable = false;
                    if(menu.children && menu.children.length>0){
                        for(let child of menu.children){
                            child.enable  = false;
                        }
                    }
                }
            },
            itemClick(menu){
                this.resetEnable();
                menu.enable = true;
                this.$emit("item",menu);
            },
            openBigMap(){
                window.open('./#/bigMap')
            }
        },
        mounted() {
            this.openKeys = [];
            for(let sub of this.subMenus){
                if(sub.enable){
                    this.openKeys.push(sub.id);
                    this.$emit("enable")
                }
            }
        }
    }
</script>

<style scoped>
    .menu{
        height: 34px;
        border: solid 1px #3987BE;
        margin: 2px 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
    }

</style>
