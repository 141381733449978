/**
 * @program: tyh-oa
 * @author: ly
 * @description: APIObject
 * @create: 2021-03-19 14:17
 **/
import axios from "axios";
axios.defaults.withCredentials = true;

//const baseUrl = "http://192.168.10.190:8086/zslt-boot/";
//const baseUrl = "http://192.168.10.45:8086/zslt-boot/";
// const baseUrl = "http://localhost:8086/zslt-boot/";

/**
 *  publicPath: '/HXZF_QT',
 *  190 开发测试
 */
const baseUrl = "/hongheApi";
const geoserver_url = '/honghezhou_geoserver';// 开发测试
//const geoserver_url = '/wndz_geoserver';


/**
 * publicPath: '/HXZFSY',
 * 190 试用
 */
/* const baseUrl = "/hxqzf-formal";// 190 试用
 const geoserver_url = '/hxzf_formal_geoserver';// 190试用版*/

class apiClass {
    constructor(url) {
        this.url = apiClass.baseUrl()  + url;
        this.tdtUrl = url;
    }
    static baseUrl(){
        return baseUrl;
    }
    static geoserverUrl(){
        return geoserver_url;
    }
    fileDownload(_this,url,fileName){//下载文件使用
        axios({
            url:this.url + url,
            responseType: 'blob',
            method: 'get',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "X-Access-Token": localStorage.getItem("token")
            }
        }).then(res =>{
            if(res.data.code === 401){
                _this.$message.error(res.data.message);
            }
            if (res.data.type === 'application/json') {
                const reader = new FileReader();
                reader.readAsText(res.data, 'utf-8');
                reader.onload = () => {
                    let msg = JSON.parse(reader.result);
                    _this.$message.error(msg.msg);
                };
            }
            else{
                // 非IE下载
                if ('download' in document.createElement('a')) {
                    let tagA = document.createElement('a');
                    tagA.href = window.URL.createObjectURL(res.data) ;// 获取当前文件的一个内存URL
                    tagA.download = fileName ;// 下载后文件名
                    tagA.style.display = 'none';
                    document.body.appendChild(tagA);
                    tagA.click();
                    window.URL.revokeObjectURL(tagA.href);
                    document.body.removeChild(tagA);
                }
                else{
                    window.navigator.msSaveBlob(res.data,fileName);
                }
            }
        }).catch(error=>{
            if(_this){
                if(error?.response.data.status == 500){
                    _this.$message.error("登录失效，请重新登录");
                    _this.$router.push("/");
                }
                _this.$message.error("axios错误");
            }});
    }
    fileDownloadGet(_this,data,fileName){//下载文件使用
        let url = this.url+"?";
        for(let i in data){
            let str = i+"="+data[i];
            url += str + "&";
        }
        url = url.substr(0,url.length-1);
        axios({
            url,
            responseType: 'blob',
            method: 'GET',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8'
            }
        }).then(res =>{
            if(res.data.code === 401){
                _this.$message.error(res.data.message);
            }
            if (res.data.type === 'application/json') {
                const reader = new FileReader();
                reader.readAsText(res.data, 'utf-8');
                reader.onload = () => {
                    let msg = JSON.parse(reader.result);
                    _this.$message.error(msg.msg);
                };
            }
            else{
                // 非IE下载
                if ('download' in document.createElement('a')) {
                    let tagA = document.createElement('a');
                    tagA.href = window.URL.createObjectURL(res.data) ;// 获取当前文件的一个内存URL
                    tagA.download = fileName ;// 下载后文件名
                    tagA.style.display = 'none';
                    document.body.appendChild(tagA);
                    tagA.click();
                    window.URL.revokeObjectURL(tagA.href);
                    document.body.removeChild(tagA);
                }
                else{
                    window.navigator.msSaveBlob(res.data,fileName);
                }
            }
        }).catch(error=>{
            console.log(error.response)
            if(error.response.data.status == 500){
                _this.$message.error("登录失效，请重新登录");
                _this.$router.push("/");
            }
            _this.$message.error("axios错误");
        })
    }

    fileDownloadPost(_this,data,fileName){//下载文件使用
        axios({
            url:this.url,
            data: data,
            responseType: 'blob',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json;charset=UTF-8',
                "X-Access-Token": localStorage.getItem("token")
            }
        }).then(res =>{
            if(res.data.code === 401){
                _this.$message.error(res.data.message);
            }
            if (res.data.type === 'application/json') {
                const reader = new FileReader();
                reader.readAsText(res.data, 'utf-8');
                reader.onload = () => {
                    let msg = JSON.parse(reader.result);
                    _this.$message.error(msg.msg);
                };
            }
            else{
                // 非IE下载
                if ('download' in document.createElement('a')) {
                    let tagA = document.createElement('a');
                    tagA.href = window.URL.createObjectURL(res.data) ;// 获取当前文件的一个内存URL
                    tagA.download = fileName ;// 下载后文件名
                    tagA.style.display = 'none';
                    document.body.appendChild(tagA);
                    tagA.click();
                    window.URL.revokeObjectURL(tagA.href);
                    document.body.removeChild(tagA);
                }
                else{
                    window.navigator.msSaveBlob(res.data,fileName);
                }
            }
        }).catch(error=>{
            if(_this){
                try{
                    _this.$message.error("下载出错！");
                }
                catch (e) {
                    console.log(e)
                }
            }});
    }
}
class getObject extends apiClass{
    constructor(url) {
        super(url);
    }
    /**
     *
     * @param data 传入参数
     * @param callback 回调函数
     * @param _this vue的实例  若传入则会提示catch
     */
    get(_this,data,callback){
        axios.get(this.url,{params:data})
            .then(res=>{
                if(res.data.code === 401){
                    _this.$message.error(res.data.message);
                }
                if (callback) {
                    callback(res)
                } else {
                    if(res.data.success){
                        _this.$message.success(res.data.message);
                    }
                    else{
                        _this.$message.error(res.data.message);
                    }
                }
            })
            .catch(error=>{
                console.log(error.response)
                if(error.response.data.status == 500){
                    _this.$message.error("登录失效，请重新登录");
                    _this.$router.push("/");
                }
                _this.$message.error("axios错误");
            })
    }
    getByteImg(_this,data,callback){
        axios.get(
            this.url,
            {params:data,responseType:'blob'},
        )
            .then(res=>{
                if(res.data.code === 401){
                    _this.$message.error(res.data.message);
                }
                if (callback) {
                    callback(res)
                } else {
                    if(res.data.success){
                        _this.$message.success(res.data.message);
                    }
                    else{
                        _this.$message.error(res.data.message);
                    }
                }
            })
            .catch(error=>{
                console.log(error.response)
                if(error.response.data.status == 500){
                    _this.$message.error("登录失效，请重新登录");
                    _this.$router.push("/");
                }
                _this.$message.error("axios错误");
            })
    }
    async asyncGet(data){
        let res = await axios.get(this.url,{params:data});
        return res.data;
    }
}
class postObject extends apiClass{
    tdtUrl(url) {
        this.url = apiClass.baseUrl()  + url;
    }
    constructor(url) {
        super(url);
    }
    post(_this,data,callback){
            axios.post(this.url,data).then(res=> {
                    if(res.data.code === 401){
                        _this.$message.error(res.data.message);
                    }
                    if (callback) {
                        callback(res)
                    } else {
                        if(res.data.success){
                            _this.$message.success(res.data.message);
                        }
                        else{
                            _this.$message.error(res.data.message);
                        }
                    }
                }
            )
            .catch(error=>{
                if(error.response.data.status == 500){
                    _this.$message.error("登录失效，请重新登录");
                    _this.$router.push("/");
                }
                _this.$message.error("axios错误");
            })
    }
    async asyncPost(data){
        let res = await axios.post(this.url,data);
        return res.data;
    }
    async urlAsyncPost(data){
        let url = this.url+"?";
        for(let i in data){
            let str = i+"="+data[i];
            url += str + "&";
        }
        url = url.substr(0,url.length-1);

        let res = await axios.post(url,data);
        return res.data;
    }
    /**
     * POST情况下用url传递参数
     * @param data 传入参数
     * @param callback 回调函数
     * @param _this vue的实例 若传入则会提示catch
     */
    urlPost(_this,data,callback){
        let url = this.url+"?";
        for(let i in data){
            let str = i+"="+data[i];
            url += str + "&";
        }
        url = url.substr(0,url.length-1);
        axios.post(url)
            .then(res=>{
                if(res.data.code === 401){
                    _this.$message.error(res.data.message);
                }
                if (callback) {
                    callback(res)
                } else {
                    if(res.data.success){
                        _this.$message.success(res.data.message);
                    }
                    else{
                        _this.$message.error(res.data.message);
                    }
                }
            })
            .catch(error=>{
                console.log(error.response)
                if(error.response.data.status == 500){
                    _this.$message.error("登录失效，请重新登录");
                    _this.$router.push("/");
                }
                _this.$message.error("axios错误");
            })
    }
    bothPost(_this,{urlParam,formParam},callback){
        let url = this.url+"?";
        for(let i in urlParam){
            if(urlParam[i] !== null && urlParam[i] !==""){
                let str = i+"="+urlParam[i];
                url += str + "&";
            }
        }
        url = url.substr(0,url.length-1);
        axios.post(url,formParam)
            .then(res=>{
                if(res.data.code === 401){
                    _this.$message.error(res.data.message);
                }
                if (callback) {
                    callback(res)
                } else {
                    if(res.data.success){
                        _this.$message.success(res.data.message);
                    }
                    else{
                        _this.$message.error(res.data.message);
                    }
                }
            })
            .catch(error=>{
                console.log(error.response)
                if(error.response.data.status == 500){
                    _this.$message.error("登录失效，请重新登录");
                    _this.$router.push("/");
                }
                _this.$message.error("axios错误");
            })
    }
    /**
     * 获取图层属性信息
     * @param data
     * @param callback
     * @param _this
     */
    requestUrlParam(_this,data,callback){
        let url = this.tdtUrl;
        url = url.substr(0,url.length-1);
        axios.post(url)
            .then(res=>{
                callback(res);
            })
            .catch(error=> {
                alert(error)
            });
    }
}
class deleteObject extends apiClass{
    constructor(url) {
        super(url);
    }
    /**
     * DELETE情况下用url传递参数
     * @param data 传入参数
     * @param callback 回调函数
     * @param _this vue的实例 若传入则会提示catch
     */

    urlDELETE(_this,data,callback){
        axios.delete(this.url,  {
            params: data
        })
        .then(res=> {
                if(res.data.code === 401){
                    _this.$message.error(res.data.message);
                }
                if (callback) {
                    callback(res)
                } else {
                    if(res.data.success){
                        _this.$message.success(res.data.message);
                    }
                    else{
                        _this.$message.error(res.data.message);
                    }
                }
            }
        )
        .catch(error=>{
            console.log(error.response)
            if(error.response.data.status == 500){
                _this.$message.error("登录失效，请重新登录");
                _this.$router.push("/");
            }
            _this.$message.error("axios错误");
        })
    }
}

class mixObject extends Classes([deleteObject,getObject,postObject]){
    constructor(url) {
        super(url);
        this.url = apiClass.baseUrl()  + url;
    }
}
function Classes(bases) {
    class Bases {
        constructor() {
            bases.forEach(base => Object.assign(this, new base()));
        }
    }
    bases.forEach(base => {
        Object.getOwnPropertyNames(base.prototype)
            .filter(prop => prop !== 'constructor')
            .forEach(prop => Bases.prototype[prop] = base.prototype[prop])
    });
    return Bases;
}

function readToken() {
    axios.interceptors.request.use(
        config =>{
            config.headers["X-Access-Token"] = localStorage.getItem("token");
            return config}, error => {return Promise.reject(error)});
}

export default {getObject,deleteObject,postObject,mixObject};
export {readToken,apiClass,baseUrl,geoserver_url};
