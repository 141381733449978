<!--
* @program: 前台 
* @author: ly
* @component:DepartmentPicker 
* @description: 
* @create: 2023-03-15 14:13
-->
<template>
    <a-tree-select
            v-model:value="department"
            show-search
            allow-clear
            :treeDefaultExpandedKeys="expandedKeys"
            style="width: 200px"
            placeholder="部门选择"
            :tree-data="departments"
            :field-names="fieldNames"
            @select="selectDepart"
    >
    </a-tree-select>
</template>

<script>
    import depart from "../../assets/api/depart";

    export default {
        name: "DepartmentPicker",
        props:["depId"],
        emits:["update:depId","select"],
        watch:{
            depId(){
                this.department = this.depId?this.depId:undefined;
            }
        },
        data() {
            return {
                departments:[],
                department:undefined,
                fieldNames:{children:'children', label:'departName', value: 'id' },
                loading:false,
                expandedKeys:[],
            }
        },
        methods: {
            init() {
                this.loading = true;
                depart.queryAllTree.post(this,{},res=>{
                    this.loading = false;
                    if(res.data.success){
                        this.departments = res.data.result;
                        sortTreeChildren(this.departments);
                        for(let node of this.departments){
                            this.expandedKeys.push(node.id)
                        }
                    }
                    else{
                        this.$message.error(res.data.message);
                    }
                })
            },
            selectDepart(value, node){
                this.$emit("update:depId",this.department);
                this.$emit("select",node);
            }
        },
        created() {
            this.init()
        }
    }
    function sortTreeChildren(treeData) {
        for (let item of treeData) {
            if (item.children) {
                item.children.sort((a, b) => {
                    return a.departName.localeCompare(b.departName, 'zh-Hans-CN');
                });
                sortTreeChildren(item.children);
            }
        }
    }
</script>

<style scoped>

</style>