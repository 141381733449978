/**
 * 地图工具配置参数
 * @description：
 * @author:rzl
 * @date:2022/10/18 14:41
 **/
const mapToggleConfig={
    /*** 地图工具 ***/
    list_img:[
        {
            id:'img_1',
            name:'地图',
            tip:'地图',
            type:'lw',
            selected:false,// 是否选中
            class:'imgClass',
            recovery:false,// 是否选中后复原
            img:require('@/page/Map/images/地图-默认.png'),// 默认图片
            enter_img:require('@/page/Map/images/地图-选中.png'),// 鼠标移入
            leave_img:require('@/page/Map/images/地图-默认.png'),// 鼠标离开
            click_img:require('@/page/Map/images/地图-默认.png'),// 鼠标点击
        },
        {
            id:'img_2',
            name:'影像',
            tip:'影像',
            type:'yx',
            selected:false,// 是否选中
            class:'imgClass',
            recovery:false,// 是否选中后复原
            img:require('@/page/Map/images/影像-默认.png'),// 默认图片
            enter_img:require('@/page/Map/images/影像-选中.png'),// 鼠标移入
            leave_img:require('@/page/Map/images/影像-默认.png'),// 鼠标离开
            click_img:require('@/page/Map/images/影像-默认.png'),// 鼠标点击
        },

        {
            id:'img_4',
            name:'地形',
            tip:'地形',
            type:'dx',
            selected:false,// 是否选中
            class:'imgClass',
            recovery:false,// 是否选中后复原
            img:require('@/page/Map/images/地形-默认.png'),// 默认图片
            enter_img:require('@/page/Map/images/地形-选中.png'),// 鼠标移入
            leave_img:require('@/page/Map/images/地形-默认.png'),// 鼠标离开
            click_img:require('@/page/Map/images/地形-默认.png'),// 鼠标点击
        },


    ],

};
export default mapToggleConfig;
