<template>
    <div class="slides">
        <input type="range" id="slider-input" min="0" disabled   :value="$props.count==null?0:$props.count" :max="$props.max" />
    </div>
</template>

<script>
    export default {
        name: "slideOne.vue",
        props:['max','count']
    }
</script>

<style scoped>
    input[type='range'] {
        -webkit-appearance: none;
        background: linear-gradient(-75deg,#4C7DBB, #122A50);
        border-radius: 4px;
        width: 100%;
        height: 6px;
        outline: none;
    }

       input[type='range']::-webkit-slider-thumb {
           -webkit-appearance: none;
           width: 4px;
           height: 18px;
           background: linear-gradient( to bottom, #196ABC 0%, #FFFFFF 100%);
           transition: 0.3s;
           cursor: pointer;
       }

       input[type='range']:active::-webkit-slider-thumb {
           background: #fff;
           box-shadow: 0 0 0 6px rgba(155, 73, 146, 0.4);
       }
    .slides{
        margin: 0px  20px;
        width: 170px;
    }

</style>