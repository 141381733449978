/**
 * @program: 前台
 * @author: ly
 * @description: Components
 * @create: 2022-12-21 09:17
 **/

import MainPage from "@/page/login/MainPage";
import MainPageWeather from "@/page/login/MainPageWeather";

import MainMapManager from "@/page/login/MainMapManager";
import MainCase from "@/page/login/MainCase";
import MainPatrol from "@/page/login/MainPatrol";
import MainDZ from "@/page/login/MainDZ";
const Components = {
    MainPage :  MainPage,
    MainPageWeather:MainPageWeather,
    MainMapManager : MainMapManager,
    MainCase : MainCase,
    MainPatrol : MainPatrol,
    MainDZ:MainDZ,
    /** 系统模块 **/
    MenuManager :  () => import("@/page/sys/MenuManager/MenuManager"),
    UserManager :  () => import("@/page/sys/userManager/UserManager"),
    RoleManager :  () => import("@/page/sys/roleManager/RoleManager"),
    LayerManager : () => import("@/page/sys/layerManager/LayerManager"),
    DepartmentManager :  () => import("@/page/sys/departmentManager/DepartmentManager"),
    SubDepartmentManager :  () => import("@/page/sys/departmentManager/SubDepartmentManager"),
    SubDepartmentUserAudit :  () => import("@/page/sys/departmentManager/SubDepartmentUserAudit"),
    SysLog :  () => import("@/page/sys/sysLog/SysLog"),
    SysDict :  () => import("@/page/sys/sysDict/SysDict"),
    TaskSetting :  () => import("@/page/sys/taskSetting/TaskSetting"),
    lineReminder:()=> import("@/page/sys/shortNote/lineReminder"),
    lineSendLog:()=>import("@/page/sys/shortLog/lineSendLog"),
    UserMail:()=>import("@/page/sys/userMail/UserMail"),
    disasterSite_main:()=>import("@/page/dzgl/disasterSite/disasterSite_main"),
    disasterSite_main2024:()=>import("@/page/dzgl/disasterSite/disasterSite_main2024"),
    loudongGL:()=>import("@/page/dzgl/ldgl/loudongGL"),
    household_main:()=>import("@/page/dzgl/household/household_main"),
    peopleInquire_main:()=>import("@/page/dzgl/peopleInquire/peopleInquire_main"),
    building_main:()=>import("@/page/dzgl/building/building_main"),
    disasterAudit_main:()=>import("@/page/dzgl/disasterSite/disasterAudit_main"),
    systemManager:()=>import("@/page/sys/system/systemManager"),
    smsFun:()=>import("@/page/sys/system/smsFun"),
    disasterStatistics_main:()=>import("@/page/dzgl/disasterSite/disasterStatistics_main"),
    dangSituation_main:()=>import("@/page/report/dangSituation/dangSituation_main"),
    hiddenTrouble_main:()=>import("@/page/report/hiddenTrouble/hiddenTrouble_main"),
    riskSlope_main:()=>import("@/page/riskSlope/riskSlopeManage/riskSlope_main"),

    note_main:()=>import("@/page/weather/forewarManage/note/note_main"),
    baseStation_main:()=>import("@/page/weather/baseStation/baseStation_main"),
    analysis_main:()=>import("@/page/weather/analysis/analysis_main"),
    forewarRank_main:()=>import("@/page/weather/forewarManage/forewarRank/forewarRank_main"),
    weatherInfo_main:()=>import("@/page/weather/weatherInfo/weatherInfo_main"),
    forewarnMessage_main:()=>import("@/page/weather/forewarManage/forewarnMessage/forewarnMessage_main"),
    pronePartition_main:()=>import("@/page/weather/pronePartition/pronePartition_main"),
    weatherAudit_main:()=>import("@/page/weather/weatherInfo/weatherAudit_main"),
    continueRain_main:()=>import("@/page/weather/continueRain/continueRain_main"),
    drawRadiusSend_main:()=>import("@/page/weather/sendMessage/drawRadiusSend/drawRadiusSend_main"),
    historyData_main:()=>import("@/page/weather/sendMessage/historyData/historyData_main"),
    messageTemplate_main:()=>import("@/page/weather/forewarManage/messageTemplate/messageTemplate_main"),
    pushConditions_main:()=>import("@/page/weather/sendMessage/pushConditions/pushConditions_main"),
    pushConditionsAudit_main:()=>import("@/page/weather/sendMessage/pushConditions/pushConditionsAudit_main"),
    rainfall_main:()=>import("@/page/weather/rainfall/rainfall"),
    geology_main:()=>import("@/page/weather/geology/geology"),
    flood_main:()=>import("@/page/weather/flood/flood"),
};

function ApplyComponents(menus,routes) {
    for (let item of menus) {
        let route = {};
        route.path = item.url;//url: 'RegisterPage', name: 'RegisterPage',
        route.name = item.component;//url: 'RegisterPage', name: 'RegisterPage',
        route.component = Components[item.component];//url: 'RegisterPage', name: 'RegisterPage',
        route.meta = {
            haveOpEnum:item.haveOpEnum,
        };
        routes.push(route);
        if (item.children && item.children.length) {
            if(!route.children){
                route.children = []
            }
            ApplyComponents(item.children,route.children);
        }
    }
}


export default ApplyComponents;
