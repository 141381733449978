<template>
    <a-layout style="overflow: hidden" class="layout-main">
        <a-layout-header class="apicture-header">
            <div class="header" style="display: flex;align-items: center;">
                   <img style="padding-left: 10px" src="../../assets/image/login/hhzlogo.png" class="non-select"  >
                   <img style="position: fixed;right: 270px;cursor: pointer" @click="headClick" src="../../assets/image/main/后台切换.png"  title="进入后台管理" >
                   <div class="non-select" style="position: fixed;right: 120px;font-size: 14px;font-weight: 500;color:#00A2FF;">欢迎：{{realName}}</div>
                   <a-tooltip title="修改密码">
                       <a-button type="link" class="user-btn" @click="resetPwd" >
                           <template #icon><user-outlined style="color:#00A2FF;"/></template>
                       </a-button>
                   </a-tooltip>
                   <a-button type="link" class="logout-btn"  @click="logout">
                       <template #icon><LogoutOutlined style="color:#00A2FF;"/></template>
                   </a-button>
            </div>

        </a-layout-header>
        <a-layout >
            <a-layout-content style="overflow:auto; background: #002E5D; min-height:500px">
                <div class="app-main-map" >
                    <ApictureMainMap @mapMethods="setMap" ref="mainMap"/>
                </div>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
    import subGroupMenu from "../../components/menu/subGroupMenu";
    import oaBanner from "./oaBanner";
    import {readToken} from "../../assets/utils/APIObject";
    import ApictureMainMap from "../Map/ApictureMainMap";
    import HeadToolBar from "./HeadToolBar";
    import {LogoutOutlined, UserOutlined} from "@ant-design/icons-vue";
    import login from "../../assets/api/sysSets";
    export default {
        name: 'ApictureMainPage',
        components:{
            UserOutlined,LogoutOutlined,
          subGroupMenu, oaBanner,ApictureMainMap,HeadToolBar
        },
        computed:{
            realName(){
                return this.userInfo?.userInfo.realName;
            },
        },
        data(){
            return{
                userInfo:null,
                collapsed:false,
                componentTags:[],
                maxLength:8,
                mapObject:null,
                mapCode:null,
                dlWidth:40,
                defaultStyle:null,
            }
        },
        created() {
           /* this.$router.push({ name:"MainDZ"});*/
            this.userInfo = JSON.parse(localStorage.getItem("userInfo"));
            readToken();
        },
        mounted() {
        },
        methods:{
            headClick(item){
                let userMenuOne=JSON.parse(localStorage.getItem('userInfo')).menus[0].componentName
                this.$router.push({ name: userMenuOne});
            },
            logout(){
                login.logout.get(this,{},res=>{
                    if(res.data.success){
                        this.$message.success('退出成功！')
                        setTimeout(()=>{
                            localStorage.setItem("userInfo","");
                            localStorage.setItem("token","");
                            localStorage.setItem("menus","");
                            this.$router.push({path: '/'});
                        },2000)
                    }
                })
            },
            setMap(map){
                this.mapObject = map;
            },
            resetPwd(){
                this.$router.push({ path: '/ResetPassword'});
            }
        },

    }
</script>

<style scoped>
    .apicture-header{
        width: 100%;
        padding: 0;
        height: 54px;
        margin-top: -4px;
        background: url('../../assets/image/main/TOP-BG.png');
    }



</style>
