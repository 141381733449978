<!--
* @program: 前台 
* @author: ly
* @component:UserPicker 
* @description: 
* @create: 2022-12-13 11:03
-->
<template>
    <a-select
            v-model:value="value"
            mode="multiple"
            :placeholder="placeholder"
            style="width: 100%"
            :filter-option="false"
            :not-found-content="loading ? undefined : null"
            :options="options"
            @search="doSearch"
            :fieldNames="fieldNames"
            @select="selectHandle"
    >
        <template v-if="loading" #notFoundContent>
            <a-spin size="small" />
        </template>
    </a-select>
</template>

<script>
    import user from "../../assets/api/user";
    export default {
        name: "UserPicker",
        props:{
            users:{
                required:true,
                default:[]
            },
            placeholder:{
                required: false,
                default: "查询用户"
            }
        },
        /**
          let userIds = [];
          for(let i in this.users){
            userIds.push(this.users[i].key);
            }
         */
        emits:["update:users"],
        watch:{
            users(){
                this.value =  this.users ;
            }
        },
        data() {
            return {
                searchData:null,
                loading:false,
                value:undefined,
                options:[],
                fieldNames:{ label: "realName", value: "id" }
            }
        },
        methods: {
            doSearch(value) {
                if(value){
                    let data = {"realName":value};
                    this.loading = true;
                    user.queryAll.post(this,data,res=>{
                        this.loading = false;
                        if(res.data.success){
                            this.options = res.data.result
                        }
                    })
                }
            },
            selectHandle(){
                this.$emit("update:users",this.value)
            }
        }
    }
</script>

<style scoped>

</style>