<!--
* @description：图层控件管理
* @author:rzl
* @date:2022/1/20 11:40
-->

<template>
    <!-- 图层管理 start-->
    <div v-drag class="layer-manage" :style="tc_style">
        <div class="layer-header">
            <div class="layer-title "><img :src="require('@/assets/image/map/图层管理.png')"> 图层管理</div>
            <div class="layer-tb"><img style="cursor: pointer;" :src="tc_tb_url" @click="tc_hide_expand"></div>
        </div>

        <div class="layer-content" :style="tree_style">
            <a-tree
                    id="layerManager"
                    class="tree_style"
                    checkable
                    show-icon
                    :tree-data="layer_list_tree_fp"
                    v-model:checkedKeys="checkedKeys"
                    :fieldNames="{children:'children', title:'name', key:'id' }"
                    @check="handleCheck"
            >

                <template #title="node">
          <span style="font-weight: 500;color: #FFFFFF;line-height: 20px;">
            {{ node.name }}
          </span>
                </template>
                <template #ml>
                    <img :src="require('@/assets/image/map/目录.png')">
                </template>
                <template #sl>
                    <img :src="require('@/assets/image/map/矢量.png')">
                </template>
                <template #sg>
                    <img :src="require('@/assets/image/map/栅格.png')">
                </template>
            </a-tree>
        </div>
    </div>
    <!-- 图层管理 end-->

</template>

<script>
    import APIObject from "@/assets/utils/APIObject";
    import showTlie from "./tuLie.js";

    export default {
        name: "layerManage_map",
        components: {},
        // 自定义指令 实现可拖动
        directives: {
            drag(el, bindings) {
                el.onmousedown = function (e) {
                    const disx = e.pageX - el.offsetLeft;
                    const disy = e.pageY - el.offsetTop;
                    document.onmousemove = function (e) {
                        el.style.left = e.pageX - disx + 'px';
                        el.style.top = e.pageY - disy + 'px'
                    };
                    document.onmouseup = function () {
                        document.onmousemove = document.onmouseup = null
                    }
                }
            }
        },

        data() {
            return {
                map_tree_fp: null,// 地图容器
                layer_list_tree_fp: [],// 图层

                checkedKeys: [],
                tc_style: "",
                tree_style: "",
                tc_tb_url: require('@/assets/image/map/展开.png'),
                flag_tc_tb: false,// 图层图标展开收缩：true:展开；false:收缩
                checkedNodes: null,// 图层管理选中值

                layer_info: null,// 点击查询的图层

                type: '1',// 1：图层管理；2：图层点击查询
                layers: [],


                queryName: null,// 查询最上面的图层名称
                layer_pz: null,// 图层配置
                layerInfo_list: [],
                argument: {
                    formColumns: [], //提供Form的字段集 this.table.getFormColumns()
                    formData: {},
                },
                fpType: '2',
                initUnShowKey: [0],
                checkedNodesPositions: [],
                isRunChild: false,
                queryLayer:''//点击查询的图层
            }
        },
        methods: {
            setStyle(type, fpType) {
                this.fpType = fpType;
            },
            /**
             * @Description: 初始化树控件
             * @params: map:地图容器；layer_list:图层数组;type:1：图层管理；2：图层点击查询
             * @return:
             * @Author: rzl
             * @Date: 2022/4/11
             */
            initTree(map, layers, layer_list, type, checkedKeys) {

                if (this.layer_list_tree_fp != null) {
                    this.layer_list_tree_fp = [];
                }
                if (this.layers.length > 0) {
                    this.layers = [];
                }
                this.map_tree_fp = map;
                this.layer_list_tree_fp = layer_list.reverse().map((el) => {
                    return {
                        ...el,
                        key: el.id
                    }
                });
                this.layers = layers;
                this.type = type;
                if (localStorage.getItem('map_level') == '2') {
                    let xzq = layer_list.filter(el => el.name == "行政区划范围")[0]
                    this.checkedKeys = [xzq.id]
                    this.handleCheck([xzq.id], {
                        checkedNodes: [xzq], checkedNodesPositions: [{
                            node: xzq,
                            pos: '0-0'
                        }]
                    })
                }
                if (localStorage.getItem('map_level') == '3') {
                    let xzq = layer_list.filter(el => el.name == "行政区划范围")[0]
                    let index = layer_list.findIndex(el => el.name == "行政区划范围")
                    if (xzq.children.length > 0) {
                        if (xzq.children[1]) {
                            this.checkedKeys = [xzq.children[1].id]
                            this.handleCheck([xzq.children[1].id], {
                                checkedNodes: [xzq.children[1]], checkedNodesPositions: [{
                                    node: xzq.children[1],
                                    pos: `0-${index}-1`
                                }]
                            })
                        } else {
                            this.checkedKeys = [xzq.children[0].id]
                            this.handleCheck([xzq.children[0].id], {
                                checkedNodes: [xzq.children[0]], checkedNodesPositions: [{
                                    node: xzq.children[0],
                                    pos: `0-${index}-0`
                                }]
                            })
                        }
                    }else{
                        this.checkedKeys = [xzq.id]
                        this.handleCheck([xzq.id], {
                            checkedNodes: [xzq], checkedNodesPositions: [{
                                node: xzq,
                                pos: `0-${index}`
                            }]
                        })
                    }

                }
                this.mapChick()

            },


            childSelect() {
                if (!this.isRunChild) {
                    let childs = this.checkedNodesPositions
                    if (childs.length == 0) return
                    const groupedData = childs.reduce((acc, obj) => {
                        const key = obj.node.layer;
                        if (!acc[key]) {
                            acc[key] = [];
                        }
                        acc[key].push(obj);
                        return acc;
                    }, {});

                    let tuLie = [];
                    this.$parent.loadChildlLayer(groupedData);
                    if (groupedData['honghezhou:easy_occur_area']?.length > 0) {
                        let pos = []
                        groupedData['honghezhou:easy_occur_area'].forEach((el) => {
                            pos.push(Number(el.pos[el.pos.length - 1]) + 1)
                        })
                        tuLie.push({
                            title: '易发分区(级别)',
                            children: showTlie('易发分区(级别)', pos)
                        })
                    }
                    if (groupedData['honghezhou:disaster_point']?.length > 0) {
                        let pos = []
                        groupedData['honghezhou:disaster_point'].forEach((el) => {
                            pos.push(Number(el.pos[el.pos.length - 1]) + 1)
                        })
                        tuLie.push({
                            title: '地灾点(风险等级)',
                            children: showTlie('地灾点(风险等级)', pos)
                        })
                    }
                    if (groupedData['honghezhou:hh_disaster_2024']?.length > 0) {
                        let pos = []
                        groupedData['honghezhou:hh_disaster_2024'].forEach((el) => {
                            pos.push(Number(el.pos[el.pos.length - 1]) + 1)
                        })
                        tuLie.push({
                            title: '地灾点(风险等级)2024',
                            children: showTlie('地灾点(风险等级)2024', pos)
                        })
                    }


                    this.$parent.tuLie = tuLie
                    this.isRunChild = true
                }
            },

            /************* 多选 start *******************/
            handleCheck(checkedKeys, e) {
                this.$parent.showTooltopStation=false
                let layer1 = this.getLayerByName('易发分区1');
                this.$parent.map.removeLayer(layer1)
                let layer2 = this.getLayerByName('地灾点数据1');
                this.$parent.map.removeLayer(layer2)
                let layer3 = this.getLayerByName('行政区');
                this.$parent.map.removeLayer(layer3)
                let layer4 = this.getLayerByName('基站');
                this.$parent.map.removeLayer(layer4)
                let layer5 = this.getLayerByName('山洪灾害监测点');
                this.$parent.map.removeLayer(layer5)
                let layer6 = this.getLayerByName('地质灾害监测点');
                this.$parent.map.removeLayer(layer6)
                let layer7 = this.getLayerByName('雨量监测站点');
                this.$parent.map.removeLayer(layer7)
                let layer8 = this.getLayerByName('地灾点数据2024');
                this.$parent.map.removeLayer(layer8)
                this.$parent.layers = this.$parent.layers.filter((el) => el.values_.name != '易发分区1')
                this.$parent.layers = this.$parent.layers.filter((el) => el.values_.name != '地灾点数据1')
                this.$parent.layers = this.$parent.layers.filter((el) => el.values_.name != '行政区')
                this.$parent.layers = this.$parent.layers.filter((el) => el.values_.name != '基站')
                this.$parent.layers = this.$parent.layers.filter((el) => el.values_.name != '山洪灾害监测点')
                this.$parent.layers = this.$parent.layers.filter((el) => el.values_.name != '地质灾害监测点')
                this.$parent.layers = this.$parent.layers.filter((el) => el.values_.name != '雨量监测站点')
                this.$parent.layers = this.$parent.layers.filter((el) => el.values_.name != '地灾点数据2024')
                this.isRunChild = false
                this.checkedNodesPositions = e.checkedNodesPositions
                this.$parent.tuLie = [];
                this.checkedNodes = checkedKeys
                let layer_list = this.layer_list_tree_fp;
                if (layer_list != null) {
                    this.layerVisible(layer_list.map(el => {
                        return {
                            access: el.access,
                            slots: el.slots,
                            id: el.id,
                            name: el.name,
                            layer: el.layer
                        }
                    }));
                }
                this.$parent.highlight_Layer();
            },
            layerVisible(layer_list) {
                for (let k in layer_list) {
                    let layer_info = layer_list[k];
                    let children = layer_info.children;
                    if (children != null && children.length > 0) {
                        this.layerVisible(children);
                    } else {
                        let flag = false;
                        let checkName = '';
                        for (let i = 0; i < this.checkedNodes.length; i++) {
                            let props = this.checkedNodes[i];
                            let name = props.name;
                            if (layer_info.name == name) {
                                checkName = name;
                                flag = true;
                            }
                        }

                        if (flag) {
                            let layer = this.getLayerByName(checkName);
                            this.setLayerVisible(layer, true);

                        } else {
                            checkName = layer_info.name;
                            let layer = this.getLayerByName(checkName);
                            this.setLayerVisible(layer, true);
                            this.childSelect();
                        }
                    }
                }
            },
            /**
             * @Description: 图层隐藏
             * @params:
             * @return:
             * @Author: rzl
             * @Date: 2022/4/14
             */
            tc_hide_expand() {
                this.flag_tc_tb = !this.flag_tc_tb;
                if (!this.flag_tc_tb) {
                    this.tc_style = "height:45px";
                    this.tree_style = "height:0px;border: 0px solid #3987BE";

                    this.tc_tb_url = require('@/assets/image/map/展开.png');
                } else {// 展开状态
                    this.tc_style = "height:251px";
                    this.tree_style = "height:201px;border: 1px solid #3987BE";

                    this.tc_tb_url = require('@/assets/image/map/收缩.png');

                }
            },
            /************* 多选 end *******************/

            /************* 单选 start *******************/
            /**
             * @Description: 获取查询图层
             * @params:
             * @return:
             * @Author: rzl
             * @Date: 2022/4/21
             */
            getLayer() {
                let layers = this.layers;
                for (let i = layers.length - 1; i >= 0; i--) {
                    let layer_info = this.layers[i].values_;
                    let visible = layer_info.visible;
                    if (visible) {
                        this.queryName = layer_info.name;
                        this.layer_pz = layer_info.source?.params_?.LAYERS;
                        return layer_info;
                    }
                }
            },
            mapChick() {
                this.map_tree_fp.on('singleclick', e => {
                    if (this.type == '1') {
                        return;
                    }
                    var view = this.map_tree_fp.getView();
                    var viewResolution = view.getResolution();
                    let layers = this.$parent.layers;
                    let i = layers.length - 1;
                    this.map_query(e, i, layers, view, viewResolution);
                })
            },
            map_query(e, i, layers, view, viewResolution) {
                if (i < 0) {
                    this.$message.info("当前点击位置暂无数据！");
                    return;
                }
                let j = i - 1;
                let layerInfo = layers[i].values_;
                if (layerInfo == null || layerInfo == undefined) {
                    this.map_query(e, j, layers, view, viewResolution);
                } else {
                    let visible = layerInfo.visible;
                    if (visible) {
                        this.queryName = layerInfo.name;
                        this.layer_pz = layerInfo.source?.params_?.LAYERS;
                        let flag = true;// 判断图层是否可以查询
                        let layerList = this.layer_list_tree_fp;
                        if (layerList.length > 0) {
                            for (const key in layerList) {
                                let name = layerInfo.name;
                                if (name == layerList[key].name && !layerList[key].access) {
                                    flag = false;
                                }
                            }
                        }
                        if (flag) {
                            var source = layerInfo.source;
                            let url_ = source.url_;
                            if (this.$checkUtil.empty_str(url_)) {// 空：影像
                                this.map_query(e, j, layers, view, viewResolution);
                            } else {
                                var url = source.getFeatureInfoUrl(
                                    e.coordinate, viewResolution, view.getProjection(),
                                    {
                                        'INFO_FORMAT': 'application/json',
                                        'FEATURE_COUNT': 50,
                                        format_options: 'callback: callback'
                                    });
                                const urlParams = new URLSearchParams(url);
                                const queryLayers = urlParams.get('QUERY_LAYERS');
                                const decodedQueryLayers = decodeURIComponent(queryLayers);
                                this.queryLayer=decodedQueryLayers
                                const findWKT = () => {
                                    new APIObject.postObject(url).requestUrlParam(this, {}, res => {
                                        let list = res.data.features;
                                        if (res.data && list?.length > 0) {
                                            let info = list[0];
                                            if (this.$checkUtil.empty_str(info.id)) {
                                                this.$message.info("当前点击位置暂无数据！");
                                                return;
                                            }
                                            let isEasy = false
                                            if (layers[i].values_.name == "易发分区1") {
                                                isEasy = info.properties.canton_id
                                            }
                                            if (layers[i].values_.name == "行政区") {
                                                isEasy = info.properties.canton_id
                                            }
                                            this.$parent.highlight_Layer(list, e.coordinate, isEasy);
                                        } else {
                                            this.map_query(e, j, layers, view, viewResolution);
                                        }

                                    })
                                }
                                findWKT();
                            }
                        } else {
                            this.map_query(e, j, layers, view, viewResolution);
                        }
                    } else {
                        this.map_query(e, j, layers, view, viewResolution);
                    }
                }

            },
            eventHandle() {

            },
            xzqHandle() {
                document.getElementsByClassName('')
            },

            /**
             * @Description: 设置图层显隐
             * @params: layer:图层；flag：true显示;false隐藏
             * @return:
             * @Author: rzl
             * @Date: 2022/4/11
             */
            setLayerVisible(layer, flag) {
                if (layer == undefined || layer == null) {
                    return;
                }
                switch (flag) {
                    case true:
                        layer.setVisible(true)
                        break
                    case false:
                        layer.setVisible(true)
                        break
                }
            },
            /**
             * @Description: 根据图层名称获取图层
             * @params: 图层名称
             * @return:
             * @Author: rzl
             * @Date: 2022/4/11
             */
            getLayerByName(name) {
                let layers = this.map_tree_fp.getLayers().array_
                let nameArr = []
                let targetIndex = -1
                for (let i = 0; i < layers.length; i++) {
                    nameArr.push(layers[i].values_.name)
                }
                targetIndex = nameArr.indexOf(name)
                return layers[targetIndex]
            },
            gb_layerInfo() {
                this.type = '1';
            },
            showLande(node) {
                let data = this.$GetEmitData(this.$eventTags.showLande, node);
                this.$emit("event", data);
            }

        }
    }
</script>

<style scoped>
    .layer-manage {
        position: absolute;
        bottom: 15px;
        width: 260px;
        height: 45px;
        background: rgba(14, 65, 118, 0.6);
        opacity: 1;
        border: 1px solid #3D8FC8;
        padding: 0px 5px;
        z-index: 10;
        transition: height 0.5s;
    }

    .layer-header {
        color: #ffffff;
        height: 34px;
        background: linear-gradient(90deg, #0974BD 0%, #0CA6D7 100%);
        margin: 5px 0px;
    }

    .layer-title {
        font-size: 18px;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;
        float: left;
        padding: 5px 5px;
        position: absolute;
    }

    .layer-tb {
        padding: 5px 18px;
        margin-left: 75%;
        position: absolute;
    }

    .layer-content {
        width: 248px;
        height: 0px;
        background: linear-gradient(90deg, rgba(12, 166, 215, 0.4) 0%, rgba(0, 193, 255, 0) 100%);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        border: 0px solid #3987BE;
        overflow: auto;
        transition: height 0.5s;
    }

    .tree_style {
        z-index: 1100;
        position: relative;
        margin-left: 40px !important;
        margin-top: 73px;

        text-align: left;
        width: 100%; /*60*/
        height: 200px;
        overflow: auto;

        box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.4);
        border-radius: 0px 0px 0px 8px;
        opacity: 1;
        transition: height 2s;

        background: rgba(51, 72, 91, 0.2);
        border: 1px solid #FFFFFF;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 20px;

        overflow-y: scroll;
    }

    :global(.layer-content .ant-tree) {
        background: none;
    }

    :global(.layer-content .ant-tree-checkbox-checked .ant-tree-checkbox-inner) {
        background-color: #0ca4d6;
    }


</style>
