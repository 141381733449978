<!--
* @program: tyh-oa 
* @author: ly
* @component:typeIndex 
* @description: 
* @create: 2021-04-15 17:05
-->
<template>
    <div  @click="onClick">
        {{index}}
    </div>
</template>

<script>
    import typeBase from "./typeBase";

    export default {
        name: "typeIndex",
        props:{
            index:{required:true},
            column:{required: true},
            record:{required:true},
            viewModel:{required:true},
            },
        mixins:[typeBase],
    }
</script>

<style scoped>
    .high-light{
        background: red;
    }
</style>