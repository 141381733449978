/** 
* 自动生成文件，请勿修改 
* @name: 红河州地灾-一张图信息查询管理
* @description: One Picture Controller
* @create: 2024/11/8 10:34:25
* @swagger: 2.0
* 合计:9条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const params = { 
	queryAllMeteorology : null, 
	queryDisasterNoGeom : null, 
	queryEasyOccurArea : null, 
	queryMeteorologyWithWkt : null, 
	queryNext : null, 
	queryNextCantons : null, 
	queryWarningInfo : null, 
	queryWarningWithWkt : null, 
	queryWeather : null, 
};
const picture = { 
	/** 1 summary:一张图-查询近期气象数据	method:get */ 
	queryAllMeteorology : new APIObject.getObject("/one/picture/queryAllMeteorology",params.queryAllMeteorology), 
	/** 2 summary:一张图-查询地灾点	method:get */ 
	queryDisasterNoGeom : new APIObject.getObject("/one/picture/queryDisasterNoGeom",params.queryDisasterNoGeom), 
	/** 3 summary:一张图-查询易发区域	method:get */ 
	queryEasyOccurArea : new APIObject.getObject("/one/picture/queryEasyOccurArea",params.queryEasyOccurArea), 
	/** 4 summary:一张图-根据wkt查询近期气象数据	method:post */ 
	queryMeteorologyWithWkt : new APIObject.postObject("/one/picture/queryMeteorologyWithWkt",params.queryMeteorologyWithWkt), 
	/** 5 summary:一张图-查询下级行政区划	method:get */ 
	queryNext : new APIObject.getObject("/one/picture/queryNext",params.queryNext), 
	/** 6 summary:一张图-查询下级行政区划以及范围红线	method:get */ 
	queryNextCantons : new APIObject.getObject("/one/picture/queryNextCantons",params.queryNextCantons), 
	/** 7 summary:一张图-查询预警信息	method:get */ 
	queryWarningInfo : new APIObject.getObject("/one/picture/queryWarningInfo",params.queryWarningInfo), 
	/** 8 summary:一张图-根据wkt查询预警信息	method:post */ 
	queryWarningWithWkt : new APIObject.postObject("/one/picture/queryWarningWithWkt",params.queryWarningWithWkt), 
	/** 9 summary:一张图-查询当天天气	method:get */ 
	queryWeather : new APIObject.getObject("/one/picture/queryWeather",params.queryWeather), 
};
export default picture;