

/**
 * @program: tyh-oa
 * @author: ly
 * @decription: 字段结构
 * @create: 2020-08-25 16:38
 **/

import ColumnType from "./column_type";
import dayjs from "dayjs";

class Column{
    /**
     * @param title 表头显示
     * @param dataIndex 对应dataObject内的属性字段/segment
     * @param type  字段类型
     * @param editable 是否允许编辑
     * @param visible 是否可见
     * @param enums type 为Enum时候的枚举数据
     * @param enumTags 当Enum类型时候，用于代替Enum值的TAG，
     * 如：数据库中["0","1"]类型,但是显示的时候却显示["未归档","已归档"],需要与enums一一对应
     * @param width 字段宽度
     * @param defaultValue  字段默认值
     * @param tableView true的情况为table的时候所显示的字段
     * @param idReplaceObject 用于idReplace的字段属性
     * @param boolTags 用于标记true 和 false的情况下 显示的文字，["是","否"]
     * @param replaceObject 用于ObjectReplace的字段属性
     * @oaram sorter: (a, b) => a.age - b.age,
     * @param categoryTrigger  字段触发器 [trigger,trigger2] true:trigger  false:trigger2
     * @param group  有时候需要将字段分区展示，用于区分字段的标记  默认group = 0
     * @children 表头分组使用
     * @param enableTime //用于设置可选时间
     * @param fixed: left/right 固定在左右两端,
     * @param rules: 数值验证方法
     * @param colSpan  表头所占的列数，默认为1
     * @param placeholder  填空时候提示词
     */
    constructor (title,dataIndex,type,editable) {
        this.align = "center";
        this.colSpan = 1;
        this.title = title;
        this.dataIndex = dataIndex;
        this.type = ColumnType.getType(type);
        this.editable = !!editable;
        this.visible = true;
        this.width = 100;
        this.maxWidth = 400;
        this.defaultValue = null;
        this.idReplaceObject = null;
        this.group = 0;
        this.rules = [];
        this.placeholder= null;
        if(this.type === ColumnType.Number){
            this.sorter = (a, b) =>{
                let as = a[this.dataIndex]?a[this.dataIndex]:0;
                let bs = b[this.dataIndex]?b[this.dataIndex]:0;
                return  as - bs;
            }
        }
        if(this.type === ColumnType.Date){
            this.sorter = (a ,b) =>{
                let as = a[this.dataIndex]?dayjs(a[this.dataIndex]):dayjs("1970-1-1");
                let bs = b[this.dataIndex]?dayjs(b[this.dataIndex]):dayjs("1970-1-1");
                return  as.isAfter(bs)
            }
        }
        if(this.type === ColumnType.Month){
            this.sorter = (a ,b) =>{
                let as = a[this.dataIndex]?dayjs(a[this.dataIndex]):dayjs("1970-1-1");
                let bs = b[this.dataIndex]?dayjs(b[this.dataIndex]):dayjs("1970-1-1");
                return  as.isAfter(bs)
            }
        }
        if(this.type === ColumnType.String){
            this.onFilter= (value, record) =>{
                return record[this.dataIndex].includes(value)
            };
            // this.sorter = (a ,b) =>{
            //     let as = a[this.dataIndex]?a[this.dataIndex]:"z";
            //     let bs = b[this.dataIndex]?b[this.dataIndex]:"z";
            //     return as.localeCompare(bs)
            // }
        }
        if(this.type === ColumnType.Boolean){
            this.sorter = (a ,b) =>{
                let as = !!a[this.dataIndex];
                let bs = !!b[this.dataIndex];
                return  as - bs;
            }
        }
    }
    setVisible(bool){
        this.visible = bool;
        return this;
    }

    /**
     * @param enums 必须是数组，且数组元素结构必须为{value,label,color}
     * @returns {Column}
     */
    setEnum(...enums){
        this.type = ColumnType.Enum;
        this.sorter = (a ,b) =>{
            let itemA = this.enums.find(item=>{return  item.value === a[this.dataIndex]});
            let itemB = this.enums.find(item=>{return  item.value === b[this.dataIndex]});
            let indexA = this.enums.indexOf(itemA);
            let indexB = this.enums.indexOf(itemB);
            return indexA - indexB;
        };
        this.enums = enums;
        return this;
    }
    setDefaultValue(value){
        this.defaultValue = value;
        return this;
    }
    setTableView(width){
        this.tableView = true;
        if(width){
            this.width = width;
        }
        if(width === false){
            this.tableView = false
        }
        return this;
    }
    setWidth(width){
        this.width = width;
        if(this.type === ColumnType.Number){
            this.width +=40;
        }
        return this;
    }
    setRequired(){
        this.rules.push({trigger: 'blur',required:true});
        return this;
    }
    setTriggerOtherColumn(value,action,action2){
        if(!this.triggerOtherColumn){
            this.triggerOtherColumn = [];
        }
        this.triggerOtherColumn.push(new triggerOtherColumnObject(value,action,action2));
        return this;
    }
    setIdReplaceObject(replaceSegment,queryObject){
        this.idReplaceObject = new IdReplaceObject(replaceSegment,queryObject);
        this.type = ColumnType.IdReplace;
        return this;
    }
    setIdReplaceXmgdObject(replaceSegment,queryObject){
        this.idReplaceObject = new IdReplaceObject(replaceSegment,queryObject);
        this.type =ColumnType.IdReplaceXmgd;
        return this;
    }
    setRemoteObject(replaceSegment,queryObject){
        this.idReplaceObject = new IdReplaceObject(replaceSegment,queryObject);
        this.type = ColumnType.RemoteEnum;
        return this;
    }
    setIdReplace2Object(replaceSegment,queryObject){
        this.idReplaceObject = new IdReplaceObject(replaceSegment,queryObject);
        this.type = ColumnType.IdReplace2;
        return this;
    }
    setObjectReplaceObject(objectName,seg,queryObject){
        this.replaceObject = new ObjectReplace(objectName,seg,queryObject);
        this.type = ColumnType.ObjectReplace;
        return this;
    }
    setBoolTags(tags){
        this.boolTags = tags;
        return this;
    }
    setChildren(children){
        this.children = children;
        return this;
    }
    setNumberRange(min,max){
        this.range = [min,max];
        return this;
    }
    setNumberFormatter(formatter,parser){
        this.formatter = formatter;
        this.parser = parser;
        return this;
    }
    setTitle(title){
        this.title = title;
        return this;
    }
    setSelectorObject(replaceSegment,options){
        this.selectorObject = new SelectorObject(replaceSegment,options);
        this.type = ColumnType.Selector;
        return this;
    }
    setCategoryTrigger(trigger,trigger2){
        this.categoryTrigger = [trigger,trigger2];
        return this;
    }
    setEditable(bool){
        this.editable = bool;
        return this;
    }
    setGroup(group){
        this.group = group;
        return this;
    }
    setCustomCell(customCell){
        this.customCell = customCell;
        return this;
    }
    setClickHandler(fun){
        this.clickHandler = fun;
        return this;
    }
    setRows(num){
        this.rows = num;
        return this;
    }
    setEnableTime(time){
        this.enableTime = time;
        return this;
    }
    setFixed(pos){
        this.fixed = pos;
        return this
    }
    setResizable(bool){
        this.resizable = bool;
        return this;
    }
    addRule(rule){
        this.rules.push(rule);
        return this;
    }

    /**
     *
     itemText:"自主巡查"
     itemValue:"1"
     * @param dictCode
     * @returns
     */
    setDictEnum(dictCode){
        let allDict = JSON.parse(localStorage.getItem("dict"));
        let dict = allDict.find(item=>{
            return  item.dictCode === dictCode;
        });
        if(dict && dict.dictItems.length>0){
            let enums = [];
            for(let item of dict.dictItems.filter(el=>el.status==1)){
                let {itemText,itemValue,remarks} = item;
                if(dictCode === "user_status"){
                    let myEnum = new MyEnum(parseInt(itemValue),itemText,remarks);
                    enums.push(myEnum);
                }
                else{
                    let myEnum = new MyEnum(itemValue,itemText,remarks);
                    enums.push(myEnum);
                }
            }
            this.setEnum(...enums);
        }
        return this;
    }
getDictEnum(dictCode){
    let enums = [];
    let allDict = JSON.parse(localStorage.getItem("dict"));
    let dict = allDict.find(item=>{
        return  item.dictCode === dictCode;
});
    if(dict && dict.dictItems.length>0){
        for(let item of dict.dictItems.filter(el=>el.status==1)){
            enums.push(item) ;
        }
    }
    return enums;
}

    setColSpan(num){
        this.colSpan = num;
        return this;
    }
    setPlaceholder(placeholder){
        this.placeholder = placeholder;
        return this
    }

}
export default Column;

class triggerOtherColumnObject {
    /**
     *
     * @param value 触发值 可以是返回true false的函数
     * @param action 触发条件为true时候的回调函数
     * @param action2 触发条件为false时候的回调函数
     */
    constructor(value,action,action2) {
        this.value = value;
        this.action = action;
        this.action2 = action2;
    }
}
class IdReplaceObject {
    /**
     *
     * @param replaceSegment 对应的字段
     * @param queryObject   编辑的时候使用的查询queryObject
     */
    constructor(replaceSegment,queryObject) {
        this.replaceSegment = replaceSegment;
        this.queryObject = queryObject;
    }
}
class SelectorObject {
    /**
     *
     * @param replaceSegment 替换的字段
     * @param options  选项
     */
    constructor(replaceSegment,options) {
        this.replaceSegment = replaceSegment;
        this.options = options;
    }
}
class ObjectReplace {
    /**
     *
     * @param objectName 后台返回的用于读取的属性字段objectName
     * @param seg         object中用于表示的的字段
     * @param queryObject 编辑的时候使用的查询queryObject
     */
    constructor(objectName,seg,queryObject) {
        this.objectName = objectName;
        this.seg = seg;
        this.queryObject = queryObject;
    }
}
class MyEnum {
    constructor(value,label,color) {
        this.value = value;
        this.label = label;
        this.color = color;
    }
}
export {MyEnum}