<!--
* @program: 前台 
* @author: ly
* @component:typeOperateEnum 
* @description: 
* @create: 2023-02-14 10:53
-->
<template>
    <div v-if="viewModel" class="align-tag">
        <a-tag v-for="item in sEnums" @click="onClick" :key="item">
            <div :style="'color:'+getColor(item)" >{{getFullLabel(item)}}</div>
        </a-tag>
    </div>
    <div v-else>
        <a-select v-if="editable"  @blur="blurTrigger"
                  mode="multiple"
                  v-model:value="value" style="width: 100%"
                  @change="changeHandler">
            <a-select-option v-for="option in defaultEnums" :value="option" :key="option">{{getFullLabel(option)}}</a-select-option>
        </a-select>
        <div v-else class="align-tag">
            <a-tag v-for="item in sEnums" @click="onClick" :key="item">
                <div :style="'color:'+getColor(item)" >{{getFullLabel(item)}}</div>
            </a-tag>
        </div>
    </div>
</template>

<script>
    import typeBase from "./typeBase";
    import {OEnums,OEnumsLabels,OEnumsColors} from "../../assets/utils/operateEnums";
    import ColorMap from "../../assets/utils/ColorMap";
    import {isFunction} from "../../assets/utils/general";
    function getLabel(option){
        let index = OEnums.indexOf(option);
        if(index !== -1){
            return OEnumsLabels[index];
        }
        else{
            return "未知"
        }
    }
    function getFullLabel(option){
        let index = OEnums.indexOf(option);
        if(index !== -1){
            return OEnumsLabels[index]+"/"+option;
        }
        else{
            return "未知"
        }
    }
    function getColor(option){
        let index = OEnums.indexOf(option);
        let color = OEnumsColors[index];
        if(ColorMap[color]){
            return ColorMap[color];
        }
        else{
            return "#f7fff6"
        }
    }
    export default {
        name: "typeOperateEnum",
        mixins:[typeBase],
        watch:{
            record(){
                this.columnTrigger();
                this.value = this.sEnums;
            }
        },
        computed:{
            sEnums(){
                let value = this.record[this.column.dataIndex];
                if(value){
                    return value.split(",");
                }
                else{
                    return [];
                }
            }
        },
        data(){
            return{
                defaultEnums:OEnums,
                defaultLabels:OEnumsLabels,
                defaultColors:OEnumsColors,
                value:undefined,
            }
        },
        methods:{
            getLabel:getLabel,
            getColor:getColor,
            getFullLabel:getFullLabel,
            changeHandler(value){
                this.record[this.column.dataIndex] = value.toString();
                this.$emit("update");
                let triggers = this.column.triggerOtherColumn;
                if(triggers && triggers.length>0){
                    //遍历设置的触发器
                    for(let i in triggers){
                        if(triggers[i].value instanceof Array){//trigger.value 为 数组的情况
                            let colValue = this.record[this.column.dataIndex];
                            if(triggers[i].value.indexOf(colValue) !== -1 && triggers[i].action){
                                this.$emit("trigger",triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.$emit("trigger",triggers[i].action2);
                                }
                            }
                        }
                        else if(isFunction(triggers[i].value)){////trigger.value 为 条件函数的情况
                            if(triggers[i].value(this.record) && triggers[i].action){
                                this.$emit("trigger",triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.$emit("trigger",triggers[i].action2);
                                }
                            }
                        }
                        else{//trigger.value 为 普通值的情况
                            if(triggers[i].value === this.record[this.column.dataIndex] && triggers[i].action){
                                this.$emit("trigger",triggers[i].action);
                            }
                            else{
                                if(triggers[i].action2){
                                    this.$emit("trigger",triggers[i].action2);
                                }
                            }
                        }
                    }
                }
            },
        },
        created() {
            this.value = this.sEnums;
        }
    }

    export {getColor,getLabel,getFullLabel}
</script>

<style scoped>
    .align-tag{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
</style>