import { createApp } from 'vue'
import App from './App.vue'

import {message } from 'ant-design-vue';
import andt from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import {createRouter,createWebHashHistory} from "vue-router"
import VueCookies from 'vue3-cookies'
import { createStore } from 'vuex'
import dayjs from "dayjs";
import "./assets/less/main.less"
import "./assets/less/antReplace.less"
import config from '../package.json'
// 默认语言为 en-US，所以如果需要使用其他语言，推荐在入口文件全局设置 locale
import 'dayjs/locale/zh-cn';
import LoginPage from "@/page/login/LoginPage";
import ForgetPassword from "@/page/login/ForgetPassword";
import ResetPassword from "@/page/login/ResetPassword";
import Page404 from "@/page/login/Page404";
import test from "@/components/test";
import ApplyComponents from "@/Components";
import RouterGuards from "@/routerGuards";
import RegisterPage from "@/page/login/RegisterPage";
import ApictureMainPage from "@/page/login/ApictureMainPage";
import checkUtil from "./page/common/js/checkUtil";
import bigMap from "./page/bigMap/index";
import { scrollBoard } from '@jiaminghi/data-view'


dayjs.locale('zh-cn');
dayjs.suppressDeprecationWarnings = true;
const app = createApp(App);
app.config.productionTip = false;
app.config.globalProperties.$message = message;
app.config.globalProperties.$config = config;
app.config.globalProperties.$checkUtil = checkUtil;
const routes = [
    {path: '/', component: LoginPage, name:"LoginPage",meta:{title:config.cnName}},
    {path: '/ForgetPassword', component: ForgetPassword, name:"ForgetPassword",meta:{title:config.cnName}},
    {path: '/ApictureMainPage', component: ApictureMainPage, name:"ApictureMainPage",meta:{title:config.cnName}},
    {path: '/ResetPassword', component: ResetPassword, name:"ResetPassword",meta:{title:config.cnName}},
    {path: '/404', component: Page404, name:"Page404",meta:{title:config.cnName}},
    {path: '/test', component: test, name:"test",meta:{title:config.cnName}},
    {path: '/bigMap', component: bigMap, name:"bigMap",meta:{title:config.cnName}},
    {path: '/RegisterPage', component: RegisterPage, name:"RegisterPage",meta:{title:config.cnName}},
    {
        path: '/LoginPage',
        meta:{title:config.cnName},
        redirect: () => {
            // 方法接收目标路由作为参数
            // return 重定向的字符串路径/路径对象
            return { path: '' }
        },
    }
];
const router = createRouter({
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
});

//刷新后从缓存读取菜单信息，如果修改菜单权限的话，缓存需要刷新才能更新
try{
    const menus = JSON.parse(localStorage.getItem("menus"));
    let subs = [];
    if(menus){
        ApplyComponents(menus,subs);
    }
    for(let item of subs){
        item.path = "/" + item.path;
        router.addRoute(item);
    }
    RouterGuards(router);
}
catch (e) {
    RouterGuards(router);
}



const store = createStore({
    state () {
        return {
            userInfo: null
        }
    },
    mutations: {
        initUserInfo(state,userInfo){//存储登录角色信息
            state.userInfo = userInfo;
        }
    },
    getters: {
        userInfo: state => {
            return state.userInfo;
        },
    }
});

app.use(router);
app.use(andt);
app.use(VueCookies);
app.use(scrollBoard);
app.use(store);
app.mount("#app");
app.config.unwrapInjectedRef = true;
export default app;


