<!--
*@description：根据字段配置值，后台查询返回
*@author:rzl
*@date:2021/5/24 13:45
-->
<template>
    <div v-if="viewModel">
        <div  >{{presentValue}}</div>
    </div>
    <div v-else>
        <a-select
                v-if="editable"
                show-search
                v-model:value="record[column.dataIndex]"
                placeholder="输入关键字查询"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @search="handleSearch"
                @change="columnTrigger"
        >
            <a-select-option v-for="option in selectOptions" :key="option.id">
                {{ option.name }}
            </a-select-option>
        </a-select>
        <div  v-else >{{presentValue}}</div>
    </div>
</template>

<script>
    import {computed} from "vue";
    import typeBase from "./typeBase";

    export default {
        name: "typeIdReplaceXmgd",
        mixins:[typeBase],
        computed:{
            presentValue(){
                return this.record[this.column.idReplaceObject.replaceSegment]?this.record[this.column.idReplaceObject.replaceSegment]:"无"
            },
            //能够选择的选项
            selectOptions(){
                return this.defaultOptions.concat(this.options)
            }
        },
        data(){
            return{
                //默认选项
                defaultOptions:[{
                        id:this.record[this.column.dataIndex],
                        name:this.record[this.column.idReplaceObject.replaceSegment]}],
                //查询到的选项
                options:[],
                queryObject:computed(()=>this.column.idReplaceObject.queryObject)
            }
        },
        methods:{
            handleSearch(key){
                if(key){
                    let options = {};
                    options[this.queryObject.tag] = key;
                    let item_options = this.queryObject.options;
                    if(item_options.length > 0){
                        for(let i=0;i<item_options.length;i++){
                          let op_name = item_options[i];
                          options[op_name] = this.record[op_name];
                        }
                    }

                    this.queryObject.api.post(this,options,res=>{
                        if(res.data.success){
                          let data = res.data.result;
                          let option_data = [];
                           if(data.length > 0){
                              for(let i=0;i<data.length;i++){
                                  let info = data[i];
                                  let parm = {
                                    id:info[this.queryObject.keyValue[0]],
                                    name:info[this.queryObject.keyValue[1]]
                                  };
                                  option_data.push(parm);
                              }
                           }
                            this.options = option_data;
                        }
                        else{
                            this.options =[];
                        }
                    },this)
                }
            }
        }
    }
</script>

<style scoped>

</style>