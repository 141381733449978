<!--
* @program: tyh-oa 
* @author: ly
* @component:DataPresent
* @description: 
* @create: 2021-03-26 11:57
-->
<template>
    <component style="min-height: 24px" :is="type"
               :viewModel="viewModel"
               :column="column"
               :record="record"
               :index="index"
               @click="handleClick"
               @trigger="handleTrigger"
               @blur="handleBlur"
               @update="handleUpdate" />

</template>

<script>
    import dataTypes from "./dataTypes";
    import { Form } from 'ant-design-vue';
    export default {
        name: "DataPresent",
        mixins:[dataTypes],
        emits:["update","trigger","click"],
        props:{
            column:{required: true},
            record:{required:false},
            viewModel:{
                required:false,
                default:true
            },
            index:{required:false}
        },
        data(){
            return{
                formItemContext : Form.useInjectFormItemContext()
            }
        },
        methods:{
            handleUpdate(){
                this.$emit("update");
                this.formItemContext.onFieldChange();
            },
            handleBlur(){
                this.$emit("update");
                this.formItemContext.onFieldBlur();
            },
            handleTrigger(action){
                this.$emit("update");
                this.formItemContext.onFieldBlur();
                this.$emit("trigger",action);
            },
            handleClick(){
                this.$emit("click")
            }
        }
    }
</script>

<style scoped>

</style>