/** 
* 自动生成文件，请勿修改 
* @name: 系统-行政区管理
* @description: Canton Controller
* @create: 2024/11/8 10:34:25
* @swagger: 2.0
* 合计:13条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const params = { 
	add : [{"id":{"type":"string","description":"主键id","required":false},"level":{"type":"string","description":"行政区级别：1-村，2-乡镇，3-区县，4-地级市，5-省级","required":true},"name":{"type":"string","description":"行政区名称","required":true},"pid":{"type":"string","description":"父级id","required":false},"remarks":{"type":"string","description":"备注","required":false},"version":{"type":"string","description":"数据版本号，显示图层的在cql中增加版本查询","required":true},"zoneCode":{"type":"string","description":"行政区代码","required":true}}], 
	del : null, 
	delBatch : null, 
	edit : [{"id":{"type":"string","description":"主键id","required":false},"level":{"type":"string","description":"行政区级别：1-村，2-乡镇，3-区县，4-地级市，5-省级","required":true},"name":{"type":"string","description":"行政区名称","required":true},"pid":{"type":"string","description":"父级id","required":false},"remarks":{"type":"string","description":"备注","required":false},"version":{"type":"string","description":"数据版本号，显示图层的在cql中增加版本查询","required":true},"zoneCode":{"type":"string","description":"行政区代码","required":true}}], 
	queryAll : [{"level":{"type":"string","description":"行政区级别：1-村，2-乡镇，3-区县，4-地级市，5-省级"},"name":{"type":"string","description":"行政区名称"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"pid":{"type":"string","description":"父级id"},"version":{"type":"string","description":"数据版本号，显示图层的在cql中增加版本查询"},"zoneCode":{"type":"string","description":"行政区代码"}}], 
	queryChildren : null, 
	queryIntersectTown : null, 
	queryIntersectVillages : null, 
	queryNext : null, 
	queryNextWithGeom : null, 
	queryOne : null, 
	queryPage : [{"level":{"type":"string","description":"行政区级别：1-村，2-乡镇，3-区县，4-地级市，5-省级"},"name":{"type":"string","description":"行政区名称"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"pid":{"type":"string","description":"父级id"},"version":{"type":"string","description":"数据版本号，显示图层的在cql中增加版本查询"},"zoneCode":{"type":"string","description":"行政区代码"}}], 
	querySimpleTree : null, 
};
const Canton = { 
	/** 1 summary:编辑	method:post */ 
	add : new APIObject.postObject("/sys/Canton/add",params.add), 
	/** 2 summary:删除（单条）	method:delete */ 
	del : new APIObject.deleteObject("/sys/Canton/del",params.del), 
	/** 3 summary:删除（批量）	method:delete */ 
	delBatch : new APIObject.deleteObject("/sys/Canton/delBatch",params.delBatch), 
	/** 4 summary:编辑	method:post */ 
	edit : new APIObject.postObject("/sys/Canton/edit",params.edit), 
	/** 5 summary:查询-不分页	method:post */ 
	queryAll : new APIObject.postObject("/sys/Canton/queryAll",params.queryAll), 
	/** 6 summary:根据行政区划id查询所有子级区划信息，树形结构	method:get */ 
	queryChildren : new APIObject.getObject("/sys/Canton/queryChildren",params.queryChildren), 
	/** 7 summary:查询-查询与指定wkt相交的乡镇	method:post */ 
	queryIntersectTown : new APIObject.postObject("重复的url",params.queryIntersectTown), 
	/** 8 summary:查询-查询与指定wkt相交的村级行政区	method:post */ 
	queryIntersectVillages : new APIObject.postObject("重复的url",params.queryIntersectVillages), 
	/** 9 summary:根据行政区划id查询下一级区划信息，只是下一级，不包含下一级下的下一级	method:get */ 
	queryNext : new APIObject.getObject("/sys/Canton/queryNext",params.queryNext), 
	/** 10 summary:根据行政区划id查询下一级区划信息(包含范围红线)，只是下一级，不包含下一级下的下一级	method:get */ 
	queryNextWithGeom : new APIObject.getObject("/sys/Canton/queryNextWithGeom",params.queryNextWithGeom), 
	/** 11 summary:查询-单条	method:get */ 
	queryOne : new APIObject.getObject("/sys/Canton/queryOne",params.queryOne), 
	/** 12 summary:查询-分页	method:post */ 
	queryPage : new APIObject.postObject("/sys/Canton/queryPage",params.queryPage), 
	/** 13 summary:根据行政区划ids查询树形结构	method:get */ 
	querySimpleTree : new APIObject.getObject("/sys/Canton/querySimpleTree",params.querySimpleTree), 
};
export default Canton;