<!--
* @program: 前台 
* @author: ly
* @component:MainMapManager 
* @description: 
* @create: 2022-12-21 09:29
-->
<template>
    <a-layout style="overflow: hidden" class="layout-main">
        <a-layout-header class="layout-header">
            <oaBanner>
                <div style="width: 100%;display: flex;align-items: center;">
                        <span class="head-title" v-for="item in menuLists" :key="item.id" @click="headClick(item)">
                            <span :class="item.enable?'menu-enable':'menu-disable'">{{item.name}}</span>
                        </span>
                    <HeadToolBar />
                </div>
            </oaBanner>
        </a-layout-header>
        <a-layout>
            <a-layout-sider v-if="subMenus.length>0" style="overflow: auto" v-model:collapsed="collapsed" :trigger="null" collapsible>
                <sub-group-menu :sub-menus="subMenus" @item="itemClick" />
            </a-layout-sider>
            <a-layout-content style="overflow:auto; background: #fff; min-height:500px">
                <div class="app-main-map" >
                    <MainMapVisual @mapMethods="setMap" ref="mainMap">
                        <template #content>
                            <router-view />
                        </template>
                    </MainMapVisual>
                </div>
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
    import MainPage from "./MainPage";
    import MainMapVisual from "@/page/Map/MainMapVisual";
    export default {
      name: "MainMapManager",
      mixins:[MainPage],
      components:{
        MainMapVisual
      }
    }
</script>

<style scoped>

</style>