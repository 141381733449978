/** 
* 自动生成文件，请勿修改 
* @name: 系统-用户登录
* @description: Login Controller
* @create: 2023/4/12 16:48:00
* @swagger: 2.0
* 合计:11条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const sysSets = { 
	/** 1 summary:用户注册接口(带部门-角色)
	description:用户注册接口(带部门-角色)
	method:post
	params:需要配合Column函数使用
	new Column("生日","birthday","string",true).setTableView(200),
	new Column("部门id","depId","string",true).setTableView(200).setRequired(),
	new Column("电子邮件","email","string",true).setTableView(200),
	new Column("手机号","phone","string",true).setTableView(200).setRequired(),
	new Column("真实姓名","realName","string",true).setTableView(200).setRequired(),
	new Column("角色id","roleId","string",true).setTableView(200).setRequired(),
	new Column("性别(1-男,2-女)","sex","integer",true).setTableView(200).setRequired(),
	new Column("短信验证码","smsCode","string",true).setTableView(200).setRequired(),
	*/ 
	appRegister : new APIObject.postObject("/sys/appRegister"), 
	/** 2 summary:密码找回（忘记密码的情况下使用）
	description:密码找回（忘记密码的情况下使用）
	method:post
	params:需要配合Column函数使用
	new Column("新密码","newPwd","string",true).setTableView(200),
	new Column("手机号","phone","string",true).setTableView(200),
	new Column("短信验证码","smsCode","string",true).setTableView(200),
	*/ 
	findPwd : new APIObject.postObject("/sys/findPwd"), 
	/** 3 summary:geoserver token验证
	description:geoserver token验证
	method:post,get
	params:需要配合Column函数使用 */ 
	geoServerTokenCheck : new APIObject.mixObject("/sys/geoServerTokenCheck"), 
	/** 4 summary:登录接口-账号密码登录
	description:登录接口-账号密码登录:密码输错3次后才需要验证码及key
	method:post
	params:需要配合Column函数使用
	new Column("账号","account","string",true).setTableView(200),
	new Column("验证码（图片验证码）-密码输错3次后才需要","captcha","string",true).setTableView(200),
	new Column("验证码key(获取图片验证码时传的随机码(13位时间戳))-密码输错3次后才需要","checkKey","string",true).setTableView(200),
	new Column("密码","password","string",true).setTableView(200),
	*/ 
	login : new APIObject.postObject("/sys/login"), 
	/** 5 summary:退出登录
	description:退出登录
	method:get
	params:
	*/ 
	logout : new APIObject.getObject("/sys/logout"), 
	/** 6 summary:获取图片验证码-登录时统一需要验证码
	description:接口描述：获取图片验证码-登录时需要，获取短信验证码前需要（包括注册、登录、找回密码等时），验证码有效期为1分钟
	method:get
	params:需要配合Column函数使用
	*/ 
	randomImage : new APIObject.getObject("/sys/randomImage"), 
	/** 7 summary:用户注册接口
	description:用户注册接口
	method:post
	params:需要配合Column函数使用
	new Column("生日","birthday","string",true).setTableView(200),
	new Column("电子邮件","email","string",true).setTableView(200),
	new Column("手机号","phone","string",true).setTableView(200),
	new Column("真实姓名","realName","string",true).setTableView(200),
	new Column("性别(1-男,2-女)","sex","integer",true).setTableView(200),
	new Column("短信验证码","smsCode","string",true).setTableView(200),
	*/ 
	register : new APIObject.postObject("/sys/register"), 
	/** 8 summary:发送短信验证码
	description:接口描述：发送短信验证码，注册、登录、重置密码等前获取验证码
	method:post
	params:需要配合Column函数使用
	new Column("验证码（图片验证码）","captcha","string",true).setTableView(200),
	new Column("验证码key(获取图片验证码时传的随机码(13位时间戳))","checkKey","string",true).setTableView(200),
	new Column("手机号","phone","string",true).setTableView(200),
	*/ 
	smsCode : new APIObject.postObject("/sys/smsCode"), 
	/** 9 summary:登录接口-短信验证码登录
	description:登录接口-短信验证码登录
	method:post
	params:需要配合Column函数使用
	new Column("账号","phone","string",true).setTableView(200),
	new Column("短信验证码","smsCode","string",true).setTableView(200),
	*/ 
	smsLogin : new APIObject.postObject("/sys/smsLogin"), 
	/** 10 summary:密码修改（在登录状态，且记得旧密码的情况下）
	description:密码修改（在登录状态，且记得旧密码的情况下）
	method:post
	params:需要配合Column函数使用
	new Column("新密码","newPwd","string",true).setTableView(200),
	new Column("重复新密码","newPwdReview","string",true).setTableView(200),
	new Column("原密码","oldPwd","string",true).setTableView(200),
	*/ 
	updatePwd : new APIObject.postObject("/sys/updatePwd"), 
	/** 11 summary:微信登陆接口
	description:undefined
	method:post
	params:需要配合Column函数使用
	new Column("code","code","string",true).setTableView(200),
	new Column("被加密的数据","encryptedData","string",true).setTableView(200),
	new Column("偏移量","iv","string",true).setTableView(200),
	*/ 
	wxLogin : new APIObject.postObject("/sys/wxLogin"), 
};
export default sysSets;