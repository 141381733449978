/** 
* 自动生成文件，请勿修改 
* @name: 红河州地灾-基站管理
* @description: Base Station Controller
* @create: 2024/11/8 10:34:25
* @swagger: 2.0
* 合计:10条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const params = { 
	add : [{"buildWay":{"type":"string","description":"建设方式，值如：改造"},"code":{"type":"string","description":"站址编码"},"countyId":{"type":"string","description":"区县行政区划id"},"geom":{"description":"位置：经纬度point(103.673798 24.033899)","$ref":"#/definitions/Geometry","originalRef":"Geometry"},"operator":{"type":"string","description":"运营商，值如：电信"},"operatorShare":{"type":"string","description":"共享运营商情况，值如：移动+电信"},"orderProp":{"type":"string","description":"订单属性，值如：新建"},"ownerProp":{"type":"string","description":"产权属性，值如：自建"},"ownerUnit":{"type":"string","description":"(原)产权单位，值如：电信"},"remarks":{"type":"string","description":"备注"},"stationName":{"type":"string","description":"站址名称，值如：弥勒对门山隧道入口"},"townId":{"type":"string","description":"乡镇行政区划id,预留，原数据中未标注，可以通过geom与乡镇界限相交得到"}}], 
	batchAdd : [{"list":{"type":"array","items":{"$ref":"#/definitions/基站-新增参数对象","originalRef":"基站-新增参数对象"}}}], 
	del : null, 
	delBatch : null, 
	edit : [{"buildWay":{"type":"string","description":"建设方式，值如：改造"},"code":{"type":"string","description":"站址编码"},"countyId":{"type":"string","description":"区县行政区划id"},"geom":{"description":"位置：经纬度point(103.673798 24.033899)","$ref":"#/definitions/Geometry","originalRef":"Geometry"},"id":{"type":"string","description":"主键id"},"operator":{"type":"string","description":"运营商，值如：电信"},"operatorShare":{"type":"string","description":"共享运营商情况，值如：移动+电信"},"orderProp":{"type":"string","description":"订单属性，值如：新建"},"ownerProp":{"type":"string","description":"产权属性，值如：自建"},"ownerUnit":{"type":"string","description":"(原)产权单位，值如：电信"},"remarks":{"type":"string","description":"备注"},"stationName":{"type":"string","description":"站址名称，值如：弥勒对门山隧道入口"},"townId":{"type":"string","description":"乡镇行政区划id,预留，原数据中未标注，可以通过geom与乡镇界限相交得到"}}], 
	queryAll : [{"buildWay":{"type":"string","description":"建设方式，值如：改造"},"code":{"type":"string","description":"站址编码"},"countyId":{"type":"string","description":"区县行政区划id"},"operator":{"type":"string","description":"运营商，值如：电信"},"operatorShare":{"type":"string","description":"共享运营商情况，值如：移动+电信"},"orderProp":{"type":"string","description":"订单属性，值如：新建"},"ownerProp":{"type":"string","description":"产权属性，值如：自建"},"ownerUnit":{"type":"string","description":"(原)产权单位，值如：电信"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"stationName":{"type":"string","description":"站址名称，值如：弥勒对门山隧道入口"},"townId":{"type":"string","description":"乡镇行政区划id,预留，原数据中未标注，可以通过geom与乡镇界限相交得到"},"wkt":{"description":"wkt范围（wkt格式）","$ref":"#/definitions/Geometry","originalRef":"Geometry"}}], 
	queryAllSimple : [{"buildWay":{"type":"string","description":"建设方式，值如：改造"},"code":{"type":"string","description":"站址编码"},"countyId":{"type":"string","description":"区县行政区划id"},"operator":{"type":"string","description":"运营商，值如：电信"},"operatorShare":{"type":"string","description":"共享运营商情况，值如：移动+电信"},"orderProp":{"type":"string","description":"订单属性，值如：新建"},"ownerProp":{"type":"string","description":"产权属性，值如：自建"},"ownerUnit":{"type":"string","description":"(原)产权单位，值如：电信"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"stationName":{"type":"string","description":"站址名称，值如：弥勒对门山隧道入口"},"townId":{"type":"string","description":"乡镇行政区划id,预留，原数据中未标注，可以通过geom与乡镇界限相交得到"},"wkt":{"description":"wkt范围（wkt格式）","$ref":"#/definitions/Geometry","originalRef":"Geometry"}}], 
	queryCount : [{"buildWay":{"type":"string","description":"建设方式，值如：改造"},"code":{"type":"string","description":"站址编码"},"countyId":{"type":"string","description":"区县行政区划id"},"operator":{"type":"string","description":"运营商，值如：电信"},"operatorShare":{"type":"string","description":"共享运营商情况，值如：移动+电信"},"orderProp":{"type":"string","description":"订单属性，值如：新建"},"ownerProp":{"type":"string","description":"产权属性，值如：自建"},"ownerUnit":{"type":"string","description":"(原)产权单位，值如：电信"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"stationName":{"type":"string","description":"站址名称，值如：弥勒对门山隧道入口"},"townId":{"type":"string","description":"乡镇行政区划id,预留，原数据中未标注，可以通过geom与乡镇界限相交得到"},"wkt":{"description":"wkt范围（wkt格式）","$ref":"#/definitions/Geometry","originalRef":"Geometry"}}], 
	queryOne : null, 
	queryPage : [{"buildWay":{"type":"string","description":"建设方式，值如：改造"},"code":{"type":"string","description":"站址编码"},"countyId":{"type":"string","description":"区县行政区划id"},"operator":{"type":"string","description":"运营商，值如：电信"},"operatorShare":{"type":"string","description":"共享运营商情况，值如：移动+电信"},"orderProp":{"type":"string","description":"订单属性，值如：新建"},"ownerProp":{"type":"string","description":"产权属性，值如：自建"},"ownerUnit":{"type":"string","description":"(原)产权单位，值如：电信"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"stationName":{"type":"string","description":"站址名称，值如：弥勒对门山隧道入口"},"townId":{"type":"string","description":"乡镇行政区划id,预留，原数据中未标注，可以通过geom与乡镇界限相交得到"},"wkt":{"description":"wkt范围（wkt格式）","$ref":"#/definitions/Geometry","originalRef":"Geometry"}}], 
};
const _default = { 
	/** 1 summary:新增	method:post */ 
	add : new APIObject.postObject("/baseStation/add",params.add), 
	/** 2 summary:新增(批量)	method:post */ 
	batchAdd : new APIObject.postObject("/baseStation/batchAdd",params.batchAdd), 
	/** 3 summary:删除(单条)	method:delete */ 
	del : new APIObject.deleteObject("/baseStation/del",params.del), 
	/** 4 summary:删除(批量)	method:delete */ 
	delBatch : new APIObject.deleteObject("/baseStation/delBatch",params.delBatch), 
	/** 5 summary:编辑	method:post */ 
	edit : new APIObject.postObject("/baseStation/edit",params.edit), 
	/** 6 summary:查询-不分页	method:post */ 
	queryAll : new APIObject.postObject("/baseStation/queryAll",params.queryAll), 
	/** 7 summary:查询-不分页(简单数据提升效率)	method:post */ 
	queryAllSimple : new APIObject.postObject("/baseStation/queryAllSimple",params.queryAllSimple), 
	/** 8 summary:查询-数据条数	method:post */ 
	queryCount : new APIObject.postObject("/baseStation/queryCount",params.queryCount), 
	/** 9 summary:查询-单条	method:get */ 
	queryOne : new APIObject.getObject("/baseStation/queryOne",params.queryOne), 
	/** 10 summary:查询-分页	method:post */ 
	queryPage : new APIObject.postObject("/baseStation/queryPage",params.queryPage), 
};
export default _default;