
<!--
* @program: tyh-oa 
* @author: ly
* @description: oaFooter
* @create: 2021-03-19 17:06
-->
<template>
    <div class="footer non-select">
        Copyright © {{$config.year}} {{$config.company}} &emsp; 版本：{{$config.version}}
    </div>
</template>

<script>
    export default {
        name: "oaFooter"
    }
</script>

<style scoped>

</style>