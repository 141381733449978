/** 
* 自动生成文件，请勿修改 
* @name: 系统-用户管理
* @description: User Controller
* @create: 2024/11/8 10:34:25
* @swagger: 2.0
* 合计:12条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const params = { 
	add : [{"account":{"type":"string","description":"登录账号，手机号","required":true},"avatar":{"type":"string","description":"头像url","required":false},"birthday":{"type":"string","description":"生日","required":false},"depId":{"type":"string","description":"部门id(新增/编辑用户时若该字段不为空，则直接将该用户绑定到该部门)","required":false},"email":{"type":"string","description":"电子邮件","required":false},"id":{"type":"string","description":"主键id","required":false},"phone":{"type":"string","description":"电话","required":true},"realName":{"type":"string","description":"真实姓名","required":true},"remarks":{"type":"string","description":"备注","required":false},"sex":{"type":"integer","format":"int32","description":"性别(0-默认未知,1-男,2-女)","required":true},"status":{"type":"integer","format":"int32","description":"状态(1-正常,2-冻结;3:注册待审核)","required":true},"workNo":{"type":"string","description":"工号，唯一","required":true}}], 
	del : null, 
	delBatch : null, 
	edit : [{"account":{"type":"string","description":"登录账号，手机号","required":true},"avatar":{"type":"string","description":"头像url","required":false},"birthday":{"type":"string","description":"生日","required":false},"depId":{"type":"string","description":"部门id(新增/编辑用户时若该字段不为空，则直接将该用户绑定到该部门)","required":false},"email":{"type":"string","description":"电子邮件","required":false},"id":{"type":"string","description":"主键id","required":false},"phone":{"type":"string","description":"电话","required":true},"realName":{"type":"string","description":"真实姓名","required":true},"remarks":{"type":"string","description":"备注","required":false},"sex":{"type":"integer","format":"int32","description":"性别(0-默认未知,1-男,2-女)","required":true},"status":{"type":"integer","format":"int32","description":"状态(1-正常,2-冻结;3:注册待审核)","required":true},"workNo":{"type":"string","description":"工号，唯一","required":true}}], 
	freeze : [{"ids":{"type":"string","description":"用户id,多个逗号分隔","required":true},"state":{"type":"integer","format":"int32","description":"状态：state:1:解冻；2：冻结;3:注册待审核","required":true}}], 
	importUser : null, 
	queryAll : [{"account":{"type":"string","description":"登录账号，手机号"},"email":{"type":"string","description":"电子邮件"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"phone":{"type":"string","description":"电话"},"realName":{"type":"string","description":"真实姓名"},"sex":{"type":"integer","format":"int32","description":"性别(0-默认未知,1-男,2-女)"},"status":{"type":"integer","format":"int32","description":"状态(1-正常,2-冻结;3:注册待审核)"},"workNo":{"type":"string","description":"工号，唯一"}}], 
	queryByDep : null, 
	queryByRole : null, 
	queryOne : null, 
	queryPage : [{"account":{"type":"string","description":"登录账号，手机号"},"email":{"type":"string","description":"电子邮件"},"pageNum":{"type":"integer","format":"int32","description":"第几页（分页查询就传，否则不需传）"},"pageSize":{"type":"integer","format":"int32","description":"每页条数（分页查询就传，否则不需传）"},"phone":{"type":"string","description":"电话"},"realName":{"type":"string","description":"真实姓名"},"sex":{"type":"integer","format":"int32","description":"性别(0-默认未知,1-男,2-女)"},"status":{"type":"integer","format":"int32","description":"状态(1-正常,2-冻结;3:注册待审核)"},"workNo":{"type":"string","description":"工号，唯一"}}], 
	resetPwd : null, 
};
const user = { 
	/** 1 summary:添加用户	method:post */ 
	add : new APIObject.postObject("/sys/user/add",params.add), 
	/** 2 summary:删除用户（单条）	method:delete */ 
	del : new APIObject.deleteObject("/sys/user/del",params.del), 
	/** 3 summary:删除用户（批量）	method:delete */ 
	delBatch : new APIObject.deleteObject("/sys/user/delBatch",params.delBatch), 
	/** 4 summary:编辑用户	method:post */ 
	edit : new APIObject.postObject("/sys/user/edit",params.edit), 
	/** 5 summary:解冻冻结用户	method:post */ 
	freeze : new APIObject.postObject("/sys/user/freeze",params.freeze), 
	/** 6 summary:导入-用户信息	method:post */ 
	importUser : new APIObject.postObject("/sys/user/importUser",params.importUser), 
	/** 7 summary:查询-不分页	method:post */ 
	queryAll : new APIObject.postObject("/sys/user/queryAll",params.queryAll), 
	/** 8 summary:查询-根据部门id得到该部门所有用户	method:post */ 
	queryByDep : new APIObject.postObject("/sys/user/queryByDep",params.queryByDep), 
	/** 9 summary:查询-根据角色id得到该角色所有用户	method:post */ 
	queryByRole : new APIObject.postObject("/sys/user/queryByRole",params.queryByRole), 
	/** 10 summary:查询-单条	method:get */ 
	queryOne : new APIObject.getObject("/sys/user/queryOne",params.queryOne), 
	/** 11 summary:查询-分页	method:post */ 
	queryPage : new APIObject.postObject("/sys/user/queryPage",params.queryPage), 
	/** 12 summary:密码重置（管理员后台重置）	method:post */ 
	resetPwd : new APIObject.postObject("/sys/user/resetPwd",params.resetPwd), 
};
export default user;