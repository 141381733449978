<!--
* @program: 前台 
* @author: ly
* @component:RegRoleSelect 
* @description: 
* @create: 2023-03-29 11:33
-->
<template>
    <a-select :fieldNames="fieldNames"
              v-model:value="value" style="width: 100%"
              :options="options"
              @change="changeHandler">
    </a-select>
</template>

<script>
    export default {
        name: "RegRoleSelect",
        props:["DepartsRoles","roleId"],
        emits:["update:roleId"],
        watch:{
            DepartsRoles(){
                this.init();
            }
        },
        data() {
            return {
                allRoles:[],//系统内所有角色
                value:undefined,
                fieldNames:{ label: "roleName", value: "id"},
                options:[]
            }
        },
        methods: {
            init() {
                this.options = [];
                this.value = this.roleId;
                if(this.DepartsRoles) {
                    let arr = this.DepartsRoles.split(",");
                    for(let it of arr){
                        let role = this.allRoles.find(item =>item.id === it);
                        if(role){
                            this.options.push(role);
                        }
                    }
                }
                else{
                    this.$message.error("该部门下没有允许注册的角色，无法注册");
                }
            },
            changeHandler(value){
                this.$emit("update:roleId",value);
            }
        },
        created() {
            this.allRoles = JSON.parse(localStorage.getItem("roles"));
            this.init()
        }
    }
</script>

<style scoped>

</style>