/**
 * 校验工具类
 * @description：
 * @author:rzl
 * @date:2022/4/1 10:06
 **/
const checkUtil = {
    /**
    * @Description: 验空:字符串
    * @params:
    * @return:  true:空；flase:非空
    * @Author: rzl
    * @Date: 2022/4/1
    */
    empty_str:function (str){
        if(str == null || str == '' || str == undefined){
            return true;
        }
        return false;
    },
    /**
    * @Description: 验空：数组
    * @params:
    * @return: true:非空；flase:空
    * @Author: rzl
    * @Date: 2022/4/1
    */
    empty_list:function (data){
        if(data != null && data.length > 0){
            return true;
        }
        return false;
    },
};
export default checkUtil;