/** 
* 自动生成文件，请勿修改 
* @name: 公共模块-几何通用模块
* @description: Geometry Common Controller
* @create: 2024/11/8 10:34:25
* @swagger: 2.0
* 合计:3条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const params = { 
	readCadGeometryAll : null, 
	readShpGeometryAll : null, 
	wktConvert : [{"id":{"type":"string","description":"主键id","required":false},"remarks":{"type":"string","description":"备注","required":false},"srid":{"type":"integer","format":"int32","description":"srid","required":true},"wkt":{"type":"string","description":"wkt","required":true}}], 
};
const geometryCommon = { 
	/** 1 summary:通用模块-读取上传的CAD图形（读取全部）	method:post */ 
	readCadGeometryAll : new APIObject.postObject("/sys/geometryCommon/readCadGeometryAll",params.readCadGeometryAll), 
	/** 2 summary:通用模块-读取上传的shp图形（读取全部）	method:post */ 
	readShpGeometryAll : new APIObject.postObject("/sys/geometryCommon/readShpGeometryAll",params.readShpGeometryAll), 
	/** 3 summary:通用模块-wkt转其他坐标系	method:post */ 
	wktConvert : new APIObject.postObject("/sys/geometryCommon/wktConvert",params.wktConvert), 
};
export default geometryCommon;