/**
 * 地图工具配置参数
 * @description：
 * @author:rzl
 * @date:2022/10/18 14:41
 **/
const mapToolConfig={
    /*** 地图工具 ***/
    list_img:[
        {
            id:'img_1',
            name:'长度测量',
            tip:'长度测量',
            type:'cd',
            selected:false,// 是否选中
            class:'imgClass',
            recovery:false,// 是否选中后复原
            img:require('@/page/Map/images/距离-默认.png'),// 默认图片
            enter_img:require('@/page/Map/images/距离-悬停.png'),// 鼠标移入
            leave_img:require('@/page/Map/images/距离-默认.png'),// 鼠标离开
            click_img:require('@/page/Map/images/距离-默认.png'),// 鼠标点击
        },
        {
            id:'img_2',
            name:'面积测量',
            tip:'面积测量',
            type:'mj',
            selected:false,// 是否选中
            class:'imgClass',
            recovery:false,// 是否选中后复原
            img:require('@/page/Map/images/面积-默认.png'),// 默认图片
            enter_img:require('@/page/Map/images/面积-悬停.png'),// 鼠标移入
            leave_img:require('@/page/Map/images/面积-默认.png'),// 鼠标离开
            click_img:require('@/page/Map/images/面积-默认.png'),// 鼠标点击
        },

        {
            id:'img_4',
            name:'清除',
            tip:'清除',
            type:'cleans',
            selected:false,// 是否选中
            class:'imgClass',
            recovery:false,// 是否选中后复原
            img:require('@/page/Map/images/分屏-默认.png'),// 默认图片
            enter_img:require('@/page/Map/images/分屏-悬停.png'),// 鼠标移入
            leave_img:require('@/page/Map/images/分屏-默认.png'),// 鼠标离开
            click_img:require('@/page/Map/images/分屏-默认.png'),// 鼠标点击
        },


    ],

};
export default mapToolConfig;
