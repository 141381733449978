<!--
* @description：关闭
* @author:rzl
* @date:2022/12/15 17:24
-->

<template>
  <a :title="title"><img  :src="img" ></a>
</template>

<script>
export default {
  name: "CloseOp",
  data(){
    return{
      img:require('@/assets/image/busines/关闭.png'),
      title:'关闭',
    }
  }
}
</script>

<style scoped>

</style>