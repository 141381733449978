<!--
* @program: office_automation2.0
* @author: ly
* @component:LTable
* @description:
* @create: 2022-10-31 10:33
-->
<template>
    <a-table
            size="small"
            bordered
            :columns="cols"
            :data-source="dataSource"
            :rowClassName = "rowClass"
            :rowKey="rowKey"
            :pagination="pagination"
            :loading="loading"
            :custom-row="customRow"
            @resizeColumn="handleResizeColumn"
            :scroll="{x:offsetWidth,y:offsetHeight}"
    >
        <template #headerCell="{ column }">
            <div class = "action-column">
                {{column.title}}
            </div>
        </template>
        <template #bodyCell="{index, record , column}">
            <template v-if="column.type === 'actions'">
                <slot name="action" :index="index" :record="record" :column="column"/>
            </template>
            <template v-else>
                <DataPresent @click="handleClick(index, record , column)" :index="index+1+passedNum" :column="column" :record="record" :view-model="record.viewModel !== false"/>
            </template>
        </template>

        <template v-if="download" #summary>
            <a-table-summary fixed>
                <a-table-summary-row>
                    <a-table-summary-cell style="text-align: center" :col-span="12">
                            <a-button @click="downLoadExcel" type="primary" danger>
                                下载为excel
                            </a-button>
                    </a-table-summary-cell>
                </a-table-summary-row>
            </a-table-summary>
        </template>
    </a-table>
</template>

<script>
    import DataPresent from "../dataPresentType/DataPresent";
    import ExportJsonExcel from "js-export-excel";
    import columnType from "../../assets/tables/column_type";
    import Column from "../../assets/tables/column";
    import dayjs from "dayjs"
    export default {
        name: "LTable",
        components:{
            DataPresent
        },
        emits:["click"],
        provide:{
            holder:"table",
        },
        props:{
            columns:{required:true}, //提供字段集
            dataSource:{required:true},//提供table的DataSource

            loading:{required:false},//table的loading状态
            showIndex:{
                required:false,
                default: true
            },
            pageSize:{
                required:false,
                default:15
            },
            showAll:{required:false},//是否显示全部字段 默认为false
            rowKey:{
                required:false,
                default:"id"
            }, //rowKey
            download:{required:false}, //是否显示下载为excel按钮
            title:{required:false} //是否显示title
        },
        computed:{
            cols(){
                let arr = [...this.columns];
                if(this.showIndex){
                    arr.unshift(
                        new Column("序号","_index",columnType.Index).setTableView(50)
                    );
                }
                arr[0].setFixed("left").setResizable(false);
                arr[arr.length-1].setFixed("right").setResizable(false);
                return arr.filter(column=>{return column.tableView});
            },
            passedNum(){
                return this.pageSize*(this.pagination.current-1);
            }
        },
        data() {
            return {
                currentIndex:null,
                pagination:{
                    current:1,
                    size:"small",
                    hideOnSinglePage: true,
                    defaultPageSize: this.pageSize,
                    showSizeChanger: false,
                    pageSizeOptions: ['10', '15', '20'],
                    onChange: (page) => {
                        this.currentIndex = null;
                        let data = { //结构与接口规定有关
                            pageNum: page,
                            pageSize: this.pageSize,
                        };
                        this.pagination.current = page;
                        this.$emit("pageChange",data);
                    }
                },
                offsetWidth:0,
                offsetHeight:0
            }
        },
        methods: {
          setPage(total,current){
            this.pagination.total = total;
            this.pagination.current = current?current:1;
          },
            setTotalSize(num){
                this.pagination.total = num;
            },
            setCurrentPage(num){
                this.pagination.current = num;
            },
            rowClass(record,index){
                if(index === this.currentIndex){
                    return "highlight"
                }
                else{
                    return ""
                }
            },
            customRow(record,index){
                let _this = this;
                return{
                    onClick(){
                        if(_this.currentIndex !== index){
                            _this.currentIndex = index;
                        }
                        else{
                            _this.currentIndex = -1;
                        }
                    },
                    onDblclick(){
                        _this.currentIndex = null;
                    }
                }
            },
            handleResizeColumn(w, col){
                col.width = w;
            },
            downLoadExcel(){
                let sheetFilter = [];
                let sheetHeader = [];
                let columnWidths = [];
                for(let i in this.cols){
                    sheetFilter.push(this.cols[i].dataIndex);
                    sheetHeader.push(this.cols[i].title);
                    columnWidths.push("8");
                }
                let sheetData = JSON.parse(JSON.stringify(this.dataSource));
                sheetData.forEach((item,index)=>{
                    item._index = index + 1;
                    for(let n in this.cols){
                        let {type,dataIndex,boolTags,enumTags,enums,idReplaceObject} = this.cols[n];
                        switch (type) {
                            case columnType.Date:{
                                item[dataIndex] = new Date(item[dataIndex]).toLocaleDateString();
                                break;
                            }
                            case columnType.Boolean :{
                                if(boolTags){
                                    item[dataIndex] = item[dataIndex]?boolTags[1]:boolTags[0];
                                }
                                else{
                                    item[dataIndex] = item[dataIndex]?"是":"否";
                                }
                                break;
                            }
                            case columnType.Enum:{
                                if(enumTags){
                                    let idx = enums.indexOf(item[dataIndex]);
                                    item[dataIndex] = enumTags[idx]?enumTags[idx]:"无";
                                }
                                break;
                            }
                            case columnType.IdReplace:{
                                item[dataIndex] = item[idReplaceObject.replaceSegment]?item[idReplaceObject.replaceSegment]:"无";
                                break;
                            }
                            case columnType.Month:{
                                item[dataIndex] =  dayjs(item[dataIndex]).format("YYYY-MM");
                                break;
                            }
                        }
                    }
                });
                let datas =[
                    {
                        sheetFilter:sheetFilter,
                        sheetHeader:sheetHeader,
                        columnWidths:columnWidths,
                        sheetName:"sheet",
                        sheetData:sheetData
                    }
                ];
                let option={};
                option.fileName = new Date().toLocaleDateString() ;//文件名
                option.datas=datas;
                new ExportJsonExcel(option).saveExcel();
            },
            handleClick(index,record,column){
              this.$emit("click",{index,record,column});
            },
            offSize(){
                if(this.$el){
                    this.offsetWidth =  this.$el.parentElement?.offsetWidth -50;
                    let childrenHeight = 0;
                    for(let child of this.$el.parentElement.children){
                        childrenHeight += child.offsetHeight;
                    }
                    if(this.dataSource.length > this.pageSize){
                        this.offsetHeight =  this.$el.parentElement?.offsetHeight - childrenHeight + this.$el.offsetHeight - 50;
                    }
                    else{
                        this.offsetHeight =  this.$el.parentElement?.offsetHeight - childrenHeight + this.$el.offsetHeight;
                    }
                }
                this.offsetWidth = this.offsetWidth>300?this.offsetWidth:300;
                this.offsetHeight = this.offsetHeight>300?this.offsetHeight:300;
            }
        },
        mounted() {
            setTimeout(()=>{
                this.offSize()
            },500)
        }
    }
</script>

<style scoped>

</style>
