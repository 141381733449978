<!--
* @program: 前台 
* @author: ly
* @component:GetSMSCode 
* @description: 
* @create: 2022-11-21 11:22
-->
<template>
    <div>
        <div style="display: flex;height: 36px;justify-content: center;">
            <a-button  type="link"  @click="sendCode">
                获取短信验证码
            </a-button>
        </div>
        <div style="display: flex;height: 36px">
            <a-input style="width: 50%;" v-model:value="captcha" placeholder="请输入图像验证码">
                <template #prefix><LockOutlined style="rgba(4, 201, 251, 0.8)" /></template>
            </a-input>
            <img @click="getCaptcha" style="width: 50%;" :src="image"/>
        </div>
    </div>
</template>

<script>
    import login from "../../assets/api/sysSets";
    import {LockOutlined} from "@ant-design/icons-vue"
    export default {
        name: "GetSMSCode",
        props:["phone"],
        components:{
            LockOutlined
        },
        data() {
            return {
                captcha:null,
                checkKey:null,
                image:null
            }
        },
        methods: {
            getCaptcha(){
                this.captcha = null;
                this.checkKey = new Date().valueOf();
                login.randomImage.get(this,{key:this.checkKey},res=>{
                    if(res.data.success){
                        this.image = res.data.result
                    }
                    else{
                        this.$message.error(res.data.message);
                    }
                })
            },
            sendCode(){
                if(!this.captcha){
                    this.$message.error("请输入验证码");
                    return
                }
                if(this.phone){
                    let data ={
                        "captcha": this.captcha,
                        "checkKey": this.checkKey,
                        "phone": this.phone
                    };
                    login.smsCode.post(this,data,res=>{
                        if(res.data.success){
                            this.$message.success(res.data.message)
                        }
                        else{
                            this.$message.error(res.data.message)
                        }
                    })
                }
                else{
                    this.$message.error("还未输入手机号")
                }
            }
        },
        created() {
            this.getCaptcha();
        }
    }
</script>

<style scoped>

</style>