<!--
* @program: TYH_office_automation 
* @author: ly
* @component:typeObjectReplace 
* @description: 
* @create: 2021-06-21 14:17
-->
<template>
    <div v-if="viewModel">
        <div >{{presentValue}}</div>
    </div>
    <div v-else>
        <a-select
                v-if="editable"
                show-search
                v-model:value="record[column.dataIndex]"
                placeholder="输入关键字查询"
                :default-active-first-option="false"
                :show-arrow="false"
                :filter-option="false"
                :not-found-content="null"
                @select="handleSelect"
                @search="handleSearch"
                @change="columnTrigger"
                @blur="blurTrigger"
        >
            <a-select-option v-for="option in selectOptions" :key="option.id">
                <a-tooltip :title="option.name">
                    <a-button style="padding: 0" type="text">
                        {{ option.name }}
                    </a-button>
                </a-tooltip>
            </a-select-option>
        </a-select>
        <div  v-else >{{presentValue}}</div>
    </div>
</template>

<script>
    import typeBase from "./typeBase";
    import {computed} from "vue";
    export default {
        name: "typeObjectReplace",
        mixins:[typeBase],
        computed:{
            presentValue(){
                let object = this.record[this.column.replaceObject.objectName];
                let seg = this.column.replaceObject.seg;
                return object?object[seg]:"无"
            },
            //能够选择的选项
            selectOptions(){
                return this.defaultOptions.concat(this.options)
            }
        },
        watch:{
            record(){
                let object = this.record[this.column.replaceObject.objectName];
                let seg = this.column.replaceObject.seg;
                if(object){
                    this.defaultOptions = [{id:this.record[this.column.dataIndex], name:object[seg]}]
                }
                else{
                    this.defaultOptions = []
                }
            }
        },
        data(){
            return{
                //默认选项
                defaultOptions:[],
                //查询到的选项
                options:[],
                queryObject:computed(()=>this.column.replaceObject.queryObject)
            }
        },
        methods:{
            handleSearch(key){
                if(key){
                    key = key.replace(/'/g, '');
                    let data={};
                    data[this.queryObject.tag] = key;
                    this.queryObject.api.requestPOSTBothParam(this,{urlParam:data,formParam:data},res=>{
                        if(res.data.success){
                            this.options = res.data.data;
                        }
                        else{
                            this.options =[];
                        }
                    })
                }
            },
            handleSelect(value, option){
                let object = {};
                object[this.column.replaceObject.seg] = option.name;
                this.record[this.column.replaceObject.objectName] = object;
            }
        },
        created() {
            let object = this.record[this.column.replaceObject.objectName];
            let seg = this.column.replaceObject.seg;
            if(object){
                this.defaultOptions = [{id:this.record[this.column.dataIndex], name:object[seg]}]
            }
            else{
                this.defaultOptions = []
            }
        }
    }
</script>

<style scoped>

</style>