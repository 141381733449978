/** 
* 自动生成文件，请勿修改 
* @name: 系统-图层角色关联
* @description: Layer Role Controller
* @create: 2024/11/8 10:34:25
* @swagger: 2.0
* 合计:7条
*/ 
 
import APIObject from "../utils/APIObject.js" 
const params = { 
	getLayerMapTree : null, 
	getLayerQueryTree : null, 
	getManageTree : null, 
	getRoleTree : null, 
	queryAllRelatedLayerIdByOwn : null, 
	queryAllRelatedLayerIdByRole : null, 
	save : null, 
};
const layerRole = { 
	/** 1 summary:查询-获取用户的地图图层管理树	method:post */ 
	getLayerMapTree : new APIObject.postObject("/sys/layerRole/getLayerMapTree",params.getLayerMapTree), 
	/** 2 summary:查询-获取用户的地图查询图层树	method:post */ 
	getLayerQueryTree : new APIObject.postObject("/sys/layerRole/getLayerQueryTree",params.getLayerQueryTree), 
	/** 3 summary:查询-查询角色图层关联管理树	method:post */ 
	getManageTree : new APIObject.postObject("/sys/layerRole/getManageTree",params.getManageTree), 
	/** 4 summary:查询-查询角色已关联的图层树	method:post */ 
	getRoleTree : new APIObject.postObject("/sys/layerRole/getRoleTree",params.getRoleTree), 
	/** 5 summary:查询-获取登录用户的所有图层id	method:post */ 
	queryAllRelatedLayerIdByOwn : new APIObject.postObject("/sys/layerRole/queryAllRelatedLayerIdByOwn",params.queryAllRelatedLayerIdByOwn), 
	/** 6 summary:查询-获取角色关联的所有图层id	method:post */ 
	queryAllRelatedLayerIdByRole : new APIObject.postObject("/sys/layerRole/queryAllRelatedLayerIdByRole",params.queryAllRelatedLayerIdByRole), 
	/** 7 summary:保存图层角色关联	method:post */ 
	save : new APIObject.postObject("/sys/layerRole/save",params.save), 
};
export default layerRole;